/** @format */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import Bottom2Function from "../../global/Bottom2Function";
import Notifications from "../../global/Notifications";
import Helper from "../../global/Helper";
function WelfareListsDetailsAdd(props) {
  const history = useHistory();
  const [airlineOption, setAirlineOption] = useState([
    <option key="al"></option>,
  ]);
  const [form, setState] = useState({
    ap_id: 0,
    wc_name: "",
    wc_tax_id: "",
    wc_house_no: "",
    wc_amphur_code: "",
    wc_province_code: "",
    wc_postcode: "",
    wc_country: "",
    wc_tel: "",
    wc_email: "",
    wc_building_name: "",
    wc_welfare_code: "",
    wc_moo: "",
    wc_road: "",
  });

  const [error, setError] = useState({
    wc_name: false,
    wc_tax_id: false,
    wc_house_no: false,
    wc_amphur_code: false,
    wc_province_code: false,
    wc_postcode: false,
    wc_country: false,
    wc_tel: false,
    wc_email: false,
    wc_building_name: false,
    wc_welfare_code: false,
    wc_moo: false,
    wc_road: false,
  });

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
    const fieldLists = [
      "wc_name",
      "wc_tax_id",
      "wc_house_no",
      "wc_amphur_code",
      "wc_province_code",
      "wc_postcode",
      "wc_country",
      "wc_tel",
      "wc_email",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
  };

  const insertData = () => {
    props.handlerIsLoading(true);

    if (
      form.wc_name &&
      form.wc_tax_id &&
      form.wc_house_no &&
      form.wc_amphur_code &&
      form.wc_province_code &&
      form.wc_postcode &&
      form.wc_country &&
      form.wc_tel &&
      form.wc_email
    ) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/welfarelists`;
      Notifications.NotiAsync(
        axios.post(apiUrl, {
          form: { ...form, u_id: Helper.GetUserData().u_id },
        }),

        (resp) => {
          history.push("/welfare-lists");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        wc_name: form.wc_name === "",
        wc_tax_id: form.wc_tax_id === "",
        wc_house_no: form.wc_house_no === "",
        wc_amphur_code: form.wc_amphur_code === "",
        wc_province_code: form.wc_province_code === "",
        wc_postcode: form.wc_postcode === "",
        wc_country: form.wc_country === "",
        wc_tel: form.wc_tel === "",
        wc_email: form.wc_email === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/airports?withCenter=1`)
      .then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={item.ap_id} value={item.ap_id}>
              {item.ap_name}
            </option>
          );
          setAirlineOption((airlineOption) => [...airlineOption, option]);
        });
      });
  }, []);

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>เพิ่มร้านค้าสวัสดิการ</div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">รายละเอียดร้านค้าสวัสดิการ</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S4">
                <FormControl fullWidth error={error.t_airline_id}>
                  <InputLabel shrink>ท่าอากาศยาน</InputLabel>
                  <NativeSelect
                    native="true"
                    onChange={updateField}
                    value={form.ap_id}
                    name="ap_id"
                  >
                    {airlineOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.wc_name}
                  fullWidth
                  label="ชื่อ"
                  name="wc_name"
                  value={form.wc_name ? form.wc_name : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.wc_tax_id}
                  fullWidth
                  label="เลขประจำตัวผู้เสียภาษี"
                  name="wc_tax_id"
                  value={form.wc_tax_id ? form.wc_tax_id : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.wc_house_no}
                  fullWidth
                  label="ที่อยู่"
                  name="wc_house_no"
                  value={form.wc_house_no ? form.wc_house_no : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.wc_road}
                  fullWidth
                  label="ถนน"
                  name="wc_road"
                  value={form.wc_road ? form.wc_road : ""}
                  onChange={updateField}
                />
              </div>
            </div>

            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.wc_building_name}
                  fullWidth
                  label="ชื่ออาคาร"
                  name="wc_building_name"
                  value={form.wc_building_name ? form.wc_building_name : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.wc_moo}
                  fullWidth
                  label="หมู่"
                  name="wc_moo"
                  value={form.wc_moo ? form.wc_moo : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.wc_tambon_code}
                  fullWidth
                  label="ตำบล/แขวง"
                  name="wc_tambon_code"
                  value={form.wc_tambon_code ? form.wc_tambon_code : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.wc_amphur_code}
                  fullWidth
                  label="อำเภอ/เขต"
                  name="wc_amphur_code"
                  value={form.wc_amphur_code ? form.wc_amphur_code : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.wc_province_code}
                  fullWidth
                  label="จังหวัด"
                  name="wc_province_code"
                  value={form.wc_province_code ? form.wc_province_code : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.wc_postcode}
                  fullWidth
                  label="รหัสไปรษณีย์"
                  name="wc_postcode"
                  value={form.wc_postcode ? form.wc_postcode : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.wc_country}
                  fullWidth
                  label="ประเทศ"
                  name="wc_country"
                  value={form.wc_country ? form.wc_country : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.wc_tel}
                  fullWidth
                  label="เบอร์โทรศัพท์"
                  name="wc_tel"
                  value={form.wc_tel ? form.wc_tel : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.wc_welfare_code}
                  fullWidth
                  label="รหัส"
                  name="wc_welfare_code"
                  value={form.wc_welfare_code ? form.wc_welfare_code : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.wc_email}
                  fullWidth
                  label="Email"
                  name="wc_email"
                  value={form.wc_email ? form.wc_email : ""}
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom2Function back="/airline" insertData={insertData} />
    </div>
  );
}

export default WelfareListsDetailsAdd;
