/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FormControl, NativeSelect } from "@material-ui/core";
import axios from "axios";
import Helper from "./Helper";

function FillterAirport(props) {
  const [form, setState] = useState({
    filter_airport: "",
  });
  const [airport, setAirport] = useState(
    [1, 2].includes(Helper.GetUserData().ul_id)
      ? [<option key="ap0" value="0"></option>]
      : []
  );

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const getAirport = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      let wait = new Promise((resolve, reject) => {
        res.data.forEach((i, index) => {
          if (
            (Helper.GetUserData().ap_id &&
              Helper.GetUserData().ap_id.toString()) === i.ap_id.toString() ||
            [1, 2].includes(Helper.GetUserData().ul_id)
          ) {
            setAirport((item) => [
              ...item,
              <option key={"ap" + i.ap_id} value={i.ap_id}>
                {i.ap_name}
              </option>,
            ]);
          }
          if (index === res.data.length - 1) resolve();
        });
      });
      wait.then(() => {
        const pagename = "fillter_airport_" + props.fillteringPage;
        let sesstion = sessionStorage.getItem(pagename);
        if ([1, 2].includes(Helper.GetUserData().ul_id)) {
          if (sesstion) {
            setState({ ...form, ...sesstion });
          } else {
            setState(form);
          }
        } else {
          setState({ ...form, filter_airport: Helper.GetUserData().ap_id });
        }
      });
    });
  };

  useEffect(() => {
    getAirport();
  }, []);

  useEffect(() => {
    if (
      ([1, 2].includes(Helper.GetUserData().ul_id) &&
        form.filter_airport === "") ||
      form.filter_airport !== ""
    )
      submitFilltering(form);
  }, [form.filter_airport]);

  const submitFilltering = (data) => {
    sessionStorage.setItem(
      "fillter_airport_" + props.fillteringPage,
      JSON.stringify(data)
    );
    props.handleFiltering(data);
  };
  return (
    <FormControl fullWidth>
      <NativeSelect
        native="true"
        name="filter_airport"
        onChange={updateField}
        value={form.filter_airport}
      >
        {airport}
      </NativeSelect>
    </FormControl>
  );
}

export default FillterAirport;
