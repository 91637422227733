/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Bottom2Function from "../../../global/Bottom2Function";
import Notifications from "../../../global/Notifications";
function RevenueCategoryAdd(props) {
  const history = useHistory();
  const [form, setState] = useState({
    rt_id: "",
    rc_name: "",
    rc_description: "",
  });
  const [category_code, setCategoryCode] = useState([]);
  const [error, setError] = useState({
    rt_id: false,
    rc_name: false,
    rc_description: false,
  });
  const [revenueType, setRevenueTypeState] = useState([]);

  const printValues = (e) => {
    e.preventDefault();
  };

  const updateField = (e) => {
    const fieldLists = ["rt_id", "rc_name", "rc_description"];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    props.handlerIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuetypes`)
      .then((resp) => {
        let isFirst = false;
        resp.data.forEach((item) => {
          if (!isFirst) {
            isFirst = true;
            setState({ rt_id: item.rt_id });
          }
          setRevenueTypeState((revenueType) => [
            ...revenueType,
            <option value={item.rt_id}>{item.rt_name}</option>,
          ]);
        });
        props.handlerIsLoading(false);
      });

    props.handlerIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorys/catelog`)
      .then((resp) => {
        resp.data.forEach((item) => {
          setCategoryCode((revenueType) => [
            ...revenueType,
            <option value={item.cgd_code + item.cgd_name}>
              {`${item.cgd_code} - ${item.cgd_name} - ${item.cgd_doa_description}`}
            </option>,
          ]);
        });
        props.handlerIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const InsertData = (e) => {
    props.handlerIsLoading(true);
    if (form.rt_id && form.rc_name && form.rc_description) {
      Notifications.NotiAsync(
        axios.post(`${process.env.REACT_APP_API_URL}/v1/revenuecategorys`, {
          form,
        }),

        (resp) => {
          history.push("/revenue-category");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        rt_id: !form.rt_id || form.rt_id === "0",
        rc_name: !form.rc_name || form.rc_name === "",
        rc_description: !form.rc_description || form.rc_description === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName">ข้อมูลประเภทรายได้หลัก</div>
      </div>
      <form onSubmit={printValues}>
        <div className="box-S4 flex-between">
          <div className="box-S4 mgBottom">
            <div className="bContentMain">
              <div className="bNameContent">ข้อมูลรายได้หลัก</div>

              <div className="bContent">
                <div className="bRow">
                  <FormControl fullWidth error={error.rt_id}>
                    <InputLabel>ประเภทรายได้</InputLabel>
                    <NativeSelect
                      native="true"
                      value={form.rt_id}
                      name="rt_id"
                      onChange={updateField}
                    >
                      {revenueType}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>

                <div className="bRow">
                  <TextField
                    error={error.rc_name}
                    fullWidth
                    label="ชื่อ"
                    name="rc_name"
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.rc_description}
                    fullWidth
                    label="รายละเอียด"
                    name="rc_description"
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.rc_period}
                    fullWidth
                    label="รอบการคิดค่าบริการ"
                    name="rc_period"
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.rc_credit}
                    fullWidth
                    label="ระยะเวลาการชำระเงิน"
                    name="rc_credit"
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.rc_mulct_rate}
                    fullWidth
                    label="ดอกเบี้ยค่าปรับเป็นร้อยละต่อปี"
                    name="rc_mulct_rate"
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.rc_inv_code}
                    fullWidth
                    label="รหัสสำหรับออกใบแจ้งหนี้"
                    name="rc_inv_code"
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <FormControl fullWidth>
                    <InputLabel shrink>
                      รหัสสำหรับส่ง Catalog Code ของกรมบัญชีกลาง
                    </InputLabel>
                    <NativeSelect
                      native="true"
                      name="rc_cgd_code"
                      onChange={updateField}
                    >
                      <option aria-label="None" value="" />
                      {category_code}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="bRow">
                  <TextField
                    error={error.welfare_code}
                    fullWidth
                    label="รหัสสวัสดิการ"
                    name="welfare_code"
                    onChange={updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Bottom2Function back="/revenue-category" insertData={InsertData} />
    </div>
  );
}

export default RevenueCategoryAdd;
