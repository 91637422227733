/** @format */

import { Button } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router";
// Components Page
import TableOtherIncome from "../../datatable/TableOtherIncome";
import FillterData3 from '../../global/FillterData3';

export class OtherIncome extends Component {
  constructor(props) {
    super(props);
    this.handleFiltering = this.handleFiltering.bind(this);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.tableRef = React.createRef();
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  handleFiltering = (v) => {
    this.tableRef.current.handleFiltering(v);
  };
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>รายได้ประเภทอื่น</div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                this.props.history.push("/other-income/add");
              }}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
        <FillterData3
          handleFiltering={this.handleFiltering.bind(this)}
          fillteringPage='OtherIncome'
        />
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการ</div>
            <div className="bContent">
              <TableOtherIncome
                ref={this.tableRef}
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OtherIncome);
