/** @format */

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { TextField } from "@material-ui/core";
import Bottom3Function from "../../global/Bottom3Function";
import Notifications from "../../global/Notifications";
import Helper from "../../global/Helper";
import SD from "../../global/SwalDialog";
function AirlineDetails(props) {
  const { id } = useParams();
  const user = Helper.GetUserData();
  const history = useHistory();
  const [timeout, handlerTimeout] = useState(false);
  const [form, setState] = useState({
    cn_no: "",
    cn_date: "",
    i_doc_no: "",
    al_name: "",
    i_revenue: "",
    i_total: "",
    cn_description: "",
    cn_total: "",
    cn_remark: "",
    u_id: user.u_id,
    cn_id: id,
  });

  const [error, setError] = useState({
    cn_no: false,
    cn_date: false,
    i_doc_no: false,
    al_name: false,
    i_revenue: false,
    i_total: false,
    cn_description: false,
    cn_total: false,
  });

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
    const fieldLists = [
      "cn_no",
      "cn_date",
      "i_doc_no",
      "al_name",
      "i_revenue",
      "i_total",
      "cn_description",
      "cn_total",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    if (e.target.name === "i_doc_no") {
      handlerTimeout(clearTimeout(timeout));
      handlerTimeout(
        setTimeout(() => {
          getInvoiceData(e.target.value);
        }, 1000)
      );
    }
  };
  const getInvoiceData = (v) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/invoice/detail/docs/${v}`;
    Notifications.NotiAsync(
      axios.get(apiUrl),
      (resp) => {
        setState({ ...form, ...resp.data });
      },
      (err) => {}
    );
  };

  const updateData = () => {
    props.handlerIsLoading(true);
    const temp = {};
    for (const property in error) {
      if (!form[property]) {
        temp[property] = true;
      }
    }
    if (Helper.IsObjectEmpty(temp)) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/reduce-dept`;
      Notifications.NotiAsync(
        axios.put(apiUrl, { form }),
        (resp) => {
          history.push("/reduce-dept");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError(temp);
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  const deleteData = () => {
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${form.cn_no} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        props.handlerIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}/v1/reduce-dept`;
        Notifications.NotiAsync(
          axios.delete(apiUrl, {
            data: {
              form,
            },
          }),
          (resp) => {
            history.push(`/reduce-dept/`);
            props.handlerIsLoading(false);
            Notifications.NotiSuccess();
          },
          (err) => {
            props.handlerIsLoading(false);
            Notifications.NotiError({ msg: err.response.data.msg });
          }
        );
      },
    });
  };

  const getData = () => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/reduce-dept/${id}`;
    Notifications.NotiAsync(
      axios.get(apiUrl),
      (resp) => {
        setState({ ...form, ...resp.data });
        props.handlerIsLoading(false);
        console.log(resp.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>ข้อมูลใบลดหนี้</div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">รายละเอียดใบลดหนี้</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.cn_no}
                  fullWidth
                  label="เลขใบลดหนี้"
                  name="cn_no"
                  value={form.cn_no}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.cn_date}
                  fullWidth
                  label="วันที่"
                  type="date"
                  name="cn_date"
                  value={Helper.CastDate(new Date(form.cn_date))}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.i_doc_no}
                  fullWidth
                  label="เลขใบแจ้งหนี้"
                  name="i_doc_no"
                  value={form.i_doc_no}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_name}
                  fullWidth
                  label="สายการบิน"
                  name="al_name"
                  value={form.al_name}
                  onChange={updateField}
                  disabled
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.i_revenue}
                  fullWidth
                  label="ประเภทรายได้"
                  name="i_revenue"
                  value={form.i_revenue}
                  onChange={updateField}
                  disabled
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.i_total}
                  fullWidth
                  label="จำนวนเงินรวม"
                  name="i_total"
                  value={Helper.CastCurrency(form.i_total)}
                  onChange={updateField}
                  disabled
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.cn_description}
                  fullWidth
                  label="รายการ"
                  name="cn_description"
                  value={form.cn_description}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.cn_total}
                  fullWidth
                  label="จำนวนเงิน"
                  name="cn_total"
                  type="number"
                  value={form.cn_total}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S4">
                <TextField
                  fullWidth
                  label="หมายเหตุ"
                  name="cn_remark"
                  value={form.cn_remark}
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom3Function
        back="/reduce-dept"
        deleteData={deleteData}
        updateData={updateData}
      />
    </div>
  );
}

export default AirlineDetails;
