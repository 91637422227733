/** @format */

import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import MaterialTable from "material-table";
import axios from "axios";
import Helper from "../global/Helper";
import TableFlightCheckInvoice from "./flight/TableFlightCheckInvoice";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Link,
  Button,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function StickyHeadTable(props) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const tableFilters = useRef(null);
  const handleClickOpen = (data, mode) => {
    tableFilters.current.handleFiltering({
      filter_airline: data.al_code,
      filter_year: data.txn_year,
      filter_month: data.txn_month,
      type_code: mode,
      filter_remain: true,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleFlights = (data) => {};
  useEffect(() => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/transports/remain/${
      Helper.GetUserData().ap_id
    }`;
    axios.get(apiUrl).then((res) => {
      setData(res.data);
      props.handlerIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title=""
            columns={[
              { title: "ปี", field: "txn_year", align: "center" },
              {
                title: "เดิอน",
                field: "txn_month_name",
                align: "center",
              },
              { title: "สายการบิน", field: "al_name", align: "center" },
              {
                title: "PSC",
                field: "psc",
                align: "center",
                filtering: false,
                render: (rowData) => {
                  return (
                    <Link
                      href="#"
                      onClick={() => handleClickOpen(rowData, "PSC")}
                    >
                      {rowData.psc}
                    </Link>
                  );
                },
              },
              {
                title: "APPS",
                field: "apps",
                align: "center",
                filtering: false,
                render: (rowData) => {
                  return (
                    <Link
                      href="#"
                      onClick={() => handleClickOpen(rowData, "APPS")}
                    >
                      {rowData.apps}
                    </Link>
                  );
                },
              },
              {
                title: "Landing",
                field: "landing",
                align: "center",
                filtering: false,
                render: (rowData) => {
                  return (
                    <Link
                      href="#"
                      onClick={() => handleClickOpen(rowData, "Landing")}
                    >
                      {rowData.landing}
                    </Link>
                  );
                },
              },
              {
                title: "Parking",
                field: "parking",
                align: "center",
                filtering: false,
                render: (rowData) => {
                  return (
                    <Link
                      href="#"
                      onClick={() => handleClickOpen(rowData, "Parking")}
                    >
                      {rowData.parking}
                    </Link>
                  );
                },
              },
            ]}
            data={data}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: true,
              filtering: true,
              actionsColumnIndex: -1,
              grouping: true,
              exportPdf: (columns, data) => {
                const temp = data.map((i) => [
                  i.txn_year,
                  i.txn_month_name,
                  i.al_name,
                  i.psc,
                  i.apps,
                  i.landing,
                  i.parking,
                ]);
                Helper.exportPDF(columns, temp);
              },
            }}
          />
        </TableContainer>
      </Paper>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <TableFlightCheckInvoice
            ref={tableFilters}
            handlerIsLoading={props.handlerIsLoading}
            handleFlights={handleFlights}
            goto="_transport-remain"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="styleBtn blue">
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
