/** @format */

import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { Button, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SD from "../../global/SwalDialog";
import Notifications from "../../global/Notifications";
import Helper from "../../global/Helper";

export default function StickyHeadTable(props) {
  const history = useHistory();
  const [airline, setAirline] = useState([]);
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = () => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/airlines`;
    axios.get(apiUrl).then((res) => {
      setAirline(res.data);
      props.handlerIsLoading(false);
    });
  };
  const deleteData = (data) => {
    const user = JSON.parse(localStorage.getItem("DataUser"));
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${data.al_name} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/airlines/${data.al_id}/${user.u_id}`,
            {}
          ),
          (resp) => {
            getData();
            Notifications.NotiSuccess();
            props.handlerIsLoading(false);
          },
          (err) => {
            Notifications.NotiError({ msg: err.response.data.msg });
            props.handlerIsLoading(false);
          }
        );
      },
    });
  };
  return (
    <Paper className="tabelData">
      <TableContainer>
        <MaterialTable
          title=""
          columns={[
            { title: "ชื่อสายการบิน", field: "al_name", align: "center" },
            { title: "สายการบิน", field: "al_code_name", align: "center" },
            {
              title: "เจ้าของ",
              field: "al_owner",
              align: "center",
            },
            { title: "ตัวแทน", field: "al_agent", align: "center" },
            { title: "ประเทศ", field: "al_country", align: "center" },
            {
              title: "",
              field: "al_id",
              render: (rowData) => {
                return (
                  <div>
                    <Tooltip title="แก้ไข" arrow>
                      <Button
                        onClick={() => {
                          history.push(`/airline/detail/${rowData.al_id}`);
                        }}
                        className="styleBtn blue w-auto"
                        variant="contained"
                        style={{ textTransform: "none", marginRight: "5px" }}
                        size="small"
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="ลบ" arrow>
                      <Button
                        onClick={() => {
                          deleteData(rowData);
                        }}
                        className="styleBtn red w-auto pl-5px"
                        variant="contained"
                        style={{ textTransform: "none", marginRight: "5px" }}
                        size="small"
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </Tooltip>
                  </div>
                );
              },
            },
          ]}
          data={airline}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            search: true,
            exportButton: true,
            filtering: false,
            actionsColumnIndex: -1,
            grouping: true,
            exportPdf: (columns, data) => {
              const temp = data.map((i) => [
                i.al_name,
                i.al_code_name,
                i.al_owner,
                i.al_agent,
                i.al_country,
              ]);
              Helper.exportPDF(
                ["ชื่อสายการบิน", "สายการบิน", "เจ้าของ", "ตัวแทน"],
                temp
              );
            },
          }}
        />
      </TableContainer>
    </Paper>
  );
}
