/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
  Button,
} from '@material-ui/core';
import axios from 'axios';
import Helper from './Helper';

function FillterData5(props) {
  const [form, setState] = useState({
    filter_date_start: Helper.CastDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    filter_date_end: Helper.CastDate(),
    filter_airport: Helper.GetUserData().ap_id,
  });
  const [airport, setAirport] = useState([]);
  const getAirport = () => {
    const user = Helper.GetUserData();
    if (![1, 2, 3].includes(user.ul_id)) {
      setAirport([]);
    }
    setAirport((item) => [
      ...item,
      <option key={'ap0'} value={0}>
        ทั้งหมด
      </option>,
    ]);
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      if (props.handleAirportList) {
        props.handleAirportList(res.data);
      }
      res.data.forEach((i) => {
        if (
          (Helper.GetUserData().ap_id &&
            Helper.GetUserData().ap_id.toString()) === i.ap_id.toString() ||
          [1, 2].includes(Helper.GetUserData().ul_id)
        ) {
          setAirport((item) => [
            ...item,
            <option key={'ap' + i.ap_id} value={i.ap_id}>
              {i.ap_name}
            </option>,
          ]);
        }
      });
    });
  };
  const getData = async () => {
    await getAirport();
    const pagename = 'fillter_data_5_' + props.fillteringPage;
    let sesstion = sessionStorage.getItem(pagename);
    if (sesstion) {
      sesstion = JSON.parse(sesstion);
      setState({ ...form, ...sesstion });
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const submitFilltering = (data) => {
    sessionStorage.setItem(
      'fillter_data_5_' + props.fillteringPage,
      JSON.stringify(data)
    );
    props.handleFiltering(data);
  };
  return (
    <div className='box-S4 flex-between mgBottom'>
      <div className='bContentMain'>
        <div className='bNameContent'>กรอง</div>
        <div className='bContent'>
          <div className='bRow flex-between'>
            <div className='box-S2 flex-between'>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label='วันและเวลา'
                type='date'
                name='filter_date_start'
                onChange={updateField}
                value={form.filter_date_start}
              />
              <div className='text-to'>ถึง</div>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label='วันและเวลา'
                type='date'
                name='filter_date_end'
                onChange={updateField}
                value={form.filter_date_end}
              />
            </div>
            <div className='box-S2'>
              <FormControl fullWidth>
                <InputLabel shrink>ท่าอากาศยาน</InputLabel>
                <NativeSelect
                  native='true'
                  name='filter_airport'
                  value={form.filter_airport}
                  onChange={updateField}
                >
                  {airport}
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className='bRow flex-between'>
            <div></div>
            <Button
              className='styleBtn blue'
              onClick={() => {
                submitFilltering(form);
              }}
            >
              Export
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillterData5;
