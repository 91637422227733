/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

function FillterData6(props) {
  const startYear = 2019;
  const years = Array.from(
    { length: new Date().getFullYear() - startYear },
    (x, i) => (
      <option key={"y" + i} value={i + 1 + startYear}>
        {i + 1 + startYear}
      </option>
    )
  ).reverse();
  const [form, setState] = useState({
    filter_month: "",
    filter_year: "",
  });
  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const pagename = "fillter_data_6_" + props.fillteringPage;
    let sesstion = sessionStorage.getItem(pagename);
    if (sesstion) {
      sesstion = JSON.parse(sesstion);
      setState({ ...form, ...sesstion });
      submitFilltering(sesstion);
    }
  }, []);

  const submitFilltering = (data) => {
    const obj = { ...data };
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    sessionStorage.setItem(
      "fillter_data_3_" + props.fillteringPage,
      JSON.stringify(obj)
    );
    props.handleFiltering(obj);
  };
  return (
    <div className="box-S4 flex-between mgBottom">
      <div className="bContentMain">
        <div className="bNameContent">กรองข้อมูล</div>
        <div className="bContent">
          <div className="bRow flex-between-start">
            <div className="box-S1" style={{ marginRight: 10 }}>
              <FormControl fullWidth>
                <InputLabel shrink>ปี</InputLabel>
                <NativeSelect
                  native="true"
                  name="filter_year"
                  onChange={updateField}
                >
                  <option key="y" value="">
                    ทั้งหมด
                  </option>
                  {years}
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
            <div className="box-S1">
              <FormControl fullWidth>
                <InputLabel shrink>เดือน</InputLabel>
                <NativeSelect
                  native="true"
                  name="filter_month"
                  onChange={updateField}
                >
                  <option key="m0" value="">
                    ทั้งหมด
                  </option>
                  <option key="m1" value="1">
                    มกราคม
                  </option>
                  <option key="m2" value="2">
                    กุมภาพันธ์
                  </option>
                  <option key="m3" value="3">
                    มีนาคม
                  </option>
                  <option key="m4" value="4">
                    เมษายน
                  </option>
                  <option key="m5" value="5">
                    พฤษภาคม
                  </option>
                  <option key="m6" value="6">
                    มิถุนายน
                  </option>
                  <option key="m7" value="7">
                    กรกฎาคม
                  </option>
                  <option key="m8" value="8">
                    สิงหาคม
                  </option>
                  <option key="m9" value="9">
                    กันยายน
                  </option>
                  <option key="m10" value="10">
                    ตุลาคม
                  </option>
                  <option key="m11" value="11">
                    พฤศจิกายน
                  </option>
                  <option key="m12" value="12">
                    ธันวาคม
                  </option>
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
            <div className="box-S1" style={{ marginLeft: 10 }}>
              <Button
                className="styleBtn blue"
                onClick={() => {
                  submitFilltering(form);
                }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillterData6;
