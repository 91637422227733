/** @format */

import React, { Component } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
  Button,
} from "@material-ui/core";
// Components Page
import BottomFunction from "../global/BottomFunction";

export class IncomeDetails extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>รายละเอียดรายรับ</div>
            <Button className="styleBtn pink" href="#">
              Export
            </Button>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="box-S1-3 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">ข้อมูลเส้นทางการบิน</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S4">
                    <FormControl fullWidth>
                      <InputLabel>สถานะ</InputLabel>
                      <NativeSelect native>
                        <option aria-label="None" value="" />
                        <option>List 1</option>
                        <option>List 1</option>
                        <option>List 1</option>
                      </NativeSelect>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-S1-3 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">ข้อมูลเส้นทางการบิน</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S4">
                    <TextField
                      fullWidth
                      label="ค่าใช้จ่าย(บาท)"
                      defaultValue="xxxxxxxxxx"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-S1-3 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">ข้อมูลเส้นทางการบิน</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S4">
                    <TextField
                      fullWidth
                      label="กำหนดชำระ"
                      defaultValue="xxxxxxxxxx"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายละเอียดสายการบิน</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    defaultValue="2020-01-01"
                    label="วันและเวลา"
                    type="date"
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="สายการบิน"
                    defaultValue="xxxxxxxxxx"
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เลขที่สัญชาติและทะเบียน"
                    defaultValue="xxxxxxxxxx"
                  />
                </div>
                <div className="box-S2">
                  <TextField fullWidth label="แบบ" defaultValue="xxxxxxxxxx" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomFunction />
      </div>
    );
  }
}

export default IncomeDetails;
