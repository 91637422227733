/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Bottom2Function from "../../global/Bottom2Function";
import Notifications from "../../global/Notifications";

function MasterItemAdd(props) {
  const history = useHistory();
  const [form, setState] = useState({
    mi_name: "",
    mi_description: "",
    rc_id: "0",
    rcs_id: "0",
    mi_price: "",
  });
  const [error, setError] = useState({
    mi_name: false,
    mi_description: false,
    rc_id: false,
    rcs_id: false,
    mi_price: false,
  });

  const [revenueCategory, setRevenueCategoryState] = useState([
    <option key="a0" value=""></option>,
  ]);
  const [revenueCategorySub, setRevenueCategorySubState] = useState([
    <option key="b0" value=""></option>,
  ]);
  const [revenueCategorySubTemp, setRevenueCategorySubTempState] = useState([]);
  const printValues = (e) => {
    e.preventDefault();
  };

  const updateField = (e) => {
    if (e.target.name === "rc_id") {
      const temp = revenueCategorySubTemp.filter((i) => {
        return i.rc_id.toString() === e.target.value;
      });
      setRevenueCategorySubState([<option key="b0" value=""></option>]);
      temp.forEach((item) => {
        setRevenueCategorySubState((revenueCategorySub) => [
          ...revenueCategorySub,
          <option key={"b" + item.rcs_id} value={item.rcs_id}>
            {item.rcs_name}
          </option>,
        ]);
      });
    }

    const fieldLists = [
      "mi_name",
      "mi_description",
      "rc_id",
      "rcs_id",
      "u_usename",
      "mi_price",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }

    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const InsertData = (e) => {
    props.handlerIsLoading(true);
    if (
      form.mi_name &&
      // form.mi_description &&
      form.rc_id &&
      form.rc_id !== "" &&
      form.rcs_id &&
      form.rcs_id !== "" &&
      form.mi_price
    ) {
      Notifications.NotiAsync(
        axios.post(`${process.env.REACT_APP_API_URL}/v1/masteritems`, { form }),

        (resp) => {
          history.push("/master-item");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        mi_name: !form.mi_name || form.mi_name === "",
        mi_description: !form.mi_description || form.mi_description === "",
        rc_id: !form.rc_id || form.rc_id === "0",
        rcs_id: !form.rcs_id || form.rcs_id === "0",
        mi_price: !form.mi_price || form.mi_price === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  useEffect(() => {
    props.handlerIsLoading(true);
    async function fetchMyData() {
      let isRevenueMain = false;
      let isRevenueSub = false;
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/revenuecategorys`;
      await axios.get(apiUrl).then((res) => {
        res.data.forEach((item) => {
          if (!isRevenueMain) {
            isRevenueMain = item.rc_id;
          }
          setRevenueCategoryState((revenueCategory) => [
            ...revenueCategory,
            <option key={"a" + item.rc_id} value={item.rc_id}>
              {item.rc_name}
            </option>,
          ]);
        });
      });

      await axios
        .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorysubs`)
        .then((res) => {
          res.data.forEach((item) => {
            if (!isRevenueSub) {
              isRevenueSub = item.rcs_id;
            }
            if (isRevenueMain === item.rc_id) {
              setRevenueCategorySubState((revenueCategorySub) => [
                ...revenueCategorySub,
                <option key={"b" + item.rcs_id} value={item.rcs_id}>
                  {item.rcs_name}
                </option>,
              ]);
            }
          });
          setRevenueCategorySubTempState(res.data);
        });
      setState({
        rc_id: isRevenueMain,
        rcs_id: isRevenueSub,
      });
    }
    fetchMyData();
    props.handlerIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName">ข้อมูลรายการหลัก</div>
      </div>
      <form onSubmit={printValues}>
        <div className="box-S4 flex-between">
          <div className="box-S4 mgBottom">
            <div className="bContentMain">
              <div className="bNameContent">ข้อมูลรายการหลัก</div>

              <div className="bContent">
                <div className="bRow">
                  <TextField
                    error={error.mi_name}
                    fullWidth
                    label="ชื่อรายการหลัก"
                    name="mi_name"
                    value={form.mi_name ? form.mi_name : ""}
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.mi_description}
                    fullWidth
                    label="รายละเอียด"
                    name="mi_description"
                    value={form.mi_description ? form.mi_description : ""}
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <FormControl fullWidth error={error.rc_id}>
                    <InputLabel>ประเภทรายได้หลัก</InputLabel>
                    <NativeSelect
                      native="true"
                      value={form.rc_id}
                      name="rc_id"
                      onChange={updateField}
                    >
                      {revenueCategory}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="bRow">
                  <FormControl fullWidth error={error.rcs_id}>
                    <InputLabel>ประเภทรายได้ย่อย</InputLabel>
                    <NativeSelect
                      native="true"
                      value={form.rcs_id}
                      name="rcs_id"
                      onChange={updateField}
                    >
                      {revenueCategorySub}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="bRow">
                  <TextField
                    error={error.mi_price}
                    fullWidth
                    label="ราคา"
                    type="number"
                    name="mi_price"
                    value={form.mi_price ? form.mi_price : ""}
                    onChange={updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Bottom2Function back="/master-item" insertData={InsertData} />
    </div>
  );
}

export default MasterItemAdd;
