/** @format */

import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { Button, Tooltip } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Description from "@material-ui/icons/Publish";
import SendIcon from "@material-ui/icons/Send";
import MaterialTable from "material-table";
import axios from "axios";
import fileDownload from "js-file-download";
import Notifications from "../global/Notifications";
import SD from "../global/SwalDialog";

import Helper from "../global/Helper";
import GetApp from "@material-ui/icons/GetApp";
import EmailIcon from "@material-ui/icons/Email";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
export class TableOtherIncome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upload_id: "",
      upload_name: "",
      upload_bill_id: "",
      upload_bill_name: "",
      otherIncome: [],
    };
    this.upLoadfile = null;
    this.upLoadbill = null;
  }

  handleFiltering = async (v) => {
    await this.setState({ q: Helper.jsonToQueryString(v) });
    await this.setState({ page: 0 });
    this.getData(0, this.state.rows, Helper.jsonToQueryString(v));
  };
  handleChangePage = async (e, page) => {
    await this.setState({ page: page });
    this.getData(page, this.state.rows, this.state.q);
  };

  handleChangeRowPerPage = async (row) => {
    await this.setState({ rows: row });
    await this.setState({ page: 0 });
    this.getData(0, row, this.state.q);
  };

  getData = (page = 0, rows = 10, q = "") => {
    this.props.handlerIsLoading(true);
    const startRow = page * rows;
    let queryString = `limit=${rows}&offset=${startRow}${q ? `&${q}` : ""}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/otherincomes?${queryString}&rt_id=2`;
    axios.get(apiUrl).then((res) => {
      this.setState({ otherIncome: res.data });
      this.props.handlerIsLoading(false);
    });
  };

  componentDidMount() {
    this.getData();
  }

  getFile = (mode, name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/otherincomes/${mode}/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name + ".pdf");
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  getFilePDF = (name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        // `/download/otherincome/invoice`,
        `${process.env.REACT_APP_API_URL}/v1/download/otherincomes/pdf/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name + ".pdf");
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  getStatus = (dt, i, withBox = true) => {
    const d = new Date(dt);
    const n = new Date();
    let cls = "";
    let text = "-";
    if (n > d && i !== "3") {
      text = "ค้างชำระ";
      cls = "cancel";
    } else {
      if (i === "3") {
        text = "ชำระแล้ว";
        cls = "complete";
      } else {
        text = "รอชำระ";
        cls = "pending";
      }
    }
    if (withBox) {
      return (
        <div className={`TagStatus ${cls}`} style={{ margin: "auto" }}>
          {text}
        </div>
      );
    } else {
      return text;
    }
  };

  cancelInvoice = (data) => {
    SD.Confirm({
      header: `ยืนยันการยกเลิกใบแจ้งหนี้ "${data.i_doc_no}"`,
      msg: `การยกเลิกจะเป็นการคืนสถานะเที่ยวบินทั้งหมดไปสู่สถานะยืนยันข้อมูล`,
      type: "warning",
      fnConfirm: () => {
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/invoice/delete-otherincome`,
            {
              data: {
                ...data,
              },
            }
          ),
          (resp) => {
            this.getData(0, this.state.rows, this.state.q);
            Notifications.NotiSuccess();
          },
          (err) => {
            Notifications.NotiError({ msg: "ไม่สามารถลบข้อมูลได้" });
          }
        );
      },
    });
  };

  sendFileUpload = (e) => {
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    var file = document.querySelector("#fileUpload");
    formData.append("file", file.files[0]);
    formData.append("id", this.state.upload_id);
    formData.append("name", this.state.upload_name);
    formData.append("uid", Helper.GetUserData().u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/otherincomes/uploadfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        document.querySelector("#fileUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
        this.getData(0, this.state.rows, this.state.q);
      },
      (err) => {
        document.querySelector("#fileUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  sendBillUpload = (e) => {
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    var file = document.querySelector("#billUpload");
    formData.append("file", file.files[0]);
    formData.append("id", this.state.upload_bill_id);
    formData.append("name", this.state.upload_bill_name);
    formData.append("uid", Helper.GetUserData().u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/otherincomes/uploadbill`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        document.querySelector("#billUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
        this.getData(0, this.state.rows, this.state.q);
      },
      (err) => {
        document.querySelector("#billUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  getFileBill = (name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/otherincomes/bill/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name);
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  sendMail = (id) => {
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    formData.append("id", id);
    formData.append("uid", Helper.GetUserData().u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/invoice/sendmail`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  resend = (id) => {
    SD.Confirm({
      header: `ยืนยันการส่งใหม่`,
      msg: `ระบบจะเปลี่ยนแปลงข้อมูลวันที่เอกสารและวันที่เริ่มชำระเป็นวันปัจจุบัน`,
      type: "warning",
      fnConfirm: () => {
        this.props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.post(`${process.env.REACT_APP_API_URL}/v1/invoice/resend`, {
            id: id,
            user: Helper.GetUserData().u_cgd_user,
            pass: Helper.GetUserData().u_cgd_pass,
            persontype: 1,
          }),
          async (resp) => {
            await this.getData();
            this.props.handlerIsLoading(false);
            Notifications.NotiSuccess();
          },
          (err) => {
            this.props.handlerIsLoading(false);
            Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
          }
        );
      },
    });
  };

  sendReceiptUpload = (e) => {
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    var file = document.querySelector("#receiptUpload");
    formData.append("file", file.files[0]);
    formData.append("id", this.state.upload_bill_id);
    formData.append("uid", Helper.GetUserData().u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/invoice/uploadreceipt`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        document.querySelector("#receiptUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
        this.getData();
      },
      (err) => {
        document.querySelector("#receiptUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };
  getFileReceipt = (name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/invoices/receipt/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name);
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };
  render() {
    return (
      <div>
        <input
          id="fileUpload"
          type="file"
          className="hidden"
          accept="application/pdf"
          ref={(input) => {
            this.upLoadfile = input;
          }}
          onChange={(e) => {
            e.preventDefault();
            this.sendFileUpload(e);
          }}
        />
        <input
          id="billUpload"
          type="file"
          className="hidden"
          accept="application/pdf"
          ref={(input) => {
            this.upLoadbill = input;
          }}
          onChange={(e) => {
            e.preventDefault();
            this.sendBillUpload(e);
          }}
        />
        <input
          id="receiptUpload"
          type="file"
          className="hidden"
          accept="application/pdf"
          ref={(input) => {
            this.upLoadReceipt = input;
          }}
          onChange={(e) => {
            e.preventDefault();
            this.sendReceiptUpload(e);
          }}
        />
        <Paper className="tabelData">
          <TableContainer>
            <MaterialTable
              title=""
              columns={[
                {
                  title: "วันที่ออก",
                  field: "i_due_date",
                  align: "center",
                  render: (rowData) => {
                    return Helper.CastDate(new Date(rowData.i_doc_date));
                  },
                },
                {
                  title: "เลขที่ใบแจ้งหนี้",
                  field: "i_doc_no",
                  align: "center",
                },
                {
                  title: "ท่าอากาศยาน",
                  field: "ap_name",
                  align: "center",
                  hidden: ![2, 1].includes(Helper.GetUserData().ul_id),
                },
                { title: "Ref 1", field: "i_ref1", align: "center" },
                { title: "Ref 2", field: "i_ref2", align: "center" },
                {
                  title: "จำนวนเงิน",
                  field: "i_total",
                  align: "center",
                  render: (rowData) => {
                    return Helper.convertToCurrency(rowData.i_total);
                  },
                },
                {
                  title: "กำหนดชำระ",
                  field: "i_due_date",
                  align: "center",
                  render: (rowData) => {
                    return Helper.CastDate(new Date(rowData.i_due_date));
                  },
                },
                {
                  title: "ประเภทรายการ",
                  field: "rc_name",
                  align: "center",
                },
                {
                  title: "ชื่อผู้ชำระเงิน",
                  field: "apa_name",
                  align: "center",
                },
                {
                  title: "CGD Status",
                  field: "cgdstatus",
                  align: "center",
                  render: (rowData) => {
                    return (
                      <p style={{ whiteSpace: "nowrap" }}>
                        {rowData.cgdstatus}
                      </p>
                    );
                  },
                },
                {
                  title: "สถานะ",
                  field: "status",
                  align: "center",
                  render: (rowData) => {
                    return Helper.getStatusInvoice(
                      rowData.i_due_date,
                      rowData.i_paid,
                      rowData.i_active
                    );
                  },
                },
                {
                  title: "",
                  field: "i_id",
                  align: "center",
                  render: (rowData) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <Tooltip title="ดู" arrow>
                          <Button
                            className="w-auto styleBtn blue"
                            href={`/other-income/detail/${encodeURI(
                              rowData.i_id
                            )}`}
                            variant="contained"
                            style={{
                              textTransform: "none",
                              marginRight: "5px",
                            }}
                            size="small"
                          >
                            <AssignmentIcon />
                          </Button>
                        </Tooltip>

                        <Tooltip title="Resend" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_paid === "1" ||
                                rowData.i_cgd === "1" ||
                                rowData.i_active === "1" ||
                                Helper.isOverDue(rowData)
                                  ? ""
                                  : "orangedark"
                              }`}
                              variant="contained"
                              disabled={
                                rowData.i_paid === "1" ||
                                rowData.i_cgd === "1" ||
                                rowData.i_active === "1" ||
                                Helper.isOverDue(rowData)
                              }
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              size="small"
                              onClick={() => {
                                this.resend(rowData.i_id);
                              }}
                            >
                              <SendIcon />
                            </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="ยกเลิก" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_paid === "1" ||
                                rowData.i_active === "1" ||
                                Helper.isOverDue(rowData)
                                  ? ""
                                  : "red"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                rowData.i_paid === "1" ||
                                rowData.i_active === "1"
                              }
                              size="small"
                              onClick={() => {
                                this.cancelInvoice(rowData);
                              }}
                            >
                              <CancelPresentationIcon />
                            </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="สร้างใหม่" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_paid === "1" ||
                                rowData.i_active === "1"
                                  ? ""
                                  : "green"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                rowData.i_paid === "1" ||
                                rowData.i_active === "1"
                              }
                              size="small"
                              href={`/other-income/add/${rowData.i_id}`}
                            >
                              <AutorenewIcon />
                            </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="Upload Bill Payment" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                /*
                                rowData.i_paid === "1" ||
                                rowData.i_active === "1" ||
                                Helper.isOverDue(rowData)
                                  ? ""
                                  : "yellow"
                                */
                                "yellow"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                false
                                /*
                                rowData.i_paid === "1" ||
                                rowData.i_active === "1" ||
                                Helper.isOverDue(rowData)
                                */
                              }
                              size="small"
                              onClick={() => {
                                SD.Input({
                                  msg: "เลขที่เอกสาร",
                                  value: rowData.upload_bill_name,
                                  btn: "Upload",
                                  fnConfirm: (value) => {
                                    if (value.status) {
                                      if (value.value) {
                                        this.upLoadbill.click();
                                      } else {
                                        Notifications.NotiWarning({
                                          msg: "กรุณากรอกเลขที่เอกสาร",
                                        });
                                      }
                                    }
                                    rowData.upload_bill_name = value.value;
                                    this.setState({
                                      upload_bill_id: rowData.i_id,
                                      upload_bill_name: value.value,
                                    });
                                  },
                                });
                              }}
                            >
                              <Description />
                            </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="Download Bill Payment" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                (rowData.i_active === "1" ||
                                  !rowData.i_upload_bill ||
                                  Helper.isOverDue(rowData)) &&
                                rowData.i_paid !== "1"
                                  ? ""
                                  : "lightpurple"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                (rowData.i_active === "1" ||
                                  !rowData.i_upload_bill ||
                                  Helper.isOverDue(rowData)) &&
                                rowData.i_paid !== "1"
                              }
                              size="small"
                              onClick={() => {
                                this.getFileBill(rowData.i_upload_bill);
                              }}
                            >
                              <GetApp />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="อัพโหลดใบเสร็จรับเงิน" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn receipt ${
                                ""
                                /*
                                  rowData.i_paid === "1" ||
                                  rowData.i_active === "1" ||
                                  Helper.isOverDue(rowData)
                                  ? ""
                                  : "receipt"
                                */
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                false
                                // rowData.i_paid === "1" ||
                                // rowData.i_active === "1" ||
                                // Helper.isOverDue(rowData)
                              }
                              size="small"
                              onClick={() => {
                                this.setState({
                                  upload_bill_id: rowData.i_id,
                                });
                                this.upLoadReceipt.click();
                              }}
                            >
                              <Description />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="ดาวน์โหลดใบเสร็จรับเงิน" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                //(
                                // rowData.i_active === "1" ||
                                !rowData.i_upload_receipt
                                  ? // || Helper.isOverDue(rowData)) &&
                                    // rowData.i_paid !== "1"
                                    ""
                                  : "receipt-download"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                //(rowData.i_active === "1" ||
                                !rowData.i_upload_receipt
                                //|| Helper.isOverDue(rowData)) &&
                                //rowData.i_paid !== "1"
                              }
                              size="small"
                              onClick={() => {
                                this.getFileReceipt(rowData.i_upload_receipt);
                              }}
                            >
                              <GetApp />
                            </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="Email" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_paid === "1" ||
                                !rowData.i_upload_bill ||
                                rowData.i_active === "1" ||
                                Helper.isOverDue(rowData)
                                  ? ""
                                  : "yellowdark"
                              }`}
                              variant="contained"
                              disabled={
                                rowData.i_paid === "1" ||
                                rowData.i_active === "1" ||
                                !rowData.i_upload_bill ||
                                Helper.isOverDue(rowData)
                              }
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              size="small"
                              onClick={() => {
                                this.sendMail(rowData.i_id);
                              }}
                            >
                              <EmailIcon />
                            </Button>
                          </span>
                        </Tooltip>
                      </div>
                    );
                  },
                },
              ]}
              data={this.state.otherIncome}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50, 100],
                search: true,
                exportButton: true,
                filtering: false,
                actionsColumnIndex: -1,
                grouping: true,
                exportPdf: (columns, data) => {
                  const temp = data.map((i) => [
                    i.i_doc_date,
                    i.i_doc_no,
                    i.icr_refno1,
                    i.icr_refno2,
                    i.i_due_date,
                    i.rc_name,
                    i.apa_name,
                    this.getStatus(i.i_due_date, i.i_paid, false),
                  ]);

                  Helper.exportPDF(
                    [
                      "วันที่ออก",
                      "เลขที่ใบแจ้งหนี้",
                      "Ref 1",
                      "Ref 2",
                      "กำหนดชำระ",
                      "ประเภทรายการ",
                      "ชื่อผู้ชำระเงิน",
                      "สถานะ",
                    ],
                    temp
                  );
                },
              }}
            />
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default TableOtherIncome;
