import React, { useState, useEffect } from "react";
import Bottom2Function from "../../../global/Bottom2Function";
import Notifications from "../../../global/Notifications";
import axios from "axios";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

function RevenueCategorySubAdd(props) {
  const history = useHistory();
  const [form, setState] = useState({
    rc_id: 0,
    rcs_name: "",
    rcs_description: "",
    rcs_public_revenue: "",
    rcs_fund_revenue: "",
    rcs_welfare_revenue: "",
  });
  const [error, setError] = useState({
    rc_id: false,
    rcs_name: false,
    rcs_description: false,
    rcs_public_revenue: false,
    rcs_fund_revenue: false,
    rcs_welfare_revenue: false,
  });
  const [revenueType, setRevenueTypeState] = useState([]);
  const printValues = (e) => {
    e.preventDefault();
  };

  const updateField = (e) => {
    const fieldLists = [
      "rc_id",
      "rcs_name",
      "rcs_description",
      "rcs_description",
      "rcs_public_revenue",
      "rcs_fund_revenue",
      "rcs_welfare_revenue",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/revenuecategorys`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((repos) => {
        let isFirst = false;
        repos.forEach((item) => {
          if (!isFirst) {
            isFirst = true;
            setState({ rc_id: item.rc_id });
          }
          setRevenueTypeState((revenueType) => [
            ...revenueType,
            <option key={item.rc_id} value={item.rc_id}>
              {item.rc_name}
            </option>,
          ]);
        });
        props.handlerIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const InsertData = (e) => {
    props.handlerIsLoading(true);
    if (
      form.rc_id &&
      form.rcs_name &&
      // form.rcs_description &&
      form.rcs_public_revenue &&
      form.rcs_fund_revenue &&
      form.rcs_welfare_revenue
    ) {
      Notifications.NotiAsync(
        axios.post(`${process.env.REACT_APP_API_URL}/v1/revenuecategorysubs`, {
          form,
        }),

        (resp) => {
          history.push("/revenue-category-sub");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        rc_id: form.rc_id === "0",
        rcs_name: !form.rcs_name || form.rcs_name === "",
        rcs_description: !form.rcs_description || form.rcs_description === "",
        rcs_public_revenue: !form.rcs_public_revenue || !form.rcs_public_revenue === "",
        rcs_fund_revenue: !form.rcs_fund_revenue || !form.rcs_fund_revenue === "",
        rcs_welfare_revenue: !form.rcs_welfare_revenue || !form.rcs_welfare_revenue === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName">ข้อมูลประเภทรายได้ย่อย</div>
      </div>
      <form onSubmit={printValues}>
        <div className="box-S4 flex-between">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลรายได้ย่อย</div>
            <div className="bContent">
              <div className="bRow">
                <FormControl fullWidth error={error.rc_id}>
                  <InputLabel>ประเภทรายได้หลัก</InputLabel>
                  <NativeSelect
                    native="true"
                    value={form.rc_id}
                    name="rc_id"
                    onChange={updateField}
                  >
                    {revenueType}
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_name}
                  fullWidth
                  label="ชื่อ"
                  name="rcs_name"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_description}
                  fullWidth
                  label="รายละเอียด"
                  name="rcs_description"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_public_revenue}
                  fullWidth
                  type="number"
                  label="รายได้สาธารณะ"
                  name="rcs_public_revenue"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_fund_revenue}
                  fullWidth
                  type="number"
                  label="รายได้จากกองทุน"
                  name="rcs_fund_revenue"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_welfare_revenue}
                  fullWidth
                  type="number"
                  label="รายได้สวัสดิการ"
                  name="rcs_welfare_revenue"
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Bottom2Function back="/revenue-category-sub" insertData={InsertData} />
    </div>
  );
}

export default RevenueCategorySubAdd;
