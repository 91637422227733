/** @format */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { TextField } from "@material-ui/core";
import Bottom2Function from "../../global/Bottom2Function";
import Notifications from "../../global/Notifications";
import Helper from "../../global/Helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
function AirlineDetails(props) {
  const user = Helper.GetUserData();
  const history = useHistory();
  const [timeout, handlerTimeout] = useState(false);
  const [inputAutoCompleteValue, setInputValueAutoComplete] = useState("");
  const [form, setState] = useState({
    cn_no: "",
    cn_date: "",
    i_doc_no: "",
    al_name: "",
    i_revenue: "",
    i_total: "",
    cn_description: "",
    cn_total: "",
    cn_remark: "",
    u_id: user.u_id,
  });
  const [iLists, setLists] = useState([]);
  const [error, setError] = useState({
    cn_no: false,
    cn_date: false,
    i_doc_no: false,
    al_name: false,
    i_revenue: false,
    i_total: false,
    cn_description: false,
    cn_total: false,
  });

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
    const fieldLists = [
      "cn_no",
      "cn_date",
      "i_doc_no",
      "al_name",
      "i_revenue",
      "i_total",
      "cn_description",
      "cn_total",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    if (e.target.name === "i_doc_no") {
      handlerTimeout(clearTimeout(timeout));
      handlerTimeout(
        setTimeout(() => {
          getInvoiceData(e.target.value);
        }, 1000)
      );
    }
  };
  const getInvoiceData = (v) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/invoice/detail/docs/${v}`;
    Notifications.NotiAsync(
      axios.get(apiUrl),
      (resp) => {
        if (resp.data) {
          setState({ ...form, ...resp.data });
        } else {
          setState({ ...form, al_name: "", i_revenue: "", i_total: "" });
          Notifications.NotiError();
        }
      },
      (err) => {
        setState({ ...form, al_name: "", i_revenue: "", i_total: "" });
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };
  const insertData = () => {
    props.handlerIsLoading(true);
    const temp = {};
    for (const property in error) {
      if (!form[property]) {
        temp[property] = true;
      }
    }
    if (Helper.IsObjectEmpty(temp)) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/reduce-dept`;
      Notifications.NotiAsync(
        axios.post(apiUrl, { form }),
        (resp) => {
          history.push("/reduce-dept");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError(temp);
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/invoice?disabledLimit=1`;
    Notifications.NotiAsync(
      axios.get(apiUrl),
      (resp) => {
        props.handlerIsLoading(false);
        setLists(resp.data.data);
      },
      (err) => {
        props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
    setState({ ...form, cn_date: Helper.CastDate() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>เพิ่มข้อมูลใบลดหนี้</div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">รายละเอียดใบลดหนี้</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.cn_no}
                  fullWidth
                  label="เลขใบลดหนี้"
                  name="cn_no"
                  value={form.cn_no}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.cn_date}
                  fullWidth
                  label="วันที่"
                  type="date"
                  name="cn_date"
                  value={form.cn_date}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <Autocomplete
                  options={iLists}
                  freeSolo
                  disableClearable
                  clearOnBlur={false}
                  getOptionLabel={(option) =>
                    option.i_doc_no ? `${option.i_doc_no}` : ""
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      {...params}
                      error={error.i_doc_no}
                      label="เลขใบแจ้งหนี้"
                    />
                  )}
                  value={""}
                  onChange={(event, newValue) => {
                    let temp = "";
                    if (typeof newValue === "string") {
                      temp = newValue;
                    } else {
                      temp = newValue.i_doc_no;
                    }
                    updateField({
                      target: { name: "i_doc_no", value: temp },
                    });
                    setInputValueAutoComplete(temp);
                  }}
                  inputValue={inputAutoCompleteValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValueAutoComplete(newInputValue);
                  }}
                ></Autocomplete>
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_name}
                  fullWidth
                  label="สายการบิน"
                  name="al_name"
                  value={form.al_name}
                  onChange={updateField}
                  disabled
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.i_revenue}
                  fullWidth
                  label="ประเภทรายได้"
                  name="i_revenue"
                  value={form.i_revenue}
                  onChange={updateField}
                  disabled
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.i_total}
                  fullWidth
                  label="จำนวนเงินรวม"
                  name="i_total"
                  value={Helper.CastCurrency(form.i_total)}
                  onChange={updateField}
                  disabled
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.cn_description}
                  fullWidth
                  label="รายการ"
                  name="cn_description"
                  value={form.cn_description}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.cn_total}
                  fullWidth
                  label="จำนวนเงิน"
                  name="cn_total"
                  type="number"
                  value={form.cn_total}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S4">
                <TextField
                  fullWidth
                  label="หมายเหตุ"
                  name="cn_remark"
                  value={form.cn_remark}
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom2Function back="/reduce-dept" insertData={insertData} />
    </div>
  );
}

export default AirlineDetails;
