/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import axios from "axios";
import Helper from "./Helper";

function FillterData3(props) {
  const startYear = 2019;
  const years = Array.from({ length: (new Date()).getFullYear() - startYear }, (x, i) => <option key={"y" + i} value={i + 1 + startYear}>{i + 1 + startYear}</option>).reverse();
  const [form, setState] = useState({
    filter_date_start: "",
    filter_date_end: "",
    filter_tax: "",
    filter_airline: "",
    filter_status: [],
    filter_airport: Helper.GetUserData().ap_id,
    filter_month: "",
    filter_year: ""
  });
  const [airline, setAirline] = useState(
    [1, 2, 3].includes(Helper.GetUserData().ul_id)
      ? [<option key="l" value="">ทั้งหมด</option>]
      : []
  );
  const [airport, setAirport] = useState(
    [1, 2].includes(Helper.GetUserData().ul_id)
      ? [<option key="x" value="">ทั้งหมด</option>]
      : []
  );
  const [revenue, setRevenue] = useState();
  const updateField = (e) => {
    if (e.target.name === "rule") {
      const old = [...form.filter_status];
      const index = old.indexOf(e.target.value);
      if (index === -1) {
        old.push(e.target.value);
      } else {
        old.splice(index, 1);
      }
      setState({
        filter_status: old,
      });
    } else {
      setState({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getIncomeType = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorys/type/${props.fillteringPage === 'Invoice' ? 1 : 2}`)
      .then((res) => {
        let temp = [<option key='y' value=''>ทั้งหมด</option>];
        res.data.forEach((i) => {
          if (props.fillteringPage === 'Invoice') {
            const option = (
              <option key={`i${i.rc_id}`} value={i.rc_code}>
                {i.rc_name}
              </option>
            );
            if (i.rc_code && i.rc_show_type === 1) temp.push(option);
          } else {
            const option = (
              <option key={`i${i.rc_id}`} value={i.rc_id}>
                {i.rc_name}
              </option>
            );
            temp.push(option);
          }
        });
        setRevenue(temp);
      });
  };

  const getAirline = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airlines`).then((res) => {
      res.data.forEach((i) => {
        if (
          (Helper.GetUserData().al_id &&
            Helper.GetUserData().al_id.toString() === i.al_id.toString()) ||
          [1, 2, 3].includes(Helper.GetUserData().ul_id)
        ) {
          setAirline((airline) => [
            ...airline,
            <option key={'al' + i.al_id} value={i.al_name}>
              {i.al_name}
            </option>,
          ]);
        }
      });
    });
  };

  const getAirport = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      res.data.forEach((i) => {
        if (
          (Helper.GetUserData().ap_id &&
            Helper.GetUserData().ap_id.toString() === i.ap_id.toString()) ||
          [1, 2].includes(Helper.GetUserData().ul_id)
        ) {
          setAirport((airport) => [
            ...airport,
            <option key={'ap' + i.ap_id} value={i.ap_id}>
              {i.ap_name}
            </option>,
          ]);
        }
      });
    });
  };

  useEffect(() => {
    getAirline();
    getAirport();
    getIncomeType();
    const pagename = "fillter_data_3_" + props.fillteringPage;
    let sesstion = sessionStorage.getItem(pagename);
    if (sesstion) {
      sesstion = JSON.parse(sesstion);
      setState({ ...form, ...sesstion });
      submitFilltering(sesstion);
    }
  }, []);

  const submitFilltering = (data) => {
    const obj = { ...data } 
    for (var propName in obj) {
      if(propName === 'filter_status' && obj[propName].length === 0) {
        delete obj[propName];
      }
      console.log(propName, obj[propName])
      if(propName === 'filter_airport' && !obj[propName]) {
        delete obj[propName];
      }
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    sessionStorage.setItem(
      "fillter_data_3_" + props.fillteringPage,
      JSON.stringify(obj)
    );
    props.handleFiltering(obj);
  };
  return (
    <div className="box-S4 flex-between mgBottom">
      <div className="bContentMain">
        <div className="bNameContent">กรองข้อมูล</div>
        <div className="bContent">
          <div className="bRow flex-between">
            <div className="box-S2">
              <TextField
                fullWidth
                label="วันเริ่มต้น"
                type="date"
                name="filter_date_start"
                InputLabelProps={{ shrink: true }}
                onChange={updateField}
                value={form.filter_date_start}
              />
            </div>
            <div className="box-S2">
              <TextField
                fullWidth
                label="วันสิ้นสุด"
                type="date"
                name="filter_date_end"
                InputLabelProps={{ shrink: true }}
                onChange={updateField}
                value={form.filter_date_end}
              />
            </div>
          </div>
          <div className="bRow flex-between">
            <div className="box-S2">
              <TextField
                fullWidth
                label="เลขที่ใบแจ้งหนี่"
                name="filter_tax"
                onChange={updateField}
                value={form.filter_tax}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="box-S2">
              <FormControl style={{ display: "flex", flexDirection: "unset", alignItems: "end" }}>
                <InputLabel shrink>สถานะ</InputLabel>
                <FormControlLabel
                  key={'rule1'}
                  style={{ alignItems: "end" }}
                  control={
                    <Checkbox
                      style={{ paddingBottom: 0 }}
                      key={'rule-item1'}
                      color='primary'
                      name={'rule'}
                      value='0'
                      checked={form.filter_status.includes('0')}
                      onChange={updateField}
                    />
                  }
                  label={'ทั้งหมด'}
                />
                <FormControlLabel
                  key={'rule2'}
                  style={{ alignItems: "end" }}
                  control={
                    <Checkbox
                      style={{ paddingBottom: 0 }}
                      key={'rule-item2'}
                      color='primary'
                      name={'rule'}
                      value='3'
                      checked={form.filter_status.includes('3')}
                      onChange={updateField}
                    />
                  }
                  label={'ชำระแล้ว'}
                />
                <FormControlLabel
                  key={'rule3'}
                  style={{ alignItems: "end" }}
                  control={
                    <Checkbox
                      style={{ paddingBottom: 0 }}
                      key={'rule-item3'}
                      color='primary'
                      name={'rule'}
                      value='2'
                      checked={form.filter_status.includes('2')}
                      onChange={updateField}
                    />
                  }
                  label={'รอชำระ'}
                />
                <FormControlLabel
                  key={'rule4'}
                  style={{ alignItems: "end" }}
                  control={
                    <Checkbox
                      style={{ paddingBottom: 0 }}
                      key={'rule-item4'}
                      color='primary'
                      name={'rule'}
                      value='1'
                      checked={form.filter_status.includes('1')}
                      onChange={updateField}
                    />
                  }
                  label={'ค้างชำระ'}
                />
                <FormControlLabel
                  key={'rule6'}
                  style={{ alignItems: "end" }}
                  control={
                    <Checkbox
                      style={{ paddingBottom: 0 }}
                      key={'rule-item4'}
                      color='primary'
                      name={'rule'}
                      value='4'
                      checked={form.filter_status.includes('4')}
                      onChange={updateField}
                    />
                  }
                  label={'ยกเลิก'}
                />
                <FormControlLabel
                  key={'rule5'}
                  style={{ alignItems: "end" }}
                  control={
                    <Checkbox
                      style={{ pointer: 'none', visibility: 'hidden', width: 1, paddingLef: 0, paddingRight: 0 }}
                      key={'rule-item99'}
                      color='primary'
                      name={''}
                      value=''
                    />
                  }
                  label={''}
                />
              </FormControl>
            </div>
          </div>

          <div className="bRow flex-between">
            <div className="box-S2">
              <FormControl fullWidth>
                <InputLabel
                  shrink={true}
                >
                  ท่าอากาศยาน
                </InputLabel>
                <NativeSelect
                  native="true"
                  name="filter_airport"
                  onChange={updateField}
                  value={form.filter_airport}
                >
                  {airport}
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
            {props.fillteringPage === 'Invoice' ?
            <div className="box-S2">
              <FormControl fullWidth>
                <InputLabel
                  shrink={true}
                >
                  สายการบิน
                </InputLabel>
                <NativeSelect
                  native="true"
                  name="filter_airline"
                  onChange={updateField}
                  value={form.filter_airline}
                >
                  {airline}
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
              </div> : <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel
                    shrink={true}
                  >
                    ประเภทรายได้
                  </InputLabel>
                  <NativeSelect
                    native="true"
                    name="filter_revenue"
                    onChange={updateField}
                    value={form.filter_revenue}
                  >
                    {revenue}
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>}
          </div>
          {props.fillteringPage === 'Invoice' ?
          <div className="bRow flex-between">
            <div className="box-S2">
              <FormControl fullWidth>
                <InputLabel
                  shrink={true}
                >
                  ประเภทรายได้
                </InputLabel>
                <NativeSelect
                  native="true"
                  name="filter_revenue"
                  onChange={updateField}
                  value={form.filter_revenue}
                >
                  {revenue}
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
            <div className="box-S1">
              <FormControl fullWidth>
                <InputLabel shrink>ปีที่ดึงข้อมูลมาออกใบแจ้งหนี้</InputLabel>
                <NativeSelect
                  native='true'
                  name='filter_year'
                  onChange={updateField}
                >
                  <option key="y" value="">ทั้งหมด</option>
                  {years}
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
            <div className="box-S1">
              <FormControl fullWidth>
                <InputLabel shrink>เดือนที่ดึงข้อมูลมาออกใบแจ้งหนี้</InputLabel>
                <NativeSelect
                  native='true'
                  name='filter_month'
                  onChange={updateField}
                >
                  <option key="m0" value="">ทั้งหมด</option>
                  <option key="m1" value="1">มกราคม</option>
                  <option key="m2" value="2">กุมภาพันธ์</option>
                  <option key="m3" value="3">มีนาคม</option>
                  <option key="m4" value="4">เมษายน</option>
                  <option key="m5" value="5">พฤษภาคม</option>
                  <option key="m6" value="6">มิถุนายน</option>
                  <option key="m7" value="7">กรกฎาคม</option>
                  <option key="m8" value="8">สิงหาคม</option>
                  <option key="m9" value="9">กันยายน</option>
                  <option key="m10" value="10">ตุลาคม</option>
                  <option key="m11" value="11">พฤศจิกายน</option>
                  <option key="m12" value="12">ธันวาคม</option>
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
          </div>
          : null}
          <div className="bRow flex-between">
            <div></div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                submitFilltering(form);
              }}
            >
              ค้นหา
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillterData3;
