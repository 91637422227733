/** @format */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Bottom1Function from "../../../global/Bottom1Function";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  NativeSelect,
  FormHelperText,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import Helper from "../../../global/Helper";

function AirlineRecordSpecialDetails(props) {
  const { id } = useParams();
  const [formFlight, setState] = useState({
    //  Section 1
    t_airline_id: "",
    t_airline: "",
    al_owner: "",
    al_agent: "",
    t_arr_dep: "A",
    t_air_type_id: "",
    t_air_type: "",
    t_callsign: "",
    t_callsign_id: "",
    ac_weight: "",
    t_flight_no: "",
    t_airport_id: "0",
    t_airport: "",
    td_used: "0",
    //  Section 2
    t_year: "",
    t_month: "",
    t_day: "",
    t_trn_date: "01/01/2020",
    t_trn_yy: "",
    t_trn_mm: "",
    t_trn_dd: "",
    t_trn_time: "",
    t_trn_day: "",
    t_departure_airport_code: "",
    t_departure_airport_name: "",
    t_traff_type: "",
    t_traff_type_id: "",
    t_port_type: "",
    t_arrival_airport_code: "",
    t_arrival_airport_name: "",
    t_card_no: "",
    t_zone_no: "",
    t_region_no: "",
    t_captian: "",
    t_crew: "",
    t_remark: "",
    // Section 3
    t_passenger_dom: "",
    t_passenger_inter: "",
    t_passenger_infrant: "",
    t_passenger_transfer: "",
    t_passenger_transit: "",
  });
  const [airline, setAirline] = useState([]);
  const [airport, setAirport] = useState([]);
  const [airlineOption, setAirlineOption] = useState([
    <option key="al"></option>,
  ]);
  const [airportOption, setAirportOption] = useState([
    <option key="ap"></option>,
  ]);
  const [portType, setPort] = useState();
  const [portOption, setPortOption] = useState([<option key="p"></option>]);
  const [traffType, setTraff] = useState();
  const [traffOption, setTraffOption] = useState([<option key="t"></option>]);
  const [callsignLists, setCallSign] = useState([]);
  const setDate = (d) => {
    const dt = new Date(d);
    let year = dt.getFullYear().toString();
    let month = (dt.getMonth() + 1).toString();
    let day = dt.getDate().toString();
    if (month <= 9) month = "0" + month;
    if (day <= 9) day = "0" + day;
    if (year.length < 4) {
      for (let x = 0; x < 4 - year.length; x++) {
        year = "0" + year;
      }
    }
    const dayOfWeek = dt.getDay();
    setState({
      ...formFlight,
      t_year: year,
      t_month: Helper.Cast2Month(month),
      t_day: day,
      t_trn_yy: year,
      t_trn_mm: month,
      t_trn_dd: day,
      t_trn_day: dayOfWeek + 1,
      t_trn_date: [year, month, day].join("-"),
    });
  };
  const updateField = (e) => {
    switch (e.target.name) {
      case "t_airport_id":
        const ap = airport.find((i) => i.ap_id.toString() === e.target.value);
        setState({
          ...formFlight,
          [e.target.name]: e.target.value,
          t_airport: ap ? ap.ap_name : "",
        });
        break;
      case "t_port_type":
        const pt = portType.find((i) => i.pt_id.toString() === e.target.value);
        setState({
          ...formFlight,
          [e.target.name]: e.target.value,
          t_departure_airport_code: pt ? pt.pt_description : "",
        });
        break;
      case "t_traff_type_id":
        const tt = traffType.find((i) => i.tt_id.toString() === e.target.value);
        setState({
          ...formFlight,
          [e.target.name]: e.target.value,
          t_traff_type: tt ? tt.tt_code : "",
        });
        break;
      case "t_trn_time":
        setState({
          ...formFlight,
          [e.target.name]: e.target.value.replaceAll(":", ""),
        });
        break;
      case "t_trn_date":
        setDate(e.target.value);
        break;
      case "td_used":
        setState({
          ...formFlight,
          [e.target.name]: e.target.checked ? "1" : "0",
        });
        break;
      case "t_airline_id":
        getAircraft(e.target.value);
        const al = airline.find((i) => {
          return i.al_id.toString() === e.target.value;
        });
        setState({
          ...formFlight,
          [e.target.name]: e.target.value,
          t_airline: al && al.al_name ? al.al_name : "",
          al_owner: al && al.al_owner ? al.al_owner : "",
          al_agent: al && al.al_agent ? al.al_agent : "",
          t_air_type_id: "",
        });
        break;
      default:
        setState({
          ...formFlight,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };
  const getTraff = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/trafftype`)
      .then((res) => {
        res.data.forEach((item, index) => {
          if (item.tt_normal === "1") {
            const option = (
              <option key={`tt${item.tt_id}`} value={item.tt_id}>
                {`${item.tt_code} (${item.tt_description})`}
              </option>
            );
            setTraffOption((airlineOption) => [...airlineOption, option]);
          }
        });
        setTraff(res.data);
      });
  };
  const getPort = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/porttype`)
      .then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={`pt${item.pt_id}`} value={item.pt_code}>
              {`${item.pt_code} (${item.pt_description})`}
            </option>
          );
          setPortOption((portOption) => [...portOption, option]);
        });
        setPort(res.data);
      });
  };
  const getAircraft = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/aircrafts/al/${id}`)
      .then((res) => {
        setCallSign(res.data);
      });
  };
  const getAirport = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      res.data.forEach((item, index) => {
        const option = (
          <option key={`ap${item.ap_id}`} value={item.ap_stt_code}>
            {item.ap_code_name
              ? `${item.ap_code_name} (${item.ap_name})`
              : item.ap_name}
          </option>
        );
        setAirportOption((airportOption) => [...airportOption, option]);
      });
      setAirport(res.data);
    });
  };
  const getData = async () => {
    props.handlerIsLoading(true);
    await getAirport();
    await getTraff();
    await getPort();
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/airlines`)
      .then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={item.al_id} value={item.al_code_name}>
              {item.al_name}
            </option>
          );
          setAirlineOption((airlineOption) => [...airlineOption, option]);
        });
        setAirline(res.data);
      });
  };
  useEffect(() => {
    if (airline.length > 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/transports/ts/${id}`)
        .then((res) => {
          const find = airline.find((i) => {
            return i.al_code_name === res.data[0].t_airline;
          });
          if (find) {
            setState({
              ...formFlight,
              ...res.data[0],
              al_owner: find.al_owner,
              al_agent: find.al_agent,
            });
            getAircraft(find.al_id);
          } else {
            setState({
              ...formFlight,
              ...res.data[0],
            });
          }
          props.handlerIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airline]);

  useEffect(() => {
    if (callsignLists.length > 0) {
      const callsign = callsignLists.find(
        (i) =>
          i.ac_callsign.toString().toLowerCase() ===
          formFlight.t_callsign.toString().toLowerCase()
      );
      setState({
        ...formFlight,
        t_callsign_id: callsign.ac_id,
        t_callsign: callsign.ac_callsign,
        ac_weight: callsign.ac_weight,
        t_air_type_id: callsign.at_id,
        t_air_type: callsign.at_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callsignLists]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>ข้อมูลเที่ยวบิน</div>
        </div>
      </div>
      <div className="box-S4 mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">รายละเอียดสายการบิน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>สายการบิน</InputLabel>
                  <NativeSelect
                    disabled
                    native="true"
                    onChange={updateField}
                    value={formFlight.t_airline}
                    name="t_airline_id"
                  >
                    {airlineOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="เจ้าของ"
                  name="al_owner"
                  value={formFlight.al_owner}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="ตัวแทน"
                  name="al_agent"
                  value={formFlight.al_agent}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <div className="box-S4">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">การเดินทาง</FormLabel>
                    <RadioGroup
                      disabled
                      aria-label="t_arr_dep"
                      name="t_arr_dep"
                      value={formFlight.t_arr_dep}
                      onChange={updateField}
                      row
                      style={{ marginTop: "0px", paddingBottom: "0px" }}
                    >
                      <FormControlLabel
                        disabled
                        value="A"
                        control={<Radio />}
                        label="เข้า"
                      />
                      <FormControlLabel
                        disabled
                        value="D"
                        control={<Radio />}
                        label="ออก"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="เลขที่สัญชาติและทะเบียน"
                  name="t_callsign"
                  value={formFlight.t_callsign}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="แบบอากาศยาน"
                  name="t_air_type"
                  value={formFlight.t_air_type}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="น้ำหนัก"
                  value={formFlight.ac_weight}
                  name="ac_weight"
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="รหัสเที่ยวบิน"
                  value={formFlight.t_flight_no}
                  name="t_flight_no"
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>สนามบิน</InputLabel>
                  <NativeSelect
                    disabled
                    native="true"
                    onChange={updateField}
                    value={formFlight.t_airport_id}
                    name="t_airport_id"
                  >
                    {airportOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div className="box-S2 hidden">
                <FormControl component="fieldset">
                  <FormLabel component="legend"></FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      disabled
                      style={{ color: "#333333 !important" }}
                      name="td_used"
                      value={formFlight.td_used}
                      control={<Checkbox color="primary" />}
                      label="ยกเลิกเที่ยวบิน"
                      labelPlacement="end"
                      onChange={updateField}
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">ข้อมูลการบิน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  disabled
                  fullWidth
                  label="TRN Date"
                  name="t_trn_date"
                  type="date"
                  value={Helper.CastDate(new Date(formFlight.t_trn_date))}
                  InputLabelProps={{ shrink: true }}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  disabled
                  fullWidth
                  label="TRN Time"
                  name="t_trn_time"
                  type="time"
                  value={Helper.Cast2Time(formFlight.t_trn_time)}
                  InputLabelProps={{ shrink: true }}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  disabled
                  label="TRN Day"
                  name="t_trn_day"
                  value={Helper.Cast2Day(formFlight.t_trn_day)}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="รหัสต้นทาง"
                  name="t_departure_airport_code"
                  value={formFlight.t_departure_airport_code}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="รหัสปลายทาง"
                  name="t_departure_airport_name"
                  onChange={updateField}
                  value={formFlight.t_departure_airport_name}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>ประเภทการบิน</InputLabel>
                  <NativeSelect
                    disabled
                    native="true"
                    onChange={updateField}
                    value={formFlight.t_traff_type_id}
                    name="t_traff_type_id"
                  >
                    {traffOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>Port TYpe</InputLabel>
                  <NativeSelect
                    disabled
                    native="true"
                    onChange={updateField}
                    value={formFlight.t_port_type}
                    name="t_port_type"
                  >
                    {portOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="รหัสปลายทาง"
                  name="t_arrival_airport_code"
                  value={formFlight.t_arrival_airport_code}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="ชื่อปลายทาง"
                  value={formFlight.t_arrival_airport_name}
                  name="t_arrival_airport_name"
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="Card No."
                  name="t_card_no"
                  value={formFlight.t_card_no}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="Zone No."
                  name="t_zone_no"
                  value={formFlight.t_zone_no}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="Region"
                  name="t_region_no"
                  value={formFlight.t_region_no}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="นักบิน"
                  name="t_captian"
                  value={formFlight.td_captain}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="ลูกเรือ"
                  name="t_crew"
                  value={formFlight.td_crew}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S4">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="หมายเหตุ"
                  name="t_remark"
                  value={formFlight.t_remark}
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">จำนวนผู้โดยสาร</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="ผู้โดยสารในประเทศ"
                  name="t_passenger_dom"
                  value={formFlight.t_passenger_dom}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="ผู้โดยสารต่างประเทศ"
                  name="t_passenger_inter"
                  value={formFlight.t_passenger_inter}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="เด็กต่ำกว่า 2ปี"
                  name="t_infrant"
                  value={formFlight.t_infants}
                  onChange={updateField}
                />
              </div>
              {/**
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="ผู้โดยสารทุพพลภาพ"
                  name="t_passenger_"
                  onChange={updateField}
                />
              </div>
               */}
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="ผู้โดยสาร Transfer"
                  name="t_passenger_transfer"
                  value={formFlight.t_passenger_transfer}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  label="ผู้โดยสาร Transit"
                  name="t_passenger_transit"
                  value={formFlight.t_passenger_transit}
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom1Function back="/airline-record" />
    </div>
  );
}

export default AirlineRecordSpecialDetails;
