/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import SD from "../../global/SwalDialog";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Bottom3Function from "../../global/Bottom3Function";
import Notifications from "../../global/Notifications";
function MasterItemDetail(props) {
  const history = useHistory();
  const [form, setState] = useState({
    mi_name: "",
    mi_description: "",
    rc_id: "0",
    rcs_id: "0",
    mi_price: "",
  });
  const [error, setError] = useState({
    mi_name: false,
    mi_description: false,
    rc_id: false,
    rcs_id: false,
    mi_price: false,
  });
  const [revenueCategory, setRevenueCategoryState] = useState([
    <option key="a0" value="0"></option>,
  ]);
  const [revenueCategorySub, setRevenueCategorySubState] = useState([
    <option key="b0" value="0"></option>,
  ]);
  const [revenueCategorySubTemp, setRevenueCategorySubTempState] = useState([]);

  const printValues = (e) => {
    e.preventDefault();
  };

  const updateField = (e) => {
    if (e.target.name === "rc_id") {
      const temp = revenueCategorySubTemp.filter((i) => {
        return i.rc_id.toString() === e.target.value;
      });
      setRevenueCategorySubState([<option key="b0" value="0"></option>]);
      temp.forEach((item) => {
        setRevenueCategorySubState((revenueCategorySub) => [
          ...revenueCategorySub,
          <option key={"b" + item.rcs_id} value={item.rcs_id}>
            {item.rcs_name}
          </option>,
        ]);
      });
    }

    const fieldLists = [
      "mi_name",
      "mi_description",
      "rc_id",
      "rcs_id",
      "u_usename",
      "mi_price",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  let { id } = useParams();
  const UpdateData = (e) => {
    props.handlerIsLoading(true);
    if (
      form.mi_name &&
      // form.mi_description &&
      form.rc_id &&
      form.rc_id !== "0" &&
      form.rcs_id &&
      form.rcs_id !== "0" &&
      form.mi_price
    ) {
      Notifications.NotiAsync(
        axios.put(`${process.env.REACT_APP_API_URL}/v1/masteritems/${id}`, {
          form,
        }),

        (resp) => {
          history.push("/master-item");

          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        mi_name: !form.mi_name || form.mi_name === "",
        // mi_description: !form.mi_description || form.mi_description === "",
        rc_id: !form.rc_id || form.rc_id === "0",
        rcs_id: !form.rcs_id || form.rcs_id === "0",
        mi_price: !form.mi_price || form.mi_price === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  const DeleteData = (e) => {
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${form.mi_name} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/masteritems/${id}`,
            {}
          ),

          (resp) => {
            history.push("/master-item");
            props.handlerIsLoading(false);
            Notifications.NotiSuccess();
          },
          (err) => {
            props.handlerIsLoading(false);
            Notifications.NotiError({ msg: err.response.data.msg });
          }
        );
      },
    });
  };

  useEffect(() => {
    props.handlerIsLoading(true);
    props.handlerIsLoading(true);
    async function fetchMyData() {
      let masterItemData = {};
      await axios
        .get(`${process.env.REACT_APP_API_URL}/v1/masteritems/${id}`)
        .then((res) => {
          masterItemData = res.data[0];
          props.handlerIsLoading(false);
        });

      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/revenuecategorys`;
      await axios.get(apiUrl).then((res) => {
        res.data.forEach((item) => {
          setRevenueCategoryState((revenueCategory) => [
            ...revenueCategory,
            <option key={"a" + item.rc_id} value={item.rc_id}>
              {item.rc_name}
            </option>,
          ]);
        });
      });

      await axios
        .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorysubs`)
        .then((res) => {
          res.data.forEach((item) => {
            if (masterItemData.rc_id === item.rc_id) {
              setRevenueCategorySubState((revenueCategorySub) => [
                ...revenueCategorySub,
                <option key={"b" + item.rcs_id} value={item.rcs_id}>
                  {item.rcs_name}
                </option>,
              ]);
            }
          });
          setRevenueCategorySubTempState(res.data);
        });
      setState(masterItemData);
    }
    fetchMyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName">ข้อมูลรายการหลัก</div>
      </div>
      <form onSubmit={printValues}>
        <div className="box-S4 flex-between">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลรายการหลัก</div>

            <div className="bContent">
              <div className="bRow">
                <TextField
                  error={error.mi_name}
                  fullWidth
                  label="ชื่อรายการหลัก"
                  value={form.mi_name ? form.mi_name : ""}
                  name="mi_name"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <TextField
                  error={error.mi_description}
                  fullWidth
                  label="รายละเอียด"
                  value={form.mi_description ? form.mi_description : ""}
                  name="mi_description"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <FormControl fullWidth error={error.rc_id}>
                  <InputLabel>ประเภทรายได้หลัก</InputLabel>
                  <NativeSelect
                    native="true"
                    value={form.rc_id ? form.rc_id : "0"}
                    name="rc_id"
                    onChange={updateField}
                  >
                    {revenueCategory}
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>
              <div className="bRow">
                <FormControl fullWidth error={error.rcs_id}>
                  <InputLabel>ประเภทรายได้ย่อย</InputLabel>
                  <NativeSelect
                    native="true"
                    value={form.rcs_id ? form.rcs_id : "0"}
                    name="rcs_id"
                    onChange={updateField}
                  >
                    {revenueCategorySub}
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>
              <div className="bRow">
                <TextField
                  error={error.mi_price}
                  fullWidth
                  type="number"
                  label="ราคา"
                  value={form.mi_price ? form.mi_price : ""}
                  name="mi_price"
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Bottom3Function
        back="/master-item"
        updateData={UpdateData}
        deleteData={DeleteData}
      />
    </div>
  );
}

export default MasterItemDetail;
