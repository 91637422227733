/** @format */

import React, { Component } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";

import { withRouter } from "react-router";
import { Redirect } from "react-router";
import Bottom1Function from "../../global/Bottom1Function";
import Notifications from "../../global/Notifications";
import axios from "axios";
import Helper from "../../global/Helper";

export class OtherIncomeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      revenueCategory: [],
      revenueCategorySub: [],
      form: {
        main: "",
        sub: "",
        i_doc_no: "",
        i_doc_date: "",
        i_due_date: "",
        apa_name: "",
        apa_email: "",
        apa_house_no: "",
        apa_road: "",
        apa_building_name: "",
        apa_moo: "",
        apa_tambon_code: "",
        apa_amphur_code: "",
        apa_province_code: "",
        apa_postcode: "",
        apa_country: "",
        apa_tel: "",

        nName: "",
        n1: 0,
        n2: 0,
        n3: 0,
        n4: 0,
        n5: 0,
        n6: 0,
        n7: 0,

        i_remark: "",
      },
    };
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.insertData = this.insertData.bind(this);
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  insertData = () => {
    this.handlerIsLoading(true);
    const form = this.state.form;
    Notifications.NotiAsync(
      axios.post(`${process.env.REACT_APP_API_URL}/v1/otherincomes`, {
        form,
      }),
      (resp) => {
        this.handlerIsLoading(false);
        this.setState({ redirect: true });
        Notifications.NotiSuccess();
      },
      (err) => {
        this.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };
  getData = async () => {
    let main = [];
    let sub = [];
    let form = {};
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorys`)
      .then((res) => {
        res.data.forEach((item) => {
          main.push(
            <option key={"a" + item.rc_id} value={item.rc_id}>
              {item.rc_name}
            </option>
          );
        });
      });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorysubs`)
      .then((res) => {
        res.data.forEach((item) => {
          sub.push(
            <option key={"b" + item.rcs_id} value={item.rcs_id}>
              {item.rcs_name}
            </option>
          );
        });
      });

    const id = this.props.match.params.id;
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/otherincomes/${id}`)
      .then((res) => {
        form = res.data;
      });

    this.setState({
      revenueCategory: main,
      revenueCategorySub: sub,
      form: { ...this.state.form, ...form },
    });
  };
  componentDidMount = () => {
    this.getData();
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/welfare-income" />;
    }
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>รายได้ประเภทอื่น</div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ประเภทรายได้</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>ประเภทรายได้หลัก</InputLabel>
                    <NativeSelect
                      native="true"
                      name="main"
                      disabled
                      value={this.state.form.i_revenue_id}
                    >
                      <option aria-label="None" value="" />
                      {this.state.revenueCategory}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลใบแจ้งหนี้</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เลขที่ใบแจ้งหนี้"
                    name="i_doc_no"
                    value={this.state.form.i_doc_no}
                    disabled
                  />
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label="รายได้ประจำปี"
                    name="i_year"
                    value={this.state.form.i_year}
                    disabled
                  />
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label="รายได้ประจำเดือน"
                    name="i_month"
                    value={this.state.form.i_month}
                    disabled
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันที่ออกใบแจ้งหนี้"
                    type="date"
                    name="i_doc_date"
                    InputLabelProps={{ shrink: true }}
                    value={Helper.CastDate(
                      new Date(this.state.form.i_doc_date)
                    )}
                    disabled
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันครบกำหนดชำระ"
                    type="date"
                    name="i_due_date"
                    InputLabelProps={{ shrink: true }}
                    disabled
                    value={Helper.CastDate(
                      new Date(this.state.form.i_due_date)
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลผู้ชำระ</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่อผู้ชำระเงิน"
                    name="apa_name"
                    disabled
                    value={
                      this.state.form.apa_name ? this.state.form.apa_name : ""
                    }
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="Email"
                    name="apa_email"
                    value={
                      this.state.form.apa_email ? this.state.form.apa_email : ""
                    }
                    disabled
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ที่อยู่"
                    name="apa_house_no"
                    value={
                      this.state.form.apa_house_no
                        ? this.state.form.apa_house_no
                        : ""
                    }
                    disabled
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ถนน"
                    name="apa_road"
                    value={
                      this.state.form.apa_road ? this.state.form.apa_road : ""
                    }
                    disabled
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่ออาคาร"
                    name="apa_building_name"
                    value={
                      this.state.form.apa_building_name
                        ? this.state.form.apa_building_name
                        : ""
                    }
                    disabled
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="หมู่"
                    name="apa_moo"
                    value={
                      this.state.form.apa_moo ? this.state.form.apa_moo : ""
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ตำบล/แขวง"
                    name="apa_tambon_code"
                    value={
                      this.state.form.apa_tambon_code
                        ? this.state.form.apa_tambon_code
                        : ""
                    }
                    disabled
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="อำเภอ/เขต"
                    name="apa_amphur_code"
                    value={
                      this.state.form.apa_amphur_code
                        ? this.state.form.apa_amphur_code
                        : ""
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="จังหวัด"
                    name="apa_province_code"
                    value={
                      this.state.form.apa_province_code
                        ? this.state.form.apa_province_code
                        : ""
                    }
                    disabled
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="รหัสไปรษณีย์"
                    name="apa_postcode"
                    value={
                      this.state.form.apa_postcode
                        ? this.state.form.apa_postcode
                        : ""
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ประเทศ"
                    name="apa_country"
                    value={
                      this.state.form.apa_country
                        ? this.state.form.apa_country
                        : ""
                    }
                    disabled
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    name="apa_tel"
                    value={
                      this.state.form.apa_tel ? this.state.form.apa_tel : ""
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการชำระ</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    name="nName"
                    disabled
                    value={this.state.form.i_name ? this.state.form.i_name : ""}
                  />
                </div>
                <div className="box-S1"></div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    type="number"
                    value={this.state.form.i_sum ? this.state.form.i_sum : 0}
                    disabled
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  รวมเป็นเงิน
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    disabled
                    type="number"
                    value={this.state.form.i_sum ? this.state.form.i_sum : 0}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  ส่วนลด
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    name="n3"
                    type="number"
                    value={
                      this.state.form.i_discount
                        ? this.state.form.i_discount
                        : 0
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  เป็นเงิน
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    disabled
                    type="number"
                    value={
                      parseInt(
                        this.state.form.i_sum ? this.state.form.i_sum : 0
                      ) -
                      parseInt(
                        this.state.form.i_discount
                          ? this.state.form.i_discount
                          : 0
                      )
                    }
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  ค่าปรับ
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    type="number"
                    value={
                      this.state.form.i_mulct ? this.state.form.i_mulct : 0
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  ปรับยอดค่าปรับ
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    type="number"
                    value={
                      this.state.form.i_adjust ? this.state.form.i_adjust : 0
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  จำนวนเงินทั้งสิ้น
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    type="number"
                    disabled
                    value={
                      this.state.form.i_total ? this.state.form.i_total : 0
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">หมายเหตุ</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S4">
                  <TextField
                    fullWidth
                    label="หมายเหตุ"
                    name="i_remark"
                    disabled
                    value={
                      this.state.form.i_remark ? this.state.form.i_remark : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Bottom1Function back="/other-income" />
      </div>
    );
  }
}
export default withRouter(OtherIncomeDetails);
