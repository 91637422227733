/** @format */

import React, { Component } from "react";

// Components Page
import TableTransportRemainCenter from "../datatable/TableTransportRemainCenter";
import FillterData6 from "../global/FillterData6";

export class TransportRemain extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.state = {
      filter: {},
    };
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  handleFiltering = (v) => {
    this.setState({ filter: v });
  };
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>เที่ยวบินคงค้าง</div>
          </div>
        </div>
        <FillterData6
          handleFiltering={this.handleFiltering.bind(this)}
          fillteringPage="TransportRemainCenter"
        />
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการเที่ยวบินคงค้าง</div>
            <div className="bContent">
              <TableTransportRemainCenter
                filter={this.state.filter}
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransportRemain;
