/** @format */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Bottom2Function from "../../../global/Bottom2Function";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  NativeSelect,
  FormHelperText,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Notifications from "../../../global/Notifications";
import Helper from "../../../global/Helper";

function AirlineRecordDetailsAdd(props) {
  const history = useHistory();
  const [formFlight, setState] = useState({
    //  Section 1
    t_airline_id: "",
    t_airline: "",
    al_owner: "",
    al_agent: "",
    t_arr_dep: "",
    t_air_type_id: "",
    t_air_type: "",
    t_callsign: "",
    t_callsign_id: "",
    ac_weight: "",
    t_flight_no: "",
    t_airport_id: "",
    t_airport: "",
    td_used: "",
    //  Section 2
    t_year: "",
    t_month: "",
    t_day: "",
    t_trn_date: "",
    t_trn_yy: "",
    t_trn_mm: "",
    t_trn_dd: "",
    t_trn_time: "",
    t_trn_day: "",
    t_departure_airport_code: "",
    t_departure_airport_name: "",
    t_port_name_iata_show: "",
    t_traff_type: "",
    t_traff_type_id: "",
    t_port_type: "",
    t_arrival_airport_code: "",
    t_arrival_airport_name: "",
    t_city_iata_show: "",
    t_card_no: "",
    t_zone_no: "",
    t_region_no: "",
    t_captian: "",
    t_crew: "",
    t_remark: "",
    // Section 3
    t_passenger_dom: 0,
    t_passenger_inter: 0,
    t_passenger_infrant: 0,
    t_passenger_transfer: 0,
    t_passenger_transit: 0,
  });

  const [error, setError] = useState({
    //  Section 1
    t_airline_id: false,
    t_airline: false,
    al_owner: false,
    al_agent: false,
    t_arr_dep: false,
    t_air_type_id: false,
    t_air_type: false,
    t_callsign: false,
    t_callsign_id: false,
    ac_weight: false,
    t_flight_no: false,
    t_airport_id: false,
    t_airport: false,
    td_used: false,
    //  Section 2
    t_year: false,
    t_month: false,
    t_day: false,
    t_trn_date: false,
    t_trn_yy: false,
    t_trn_mm: false,
    t_trn_dd: false,
    t_trn_time: false,
    t_trn_day: false,
    t_departure_airport_code: false,
    t_departure_airport_name: false,
    t_traff_type: false,
    t_traff_type_id: false,
    t_port_type: false,
    t_arrival_airport_code: false,
    t_arrival_airport_name: false,
    t_card_no: false,
    t_zone_no: false,
    t_region_no: false,
    t_captian: false,
    t_crew: false,
    t_remark: false,
    // Section 3
    t_passenger_dom: false,
    t_passenger_inter: false,
    t_passenger_infrant: false,
    t_passenger_transfer: false,
    t_passenger_transit: false,
  });
  const [airline, setAirline] = useState([]);
  const [airport, setAirport] = useState([]);
  const [airlineOption, setAirlineOption] = useState([
    <option key="al"></option>,
  ]);
  const [airportOption, setAirportOption] = useState([
    <option key="ap"></option>,
  ]);
  const [portOption, setPortOption] = useState([<option key="p"></option>]);
  const [traffType, setTraff] = useState();
  const [traffOption, setTraffOption] = useState([<option key="t"></option>]);
  const [callsignLists, setCallSign] = useState([]);
  const [icaoLists, setICAOCode] = useState([]);
  const [inputAutoCompleteValue, setInputValueAutoComplete] = useState("");
  const [inputAutoCompleteValuePortName, setInputValueAutoCompletePortName] =
    useState("");
  const [inputAutoCompleteValueCity, setInputValueAutoCompleteCity] =
    useState("");
  const [isDom, setIsDom] = useState(null);
  const setDate = (d) => {
    const dt = new Date(d);
    let year = dt.getFullYear().toString();
    let month = (dt.getMonth() + 1).toString();
    let day = dt.getDate().toString();
    if (month <= 9) month = "0" + month;
    if (day <= 9) day = "0" + day;
    if (year.length < 4) {
      for (let x = 0; x < 4 - year.length; x++) {
        year = "0" + year;
      }
    }
    const dayOfWeek = dt.getDay();
    setState({
      ...formFlight,
      t_year: year,
      t_month: Helper.Cast2Month(month),
      t_day: day,
      t_trn_yy: year,
      t_trn_mm: month,
      t_trn_dd: day,
      t_trn_day: dayOfWeek + 1,
      t_trn_date: d,
    });
  };
  const updateField = (e) => {
    const fieldLists = [
      "t_passenger_transit",
      "t_passenger_transfer",
      "t_passenger_infrant",
      "t_passenger_inter",
      "t_passenger_dom",
      "t_remark",
      "t_crew",
      "t_captian",
      "t_region_no",
      "t_zone_no",
      "t_card_no",
      "t_arrival_airport_name",
      "t_arrival_airport_code",
      "t_departure_airport_code",
      "t_port_type",
      "t_traff_type_id",
      "t_traff_type",
      "t_departure_airport_name",
      "t_trn_day",
      "t_trn_time",
      "t_trn_dd",
      "t_trn_mm",
      "t_trn_yy",
      "t_airline_id",
      "t_airline",
      "al_owner",
      "al_agent",
      "t_arr_dep",
      "t_air_type_id",
      "t_air_type",
      "t_callsign",
      "t_callsign_id",
      "ac_weight",
      "t_flight_no",
      "t_airport_id",
      "t_airport",
      "td_used",
      "t_year",
      "t_month",
      "t_day",
      "t_trn_date",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    if (e.target.type === "number")
      e.target.value = e.target.value < 0 ? 0 : e.target.value;
    switch (e.target.name) {
      case "t_airport_id":
        const ap = airport.find((i) => i.ap_id.toString() === e.target.value);
        if (ap) {
          setState({
            ...formFlight,
            [e.target.name]: e.target.value,
            t_airport: ap.ap_name,
          });
        }
        break;
      case "t_port_type":
        setState({
          ...formFlight,
          [e.target.name]: e.target.value,
        });
        break;
      case "t_traff_type_id":
        const tt = traffType.find((i) => i.tt_id.toString() === e.target.value);
        setState({
          ...formFlight,
          [e.target.name]: e.target.value,
          t_traff_type: tt ? tt.tt_code : "",
        });
        setIsDom(tt.tt_dom === "0");
        break;
      case "t_trn_time":
        setState({
          ...formFlight,
          [e.target.name]: e.target.value.replaceAll(":", ""),
        });
        break;
      case "t_trn_date":
        setDate(e.target.value);
        break;
      case "td_used":
        setState({
          ...formFlight,
          [e.target.name]: e.target.checked ? "1" : "0",
        });
        break;
      case "t_airline_id":
        getAircraft(e.target.value);
        const al = airline.find((i) => {
          return i.al_id.toString() === e.target.value;
        });
        setState({
          ...formFlight,
          [e.target.name]: e.target.value,
          t_airline_code: al && al.al_code_name ? al.al_code_name : "",
          t_airline: al && al.al_name ? al.al_name : "",
          al_owner: al && al.al_owner ? al.al_owner : "",
          al_agent: al && al.al_agent ? al.al_agent : "",
          t_air_type_id: "",
        });
        break;
      case "t_arr_dep":
        const val = e.target.value;
        if (Helper.GetUserData().ul_id !== 1) {
          if (val === "D") {
            setState({
              ...formFlight,
              t_departure_airport_code: Helper.GetUserData().ap_code_name,
              t_departure_airport_name: Helper.GetUserData().ap_name,
              t_port_name_iata_show: `${Helper.GetUserData().apc_iata_code} (${
                Helper.GetUserData().apc_name
              })`,
              t_arrival_airport_code: "",
              t_arrival_airport_name: "",
              t_city_iata_show: ``,
              [e.target.name]: e.target.value,
            });
            setInputValueAutoCompletePortName(
              `${Helper.GetUserData().ap_code_name} (${
                Helper.GetUserData().apc_name
              })`
            );
            setInputValueAutoCompleteCity(``);
          } else if (val === "A") {
            setState({
              ...formFlight,
              t_arrival_airport_code: Helper.GetUserData().ap_code_name,
              t_arrival_airport_name: Helper.GetUserData().ap_name,
              t_city_iata_show: `${Helper.GetUserData().apc_iata_code} (${
                Helper.GetUserData().apc_name
              })`,
              t_departure_airport_code: "",
              t_departure_airport_name: "",
              t_port_name_iata_show: ``,
              [e.target.name]: e.target.value,
            });
            setInputValueAutoCompleteCity(
              `${Helper.GetUserData().ap_code_name} (${
                Helper.GetUserData().apc_name
              })`
            );
            setInputValueAutoCompletePortName(``);
          }
        } else {
          setState({
            ...formFlight,
            [e.target.name]: e.target.value,
          });
        }
        break;
      default:
        setState({
          ...formFlight,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const insertData = () => {
    props.handlerIsLoading(true);
    if (
      formFlight.t_airline_id !== "" &&
      formFlight.t_airline &&
      formFlight.al_owner &&
      formFlight.al_agent &&
      formFlight.t_arr_dep &&
      formFlight.t_air_type_id !== "" &&
      formFlight.t_airport_id !== "" &&
      formFlight.t_air_type &&
      formFlight.t_callsign &&
      formFlight.t_callsign_id &&
      formFlight.ac_weight &&
      formFlight.t_flight_no &&
      //formFlight.td_used && CHECKBOX FLIGHT
      formFlight.t_year &&
      formFlight.t_month &&
      formFlight.t_day &&
      formFlight.t_trn_date &&
      formFlight.t_trn_time &&
      formFlight.t_trn_day &&
      formFlight.t_departure_airport_code &&
      formFlight.t_departure_airport_name &&
      formFlight.t_traff_type &&
      formFlight.t_traff_type_id &&
      formFlight.t_port_type &&
      formFlight.t_arrival_airport_code &&
      formFlight.t_arrival_airport_name &&
      formFlight.t_card_no &&
      formFlight.t_zone_no &&
      formFlight.t_region_no
      // formFlight.t_captian &&
      // formFlight.t_crew &&
      // formFlight.t_remark &&
      // formFlight.t_passenger_dom &&
      // formFlight.t_passenger_inter &&
      // formFlight.t_passenger_infrant &&
      // formFlight.t_passenger_transfer &&
      // formFlight.t_passenger_transit
    ) {
      const fAirport = airport.find(
        (i) => i.ap_id.toString() === formFlight.t_airport_id.toString()
      );
      const temp = formFlight;
      if (fAirport) {
        temp.t_airport_id = fAirport.ap_stt_code;
      }
      Notifications.NotiAsync(
        axios.post(`${process.env.REACT_APP_API_URL}/v1/transports`, {
          formFlight: temp,
        }),
        (resp) => {
          props.handlerIsLoading(false);
          history.push("/airline-record");
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        t_airline_id: formFlight.t_airline_id === "",
        t_airline: formFlight.t_airline === "",
        al_owner: formFlight.al_owner === "",
        al_agent: formFlight.al_agent === "",
        t_arr_dep: formFlight.t_arr_dep === "",
        t_air_type_id: formFlight.t_air_type_id === "",
        t_air_type: formFlight.t_air_type === "",
        t_callsign: formFlight.t_callsign === "",
        t_callsign_id: formFlight.t_callsign_id === "",
        ac_weight: formFlight.ac_weight === "",
        t_flight_no: formFlight.t_flight_no === "",
        td_used: formFlight.td_used === "",
        //  Section 2
        t_year: formFlight.t_year === "",
        t_month: formFlight.t_month === "",
        t_day: formFlight.t_day === "",
        t_trn_date: formFlight.t_trn_date === "",
        t_trn_yy: formFlight.t_trn_yy === "",
        t_trn_mm: formFlight.t_trn_mm === "",
        t_trn_dd: formFlight.t_trn_dd === "",
        t_trn_time: formFlight.t_trn_time === "",
        t_trn_day: formFlight.t_trn_day === "",
        t_departure_airport_code: formFlight.t_departure_airport_code === "",
        t_departure_airport_name: formFlight.t_departure_airport_name === "",
        t_traff_type: formFlight.t_traff_type === "",
        t_traff_type_id: formFlight.t_traff_type_id === "",
        t_port_type: formFlight.t_port_type === "",

        t_arrival_airport_code: formFlight.t_arrival_airport_code === "",
        t_arrival_airport_name: formFlight.t_arrival_airport_name === "",
        t_card_no: formFlight.t_card_no === "",
        t_zone_no: formFlight.t_zone_no === "",
        t_region_no: formFlight.t_region_no === "",
        t_captian: formFlight.t_captian === "",
        t_crew: formFlight.t_crew === "",
        t_airport_id: formFlight.t_airport_id === "",
        // t_remark: formFlight.t_remark === "",
        // Section 3
        // t_passenger_dom: formFlight.t_passenger_dom === "",
        // t_passenger_inter: formFlight.t_passenger_inter === "",
        // t_passenger_infrant: formFlight.t_passenger_infrant === "",
        // t_passenger_transfer: formFlight.t_passenger_transfer === "",
        // t_passenger_transit: formFlight.t_passenger_transit === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };
  const getTraff = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/trafftype`)
      .then((res) => {
        res.data.forEach((item, index) => {
          if (item.tt_normal === "1") {
            const option = (
              <option key={`tt${item.tt_id}`} value={item.tt_id}>
                {`${item.tt_code} (${item.tt_description})`}
              </option>
            );
            setTraffOption((airlineOption) => [...airlineOption, option]);
          }
        });
        setTraff(res.data);
      });
  };
  const getPort = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/porttype`)
      .then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={`pt${item.pt_id}`} value={item.pt_code}>
              {`${item.pt_code} (${item.pt_description})`}
            </option>
          );
          setPortOption((portOption) => [...portOption, option]);
        });
      });
  };
  const getAirportCode = () => {
    props.handlerIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/airports/portcode/apc_gps_code`)
      .then((res) => {
        setICAOCode(res.data);
        props.handlerIsLoading(false);
      });
  };
  const getAircraft = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/aircrafts/al/${id}`)
      .then((res) => {
        setCallSign(res.data);
      });
  };
  const getAirport = () => {
    if (Helper.GetUserData().ul_id === 3) {
      setState({
        ...formFlight,
        t_airport_id: Helper.GetUserData().ap_id,
      });
    }
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      res.data.forEach((item, index) => {
        if (
          (Helper.GetUserData().ul_id === 3 &&
            Helper.GetUserData().ap_id === parseInt(item.ap_id)) ||
          Helper.GetUserData().ul_id !== 3
        ) {
          const option = (
            <option key={`ap${item.ap_id}`} value={item.ap_id}>
              {item.ap_code_name
                ? `${item.ap_code_name} (${item.ap_name})`
                : item.ap_name}
            </option>
          );
          setAirportOption((airportOption) => [...airportOption, option]);
        }
      });
      updateField({
        target: { name: "t_airport_id", value: res.data[0].ap_id },
      });
      setAirport(res.data);
    });
  };
  const getData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/airlines`)
      .then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={item.al_id} value={item.al_id}>
              {item.al_name}
            </option>
          );
          setAirlineOption((airlineOption) => [...airlineOption, option]);
        });
        setAirline(res.data);
      });
    await getAirport();
    await getTraff();
    await getPort();
    await getAirportCode();
  };
  useEffect(() => {
    getData();
    if (isDom) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>เพิ่มข้อมูลเที่ยวบิน</div>
        </div>
      </div>
      <div className="box-S4 mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">รายละเอียดสายการบิน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth error={error.t_airline_id}>
                  <InputLabel shrink>สายการบิน</InputLabel>
                  <NativeSelect
                    native="true"
                    onChange={updateField}
                    value={formFlight.t_airline_id}
                    name="t_airline_id"
                  >
                    {airlineOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_owner}
                  fullWidth
                  label="เจ้าของ"
                  name="al_owner"
                  value={formFlight.al_owner}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.al_agent}
                  fullWidth
                  label="ตัวแทน"
                  name="al_agent"
                  value={formFlight.al_agent}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <div className="box-S4">
                  <FormControl component="fieldset" error={error.t_arr_dep}>
                    <FormLabel component="legend">การเดินทาง</FormLabel>
                    <RadioGroup
                      aria-label="t_arr_dep"
                      name="t_arr_dep"
                      onChange={updateField}
                      row
                      style={{ marginTop: "0px", paddingBottom: "0px" }}
                    >
                      <FormControlLabel
                        value="A"
                        control={<Radio />}
                        label="เข้า"
                      />
                      <FormControlLabel
                        value="D"
                        control={<Radio />}
                        label="ออก"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="bRow flex-between">
              <div className="box-S2">
                <Autocomplete
                  options={callsignLists}
                  freeSolo
                  disableClearable
                  clearOnBlur={false}
                  getOptionLabel={(option) =>
                    option.ac_callsign
                      ? `${option.ac_callsign} (${option.at_name}/${option.ac_weight})`
                      : ""
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      {...params}
                      error={error.t_callsign}
                      label="เลขที่สัญชาติและทะเบียน"
                    />
                  )}
                  value={""}
                  onChange={(event, newValue) => {
                    let find = newValue;
                    if (typeof newValue === "string") {
                      find = callsignLists.find((i) => {
                        if (i.ac_callsign)
                          return (
                            i.ac_callsign.toString().toLowerCase() ===
                            newValue.toString().toLowerCase()
                          );
                        return false;
                      });
                    }
                    if (find) {
                      setState({
                        ...formFlight,
                        t_callsign_id: find.ac_id,
                        t_callsign: find.ac_callsign,
                        ac_weight: find.ac_weight,
                        t_air_type_id: find.at_id,
                        t_air_type: find.at_name,
                      });
                      setInputValueAutoComplete(
                        `${find.ac_callsign} (${find.at_name}/${find.ac_weight})`
                      );
                    } else {
                      setState({
                        ...formFlight,
                        t_callsign_id: "",
                        t_callsign: "",
                        ac_callsign: "",
                        ac_weight: "",
                        t_air_type_id: "",
                        t_air_type: "",
                      });
                    }
                  }}
                  inputValue={inputAutoCompleteValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValueAutoComplete(newInputValue);
                  }}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.t_air_type}
                  fullWidth
                  label="แบบอากาศยาน"
                  name="t_air_type"
                  value={formFlight.t_air_type}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.ac_weight}
                  fullWidth
                  label="น้ำหนัก"
                  value={formFlight.ac_weight}
                  name="ac_weight"
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.t_flight_no}
                  fullWidth
                  label="Flight No."
                  value={formFlight.t_flight_no}
                  name="t_flight_no"
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth error={error.t_airport_id}>
                  <InputLabel>สนามบิน</InputLabel>
                  <NativeSelect
                    native="true"
                    onChange={updateField}
                    value={formFlight.t_airport_id}
                    name="t_airport_id"
                  >
                    {airportOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div className="box-S2 hidden">
                <FormControl component="fieldset" error={error.td_used}>
                  <FormLabel component="legend"></FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      style={{ color: "#333333 !important" }}
                      name="td_used"
                      value={formFlight.td_used}
                      control={<Checkbox color="primary" />}
                      label="ยกเลิกเที่ยวบิน"
                      labelPlacement="end"
                      onChange={updateField}
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">ข้อมูลการบิน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.t_trn_date}
                  fullWidth
                  label="TRN Date"
                  name="t_trn_date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={updateField}
                  value={formFlight.t_trn_date}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.t_trn_time}
                  fullWidth
                  label="TRN Time"
                  name="t_trn_time"
                  type="time"
                  value={Helper.Cast2Time(formFlight.t_trn_time)}
                  InputLabelProps={{ shrink: true }}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.t_trn_day}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="TRN Day"
                  name="t_trn_day"
                  value={Helper.Cast2Day(formFlight.t_trn_day)}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <Autocomplete
                  options={icaoLists}
                  freeSolo
                  disableClearable
                  clearOnBlur={false}
                  getOptionLabel={(option) =>
                    option.apc_gps_code
                      ? `${option.apc_gps_code} (${option.apc_name})`
                      : ""
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      {...params}
                      error={error.t_departure_airport_code}
                      label="รหัสต้นทาง"
                    />
                  )}
                  value={""}
                  onChange={(event, newValue) => {
                    let find = newValue;
                    if (typeof newValue === "string") {
                      find = icaoLists.find(
                        (i) =>
                          i.apc_gps_code.toString().toLowerCase() ===
                          newValue.toString().toLowerCase()
                      );
                    }
                    if (find) {
                      setState({
                        ...formFlight,
                        t_departure_airport_code: find.apc_gps_code,
                        t_departure_airport_name: find.apc_iata_code,
                        t_port_name_iata_show: `${find.apc_iata_code} (${find.apc_name})`,
                      });
                      setInputValueAutoCompletePortName(
                        `${find.apc_gps_code} (${find.apc_name})`
                      );
                    } else {
                      setState({
                        ...formFlight,
                        t_departure_airport_code: "",
                      });
                    }
                  }}
                  inputValue={inputAutoCompleteValuePortName}
                  onInputChange={(event, newInputValue) => {
                    setInputValueAutoCompletePortName(newInputValue);
                  }}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={error.t_departure_airport_name}
                  value={formFlight.t_port_name_iata_show}
                  disabled
                  fullWidth
                  label="รหัสปลายทาง"
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth error={error.t_traff_type_id}>
                  <InputLabel>ประเภทการบิน</InputLabel>
                  <NativeSelect
                    native="true"
                    onChange={updateField}
                    value={formFlight.t_traff_type_id}
                    name="t_traff_type_id"
                  >
                    {traffOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div className="box-S2">
                <FormControl fullWidth error={error.t_port_type}>
                  <InputLabel>Port Type</InputLabel>
                  <NativeSelect
                    native="true"
                    onChange={updateField}
                    value={formFlight.t_port_type}
                    name="t_port_type"
                  >
                    {portOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <Autocomplete
                  options={icaoLists}
                  freeSolo
                  disableClearable
                  clearOnBlur={false}
                  getOptionLabel={(option) =>
                    option.apc_gps_code
                      ? `${option.apc_gps_code} (${option.apc_name})`
                      : ""
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      {...params}
                      error={error.t_arrival_airport_code}
                      label="รหัสปลายทาง"
                    />
                  )}
                  value={""}
                  onChange={(event, newValue) => {
                    let find = newValue;
                    if (typeof newValue === "string") {
                      find = icaoLists.find(
                        (i) =>
                          i.apc_gps_code.toString().toLowerCase() ===
                          newValue.toString().toLowerCase()
                      );
                    }
                    if (find) {
                      setState({
                        ...formFlight,
                        t_arrival_airport_code: find.apc_gps_code,
                        t_arrival_airport_name: find.apc_iata_code,
                        t_city_iata_show: `${find.apc_iata_code} (${find.apc_name})`,
                      });
                      setInputValueAutoCompleteCity(
                        `${find.apc_gps_code} (${find.apc_name})`
                      );
                    } else {
                      setState({
                        ...formFlight,
                        t_arrival_airport_code: "",
                      });
                    }
                  }}
                  inputValue={inputAutoCompleteValueCity}
                  onInputChange={(event, newInputValue) => {
                    setInputValueAutoCompleteCity(newInputValue);
                  }}
                />
              </div>
              <div className="box-S2">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={error.t_arrival_airport_name}
                  value={formFlight.t_city_iata_show}
                  disabled
                  fullWidth
                  label="ชื่อปลายทาง"
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.t_card_no}
                  fullWidth
                  label="Card No."
                  name="t_card_no"
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.t_zone_no}
                  fullWidth
                  label="Zone No."
                  name="t_zone_no"
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.t_region_no}
                  fullWidth
                  label="Region"
                  name="t_region_no"
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.t_captian}
                  fullWidth
                  label="นักบิน"
                  name="t_captian"
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.t_crew}
                  fullWidth
                  label="ลูกเรือ"
                  name="t_crew"
                  type="Number"
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S4">
                <TextField
                  error={error.t_remark}
                  fullWidth
                  label="หมายเหตุ"
                  name="t_remark"
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">จำนวนผู้โดยสาร</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.t_passenger_dom}
                  fullWidth
                  label="ผู้โดยสารในประเทศ"
                  name="t_passenger_dom"
                  type="Number"
                  onChange={updateField}
                  //disabled={isDom === null || !isDom}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.t_passenger_inter}
                  fullWidth
                  label="ผู้โดยสารต่างประเทศ"
                  name="t_passenger_inter"
                  type="Number"
                  onChange={updateField}
                  //disabled={isDom === null || isDom}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.t_passenger_infrant}
                  fullWidth
                  label="เด็กต่ำกว่า 2ปี"
                  name="t_passenger_infrant"
                  type="Number"
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.t_passenger_transfer}
                  fullWidth
                  label="ผู้โดยสาร Transfer"
                  name="t_passenger_transfer"
                  type="Number"
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.t_passenger_transit}
                  fullWidth
                  label="ผู้โดยสาร Transit"
                  name="t_passenger_transit"
                  type="Number"
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom2Function back="/airline-record" insertData={insertData} />
    </div>
  );
}

export default AirlineRecordDetailsAdd;
