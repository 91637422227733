/** @format */

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import TableAirlineAddress from "../../datatable/airline/TableAirlineAddress";
import FillterAirport from "../../global/FillterAirport";
import { withRouter } from "react-router";

export class AirlineAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
    this.tableData = React.createRef();
    this.handleFiltering = this.handleFiltering.bind(this);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  handleFiltering = (v) => {
    this.tableData.current.handleFiltering(v);
  };
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "500px",
              }}
            >
              <p
                style={{ margin: 0, whiteSpace: "nowrap", marginRight: "10px" }}
              >
                ที่อยู่ใบแจ้งหนี้สายการบิน
              </p>
              <FillterAirport
                handleFiltering={this.handleFiltering.bind(this)}
                fillteringPage="AirlineAddress"
              />
            </div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                this.props.history.push("/airline-address/add");
              }}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการ</div>
            <div className="bContent">
              <TableAirlineAddress
                ref={this.tableData}
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AirlineAddress);
