/** @format */

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router";
import { FormControl, NativeSelect } from "@material-ui/core";
import ReactECharts from "echarts-for-react";

// Components Page
import TableTargetBudgetCash from "../datatable/TableTargetBudgetCash";

export class ReportTargetBudgetCash extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.handleFiltering = this.handleFiltering.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleSetOptions = this.handleSetOptions.bind(this);
    this.tableRef = React.createRef();
    this.chartRef = React.createRef();
    this.state = {
      yearLists: [],
      year: new Date().getFullYear() + 543,
      options: {
        notMerge: true,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          showTitle: false,
          bottom: 20,
          left: 0,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        grid: {},
        legend: {
          type: "scroll",
        },
        xAxis: [
          {
            type: "category",
            data: [
              "ตุลาคม",
              "พฤศจิกายน",
              "ธันวาคม",
              "มกราคม",
              "กุมภาพันธ์",
              "มีนาคม",
              "เมษายน",
              "พฤษภาคม",
              "มิถุนายน",
              "กรกฎาคม",
              "สิงหาคม",
              "กันยายน",
            ],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [],
      },
    };
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  handleFiltering = (v) => {
    this.tableRef.current.handleFiltering(v);
  };
  handleExport = () => {
    this.tableRef.current.handleExport();
  };
  handleSetOptions = (y, v) => {
    const newOptions = { ...this.state.options };
    try {
      const total = v.find((x) => x.order === 0);
      newOptions.series = [
        {
          name: "แผนสะสม" + total.title,
          type: "line",
          data: total.values.plan_collect.map((x) => {
            x = x.replace(/,/g, "");
            return parseFloat(x);
          }),
        },
        {
          name: "ผลสะสม ปี " + y,
          type: "line",
          data: total.values.result_collect.map((x) => {
            x = x.replace(/,/g, "");
            return parseFloat(x);
          }),
        },
      ];

      const barItems = v.filter((x) => x.order !== 0 && x.order % 1 > 0);
      barItems.forEach((x) => {
        let items = [];
        if (x.values.hasOwnProperty("result")) {
          items = x.values.result;
        }
        if (x.values.hasOwnProperty("sum_collect")) {
          items = x.values.sum_collect;
        }
        const bar = {
          name: x.order + " " + x.title,
          type: "bar",
          stack: "total",
          data: items.map((x) => {
            x = x.toString().replace(/,/g, "");
            return parseFloat(x).toFixed(2);
          }),
        };
        newOptions.series.push(bar);
      });
    } catch (error) {
      newOptions.series = [];
      console.log("set options error", error);
    }

    this.setState({ options: newOptions }, () => {
      this.chartRef.current.getEchartsInstance().setOption(newOptions, true);
    });
  };
  updateField = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (e.target.name === "year") {
        this.handleFiltering(e.target.value);
      }
    });
  };

  componentDidMount() {
    let yearLists = [];
    const year = new Date().getFullYear() + 543;
    const start = 2566;
    for (let i = start; i <= year+1; i++) {
      yearLists.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    this.setState({ yearLists: yearLists });
  }

  render() {
    return (
      <div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent flex-between-center">
              <div
                className="box-S2"
                style={{ alignItems: "baseline", flexDirection: "row" }}
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  แผน-ผลรายได้ปีงบประมาณ พ.ศ.
                </span>
                <FormControl style={{ width: "90px" }}>
                  <NativeSelect
                    native="true"
                    name="year"
                    value={this.state.year}
                    onChange={this.updateField}
                  >
                    {this.state.yearLists}
                  </NativeSelect>
                </FormControl>
                <span style={{ whiteSpace: "nowrap" }}>
                  (เงินสดที่จัดเก็บได้จริง)
                </span>
              </div>
              <div className="box-S2 flex-between">
                <div> </div>
                <Button
                  className={`styleBtn blue`}
                  onClick={() => {
                    this.handleExport();
                  }}
                >
                  Export
                </Button>
              </div>
            </div>
            <div className="bContent">
              <ReactECharts option={this.state.options} ref={this.chartRef} />
              <TableTargetBudgetCash
                ref={this.tableRef}
                handlerIsLoading={this.handlerIsLoading.bind(this)}
                handleFiltering={this.handleFiltering.bind(this)}
                handleExport={this.handleExport.bind(this)}
                handleSetOptions={this.handleSetOptions.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportTargetBudgetCash);
