/** @format */

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  NativeSelect,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { Component } from "react";
// Components Page
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Bottom2Function from "../global/Bottom2Function";
import Helper from "../global/Helper";

export class Report extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);

    const dt = new Date();
    let yearnow = dt.getFullYear() + 543;
    if (dt.getMonth() + 1 >= 10) yearnow = yearnow + 1;
    this.state = {
      month: [],
      yearLists: [],
      year: yearnow.toString(),
      monthList1: [],
      monthList2: [],
      month1: "9",
      month2: "9",
      type: "0",
      tempMonths: [
        { i: 9, t: "ตุลาคม" },
        { i: 10, t: "พฤศจิกายน" },
        { i: 11, t: "ธันวาคม" },
        { i: 0, t: "มกราคม" },
        { i: 1, t: "กุมภาพันธ์" },
        { i: 2, t: "มีนาคม" },
        { i: 3, t: "เมษายน" },
        { i: 4, t: "พฤษภาคม" },
        { i: 5, t: "มิถุนายน" },
        { i: 6, t: "กรกฎาคม" },
        { i: 7, t: "สิงหาคม" },
        { i: 8, t: "กันยายน" },
      ],
      column: [
        { a: "เลขใบแจ้งหนี้", f: "i_doc_no" },
        { a: "ท่าอากาศยาน", f: "ap_name" },
        { a: "วันออกใบแจ้งหนี้", f: "i_doc_date" },
        { a: "วันครบกำหนดชำระ", f: "i_due_date" },
        { a: "ref1", f: "i_ref1" },
        { a: "ref2", f: "i_ref2" },
        { a: "ชื่อผู้ชำระ", f: "apa_name" },
        { a: "ชื่อรายการ", f: "i_revenue" },
        { a: "ประเภทรายการ", f: "rc_description" },
        { a: "สถานะ", f: "status" },
        { a: "จำนวนเงิน", f: "i_sum" },
        { a: "ค่าปรับ", f: "i_mulct" },
        { a: "ส่วนลด", f: "i_discount" },
        { a: "รวมทั้งสิ้น", f: "i_total" },
        { a: "สายการบิน", f: "al_name" },
        { a: "ปีที่ดึงข้อมูลมาออกใบแจ้งหนี้", f: "i_year" },
        { a: "เดือนที่ดึงข้อมูลมาออกใบแจ้งหนี้", f: "i_month" },
        { a: "วันที่ชำระเงิน", f: "i_paid_date" },
      ],
      monthIn: "",
      rule: ["0"],
      d1: true,
      d2: true,
      d3: true,
      d4: true,
      d5: true,
      d6: true,
      d7: true,
      d8: true,
      d9: true,
      d10: true,
      d11: true,
      d12: true,
      d13: true,
      d14: true,
      d15: true,
      d16: true,
      d17: true,
      d18: true,
    };
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };

  createYearLists = () => {
    const years = [];
    const dt = new Date();
    let yearnow = dt.getFullYear() + 543;
    if (dt.getMonth() + 1 >= 10) yearnow = yearnow + 1;
    for (let i = yearnow; i >= 2560; i--) {
      years.push(
        <option key={`y${i}`} value={i}>
          {i}
        </option>
      );
    }
    this.setState({ yearLists: years });
  };

  createMonthLists = (cb) => {
    const monthLists1 = [];
    const monthLists2 = [];
    const fIndex = this.state.tempMonths.findIndex(
      (item) => item.i === parseInt(this.state.month1)
    );
    this.state.tempMonths.forEach((item, index) => {
      const year =
        item.i >= 9 && item.i <= 12 ? this.state.year - 1 : this.state.year;
      monthLists1.push(
        <option key={`m1${item.i + 1}`} value={item.i}>
          {year} - {item.t}
        </option>
      );
      const checkAvaliable = index >= fIndex ? true : false;
      if (checkAvaliable) {
        monthLists2.push(
          <option key={`m2${item.i + 1}`} value={item.i}>
            {year} - {item.t}
          </option>
        );
      }
    });
    this.setState(
      {
        monthList1: monthLists1,
        monthList2: monthLists2,
        monthIn: this.getMonthIn(),
      },
      () => {
        if (typeof cb === "function") cb();
      }
    );
  };

  getMonthIn = () => {
    const monthIndex1 = this.state.tempMonths.findIndex(
      (item) => item.i === parseInt(this.state.month1)
    );
    const monthIndex2 = this.state.tempMonths.findIndex(
      (item) => item.i === parseInt(this.state.month2)
    );
    const monthIn = this.state.tempMonths
      .filter((item, index) => index >= monthIndex1 && index <= monthIndex2)
      .map((item) => {
        return parseInt(item.i) + 1;
      })
      .join(",");
    return monthIn;
  };

  daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  getMonthNumber = (v) => {
    return this.state.months.indexOf(v) + 1;
  };
  exportExcel = () => {
    try {
      this.props.handlerIsLoading(true);
      // is_special=0&
      const queryString = `filter_months=${
        this.state.monthIn
      }&filter_year=${this.state.year.toString().slice(-2)}${
        this.state.type !== "0" ? `&filter_type=${this.state.type}` : ""
      }${
        this.state.airport_value !== 0
          ? `&filter_airport=${this.state.airport_value}`
          : ""
      }${
        !this.state.rule.includes("0")
          ? `&filter_duedate=${this.state.rule.join(",")}`
          : ""
      }`;
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/invoice/report?${queryString}`;
      axios.get(apiUrl).then((res) => {
        let countField = 0;
        const alpb = [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
        ];
        let data = [{}];
        this.state.column.forEach((item, index) => {
          if (this.state["d" + (index + 1)]) {
            data[0][alpb[countField]] = item.a;
            countField++;
          }
        });

        res.data.data.forEach((i) => {
          countField = 0;
          let temp = [];
          this.state.column.forEach((item, index) => {
            if (this.state["d" + (index + 1)]) {
              switch (item.f) {
                case "i_doc_date":
                case "i_due_date":
                  temp[alpb[countField]] = Helper.CastDate(new Date(i[item.f]));
                  break;
                case "i_paid_date":
                  temp[alpb[countField]] =
                    Helper.CastDate(new Date(i[item.f])) +
                    " " +
                    Helper.CastTime(new Date(i[item.f]));
                  break;
                case "i_sum":
                case "i_mulct":
                case "i_total":
                case "i_discount":
                  if (!i[item.f]) {
                    i[item.f] = 0;
                  } else {
                    temp[alpb[countField]] = i[item.f]
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }
                  break;
                case "status":
                  temp[alpb[countField]] = Helper.getStatusInvoice(
                    i.i_due_date,
                    i.i_paid,
                    i.i_active,
                    false
                  );
                  break;
                case "i_revenue":
                  temp[alpb[countField]] = i[item.f]
                    ? i[item.f]
                    : i.i_remark || "";
                  break;
                default:
                  temp[alpb[countField]] = i[item.f];
                  break;
              }
              countField++;
            }
          });
          data.push(temp);
        });

        const header = {
          header: alpb,
          origin: "A7",
          skipHeader: true,
        };
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fAirportName = this.state.airport.find(
          (i) => i.ap_id.toString() === this.state.airport_value.toString()
        );
        let type = "ทั้งหมด";
        if (this.state.type === "1") type = "การบิน";
        if (this.state.type === "3") type = "สวัสดิการ";
        if (this.state.type === "2") type = "อื่น ๆ";

        let rules = ["ทั้งหมด"];
        if (!this.state.rule.includes("0")) {
          rules = [];
          if (this.state.rule.includes("1")) rules.push("ค้างชำระ");
          if (this.state.rule.includes("2")) rules.push("รอชำระ");
          if (this.state.rule.includes("3")) rules.push("ชำระแล้ว");
        }

        const fMonth1 = this.state.tempMonths.find(
          (item) => item.i === parseInt(this.state.month1)
        );
        const fMonth2 = this.state.tempMonths.find(
          (item) => item.i === parseInt(this.state.month2)
        );
        let month = `${fMonth1.t} ${
          fMonth1.i >= 9 && fMonth1.i <= 12
            ? this.state.year - 1
            : this.state.year
        }`;
        if (this.state.month1 !== this.state.month2) {
          month += ` ถึง ${fMonth2.t} ${
            fMonth2.i >= 9 && fMonth2.i <= 12
              ? this.state.year - 1
              : this.state.year
          }`;
        }
        const sheet = XLSX.utils.aoa_to_sheet([
          [`ปีงบประมาณ ${this.state.year}`],
          [`ข้อมูลเดือน ${month}`],
          [`ประเภทรายได้ ${type}`],
          [`ท่าอากาศยาน ${fAirportName ? fAirportName.ap_name : "ทั้งหมด"}`],
          [`เงื่อนไขการชำระ ${rules.join(",")}`],
        ]);
        XLSX.utils.sheet_add_json(sheet, data, header);
        const wb = { Sheets: { data: sheet }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const excelFiles = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(excelFiles, "Report" + fileExtension);
        this.props.handlerIsLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };
  updateField = (e) => {
    if (e.target.name === "year") {
      this.setState({ [e.target.name]: e.target.value }, () => {
        this.createMonthLists();
      });
    } else if (e.target.name === "month1") {
      this.setState(
        {
          [e.target.name]: e.target.value,
          month2: e.target.value,
        },
        () => {
          this.createMonthLists();
        }
      );
    } else if (e.target.name === "month2") {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          this.setState({
            monthIn: this.getMonthIn(),
          });
        }
      );
    } else if (e.target.name === "rule") {
      const old = [...this.state.rule];
      const index = old.indexOf(e.target.value);
      if (index === -1) {
        old.push(e.target.value);
      } else {
        old.splice(index, 1);
      }
      this.setState({
        [e.target.name]: old,
      });
    } else if (
      this.state.column
        .map((c, index) => "d" + (index + 1))
        .includes(e.target.name)
    ) {
      this.setState({ [e.target.name]: e.target.checked }, () => {});
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {});
    }
  };

  sortByMonth = (arr) => {
    return arr.sort(function (a, b) {
      return this.state.months.indexOf(a) - this.state.months.indexOf(b);
    });
  };

  componentDidMount() {
    this.createYearLists();
    this.createMonthLists();
    this.getAirport();
  }

  getAirport = () => {
    const temp = [
      <option key={`ap0}`} value={0}>
        ทั้งหมด
      </option>,
    ];
    let first = 0;
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      res.data.forEach((item, index) => {
        if (
          (Helper.GetUserData().ul_id === 3 &&
            Helper.GetUserData().ap_id === parseInt(item.ap_id)) ||
          Helper.GetUserData().ul_id !== 3
        ) {
          const option = (
            <option key={`ap${item.ap_id}`} value={item.ap_id}>
              {item.ap_code_name
                ? `${item.ap_code_name} (${item.ap_name})`
                : item.ap_name}
            </option>
          );
          temp.push(option);
          // if (index === 0) first = item.ap_id;
        }
      });
      this.setState({
        airport: res.data,
        airportOption: temp,
        airport_value: first,
      });
    });
  };

  render() {
    return (
      <div>
        <div className="box-S4 flex-between mgBottom">
          <div className="box-S4 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">รายงาน</div>
            </div>
          </div>
        </div>

        <div className="box-S4 flex-between mgBottom">
          <div className="box-S2 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">ปีงบประมาณ</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S4">
                    <FormControl fullWidth>
                      <InputLabel></InputLabel>
                      <NativeSelect
                        native="true"
                        name="year"
                        onChange={this.updateField}
                      >
                        {this.state.yearLists}
                      </NativeSelect>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-S2 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">ประเภทรายได้</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S4">
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        aria-label="type"
                        name="type"
                        onChange={this.updateField}
                        row
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio checked={this.state.type === "0"} />}
                          label="ทั้งหมด"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="การบิน"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="สวัสดิการ"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="อื่น ๆ"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="box-S2 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">ข้อมูลเส้นทางการบิน</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S2">
                    <FormControl fullWidth>
                      <InputLabel></InputLabel>
                      <NativeSelect
                        native="true"
                        name="month1"
                        value={this.state.month1}
                        onChange={this.updateField}
                      >
                        {this.state.monthList1}
                      </NativeSelect>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                  </div>
                  <div className="box-S2">
                    <FormControl fullWidth>
                      <InputLabel></InputLabel>
                      <NativeSelect
                        native="true"
                        name="month2"
                        value={this.state.month2}
                        onChange={this.updateField}
                      >
                        {this.state.monthList2}
                      </NativeSelect>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-S2 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">ท่าอากาศยาน</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S4">
                    <FormControl fullWidth>
                      <InputLabel></InputLabel>
                      <NativeSelect
                        native="true"
                        name="airport_value"
                        onChange={this.updateField}
                      >
                        {this.state.airportOption}
                      </NativeSelect>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className="bRow flex-between">
                  <span className="color-primary"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="box-S4 flex-between mgBottom">
          <div className="box-S4 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">เงื่อนไขการชำระ</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S4">
                    <FormControlLabel
                      key={"rule1"}
                      control={
                        <Checkbox
                          key={"rule-item1"}
                          color="primary"
                          name={"rule"}
                          value="0"
                          checked={this.state.rule.includes("0")}
                          onChange={this.updateField}
                        />
                      }
                      label={"ทั้งหมด"}
                    />
                    <FormControlLabel
                      key={"rule2"}
                      control={
                        <Checkbox
                          key={"rule-item2"}
                          color="primary"
                          name={"rule"}
                          value="3"
                          onChange={this.updateField}
                        />
                      }
                      label={"ชำระแล้ว"}
                    />
                    <FormControlLabel
                      key={"rule3"}
                      control={
                        <Checkbox
                          key={"rule-item3"}
                          color="primary"
                          name={"rule"}
                          value="2"
                          onChange={this.updateField}
                        />
                      }
                      label={"รอชำระ"}
                    />
                    <FormControlLabel
                      key={"rule4"}
                      control={
                        <Checkbox
                          key={"rule-item4"}
                          color="primary"
                          name={"rule"}
                          value="1"
                          onChange={this.updateField}
                        />
                      }
                      label={"ค้างชำระ"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="box-S4 flex-between">
            <div className="bContentMain">
              <div className="bNameContent">ข้อมูล</div>
              <div className="bContent">
                <div className="bRow">
                  {this.state.column.map((column, index) => (
                    <FormControlLabel
                      key={"f" + (index + 1)}
                      control={
                        <Checkbox
                          key={"d" + (index + 1)}
                          color="primary"
                          name={"d" + (index + 1)}
                          value="1"
                          onChange={this.updateField}
                          checked={this.state["d" + (index + 1)] === true}
                        />
                      }
                      label={column.a}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Bottom2Function
          back="/"
          textGreenBtn="สร้างรายงาน"
          insertData={() => {
            this.exportExcel();
          }}
        />
      </div>
    );
  }
}

export default Report;
