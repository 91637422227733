/** @format */

import React, { Component } from "react";
import {
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";

import TableLogEmail from "../datatable/TableLogEmail";

export class LogEmail extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.state = {
      // start date 3 month ago from current date and format to yyyy-mm-dd
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().slice(0, 10),
      // end date current date and format to yyyy-mm-dd
      dateEnd: new Date().toISOString().slice(0, 10),
      status: "0",
      filter: {}
    };
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  componentDidMount() {
    this.props.handlerIsLoading(false);
    this.setState({
      filter: {
        dateStart: this.state.dateStart,
        dateEnd: this.state.dateEnd,
        status: this.state.status
      }
    })
  }

  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>ข้อมูลการส่งอีเมล</div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
      <div className="bContentMain">
        <div className="bNameContent">กรองข้อมูล</div>
        <div className="bContent">
          <div className="bRow flex-between-start" style={{gap: 16}}>
            <div className="box-S1">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label='วันที่เริ่มต้น'
                  type='date'
                  value={this.state.dateStart}
                  onChange={e => {
                    this.setState({dateStart: e.target.value})
                  }}
                />
            </div>
            <div className="box-S1">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label='วันที่สิ้นสุด'
                  type='date'
                  value={this.state.dateEnd}
                  onChange={e => {
                    this.setState({dateEnd: e.target.value})
                  }}
                />
            </div>
          </div>
          <div className="bRow flex-between-start" style={{gap: 16}}>
            <RadioGroup
              aria-label="status"
              name="status"
              value={this.state.status}
              row
              style={{
                paddingBottom: "0px",
                paddingLeft: "15px",
              }}
              onChange={(e) => {
                this.setState({ status: e.target.value });
              }}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="ไม่สำเร็จ"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="สำเร็จ"
              />
              <FormControlLabel
                value="-1"
                control={<Radio />}
                label="ทั้งหมด"
              />
            </RadioGroup>
          </div>
          <div className="bRow flex-between">
            <div></div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                this.setState({
                  filter: {
                    dateStart: this.state.dateStart,
                    dateEnd: this.state.dateEnd,
                    status: this.state.status
                  }
                })
              }}
            >
              ค้นหา
            </Button>
          </div>
        </div>
      </div>
    </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการข้อมูลการส่งอีเมล</div>
            <div className="bContent">
              <TableLogEmail
                handlerIsLoading={this.handlerIsLoading.bind(this)}
                filter={this.state.filter}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogEmail;
