/** @format */

import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Button, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MaterialTable from "material-table";
import axios from "axios";
import SD from "../../global/SwalDialog";
import Notifications from "../../global/Notifications";
import Helper from "../../global/Helper";

export class TableAirlineAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      airline: [],
      page: 0,
      rows: 10,
      total: 0,
    };
  }
  componentDidMount() {
    // this.getData();
  }
  getData = (page = 0, rows = 10, q = "") => {
    const startRow = page * rows;
    const queryString = `limit=${rows}&offset=${startRow}${q ? `&${q}` : ""}`;
    this.props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/airlinespayment${
      ![1, 2, 3].includes(Helper.GetUserData().ul_id)
        ? `/${Helper.GetUserData().al_id}`
        : ""
    }?${queryString}`;
    axios.get(apiUrl).then((res) => {
      const data = res.data;
      this.setState({ ...this.state, airline: data });
      this.props.handlerIsLoading(false);
    });
  };
  handleFiltering = async (v) => {
    await this.setState({ q: Helper.jsonToQueryString(v) });
    await this.setState({ page: 0 });
    this.getData(0, this.state.rows, Helper.jsonToQueryString(v));
  };
  handleChangePage = async (e, page) => {
    await this.setState({ page: page });
    this.getData(page, this.state.rows, this.state.q);
  };
  handleChangeRowPerPage = async (row) => {
    await this.setState({ rows: row });
    await this.setState({ page: 0 });
    this.getData(0, row, this.state.q);
  };
  deleteData = (data) => {
    const user = JSON.parse(localStorage.getItem("DataUser"));
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${data.apa_name} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        this.props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/airlines-address/${data.apa_id}/${user.u_id}`,
            {}
          ),
          (resp) => {
            this.getData();
            Notifications.NotiSuccess();
            this.props.handlerIsLoading(false);
          },
          (err) => {
            Notifications.NotiError({ msg: err.response.data.msg });
            this.props.handlerIsLoading(false);
          }
        );
      },
    });
  };
  render() {
    return (
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title=""
            columns={[
              { title: "ชื่อสายการบิน", field: "apa_name", align: "center" },
              {
                title: "เลขประจำตัวผู้เสียภาษี",
                field: "apa_tax_id",
                align: "center",
              },
              { title: "ที่อยู่", field: "apa_house_no", align: "center" },
              { title: "อำเภอ/เขต", field: "apa_amphur_code", align: "center" },
              { title: "จังหวัด", field: "apa_province_code", align: "center" },
              { title: "รหัสไปรษณีย์", field: "apa_postcode", align: "center" },
              { title: "โทร", field: "apa_tel", align: "center" },
              { title: "ประเทศ", field: "apa_country", align: "center" },
              { title: "Email", field: "apa_email", align: "center" },
              {
                title: "",
                field: "apa_id",
                render: (rowData) => {
                  return (
                    <div>
                      <Tooltip title="แก้ไข" arrow>
                        <Button
                          href={`/airline-address/detail/${rowData.apa_id}`}
                          className="styleBtn blue w-auto"
                          variant="contained"
                          style={{ textTransform: "none", marginRight: "5px" }}
                          size="small"
                        >
                          <EditIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="ลบ" arrow>
                        <Button
                          onClick={() => {
                            this.deleteData(rowData);
                          }}
                          className="styleBtn red w-auto pl-5px"
                          variant="contained"
                          style={{ textTransform: "none", marginRight: "5px" }}
                          size="small"
                        >
                          <DeleteForeverIcon />
                        </Button>
                      </Tooltip>
                    </div>
                  );
                },
              },
            ]}
            data={this.state.airline}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: true,
              filtering: false,
              actionsColumnIndex: -1,
              grouping: true,
              exportPdf: (columns, data) => {
                const temp = data.map((i) => [
                  i.al_name,
                  i.al_code_name,
                  i.al_owner,
                  i.al_agent,
                  i.al_country,
                ]);
                Helper.exportPDF(
                  ["ชื่อสายการบิน", "สายการบิน", "เจ้าของ", "ตัวแทน"],
                  temp
                );
              },
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  rowsPerPage={this.state.rows}
                  count={this.state.total ? parseInt(this.state.total) : 0}
                  page={this.state.page}
                  onChangePage={(e, page) => this.handleChangePage(e, page)}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.handleChangeRowPerPage(event.target.value);
                  }}
                />
              ),
            }}
          />
        </TableContainer>
      </Paper>
    );
  }
}

export default TableAirlineAddress;
