/** @format */

import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router';

// Components Page
import TableInvoice from '../datatable/TableInvoice';
import FillterData3 from '../global/FillterData3';
import Helper from '../global/Helper';

export class Invoice extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.handleFiltering = this.handleFiltering.bind(this);
    this.tableRef = React.createRef();
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  handleFiltering = (v) => {
    this.tableRef.current.handleFiltering(v);
  };
  isEnableCreateInvoiceButton() {
    return (
      (!Helper.GetUserData().u_cgd_user || !Helper.GetUserData().u_cgd_pass) &&
      Helper.GetUserData().ul_id !== 1
    );
  }
  render() {
    return (
      <div>
        <div className='box-S4 mgBottom'>
          <div className='bPageName flex-between'>
            <div>ใบแจ้งหนี้</div>
            <Button
              disabled={this.isEnableCreateInvoiceButton()}
              className={`styleBtn ${
                !this.isEnableCreateInvoiceButton() ? 'create' : 'disabled'
              }`}
              onClick={() => {
                this.props.history.push('/invoice/create');
              }}
            >
              ออกใบแจ้งหนี้
            </Button>
          </div>
        </div>
        <FillterData3
          handleFiltering={this.handleFiltering.bind(this)}
          fillteringPage='Invoice'
        />
        <div className='box-S4 flex-between mgBottom'>
          <div className='bContentMain'>
            <div className='bNameContent'>รายการใบแจ้งหนี้</div>
            <div className='bContent'>
              <TableInvoice
                ref={this.tableRef}
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Invoice);
