/** @format */

import React from "react";
import { Button } from "@material-ui/core";

function Bottom1FunctionUpdateData(props) {
  return (
    <div>
      <div className="box-S4 bContentMain bContent flex-center">
        <div>
          <Button
            className="styleBtn space green"
            onClick={() => {
              props.updateData();
            }}
          >
            แก้ไขข้อมูล
          </Button>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Bottom1FunctionUpdateData;
