import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function Bottom3Function(props) {
  const history = useHistory();
  return (
    <div>
      <div className="box-S4 bContentMain bContent flex-between">
        <div>
          <Button
            className="styleBtn"
            onClick={() => {
              history.push(props.back);
            }}
          >
            ย้อนกลับ
          </Button>
        </div>
        <div>
          <Button
            className="styleBtn space green"
            onClick={() => {
              props.updateData();
            }}
          >
            บันทึก
          </Button>
        </div>
        <div>
          <Button
            className="styleBtn red"
            onClick={() => {
              props.deleteData();
            }}
          >
            ลบ
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Bottom3Function;
