/** @format */

import React, { Component } from "react";
import { TextField, Paper, TableContainer } from "@material-ui/core";
import MaterialTable from "material-table";
export class TableFlightCheckInvoiceWithQueryData extends Component {
  render() {
    return (
      <div>
        <Paper className="tabelData">
          <TableContainer>
            <MaterialTable
              title=""
              columns={[
                {
                  title: "วัน และ เวลา",
                  field: "t_datetime",
                  type: "datetime",
                  align: "center",
                },
                {
                  title: "เลขที่สัญชาติ / เลขทะเบียน",
                  field: "t_callsign",
                  align: "center",
                },
                {
                  title: "รหัสเที่ยวบิน",
                  field: "t_flight_no",
                  align: "center",
                },
                { title: "แบบยานอากาศ", field: "t_air_type", align: "center" },
                {
                  title: "น้ำหนัก",
                  field: "td_arrival_freight_f",
                  align: "center",
                },
                {
                  title: "ผู้โดยสารในประเทศ",
                  field: "t_passenger_dom",
                  align: "center",
                },
                {
                  title: "ผู้โดยสารต่างประเทศ",
                  field: "t_passenger_inter",
                  align: "center",
                },
                {
                  title: "ยอดเดิม",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return <div>{rowData.id_price}</div>;
                  },
                },
                {
                  title: "ส่วนลด %",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return rowData.id_dis_per;
                  },
                },
                {
                  title: "ส่วนลดบาท",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return rowData.id_dis_bath;
                  },
                },
                {
                  title: "ปรับยอด",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return rowData.id_adjust;
                  },
                },
                {
                  title: "คงเหลือ",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return rowData.id_sum;
                  },
                },
                {
                  title: "หมายเหตุ",
                  field: "id_remark",
                  align: "center",
                  render: (rowData) => {
                    return rowData.id_remark;
                  },
                },
              ]}
              data={this.props.data ? this.props.data : []}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50, 100],
                search: true,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                filtering: false,
                actionsColumnIndex: -1,
                grouping: true,
                sorting: true,
                headerStyle: {
                  position: "sticky",
                  top: "0",
                },
              }}
            />
          </TableContainer>
        </Paper>
        {!this.props.isReadonly && (
          <div className="box-S4 flex-between mgBottom">
            <div className="bContentMain">
              <div className="bNameContent">ผลรวมรายได้ทั้งหมด</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S1">
                    <TextField
                      fullWidth
                      label="ยอดเดิม"
                      disabled={true}
                      value={this.state.getTotalPrice}
                    />
                  </div>
                  <div className="box-S1">
                    <TextField
                      fullWidth
                      label="ส่วนลดบาท"
                      disabled={true}
                      value={this.state.getDiscount}
                    />
                  </div>
                  <div className="box-S1">
                    <TextField
                      fullWidth
                      label="ปรับยอด"
                      disabled={true}
                      value={this.state.getTotalPriceChange}
                      type="text"
                    />
                  </div>
                  <div className="box-S1">
                    <TextField
                      fullWidth
                      label="คงเหลือ"
                      disabled={true}
                      value={this.state.getTotal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TableFlightCheckInvoiceWithQueryData;
