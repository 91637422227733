/** @format */

import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import Bottom3Function from "../../global/Bottom3Function";
import Notifications from "../../global/Notifications";
import SD from "../../global/SwalDialog";
import Helper from "../../global/Helper";

function AirlineAddressDetails(props) {
  const { id } = useParams();
  const history = useHistory();
  const [airlineOption, setAirlineOption] = useState([
    <option key="al"></option>,
  ]);
  const [airportOption, setAirportOption] = useState([]);

  const [addr, setAddr] = useState([]);
  const [province, setProvince] = useState([]);
  const [amphur, setAmphur] = useState([]);
  const [tambon, setTambon] = useState([]);
  const [zipcode, setZipcode] = useState([]);
  const [form, setState] = useState({
    al_id: 0,
    ap_id: 0,
    apa_name: "",
    apa_tax_id: "",
    apa_house_no: "",
    apa_amphur_code: "",
    apa_province_code: "",
    apa_postcode: "",
    apa_country: "",
    apa_tel: "",
    apa_email: "",
    apa_tambon_code: "",
  });

  const [error, setError] = useState({
    apa_name: false,
    apa_tax_id: false,
    apa_house_no: false,
    apa_amphur_code: false,
    apa_province_code: false,
    apa_postcode: false,
    apa_country: false,
    apa_tel: false,
    apa_email: false,
    apa_tambon_code: false,
  });

  const updateField = (e) => {
    if (e.target.name === "apa_province_code") {
      const unique = [
        ...new Set(
          addr
            .filter((item) => item.province === e.target.value && item.amphoe)
            .map((item) => {
              return item.amphoe;
            })
        ),
      ];
      unique.sort();
      const amph = [
        ...unique
          .filter((item) => item)
          .map((item) => {
            return (
              <option key={"amp" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      setState({
        ...form,
        [e.target.name]: e.target.value,
        apa_amphur_code: "",
        apa_tambon_code: "",
        apa_postcode: "",
      });
      setAmphur(amph);
    } else if (e.target.name === "apa_amphur_code") {
      const unique = [
        ...new Set(
          addr
            .filter(
              (item) =>
                item.amphoe === e.target.value &&
                item.province === form.apa_province_code &&
                item.district
            )
            .map((item) => {
              return item.district;
            })
        ),
      ];
      unique.sort();
      const district = [
        ...unique
          .filter((item) => item)
          .map((item) => {
            return (
              <option key={"tum" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      setState({
        ...form,
        [e.target.name]: e.target.value,
        apa_tambon_code: "",
        apa_postcode: "",
      });
      setTambon(district);
    } else if (e.target.name === "apa_tambon_code") {
      const unique = [
        ...new Set(
          addr
            .filter(
              (item) =>
                item.district === e.target.value &&
                item.amphoe === form.apa_amphur_code &&
                item.province === form.apa_province_code &&
                item.zipcode
            )
            .map((item) => {
              return item.zipcode;
            })
        ),
      ];
      unique.sort();
      let zipFirst = "";
      const zipcode = [
        ...unique
          .filter((item) => item)
          .map((item) => {
            if (zipFirst === "") {
              zipFirst = item;
            }
            return (
              <option key={"zip" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      setState({
        ...form,
        [e.target.name]: e.target.value,
        apa_postcode: zipFirst,
      });
      setZipcode(zipcode);
    } else {
      setState({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
    const fieldLists = [
      "apa_name",
      "apa_tax_id",
      "apa_house_no",
      "apa_amphur_code",
      "apa_province_code",
      "apa_postcode",
      "apa_country",
      "apa_tel",
      "apa_email",
      "apa_tambon_code",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
  };

  const deleteData = (e) => {
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${form.apa_name} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/airlines-address/${id}/${
              Helper.GetUserData().u_id
            }`,
            {}
          ),
          (resp) => {
            history.push("/airline-address");
            props.handlerIsLoading(false);
            Notifications.NotiSuccess();
          },
          (err) => {
            props.handlerIsLoading(false);
            Notifications.NotiError({ msg: err.response.data.msg });
          }
        );
      },
    });
  };

  const updateData = () => {
    props.handlerIsLoading(true);
    if (
      form.apa_name &&
      form.apa_tax_id &&
      form.apa_house_no &&
      form.apa_amphur_code &&
      form.apa_province_code &&
      form.apa_postcode &&
      form.apa_country &&
      form.apa_tel &&
      form.apa_email &&
      form.apa_tambon_code
    ) {
      const apiUrl = `${
        process.env.REACT_APP_API_URL
      }/v1/airlines-address/${id}/${Helper.GetUserData().u_id}`;
      Notifications.NotiAsync(
        axios.put(apiUrl, { form }),

        (resp) => {
          history.push("/airline-address");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        apa_name: form.apa_name === "",
        apa_tax_id: form.apa_tax_id === "",
        apa_house_no: form.apa_house_no === "",
        apa_amphur_code: form.apa_amphur_code === "",
        apa_province_code: form.apa_province_code === "",
        apa_postcode: form.apa_postcode === "",
        apa_country: form.apa_country === "",
        apa_tel: form.apa_tel === "",
        apa_email: form.apa_email === "",
        apa_tambon_code: form.apa_tambon_code === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airlines`).then((res) => {
      res.data.forEach((item, index) => {
        const option = (
          <option key={item.al_id} value={item.al_id}>
            {item.al_name}
          </option>
        );
        setAirlineOption((airlineOption) => [...airlineOption, option]);
      });
    });
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      res.data.forEach((item, index) => {
        const option = (
          <option key={item.ap_id} value={item.ap_id}>
            {item.ap_name}
          </option>
        );
        if (
          (Helper.GetUserData().ap_id &&
            Helper.GetUserData().ap_id.toString()) === item.ap_id.toString() ||
          [1, 2].includes(Helper.GetUserData().ul_id)
        ) {
          setAirportOption((airportOption) => [...airportOption, option]);
        }
      });
    });
    setState((form) => {
      form.ap_id = Helper.GetUserData().ap_id;
      return form;
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        props.handlerIsLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}/v1/airlinespayment/apa/${id}`;
        await axios.get(apiUrl).then((res) => {
          setState(res.data[0]);
        });
        await axios
          .get(`${process.env.REACT_APP_API_URL}/v1/addr`)
          .then((res) => {
            const unique = [...new Set(res.data.map((item) => item.province))];
            setProvince([
              ...unique.map((item) => {
                return (
                  <option key={"p" + item} value={item}>
                    {item}
                  </option>
                );
              }),
            ]);
            setAddr(res.data);
          });

        props.handlerIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const unique = [
      ...new Set(
        addr
          .filter(
            (item) => item.province === form.apa_province_code && item.amphoe
          )
          .map((item) => {
            return item.amphoe;
          })
      ),
    ].sort();
    const amph = [
      ...unique
        .filter((item) => item)
        .map((item) => {
          return (
            <option key={"amp" + item} value={item}>
              {item}
            </option>
          );
        }),
    ];
    setAmphur(amph);
    const uniqueTambom = [
      ...new Set(
        addr
          .filter(
            (item) =>
              item.amphoe === form.apa_amphur_code &&
              item.province === form.apa_province_code &&
              item.district
          )
          .map((item) => {
            return item.district;
          })
      ),
    ].sort();
    const district = [
      ...uniqueTambom
        .filter((item) => item)
        .map((item) => {
          return (
            <option key={"tum" + item} value={item}>
              {item}
            </option>
          );
        }),
    ];
    setTambon(district);
    const uniqueZipcode = [
      ...new Set(
        addr
          .filter(
            (item) =>
              item.district === form.apa_tambon_code &&
              item.province === form.apa_province_code &&
              item.amphoe === form.apa_amphur_code &&
              item.zipcode
          )
          .map((item) => {
            return item.zipcode;
          })
      ),
    ].sort();
    let zipFirst = "";
    const zipcode = [
      ...uniqueZipcode
        .filter((item) => item)
        .map((item) => {
          if (zipFirst === "") {
            zipFirst = item;
          }
          return (
            <option key={"zip" + item} value={item}>
              {item}
            </option>
          );
        }),
    ];
    setZipcode(zipcode);
  }, [addr, form]);
  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>รายละเอียดที่อยู่ใบแจ้งหนี้สายการบิน</div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">รายละเอียดสายการบิน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth error={error.t_airline_id}>
                  <InputLabel shrink>สายการบิน</InputLabel>
                  <NativeSelect
                    native="true"
                    onChange={updateField}
                    value={form.al_id}
                    name="al_id"
                  >
                    {airlineOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>ท่าอากาศยาน</InputLabel>
                  <NativeSelect
                    native="true"
                    onChange={updateField}
                    value={form.ap_id}
                    name="ap_id"
                  >
                    {airportOption}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.apa_name}
                  fullWidth
                  label="ชื่อผู้ชำระ"
                  name="apa_name"
                  value={form.apa_name ? form.apa_name : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.apa_tax_id}
                  fullWidth
                  label="เลขประจำตัวผู้เสียภาษี"
                  name="apa_tax_id"
                  value={form.apa_tax_id ? form.apa_tax_id : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S4">
                <TextField
                  error={error.apa_house_no}
                  fullWidth
                  label="ที่อยู่"
                  name="apa_house_no"
                  value={form.apa_house_no ? form.apa_house_no : ""}
                  onChange={updateField}
                />
              </div>
            </div>

            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>จังหวัด</InputLabel>
                  <NativeSelect
                    native="true"
                    name="apa_province_code"
                    onChange={updateField}
                    value={form.apa_province_code ? form.apa_province_code : ""}
                  >
                    <option aria-label="None" value="" />
                    {province}
                  </NativeSelect>
                </FormControl>
              </div>

              <div className="box-S2">
                <TextField
                  fullWidth
                  label="ชื่ออาคาร"
                  name="apa_building_name"
                  value={form.apa_building_name ? form.apa_building_name : ""}
                  onChange={updateField}
                />
              </div>
            </div>

            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>อำเภอ/เขต</InputLabel>
                  <NativeSelect
                    native="true"
                    name="apa_amphur_code"
                    onChange={updateField}
                    value={form.apa_amphur_code ? form.apa_amphur_code : ""}
                    disabled={form.apa_province_code === ""}
                  >
                    <option aria-label="None" value="" />
                    {amphur}
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="หมู่"
                  name="apa_moo"
                  value={form.apa_moo ? form.apa_moo : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>ตำบล/แขวง</InputLabel>
                  <NativeSelect
                    native="true"
                    name="apa_tambon_code"
                    onChange={updateField}
                    value={form.apa_tambon_code ? form.apa_tambon_code : ""}
                    disabled={form.apa_amphur_code === ""}
                  >
                    <option aria-label="None" value="" />
                    {tambon}
                  </NativeSelect>
                </FormControl>
              </div>

              <div className="box-S2">
                <TextField
                  fullWidth
                  label="ถนน"
                  name="apa_road"
                  value={form.apa_road ? form.apa_road : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>รหัสไปรษณีย์</InputLabel>
                  <NativeSelect
                    native="true"
                    name="apa_postcode"
                    onChange={updateField}
                    value={form.apa_postcode ? form.apa_postcode : ""}
                    disabled={form.apa_tambon_code === ""}
                  >
                    <option aria-label="None" value="" />
                    {zipcode}
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="เบอร์โทรศัพท์"
                  name="apa_tel"
                  value={form.apa_tel ? form.apa_tel : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="ประเทศ"
                  name="apa_country"
                  value={form.apa_country ? form.apa_country : ""}
                  onChange={updateField}
                />
              </div>

              <div className="box-S2">
                <TextField
                  fullWidth
                  label="Email"
                  name="apa_email"
                  value={form.apa_email ? form.apa_email : ""}
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bottom3Function
        back="/airline-address"
        updateData={updateData}
        deleteData={deleteData}
      />
    </div>
  );
}

export default AirlineAddressDetails;
