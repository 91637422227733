/** @format */

import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { Button, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SD from "../global/SwalDialog";
import Notifications from "../global/Notifications";
import Helper from "../global/Helper";

export default function StickyHeadTable(props) {
  const history = useHistory();
  const [aircraft, setAircraft] = useState([]);
  const getData = () => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/aircrafts`;
    axios.get(apiUrl).then((res) => {
      setAircraft(res.data);
      props.handlerIsLoading(false);
    });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteData = (data) => {
    const user = JSON.parse(localStorage.getItem("DataUser"));
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${data.ac_callsign} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/aircrafts/${data.ac_id}/${user.u_id}`,
            {}
          ),
          (resp) => {
            getData();
            Notifications.NotiSuccess();
          },
          (err) => {
            Notifications.NotiError({ msg: "ไม่สามารถลบข้อมูลได้" });
          }
        );
      },
    });
  };

  return (
    <Paper className="tabelData">
      <TableContainer>
        <MaterialTable
          title=""
          columns={[
            { title: "สายการบิน", field: "al_name", align: "center" },
            { title: "แบบ", field: "at_name", align: "center" },
            {
              title: "เลขสัญชาติและทะเบียน",
              field: "ac_callsign",
              align: "center",
            },
            {
              title: "Gross Weight(Kg.)",
              field: "ac_weight",
              align: "center",
            },
            {
              title: "",
              field: "ac_id",
              render: (rowData) => {
                return (
                  <div>
                    <Tooltip title="แก้ไข" arrow>
                      <Button
                        onClick={() => {
                          history.push(`/aircraft/detail/${rowData.ac_id}`);
                        }}
                        className="styleBtn blue w-auto"
                        variant="contained"
                        style={{ textTransform: "none", marginRight: "5px" }}
                        size="small"
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="ลบ" arrow>
                      <Button
                        onClick={() => {
                          deleteData(rowData);
                        }}
                        className="styleBtn red w-auto pl-5px"
                        variant="contained"
                        style={{ textTransform: "none", marginRight: "5px" }}
                        size="small"
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </Tooltip>
                  </div>
                );
              },
            },
          ]}
          data={aircraft}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            search: true,
            exportButton: true,
            filtering: false,
            actionsColumnIndex: -1,
            grouping: true,
            exportPdf: (columns, data) => {
              const temp = data.map((i) => [
                i.al_name,
                i.at_name,
                i.ac_callsign,
                i.ac_weight,
              ]);
              Helper.exportPDF(
                [
                  "สายการบิน",
                  "แบบ",
                  "เลขสัญชาติและทะเบียน",
                  "Gross Weight(Kg.)",
                ],
                temp
              );
            },
          }}
        />
      </TableContainer>
    </Paper>
  );
}
