/** @format */

import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import {
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SD from "../global/SwalDialog";
import Notifications from "../global/Notifications";
import Helper from "../global/Helper";

export default function StickyHeadTable(props) {
  const history = useHistory();
  const [welfarelists, setWelfarelists] = useState([]);
  const [airportOption, setAirportOption] = useState([
    <option key="ap" value="-1">
      ทั้งหมด
    </option>,
  ]);
  const [filter_airport, setFilterAirport] = useState(
    Helper.GetUserData().ap_id
  );
  useEffect(() => {
    getData();
    getAirport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = () => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/welfarelists/ap/${filter_airport}`;
    axios.get(apiUrl).then((res) => {
      setWelfarelists(res.data);
      props.handlerIsLoading(false);
    });
  };
  const getAirport = () => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/airports?withCenter=1`;
    axios.get(apiUrl).then((res) => {
      res.data.forEach((i, index) => {
        setAirportOption((ap) => [
          ...ap,
          <option key={index} value={i.ap_id}>
            {i.ap_name}
          </option>,
        ]);
      });
      setFilterAirport(Helper.GetUserData().ap_id);
      props.handlerIsLoading(false);
    });
  };
  const deleteData = (data) => {
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${data.wc_name} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.delete(`${process.env.REACT_APP_API_URL}/v1/welfarelists`, {
            data: {
              wc_id: data.wc_id,
              u_id: Helper.GetUserData().u_id,
            },
          }),
          (resp) => {
            getData();
            Notifications.NotiSuccess();
            props.handlerIsLoading(false);
          },
          (err) => {
            Notifications.NotiError({ msg: err.response.data.msg });
            props.handlerIsLoading(false);
          }
        );
      },
    });
  };
  const filteringAirport = () => {
    getData();
  };
  const updateAirport = (e) => {
    console.log(e.target.value);
    setFilterAirport(e.target.value);
  };
  return (
    <div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">กรอง</div>
          <div className="bContent">
            <div className="bRow" style={{ display: "flex" }}>
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>สนามบิน</InputLabel>
                  <NativeSelect
                    native="true"
                    name="filter_airport"
                    value={filter_airport}
                    onChange={updateAirport}
                  >
                    {airportOption}
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>
              <div className="box-S1">
                <Button
                  style={{ marginTop: "14px", marginLeft: "14px" }}
                  className="styleBtn blue"
                  onClick={() => {
                    filteringAirport();
                  }}
                >
                  ค้นหา
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title=""
            columns={[
              { title: "ท่าอากาศยาน", field: "ap_name", align: "center" },
              {
                title: "ชื่อ",
                field: "wc_name",
                align: "center",
              },
              {
                title: "รหัส",
                field: "wc_welfare_code",
                align: "center",
              },
              {
                title: "เลขประจำตัวผู้เสียภาษี",
                field: "wc_tax_id",
                align: "center",
              },
              { title: "ที่อยู่", field: "addr", align: "center" },
              { title: "โทร", field: "wc_tel", align: "center" },
              { title: "Email", field: "wc_email", align: "center" },
              {
                title: "",
                field: "wc_id",
                render: (rowData) => {
                  return (
                    <div>
                      <Tooltip title="แก้ไข" arrow>
                        <Button
                          onClick={() => {
                            history.push(
                              `/welfare-lists/detail/${rowData.wc_id}`
                            );
                          }}
                          className="styleBtn blue w-auto"
                          variant="contained"
                          style={{ textTransform: "none", marginRight: "5px" }}
                          size="small"
                        >
                          <EditIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="ลบ" arrow>
                        <Button
                          onClick={() => {
                            deleteData(rowData);
                          }}
                          className="styleBtn red w-auto pl-5px"
                          variant="contained"
                          style={{ textTransform: "none", marginRight: "5px" }}
                          size="small"
                        >
                          <DeleteForeverIcon />
                        </Button>
                      </Tooltip>
                    </div>
                  );
                },
              },
            ]}
            data={welfarelists}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: true,
              filtering: false,
              actionsColumnIndex: -1,
              grouping: true,
              exportPdf: (columns, data) => {
                const temp = data.map((i) => [
                  i.wc_name,
                  i.wc_welfare_code,
                  i.wc_tax_id,
                  i.addr,
                  i.wc_tel,
                  i.wc_email,
                ]);
                Helper.exportPDF(
                  [
                    "ท่าอากาศยาน",
                    "ชื่อ",
                    "เลขประจำตะวผู้เสียภาษี",
                    "ที่อยู่",
                    "โทร",
                    "Email",
                  ],
                  temp
                );
              },
            }}
          />
        </TableContainer>
      </Paper>
    </div>
  );
}
