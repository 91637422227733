/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
  Button,
} from "@material-ui/core";
import axios from "axios";
import Helper from "./Helper";

function FillterData2(props) {
  const [form, setState] = useState({
    filter_date_start: Helper.CastDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    filter_date_end: Helper.CastDate(),
    filter_airline: "",
    filter_airport: Helper.GetUserData().ap_id,
    filter_airtype: "",
    filter_status: "-1",
    isFirstTime: true,
    filter_time_range: "1",
  });
  const [disabled, setDisabled] = useState({
    disabled_date_start: false,
    disabled_date_end: false,
    disabled_airline: false,
    disabled_airport: false,
    disabled_airtype: false,
    disabled_status: false,
  });
  const [airline, setAirline] = useState([
    <option key="l" value="">
      ไม่ระบุ
    </option>,
  ]);
  const [airport, setAirport] = useState([]);
  const [airtype, setAirType] = useState([
    <option key="t" value="">
      ไม่ระบุ
    </option>,
  ]);
  const [airstatus, setStatus] = useState([
    <option key="s" value="-1">
      ทั้งหมด
    </option>,
  ]);
  const getAirtype = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/trafftype`)
      .then((res) => {
        res.data.forEach((i, index) => {
          if (
            (props.isSpecial === "1" && i.tt_normal === "1") ||
            (props.isSpecial === "0" && i.tt_normal === "0") ||
            props.isSpecial === "all"
          ) {
            setAirType((airtype) => [
              ...airtype,
              <option key={index} value={i.tt_code}>
                {i.tt_code} ({i.tt_description})
              </option>,
            ]);
          }
        });
      });
  };
  const getAirport = () => {
    const user = Helper.GetUserData();
    if (![1, 2, 3].includes(user.ul_id)) {
      setAirport([]);
    }
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      res.data.forEach((i) => {
        if (
          (Helper.GetUserData().ap_id &&
            Helper.GetUserData().ap_id.toString()) === i.ap_id.toString() ||
          [1, 2].includes(Helper.GetUserData().ul_id)
        ) {
          setAirport((item) => [
            ...item,
            <option key={"ap" + i.ap_id} value={i.ap_id}>
              {i.ap_name}
            </option>,
          ]);
        }
      });
    });
  };
  const getAirline = () => {
    const user = Helper.GetUserData();
    if (![1, 2, 3].includes(user.ul_id)) {
      setAirline([]);
    }
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airlines`).then((res) => {
      res.data.forEach((i) => {
        if (
          [1, 2, 3].includes(user.ul_id) ||
          (user.ul_id === 4 && i.al_id === user.al_id)
        ) {
          setAirline((airline) => [
            ...airline,
            <option key={i.al_id} value={i.al_code_name}>
              {i.al_name}
            </option>,
          ]);
        }
        if (user.ul_id === 4 && i.al_id === user.al_id) {
          setState({ ...form, filter_airline: i.al_code_name });
        }
      });
    });
  };
  const getStatus = () => {
    setStatus((status) => [
      ...status,
      <option key="s1" value="1">
        ไม่ตรงกับสถิติ
      </option>,
      <option key="s2" value="2">
        ตรงกับสถิติ
      </option>,
      <option key="s3" value="0">
        ยังไม่ยืนยัน
      </option>,
    ]);
  };
  const getData = async () => {
    await getAirtype();
    await getAirline();
    await getAirport();
    await getStatus();
    const temp = {};
    if (props.isDisabledField) {
      const disabledField = props.isDisabledField.split(",");
      disabledField.forEach((item) => {
        temp[`disabled_${item}`] = true;
      });
    }
    setDisabled({
      ...disabled,
      ...temp,
    });
    const pagename = "fillter_data_2_" + props.fillteringPage;
    let sesstion = sessionStorage.getItem(pagename);
    if (sesstion) {
      sesstion = JSON.parse(sesstion);
      setState({ ...form, isFirstTime: false, ...sesstion });
      submitFilltering(sesstion);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const submitFilltering = (data) => {
    sessionStorage.setItem(
      "fillter_data_2_" + props.fillteringPage,
      JSON.stringify(data)
    );
    props.handleFiltering(data);
    console.log("submitFiltering");
  };
  return (
    <div className="box-S4 flex-between mgBottom">
      <div className="bContentMain">
        <div className="bNameContent">กรอง</div>
        <div className="bContent">
          <div className="bRow flex-between">
            <div className="box-S2 flex-between">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="วันและเวลา"
                type="date"
                name="filter_date_start"
                onChange={updateField}
                value={form.filter_date_start}
              />
              <div className="text-to">ถึง</div>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="วันและเวลา"
                type="date"
                name="filter_date_end"
                onChange={updateField}
                value={form.filter_date_end}
              />
            </div>
            <div className="box-S2">
              <FormControl fullWidth>
                <InputLabel shrink>สายการบิน</InputLabel>
                <NativeSelect
                  native="true"
                  name="filter_airline"
                  value={form.filter_airline}
                  onChange={updateField}
                >
                  {airline}
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="bRow flex-between">
            {!disabled.disabled_airtype && (
              <div className={"box-S2"}>
                <FormControl fullWidth>
                  <InputLabel shrink>ประเภทการบิน</InputLabel>
                  <NativeSelect
                    native="true"
                    name="filter_airtype"
                    value={form.filter_airtype}
                    onChange={updateField}
                  >
                    {airtype}
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>
            )}
            {!disabled.disabled_airport && (
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>ท่าอากาศยาน</InputLabel>
                  <NativeSelect
                    native="true"
                    name="filter_airport"
                    value={form.filter_airport}
                    onChange={updateField}
                  >
                    {airport}
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>
            )}
          </div>
          <div className="bRow flex-between">
            {!disabled.disabled_status && (
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>สถานะ</InputLabel>
                  <NativeSelect
                    native="true"
                    name="filter_status"
                    value={form.filter_status}
                    onChange={updateField}
                  >
                    {airstatus}
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>
            )}
            {props.with_time_range && (
              <div className="box-S2">
                <FormControl fullWidth>
                  <InputLabel shrink>ช่วงเวลา</InputLabel>
                  <NativeSelect
                    native="true"
                    name="filter_time_range"
                    value={form.filter_time_range}
                    onChange={updateField}
                  >
                    <option value="1">00.00 - 23:59</option>
                    <option value="2">06.00 - 17.59</option>
                    <option value="3">18.00 - 05.59</option>
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>
            )}
          </div>
          <div className="bRow flex-between">
            <div></div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                submitFilltering(form);
              }}
            >
              ค้นหา
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillterData2;
