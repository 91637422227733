/** @format */

import React, { Component } from "react";
import TableFlightCheckInvoice from "../datatable/flight/TableFlightCheckInvoice";
import TableFlightCheckInvoiceWithInput from "../datatable/flight/TableFlightCheckInvoiceWithInput";
import Bottom2Function from "../global/Bottom2Function";
import { withRouter } from "react-router";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import axios from "axios";
import "../../css/invoice.css";
import fileDownload from "js-file-download";
import Notifications from "../global/Notifications";
import Helper from "../global/Helper";
import SD from "../global/SwalDialog";

export class InvoiceCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearstart: 2019,
      monthLists: [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      step: 1,
      i_id: 0,
      isDisable: this.props.match.params.type !== "create",
      modalIsOpennDiscount: false,
      modalIsOpennDiscountBaht: false,
      airline: [1, 2].includes(Helper.GetUserData().ul_id)
        ? []
        : [<option key="a" value=""></option>],
      airlines: [],
      income_type: [],
      year: [],
      month: [],
      form: {
        select_airline_id: Helper.GetUserData().al_id,
        select_airline: "",
        select_incometype: "",
        select_incometype_text: "",
        select_incometype_description: "",
        select_incometype_id: 0,
        select_psc_date: "0",
        select_year: "",
        select_month: "",
        val_taxno: "",
        val_invoicedate: "",
        val_payablename: "",
        val_invoiceexpire: "",
        adjust_dept_dom: 0,
        adjust_dept_inter: 0,
        i_inv_type: "0",
        i_mulct_startdate: "",
        revenue_filter: [],
        i_remark: "",
      },
      revenue: [],
      addr: {},
      paymentAddr: [],
      paymentOption: [],
      summary: {
        getTotalPrice: 0,
        getDiscountDom: 0,
        getDiscountInter: 0,
        getTotalPriceChange: 0,
        getTotal: 0,
        data: [],
        sumDom: 0,
        sumInter: 0,
        totalDom: 0,
        totalInter: 0,
      },
      addrLists: [],
      province: [],
      amphur: [],
      tambon: [],
    };
    this.tableFlightCheck = React.createRef();
    this.tableFlightCheckWithInput = React.createRef();
    this.backFunctionCallback = this.backFunctionCallback.bind(this);
    this.handleSummaryResult = this.handleSummaryResult.bind(this);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.handleFlights = this.handleFlights.bind(this);
  }
  handleFlights = (flight) => {
    if (this.props.isSpecial && this.state.summary.data.length > 0) {
      const a = flight.filter((i) => i.checked);
      this.setState({ summary: { data: a } });
    } else {
      this.setState({ summary: { data: flight } });
    }
  };
  handleSummaryResult = (a, b, c, d, e, f, g, h, i, j, k) => {
    let deptDom = 0,
      deptInter = 0;
    if (this.state.form.i_inv_type === "1") {
      /*
      deptDom = Helper.getCalculateDept(
        'PSC',
        95700,
        25,
        '2022-02-01',
        '2022-02-02'
      );
      */
      deptDom = Helper.getCalculateDept(
        this.state.form.select_incometype_text,
        g + b,
        Number(this.state.form.revenue_select.rc_mulct_rate)
          ? parseFloat(this.state.form.revenue_select.rc_mulct_rate)
          : 7.5,
        this.state.form.i_mulct_startdate,
        this.state.form.val_invoiceexpire
      );
      deptInter = Helper.getCalculateDept(
        this.state.form.select_incometype_text,
        h + k,
        Number(this.state.form.revenue_select.rc_mulct_rate)
          ? parseFloat(this.state.form.revenue_select.rc_mulct_rate)
          : 7.5,
        this.state.form.i_mulct_startdate,
        this.state.form.val_invoiceexpire
      );
    }
    this.setState({
      summary: {
        getTotalPrice: a,
        getDiscountDom: b,
        getTotalPriceChange: c,
        getTotal:
          parseFloat(e) +
          parseFloat(deptDom) +
          parseFloat(deptInter) +
          parseFloat(
            this.state.form.i_adjust_dom ? this.state.form.i_adjust_dom : 0
          ) +
          parseFloat(
            this.state.form.i_adjust_inter ? this.state.form.i_adjust_inter : 0
          ),
        calculateDiscount: e,
        data: f,
        getDept: parseFloat(deptDom) + parseFloat(deptInter),
        sumDom: g,
        sumInter: h,
        totalDom: i,
        totalInter: j,
        getDiscountInter: k,
        getDeptDom: parseFloat(deptDom),
        getDeptInter: parseFloat(deptInter),
        getDiscount: d,
      },
    });
  };
  handleFiltering = (isSearch = false) => {
    let dayStart = "";
    let dayEnd = "";
    if (["PSC", "Mulct-PSC"].includes(this.state.form.select_incometype_text)) {
      if (
        !this.state.form.select_psc_date ||
        this.state.form.select_psc_date === "0"
      ) {
        dayStart = "1";
        dayEnd = "15";
      } else {
        dayStart = "16";
        dayEnd = new Date(
          this.state.form.select_year,
          this.state.form.select_month,
          0
        )
          .getDate()
          .toString();
      }
    }
    const v = {
      filter_airline: this.state.form.select_airline
        ? this.state.form.select_airline
        : "",
      filter_type: this.state.form.select_incometype
        ? this.state.form.select_incometype
        : "",
      filter_year: this.state.form.select_year
        ? this.state.form.select_year
        : "",
      filter_month: this.state.form.select_month
        ? this.state.form.select_month
        : "",
      type_code: this.state.form.select_incometype_text,
      filter_half:
        !this.state.form.select_psc_date ||
        this.state.form.select_psc_date === "0"
          ? "0"
          : "1",
      filter_day_start: dayStart,
      filter_day_end: dayEnd,
    };
    if (this.state.step === 1) {
      this.tableFlightCheck.current.initData(v, isSearch);
    } else if (this.state.step === 2) {
      this.tableFlightCheckWithInput.current.initData(v, isSearch);
    } else {
    }
  };
  backFunctionCallback = () => {
    this.setState({ step: this.state.step - 1 }, () => {
      if (this.state.step === 1 || this.state.step === 2)
        this.handleFiltering();
    });
  };
  getDateNow = () => {
    const dt = new Date();
    let year = dt.getFullYear().toString();
    let month = (dt.getMonth() + 1).toString();
    let day = dt.getDate().toString();
    if (month <= 9) month = "0" + month;
    if (day <= 9) day = "0" + day;
    if (year.length < 4) {
      for (let x = 0; x < 4 - year.length; x++) {
        year = "0" + year;
      }
    }
    return [year, month, day].join("-");
  };
  getAirline = () => {
    const temp = [];
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airlines`).then((res) => {
      res.data.forEach((i) => {
        if (
          (Helper.GetUserData().al_id &&
            Helper.GetUserData().al_id.toString() === i.al_id.toString()) ||
          [1, 2, 3].includes(Helper.GetUserData().ul_id)
        ) {
          temp.push(
            <option key={i.al_id} value={i.al_code_name}>
              {i.al_name}
            </option>
          );
          this.setState(
            {
              form: {
                ...this.state.form,
                select_airline: i.al_code_name,
                select_airline_id: i.al_id,
              },
            },
            () => {}
          );
        }
      });
      this.setState({
        airlines: res.data,
        airline: temp,
      });
    });
  };
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  getIncomeType = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorys/type/1`)
      .then((res) => {
        let temp = [<option key="i" value=""></option>];
        res.data.forEach((i) => {
          const option = (
            <option key={`i${i.rc_id}`} value={i.rc_id}>
              {i.rc_name}
            </option>
          );
          if (i.rc_code && i.rc_show_type === 1) temp.push(option);
        });
        this.setState({ income_type: temp, revenue: res.data }, () => {
          const id = this.props.match.params.id;
          if (id) this.getInvoice();
        });
      });
  };
  getPaymentName = () => {
    const filter_airport =
      Helper.GetUserData().ul_id === 1
        ? ""
        : // : `?filter_airport=${Helper.GetUserData().al_id}`;
          `?filter_airport=${Helper.GetUserData().ap_id}`;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/airlinespayment/${this.state.form.select_airline_id}${filter_airport}`
      )
      .then((res) => {
        let temp = [];
        res.data.forEach((i) => {
          const option = (
            <option key={`p${i.apa_id}`} value={i.apa_id}>
              {i.apa_name}
            </option>
          );
          temp.push(option);
        });
        this.setState({ paymentOption: temp, paymentAddr: res.data });
      });
  };

  getAddr = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/addr`).then((res) => {
      const addr = res.data;
      const unique = [...new Set(res.data.map((item) => item.province))];
      const province = [
        ...unique.map((item) => {
          return (
            <option key={"p" + item} value={item}>
              {item}
            </option>
          );
        }),
      ];
      this.setState({ addrLists: addr, province: province });
    });
  };

  getInvoice = () => {
    this.props.handlerIsLoading(true);
    const id = this.props.match.params.id;
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/invoice/detail/${id}`)
      .then((res) => {
        if (res.data) {
          const data = res.data.data;
          const detail = res.data.detail;
          let tempDiscount = 0;
          let tempAdjust = 0;
          const al = this.state.airlines.find((i) => i.al_id === data.al_id);
          const fRevenue = this.state.revenue.find(
            (i) => `${i.rc_id}` === `${data.i_revenue_id}`
          );
          const filterRevenue = this.state.revenue.filter(
            (i) => i.rc_code === fRevenue.rc_code
          );
          detail.forEach((v) => {
            tempDiscount = tempDiscount + v.id_dis_bath;
            tempAdjust = tempAdjust + v.id_adjust;
            v.td_arrival_freight_f = parseInt(v.td_arrival_freight_f) / 1000;
            v.status = Helper.getStatusFlightCheck(v.td_status);
            const m = fRevenue.rc_code;
            if (["PSC", "Mulct-PSC"].includes(m)) {
              v.price = Helper.getCalculatePSC(
                parseInt(v.td_passenger_dom_f),
                parseInt(v.td_passenger_inter_f)
              );
            } else if (["APPS", "Mulct-APPS"].includes(m)) {
              v.price = Helper.getCalculateAPPS(parseInt(v.td_passenger_f));
            } else if (["Landing", "Mulct-Landing"].includes(m)) {
              v.price = Helper.getCalculateLanding(v.td_arrival_freight_f);
            } else if (["Parking", "Mulct-Parking"].includes(m)) {
              v.price = Helper.getCalculateParking(
                v.date_diff,
                v.td_arrival_freight_f
              );
            } else {
              v.price = 0;
            }
            v.discount = v.id_dis_bath;
            v.percent = v.id_dis_per;
            v.changePrice = v.id_adjust;
            v.total = v.id_sum;
            v.remark = v.id_remark;
          });
          this.setState(
            {
              form: {
                ...this.state.form,
                ...data,
                select_airline: al ? al.al_code_name : "",
                select_incometype: data.i_revenue_id,
                select_year: Helper.GetYear(new Date(data.i_doc_date)),
                select_month: Helper.GetMonth(new Date(data.i_doc_date)),
                val_payablename: data.apa_id,
                select_psc_date: data.i_psc_half,
                i_inv_type: "1",
                i_mulct_startdate: data.i_mulct_startdate
                  ? Helper.CastDate(new Date(data.i_mulct_startdate))
                  : Helper.CastDate(Helper.addDays(data.i_due_date)),
                i_doc_no: "",
                select_airline_id: al.al_id,
                select_incometype_text: fRevenue ? fRevenue.rc_code : "",
                select_incometype_id: fRevenue ? fRevenue.rt_id : 0,
                select_incometype_description: fRevenue
                  ? fRevenue.rc_description
                  : "",
                revenue_select: fRevenue,
                revenue_filter: filterRevenue,
              },
              addr: {
                apa_name: data.apa_name,
                apa_house_no: data.apa_house_no,
                apa_tax_id: data.apa_tax_id,
                apa_amphur_code: data.apa_amphur_code,
                apa_province_code: data.apa_province_code,
                apa_postcode: data.apa_postcode,
                apa_tel: data.apa_tel,
                apa_country: data.apa_country,
              },
              summary: {
                getTotalPrice: Helper.CastCurrency(data.i_sum),
                getDiscount: Helper.CastCurrency(data.i_discount),
                getTotalPriceChange: Helper.CastCurrency(tempAdjust),
                getTotal: Helper.CastCurrency(data.i_total),
                calculateDiscount: Helper.CastCurrency(data.i_total),
                data: detail,
              },
            },
            () => {
              this.props.handlerIsLoading(false);
              this.tableFlightCheck.current.initData();
              this.getPaymentName();
            }
          );
        }
      });
  };
  async componentDidMount() {
    this.props.handlerIsLoading(true);
    await this.getAirline();
    await this.getIncomeType();
    await this.getPaymentName();
    await this.getAddr();
    const years = [<option key="y" value=""></option>];
    const dt = new Date();
    const yearnow = dt.getFullYear();
    for (let i = yearnow; i >= this.state.yearstart; i--) {
      years.push(
        <option key={`y${i}`} value={i}>
          {i}
        </option>
      );
    }
    this.setState({
      year: years,
    });
    const months = [];
    this.state.monthLists.forEach((item, index) => {
      months.push(
        <option key={`m${index}`} value={index}>
          {item}
        </option>
      );
    });
    this.setState({
      month: months,
    });
    if (this.getParams("goto") === "createinvoice") {
      this.setState(
        {
          form: {
            ...this.state.form,
            select_airline: this.getParams("filter_airline"),
            select_incometype: this.getParams("filter_type"),
            select_year: this.getParams("filter_year"),
            select_month: this.getParams("filter_month"),
            val_invoicedate: this.getDateNow(),
            val_invoiceexpire: Helper.CastDate(
              Helper.GetPlusDate(new Date(this.getDateNow()), 15)
            ),
          },
        },
        () => {
          this.handleFiltering();
        }
      );
    } else {
      this.setState({
        form: {
          val_invoicedate: this.getDateNow(),
          val_invoiceexpire: Helper.CastDate(
            Helper.GetPlusDate(new Date(this.getDateNow()), 15)
          ),
        },
      });
    }

    const id = this.props.match.params.id;
    if (!id) this.props.handlerIsLoading(false);
  }
  updateFieldAddr = (e) => {
    this.setState({
      addr: { ...this.state.addr, [e.target.name]: e.target.value },
    });
  };
  updateField = (e) => {
    if (e.target.name === "val_payablename") {
      const find = this.state.paymentAddr.find(
        (i) => e.target.value.toString() === i.apa_id.toString()
      );
      console.log(find);
      const uniqueAmph = [
        ...new Set(
          this.state.addrLists
            .filter(
              (item) => item.province === find.apa_province_code && item.amphoe
            )
            .map((item) => {
              return item.amphoe;
            })
        ),
      ].sort();
      const amph = [
        ...uniqueAmph
          .filter((item) => item)
          .map((item) => {
            return (
              <option key={"amp" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      const uniqueDistrict = [
        ...new Set(
          this.state.addrLists
            .filter(
              (item) =>
                item.amphoe === find.apa_amphur_code &&
                item.province === find.apa_province_code &&
                item.district
            )
            .map((item) => {
              return item.district;
            })
        ),
      ].sort();
      const district = [
        ...uniqueDistrict
          .filter((item) => item)
          .map((item) => {
            return (
              <option key={"tum" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];

      const uniqueZipcode = [
        ...new Set(
          this.state.addrLists
            .filter(
              (item) =>
                item.district === find.apa_tambon_code &&
                item.amphoe === find.apa_amphur_code &&
                item.province === find.apa_province_code &&
                item.zipcode
            )
            .map((item) => {
              return item.zipcode;
            })
        ),
      ].sort();
      const zipcode = [
        ...uniqueZipcode
          .filter((item) => item)
          .map((item) => {
            return (
              <option key={"zip" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];

      this.setState({
        addr: find,
        amphur: amph,
        tambon: district,
        zipcode: zipcode,
      });
    }
    if (e.target.name === "apa_province_code") {
      const unique = [
        ...new Set(
          this.state.addrLists
            .filter((item) => item.province === e.target.value && item.amphoe)
            .map((item) => {
              return item.amphoe;
            })
        ),
      ];
      unique.sort();
      const amph = [
        ...unique
          .filter((item) => item)
          .map((item) => {
            return (
              <option key={"amp" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      this.setState({
        addr: {
          ...this.state.addr,
          [e.target.name]: e.target.value,
          apa_amphur_code: "",
          apa_tambon_code: "",
          apa_postcode: "",
        },
        amphur: amph,
      });
    } else if (e.target.name === "apa_amphur_code") {
      const unique = [
        ...new Set(
          this.state.addrLists
            .filter(
              (item) =>
                item.amphoe === e.target.value &&
                item.province === this.state.form.apa_province_code &&
                item.district
            )
            .map((item) => {
              return item.district;
            })
        ),
      ];
      unique.sort();
      const district = [
        ...unique
          .filter((item) => item)
          .map((item) => {
            return (
              <option key={"tum" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      this.setState({
        addr: {
          ...this.state.addr,
          [e.target.name]: e.target.value,
          apa_tambon_code: "",
          apa_postcode: "",
        },
        tambon: district,
      });
    } else if (e.target.name === "apa_tambon_code") {
      const unique = [
        ...new Set(
          this.state.addrLists
            .filter(
              (item) =>
                item.district === e.target.value &&
                item.amphoe === this.state.form.apa_amphur_code &&
                item.province === this.state.form.apa_province_code &&
                item.zipcode
            )
            .map((item) => {
              return item.zipcode;
            })
        ),
      ];
      unique.sort();
      let zipFirst = "";
      const zipcode = [
        ...unique
          .filter((item) => item)
          .map((item) => {
            if (zipFirst === "") {
              zipFirst = item;
            }
            return (
              <option key={"zip" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      this.setState({
        addr: {
          ...this.state.addr,
          [e.target.name]: e.target.value,
          apa_postcode: zipFirst,
        },
        zipcode: zipcode,
      });
    }

    if (e.target.name === "select_airline") {
      const find = this.state.airlines.find(
        (i) => e.target.value.toString() === i.al_code_name.toString()
      );
      this.setState(
        {
          form: {
            ...this.state.form,
            [e.target.name]: e.target.value,
            select_airline_id: find.al_id,
          },
        },
        () => {
          this.getPaymentName();
        }
      );
    } else if (e.target.name === "val_invoicedate") {
      const expire = this.calcuExpireDate(
        e.target.value,
        this.state.form.select_incometype_text,
        this.state.form.select_psc_date
      );
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          val_invoiceexpire: Helper.CastDate(expire),
        },
      });
    } else if (e.target.name === "select_psc_date") {
      const expire = this.calcuExpireDate(
        this.state.form.val_invoicedate,
        this.state.form.select_incometype_text,
        e.target.value
      );
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          val_invoiceexpire: Helper.CastDate(expire),
        },
      });
    } else if (e.target.name === "select_incometype") {
      const fRevenue = this.state.revenue.find(
        (i) => `${i.rc_id}` === `${e.target.value}`
      );
      const filterRevenue = this.state.revenue.filter(
        (i) => i.rc_code === fRevenue.rc_code
      );
      const expire = this.calcuExpireDate(
        this.state.form.val_invoicedate,
        fRevenue.rc_code,
        this.state.form.select_psc_date
      );
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          select_incometype_text: fRevenue ? fRevenue.rc_code : "",
          select_incometype_id: fRevenue ? fRevenue.rt_id : 0,
          select_incometype_description: fRevenue
            ? fRevenue.rc_description
            : "",
          revenue_select: fRevenue,
          revenue_filter: filterRevenue,
          val_invoiceexpire: Helper.CastDate(expire),
        },
      });
    } else if (e.target.name === "adjust_dept_dom") {
      this.setState({
        summary: {
          ...this.state.summary,
          getTotal:
            parseFloat(this.state.summary.getDeptDom) +
            parseFloat(this.state.summary.getDeptInter) +
            parseFloat(this.state.summary.calculateDiscount) +
            parseFloat(this.state.form.adjust_dept_dom) +
            parseFloat(Number(e.target.value) ? e.target.value : 0),
        },
        form: { ...this.state.form, [e.target.name]: e.target.value },
      });
    } else if (e.target.name === "adjust_dept_inter") {
      this.setState({
        summary: {
          ...this.state.summary,
          getTotal:
            parseFloat(this.state.summary.getDeptDom) +
            parseFloat(this.state.summary.getDeptInter) +
            parseFloat(this.state.summary.calculateDiscount) +
            parseFloat(this.state.form.adjust_dept_inter) +
            parseFloat(Number(e.target.value) ? e.target.value : 0),
        },
        form: { ...this.state.form, [e.target.name]: e.target.value },
      });
    } else if (e.target.name === "i_inv_type") {
      const filterRevenue = this.state.revenue.find(
        (i) => `${i.rc_id}` === `${this.state.form.select_incometype}`
      );
      if (!filterRevenue) {
        Notifications.NotiWarning({ msg: "กรุณาเลือกประเภทรายได้" });
        return;
      }
      const revenue = this.state.revenue.filter((i) => {
        const a = [
          "Mulct-Landing",
          "Mulct-Parking",
          "Mulct-APPS",
          "Mulct-PSC",
        ].includes(i.rc_code);
        const b = filterRevenue.rc_code;
        const c = i.rc_code.includes(filterRevenue.rc_code);
        const d = e.target.value === "1";
        return (a && b && c && d) || !d;
      });
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          revenue_filter: revenue,
        },
      });
    } else if (e.target.name === "i_remark") {
      let val = e.target.value;
      if (val.length > 50) val = val.substring(0, 50);
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: val,
        },
      });
    } else {
      this.setState({
        form: { ...this.state.form, [e.target.name]: e.target.value },
      });
    }
  };
  calcuExpireDate = (date, type, isHalf) => {
    if (!isHalf) isHalf = "0";
    console.log("date", date);
    console.log("type", type);
    console.log("isHalf", isHalf);
    const inputDate = new Date(date);
    console.log("inputDate", inputDate);
    let expire = Helper.GetPlusDate(inputDate, 15);
    console.log("expire 1", expire);
    if (["PSC", "Mulct-PSC"].includes(type)) {
      if (isHalf === "0") {
        const tempDate = new Date(
          inputDate.getFullYear(),
          inputDate.getMonth(),
          0
        );
        const lastDay = tempDate.getDate();
        expire = new Date(tempDate.getFullYear(), tempDate.getMonth(), lastDay);
      }
      if (isHalf === "1") {
        expire = new Date(inputDate.getFullYear(), inputDate.getMonth(), 15);
      }
    }
    console.log("expire 2", expire);
    return expire;
  };
  calcuRateIncome = () => {
    if (this.state.step === 1) {
      const fnd = this.state.summary.data.find(
        (item) => item.td_status !== "3"
      );
      if (fnd) {
        Notifications.NotiWarning({ msg: "โปรดยืนยันเที่ยวบินให้ครบถ้วน" });
        return;
      }
      this.setState({ step: 2 }, () => {
        this.handleFiltering();
      });
    }
    if (this.state.step === 2) {
      this.setState({ step: 3 }, () => {
        this.handlerIsLoading(false);
      });
    }
    if (this.state.step === 3) {
      const id = this.props.match.params.id;
      const form = {
        ap_id: Helper.GetUserData().ap_id,
        al_id: this.state.form.select_airline_id
          ? this.state.form.select_airline_id
          : Helper.GetUserData().al_id,
        i_doc_no: this.state.form.i_doc_no,
        i_doc_date: this.state.form.val_invoicedate,
        i_due_date: this.state.form.val_invoiceexpire,
        apa_id: this.state.addr.apa_id,
        apa_name: this.state.addr.apa_name,
        apa_tax_id: this.state.addr.apa_tax_id,
        apa_house_no: this.state.addr.apa_house_no,
        apa_amphur_code: this.state.addr.apa_amphur_code,
        apa_province_code: this.state.addr.apa_province_code,
        apa_postcode: this.state.addr.apa_postcode,
        apa_tel: this.state.addr.apa_tel,
        apa_country: this.state.addr.apa_country,
        apa_building_name: this.state.addr.apa_building_name,
        apa_moo: this.state.addr.apa_moo,
        apa_email: this.state.addr.apa_email,
        apa_road: this.state.addr.apa_road,
        apa_soi: this.state.addr.apa_soi,
        apa_tambon_code: this.state.addr.apa_tambon_code,
        i_revenue: this.state.form.select_incometype,
        i_total: this.state.summary.getTotal,
        i_sum: this.state.summary.getTotalPrice,
        i_discount:
          parseFloat(this.state.summary.getDiscountDom) +
          parseFloat(this.state.summary.getDiscountInter),
        i_mulct:
          parseFloat(this.state.summary.getDeptDom) +
          parseFloat(this.state.summary.getDeptInter),
        i_mulct_dom: this.state.summary.getDeptDom,
        i_mulct_inter: this.state.summary.getDeptInter,
        i_adjust:
          parseFloat(this.state.form.adjust_dept_dom) +
          parseFloat(this.state.form.adjust_dept_inter),
        i_adjust_dom: this.state.form.adjust_dept_dom,
        i_adjust_inter: this.state.form.adjust_dept_inter,
        i_remark: this.state.form.i_remark,
        ref: id ? id : "",
        i_special: this.props.isSpecial ? 1 : 0,
        i_revenue_type: this.state.form.select_incometype_id,
        i_revenue_text: this.state.form.select_incometype_text,
        i_inv_type: this.state.form.i_inv_type,
        i_mulct_startdate: this.state.form.i_mulct_startdate,
        i_psc_half: ["PSC", "Mulct-PSC"].includes(
          this.state.form.select_incometype_text
        )
          ? !this.state.form.select_psc_date ||
            this.state.form.select_psc_date === "0"
            ? "0"
            : "1"
          : "",
        i_month: this.state.form.select_month,
        i_year: this.state.form.select_year,
        cgd_user: Helper.GetUserData().u_cgd_user,
        cgd_pass: Helper.GetUserData().u_cgd_pass,
        i_sum_dom: this.state.summary.sumDom,
        i_sum_int: this.state.summary.sumInter,
        sum_dom: this.state.summary.sumDom,
        sum_inter: this.state.summary.sumInter,
        total_dom: this.state.summary.totalDom,
        total_inter: this.state.summary.totalInter,
      };

      Notifications.NotiAsync(
        axios.post(`${process.env.REACT_APP_API_URL}/v1/invoice`, {
          form,
        }),
        (resp) => {
          const id = resp.data.id;
          const inv = resp.data.inv;
          this.setState({ i_id: id });
          const data = this.state.summary.data;
          const revenue = this.state.form.select_incometype_text;
          axios
            .post(`${process.env.REACT_APP_API_URL}/v1/invoice/detail`, {
              data,
              i_id: id,
              revenue: revenue,
            })
            .then((resp) => {
              this.handlerIsLoading(false);
              Notifications.NotiSuccess();
              SD.Dialog({
                header: "หมายเลขใบแจ้งหนี้!",
                msg: inv,
                type: "info",
                fnConfirm: () => {
                  this.props.history.push(
                    `/invoice${this.props.isSpecial ? "-special" : ""}`
                  );
                },
              });
            });
        },
        (err) => {
          this.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    }
  };

  getParams = (p) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    return p !== "all" ? url.searchParams.get(p) : window.location.search;
  };

  getFileDocx = (name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/invoices/docx/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name);
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };

  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>สร้างใบแจ้งหนี้</div>
            {this.state.step === 3 && false && (
              <div>
                <Button
                  fullwidth
                  className="styleBtn pink"
                  variant="contained"
                  style={{
                    textTransform: "none",
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                  size="small"
                  onClick={() => {
                    this.getFileDocx(`invoice-${this.state.i_id}.docx`);
                  }}
                >
                  WORD
                </Button>
              </div>
            )}
          </div>
        </div>
        {this.state.step !== 3 && (
          <div className="box-S4 flex-between mgBottom">
            <div className="bContentMain">
              <div className="bNameContent"></div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S1">
                    <FormControl
                      fullWidth
                      disabled={
                        this.state.step !== 1 || this.props.match.params.id
                      }
                    >
                      <InputLabel shrink>สายการบิน</InputLabel>
                      <NativeSelect
                        native="true"
                        name="select_airline"
                        value={
                          this.state.form.select_airline
                            ? this.state.form.select_airline
                            : ""
                        }
                        onChange={this.updateField}
                      >
                        {this.state.airline}
                      </NativeSelect>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                  </div>
                  <div className="box-S1">
                    <FormControl
                      fullWidth
                      disabled={
                        this.state.step !== 1 || this.props.match.params.id
                      }
                    >
                      <InputLabel shrink>ประเภทรายได้</InputLabel>
                      <NativeSelect
                        native="true"
                        name="select_incometype"
                        value={
                          this.state.form.select_incometype
                            ? this.state.form.select_incometype
                            : ""
                        }
                        onChange={this.updateField}
                      >
                        {this.state.income_type}
                      </NativeSelect>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                    {["PSC", "Mulct-PSC"].includes(
                      this.state.form.select_incometype_text
                    ) && (
                      <FormControl
                        fullWidth
                        disabled={
                          this.state.step !== 1 || this.props.match.params.id
                        }
                      >
                        <InputLabel shrink>ช่วงวันที่</InputLabel>
                        <NativeSelect
                          native="true"
                          name="select_psc_date"
                          value={
                            this.state.form.select_psc_date
                              ? this.state.form.select_psc_date
                              : ""
                          }
                          onChange={this.updateField}
                        >
                          <option key="hfm0" value="0">
                            1 - 15
                          </option>
                          <option key="hfm1" value="1">
                            16 - สิ้นเดือน
                          </option>
                        </NativeSelect>
                        <FormHelperText> </FormHelperText>
                      </FormControl>
                    )}
                  </div>
                  <div className="box-S1">
                    <FormControl
                      fullWidth
                      disabled={
                        this.state.step !== 1 || this.props.match.params.id
                      }
                    >
                      <InputLabel shrink>ปี</InputLabel>
                      <NativeSelect
                        native="true"
                        name="select_year"
                        value={
                          this.state.form.select_year
                            ? this.state.form.select_year
                            : ""
                        }
                        onChange={this.updateField}
                      >
                        {this.state.year}
                      </NativeSelect>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                  </div>
                  <div className="box-S1">
                    <FormControl
                      fullWidth
                      disabled={
                        this.state.step !== 1 || this.props.match.params.id
                      }
                    >
                      <InputLabel shrink>เดือน</InputLabel>
                      <NativeSelect
                        native="true"
                        name="select_month"
                        value={
                          this.state.form.select_month
                            ? this.state.form.select_month
                            : ""
                        }
                        onChange={this.updateField}
                      >
                        {this.state.month}
                      </NativeSelect>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                {this.state.step === 1 && !this.props.match.params.id && (
                  <div className="bRow flex-between">
                    <div></div>
                    <Button
                      style={{}}
                      className="styleBtn space blue"
                      onClick={() => {
                        this.handleFiltering(true);
                      }}
                    >
                      ค้นหา
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลใบแจ้งหนี้</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S4">
                  <FormControl component="fieldset" className="groupRadio">
                    <FormLabel component="legend"></FormLabel>
                    <RadioGroup
                      aria-label="Invoice Type"
                      name="i_inv_type"
                      row
                      onChange={this.updateField}
                      disabled={this.state.step !== 1}
                      value={
                        this.state.form.i_inv_type
                          ? this.state.form.i_inv_type
                          : "0"
                      }
                    >
                      <FormControlLabel
                        value="0"
                        control={
                          <Radio
                            disabled={
                              this.state.step !== 1 ||
                              this.props.match.params.id
                            }
                          />
                        }
                        label="ออกปกติ"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio disabled={this.state.step !== 1} />}
                        label={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            ออกแบบมีค่าปรับ เริ่มตั้งแต่วันที่
                            <TextField
                              label=""
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              disabled={
                                this.state.step !== 1 ||
                                !this.state.form.i_inv_type ||
                                this.state.form.i_inv_type === "0"
                              }
                              name="i_mulct_startdate"
                              value={
                                this.state.form.i_mulct_startdate
                                  ? this.state.form.i_mulct_startdate
                                  : ""
                              }
                              onChange={this.updateField}
                            />
                          </div>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="เลขใบแจ้งหนี้"
                    name="i_doc_no"
                    value={
                      this.state.form.i_doc_no ? this.state.form.i_doc_no : ""
                    }
                    onChange={this.updateField}
                    disabled
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันออกใบแจ้งหนี้"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.step !== 1}
                    name="val_invoicedate"
                    value={
                      this.state.form.val_invoicedate
                        ? this.state.form.val_invoicedate
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth disabled={this.state.step !== 1}>
                    <InputLabel>สำนักงานสายการบิน</InputLabel>
                    <NativeSelect
                      native="true"
                      disabled={this.state.step !== 1}
                      onChange={this.updateField}
                      name="val_payablename"
                      value={
                        this.state.form.val_payablename
                          ? this.state.form.val_payablename
                          : ""
                      }
                    >
                      <option aria-label="None" value="" />
                      {this.state.paymentOption}
                    </NativeSelect>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันครบกำหนดชำระ"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.step !== 1}
                    name="val_invoiceexpire"
                    value={
                      this.state.form.val_invoiceexpire
                        ? this.state.form.val_invoiceexpire
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S4">
                  <TextField
                    fullWidth
                    label="หมายเหตุ (ไม่เกิน 50 ตัวอักษร)"
                    name="i_remark"
                    value={this.state.form.i_remark}
                    onChange={this.updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลผู้ชำระเงิน</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่อผู้ชำระเงิน"
                    name="apa_name"
                    onChange={this.updateFieldAddr}
                    value={
                      this.state.addr.apa_name ? this.state.addr.apa_name : ""
                    }
                  />
                </div>

                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ที่อยู่"
                    name="apa_house_no"
                    value={
                      this.state.addr.apa_house_no
                        ? this.state.addr.apa_house_no
                        : ""
                    }
                    onChange={this.updateFieldAddr}
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>จังหวัด</InputLabel>
                    <NativeSelect
                      native="true"
                      name="apa_province_code"
                      onChange={this.updateField}
                      value={
                        this.state.addr.apa_province_code
                          ? this.state.addr.apa_province_code
                          : ""
                      }
                    >
                      <option aria-label="None" value="" />
                      {this.state.province}
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่ออาคาร"
                    name="apa_building_name"
                    value={
                      this.state.addr.apa_building_name
                        ? this.state.addr.apa_building_name
                        : ""
                    }
                    onChange={this.updateFieldAddr}
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>อำเภอ/เขต</InputLabel>
                    <NativeSelect
                      native="true"
                      name="apa_amphur_code"
                      onChange={this.updateField}
                      value={
                        this.state.addr.apa_amphur_code
                          ? this.state.addr.apa_amphur_code
                          : ""
                      }
                      disabled={this.state.addr.apa_province_code === ""}
                    >
                      <option aria-label="None" value="" />
                      {this.state.amphur}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="หมู่"
                    name="apa_moo"
                    value={
                      this.state.addr.apa_moo ? this.state.addr.apa_moo : ""
                    }
                    onChange={this.updateFieldAddr}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>ตำบล/แขวง</InputLabel>
                    <NativeSelect
                      native="true"
                      name="apa_tambon_code"
                      onChange={this.updateField}
                      value={
                        this.state.addr.apa_tambon_code
                          ? this.state.addr.apa_tambon_code
                          : ""
                      }
                      disabled={this.state.addr.apa_amphur_code === ""}
                    >
                      <option aria-label="None" value="" />
                      {this.state.tambon}
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ถนน"
                    name="apa_road"
                    value={
                      this.state.addr.apa_road ? this.state.addr.apa_road : ""
                    }
                    onChange={this.updateFieldAddr}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>รหัสไปรษณีย์</InputLabel>
                    <NativeSelect
                      native="true"
                      name="apa_postcode"
                      onChange={this.updateField}
                      value={
                        this.state.addr.apa_postcode
                          ? this.state.addr.apa_postcode
                          : ""
                      }
                      disabled={this.state.addr.apa_tambon_code === ""}
                    >
                      <option aria-label="None" value="" />
                      {this.state.zipcode}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    name="apa_tel"
                    value={
                      this.state.addr.apa_tel ? this.state.addr.apa_tel : ""
                    }
                    onChange={this.updateFieldAddr}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ประเทศ"
                    name="apa_country"
                    value={
                      this.state.addr.apa_country
                        ? this.state.addr.apa_country
                        : ""
                    }
                    onChange={this.updateFieldAddr}
                  />
                </div>

                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="Email"
                    name="apa_email"
                    value={
                      this.state.addr.apa_email ? this.state.addr.apa_email : ""
                    }
                    onChange={this.updateFieldAddr}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.step === 3 && (
          <div className="box-S4 flex-between mgBottom">
            <div className="bContentMain">
              <div className="bNameContent flex-between">
                <div>รายการชำระ</div>
              </div>
              <div className="bContent">
                <div className="boxListInvoice">
                  {this.state.form.revenue_filter.map((i) => {
                    return (
                      <div className="bRow flex-between">
                        <div className="textlist">{i.rc_description}</div>
                        <div className="textlist-num">
                          {i.rc_source_type === "D"
                            ? Helper.CastCurrency(this.state.summary.sumDom)
                            : Helper.CastCurrency(this.state.summary.sumInter)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="boxTotalInvoice">
                  <div className="bRow flex-between">
                    <div className="textlist">รวมเป็นเงิน</div>
                    <div className="textlist-num">
                      {Helper.CastCurrency(this.state.summary.getTotalPrice)}
                    </div>
                  </div>
                  {this.state.form.revenue_filter.length === 1 && (
                    <div className="bRow flex-between">
                      <div className="textlist">ส่วนลด + ปรับยอด</div>
                      <div className="textlist-num">
                        {Helper.CastCurrency(this.state.summary.getDiscountDom)}
                      </div>
                    </div>
                  )}
                  {this.state.form.revenue_filter.length === 2 && (
                    <div className="bRow flex-between">
                      <div className="textlist">
                        ส่วนลด + ปรับยอด (ในประเทศ)
                      </div>
                      <div className="textlist-num">
                        {Helper.CastCurrency(this.state.summary.getDiscountDom)}
                      </div>
                    </div>
                  )}
                  {this.state.form.revenue_filter.length === 2 && (
                    <div className="bRow flex-between">
                      <div className="textlist">
                        ส่วนลด + ปรับยอด (ต่างประเทศ)
                      </div>
                      <div className="textlist-num">
                        {Helper.CastCurrency(
                          this.state.summary.getDiscountInter
                        )}
                      </div>
                    </div>
                  )}
                  <div className="bRow flex-between">
                    <div className="textlist">รวมเป็นเงิน</div>
                    <div className="textlist-num">
                      {Helper.CastCurrency(
                        this.state.summary.calculateDiscount
                      )}
                    </div>
                  </div>
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 1 && (
                      <div className="bRow flex-between">
                        <div className="textlist">ค่าปรับ</div>
                        <div className="textlist-num">
                          {Helper.CastCurrency(this.state.summary.getDeptDom)}
                        </div>
                      </div>
                    )}
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 1 && (
                      <div className="bRow flex-between">
                        <div className="textlist">ปรับยอดค่าปรับ</div>
                        <div className="textlist-num">
                          <TextField
                            fullWidth
                            label=""
                            name="adjust_dept_dom"
                            value={
                              this.state.form.adjust_dept_dom
                                ? this.state.form.adjust_dept_dom
                                : 0
                            }
                            type="text"
                            onChange={this.updateField}
                          />
                        </div>
                      </div>
                    )}

                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 2 && (
                      <div className="bRow flex-between">
                        <div className="textlist">ค่าปรับ (ในประเทศ)</div>
                        <div className="textlist-num">
                          {Helper.CastCurrency(this.state.summary.getDeptDom)}
                        </div>
                      </div>
                    )}
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 2 && (
                      <div className="bRow flex-between">
                        <div className="textlist">
                          ปรับยอดค่าปรับ (ในประเทศ)
                        </div>
                        <div className="textlist-num">
                          <TextField
                            fullWidth
                            label=""
                            name="adjust_dept_dom"
                            value={
                              this.state.form.adjust_dept_dom
                                ? this.state.form.adjust_dept_dom
                                : 0
                            }
                            type="text"
                            onChange={this.updateField}
                          />
                        </div>
                      </div>
                    )}

                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 2 && (
                      <div className="bRow flex-between">
                        <div className="textlist">ค่าปรับ (ต่างประเทศ)</div>
                        <div className="textlist-num">
                          {Helper.CastCurrency(this.state.summary.getDeptInter)}
                        </div>
                      </div>
                    )}
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 2 && (
                      <div className="bRow flex-between">
                        <div className="textlist">
                          ปรับยอดค่าปรับ (ต่างประเทศ)
                        </div>
                        <div className="textlist-num">
                          <TextField
                            fullWidth
                            label=""
                            name="adjust_dept_inter"
                            value={
                              this.state.form.adjust_dept_inter
                                ? this.state.form.adjust_dept_inter
                                : 0
                            }
                            type="text"
                            onChange={this.updateField}
                          />
                        </div>
                      </div>
                    )}
                </div>
                <div className="boxGrandTotalInvoice">
                  <div className="bRow flex-between">
                    <div className="textlist">รวมทั้งสิ้น</div>
                    <div className="textlist-num">
                      {Helper.CastCurrency(this.state.summary.getTotal)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.step !== 1 && (
          <div className="box-S4 flex-between mgBottom">
            <div className="bContentMain">
              <div className="bNameContent">ผลรวมรายได้ทั้งหมด</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div className="box-S1">
                    <TextField
                      fullWidth
                      label="ยอดเดิม"
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                      value={
                        this.state.summary.getTotalPrice
                          ? Helper.CastCurrency(
                              this.state.summary.getTotalPrice
                            )
                          : 0
                      }
                    />
                  </div>
                  <div className="box-S1">
                    <TextField
                      fullWidth
                      label="ส่วนลดบาท"
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                      value={Helper.CastCurrency(
                        this.state.summary.getDiscount
                      )}
                    />
                  </div>
                  <div className="box-S1">
                    <TextField
                      fullWidth
                      label="ปรับยอด"
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                      value={Helper.CastCurrency(
                        this.state.summary.getTotalPriceChange
                      )}
                    />
                  </div>
                  <div className="box-S1">
                    <TextField
                      fullWidth
                      label="คงเหลือ"
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                      value={
                        this.state.summary.calculateDiscount
                          ? Helper.CastCurrency(
                              this.state.summary.calculateDiscount
                            )
                          : 0
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการเที่ยวการบิน</div>
            <div className="bContent">
              {this.state.step === 1 && (
                <TableFlightCheckInvoice
                  ref={this.tableFlightCheck}
                  handlerIsLoading={this.handlerIsLoading.bind(this)}
                  handleFlights={this.handleFlights.bind(this)}
                  isSpecial={this.props.isSpecial}
                  data={this.state.summary.data}
                />
              )}
              {this.state.step !== 1 && (
                <TableFlightCheckInvoiceWithInput
                  ref={this.tableFlightCheckWithInput}
                  isReadonly={this.state.step === 3}
                  data={this.state.summary.data}
                  handleSummaryResult={this.handleSummaryResult.bind(this)}
                  handlerIsLoading={this.handlerIsLoading.bind(this)}
                  isCreate={true}
                />
              )}
            </div>
          </div>
        </div>
        <Bottom2Function
          back={
            this.state.step === 1
              ? `/invoice`
              : this.backFunctionCallback.bind(this)
          }
          styleGreenBtn={{ width: "auto" }}
          textGreenBtn={
            this.state.step === 1
              ? `คำนวณรายได้`
              : this.state.step === 2
              ? `สรุปข้อมูล`
              : `บันทึกและออกใบแจ้งหนี้`
          }
          insertData={() => {
            this.calcuRateIncome();
          }}
        />
      </div>
    );
  }
}

export default withRouter(InvoiceCreate);
