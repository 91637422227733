import React from "react";
import clsx from "clsx";
import MainMenuList from "./MainMenuList";
import { makeStyles } from "@material-ui/core/styles";
import { SwipeableDrawer, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import UserFuntion from "./UserFuntion";

const useStyles = makeStyles({
  list: {
    width: 255,
  },
  fullList: {
    width: "auto",
  },
});

export default function MainMenuMobile() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* เพิ่ม Component Munu List */}
      <UserFuntion key={-2} />
      <MainMenuList key={-3} />
    </div>
  );

  return (
    <div className="Lmobile">
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            onClick={toggleDrawer(anchor, true)}
            aria-label="MenuIcon"
            className="fixColorTextThemes"
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
