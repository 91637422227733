/** @format */

import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { Button, Tooltip } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MaterialTable from "material-table";
import axios from "axios";
import Helper from "../../global/Helper";

export default function StickyHeadTable(props) {
  const [valuedata, setValuedata] = useState([]);

  useEffect(() => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/revenuecategorys`;
    axios.get(apiUrl).then((res) => {
      setValuedata(res.data);
      props.handlerIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className="tabelData">
      <TableContainer>
        <MaterialTable
          title=""
          columns={[
            { title: "ประเภทรายได้", field: "rt_name", align: "center" },
            { title: "ชื่อรายได้หลัก", field: "rc_name", align: "center" },
            { title: "รายละเอียด", field: "rc_description", align: "center" },
            {
              title: "",
              field: "rt_id",
              render: (rowData) => {
                return (
                  <Tooltip title="ดู" arrow>
                    <Button
                      href={`/revenue-category/detail/${rowData.rc_id}`}
                      className="styleBtn blue w-auto"
                      variant="contained"
                      style={{ textTransform: "none", marginRight: "5px" }}
                      size="small"
                    >
                      <AssignmentIcon />
                    </Button>
                  </Tooltip>
                );
              },
            },
          ]}
          data={valuedata}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            search: true,
            exportButton: true,
            filtering: false,
            actionsColumnIndex: -1,
            grouping: true,
            exportPdf: (columns, data) => {
              const temp = data.map((i) => [
                i.rt_name,
                i.rc_name,
                i.rc_description,
              ]);
              Helper.exportPDF(
                ["ประเภทรายได้", "ชื่อรายได้หลัก", "รายละเอียด"],
                temp
              );
            },
          }}
        />
      </TableContainer>
    </Paper>
  );
}
