import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import SD from "../../../global/SwalDialog";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Bottom3Function from "../../../global/Bottom3Function";
import Notifications from "../../../global/Notifications";

function RevenueCategorySubDetail(props) {
  const history = useHistory();
  const [form, setState] = useState({
    rc_id: "",
    rcs_name: "",
    rcs_description: "",
    rcs_public_revenue: "",
    rcs_fund_revenue: "",
    rcs_welfare_revenue: "",
  });
  const [error, setError] = useState({
    rc_id: false,
    rcs_name: false,
    rcs_description: false,
    rcs_public_revenue: false,
    rcs_fund_revenue: false,
    rcs_welfare_revenue: false,
  });
  const [revenueType, setRevenueTypeState] = useState([
    <option key="a0" value="0"></option>,
  ]);
  const { id } = useParams();

  const printValues = (e) => {
    e.preventDefault();
  };

  const updateField = (e) => {
    const fieldLists = [
      "rc_id",
      "rcs_name",
      "rcs_description",
      "rcs_description",
      "rcs_public_revenue",
      "rcs_fund_revenue",
      "rcs_welfare_revenue",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const UpdateData = (e) => {
    props.handlerIsLoading(true);
    if (
      form.rc_id !== "" &&
      form.rcs_name !== "" &&
      // form.rcs_description &&
      form.rcs_public_revenue !== "" &&
      form.rcs_fund_revenue !== "" &&
      form.rcs_welfare_revenue !== ""
    ) {
      Notifications.NotiAsync(
        axios.put(
          `${process.env.REACT_APP_API_URL}/v1/revenuecategorysubs/${id}`,
          {
            form,
          }
        ),

        (resp) => {
          history.push("/revenue-category-sub");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        rc_id: form.rc_id === "0",
        rcs_name: !form.rcs_name || form.rcs_name === "",
        // rcs_description: !form.rcs_description || form.rcs_description === "",
        rcs_public_revenue: form.rcs_public_revenue === "",
        rcs_fund_revenue: form.rcs_fund_revenue === "",
        rcs_welfare_revenue: form.rcs_welfare_revenue === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  const DeleteData = (e) => {
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${form.rcs_name} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/revenuecategorysubs/${id}`
          ),

          (resp) => {
            history.push("/revenue-category-sub");
            props.handlerIsLoading(false);
            Notifications.NotiSuccess();
          },
          (err) => {
            props.handlerIsLoading(false);
            Notifications.NotiError({ msg: err.response.data.msg });
          }
        );
      },
    });
  };
  useEffect(() => {
    props.handlerIsLoading(true);
    async function fetchMyData() {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorys`)
        .then((res) => {
          res.data.forEach((item) => {
            setRevenueTypeState((revenueType) => [
              ...revenueType,
              <option key={item.rc_id} value={item.rc_id}>
                {item.rc_name}
              </option>,
            ]);
          });
        });

      await axios
        .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorysubs/${id}`)
        .then((res) => {
          setState(res.data[0]);
        });
      props.handlerIsLoading(false);
    }
    fetchMyData();
    props.handlerIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName">ข้อมูลประเภทรายได้ย่อย</div>
      </div>
      <form onSubmit={printValues}>
        <div className="box-S4 flex-between">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลรายได้ย่อย</div>

            <div className="bContent">
              <div className="bRow">
                <FormControl fullWidth error={error.rc_id}>
                  <InputLabel>ประเภทรายได้หลัก</InputLabel>
                  <NativeSelect
                    native="true"
                    value={form.rc_id ? form.rc_id : "0"}
                    name="rc_id"
                    onChange={updateField}
                  >
                    {revenueType}
                  </NativeSelect>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_name}
                  fullWidth
                  label="ชื่อ"
                  value={form.rcs_name ? form.rcs_name : ""}
                  name="rcs_name"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_description}
                  fullWidth
                  label="รายละเอียด"
                  value={form.rcs_description ? form.rcs_description : ""}
                  name="rcs_description"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_public_revenue}
                  fullWidth
                  type="number"
                  label="รายได้สาธารณะ"
                  value={
                    form.rcs_public_revenue ? form.rcs_public_revenue : "0"
                  }
                  name="rcs_public_revenue"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_fund_revenue}
                  fullWidth
                  type="number"
                  label="รายได้จากกองทุน"
                  value={form.rcs_fund_revenue ? form.rcs_fund_revenue : "0"}
                  name="rcs_fund_revenue"
                  onChange={updateField}
                />
              </div>
              <div className="bRow">
                <TextField
                  error={error.rcs_welfare_revenue}
                  fullWidth
                  type="number"
                  label="รายได้สวัสดิการ"
                  value={
                    form.rcs_welfare_revenue ? form.rcs_welfare_revenue : "0"
                  }
                  name="rcs_welfare_revenue"
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Bottom3Function
        back="/revenue-category-sub"
        updateData={UpdateData}
        deleteData={DeleteData}
      />
    </div>
  );
}

export default RevenueCategorySubDetail;
