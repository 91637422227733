/** @format */

import React, { Component } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";

import { Redirect } from "react-router";
// Components Page
import Bottom2Function from "../../global/Bottom2Function";
import Notifications from "../../global/Notifications";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import Helper from "../../global/Helper";

export class WelfareIncomeAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      revenueCategory: [],
      revenueCategorySub: [],
      rCategory: [],
      form: {
        main: "",
        sub: "",
        i_doc_no: "",
        i_doc_date: Helper.CastDate(new Date()),
        i_due_date: Helper.CastDate(new Date()),
        apa_name: "",
        apa_email: "",
        apa_house_no: "",
        apa_road: "",
        apa_building_name: "",
        apa_moo: "",
        apa_tambon_code: "",
        apa_amphur_code: "",
        apa_province_code: "",
        apa_postcode: "",
        apa_country: "",
        apa_tel: "",
        nName: "",
        n1: 0,
        n2: 0,
        n3: 0,
        n4: 0,
        n5: 0,
        n6: 0,
        n7: 0,
        i_remark: "",
        ref1: "",
        ap_inv_code: "",
      },
      wcLists: [],
      inputAutoComplete: "",
    };
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.insertData = this.insertData.bind(this);
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  insertData = () => {
    this.handlerIsLoading(true);
    let form = this.state.form;
    form.ap_id = Helper.GetUserData().ap_id;
    Notifications.NotiAsync(
      axios.post(`${process.env.REACT_APP_API_URL}/v1/otherincomes`, {
        form,
      }),
      (resp) => {
        this.handlerIsLoading(false);
        this.setState({ redirect: true });
        Notifications.NotiSuccess();
      },
      (err) => {
        this.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };
  getData = async () => {
    let main = [];
    let sub = [];
    let m = [];
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorys`)
      .then((res) => {
        m = res.data;
        res.data.forEach((item) => {
          if (parseInt(item.rt_id) === 3) {
            main.push(
              <option key={"a" + item.rc_id} value={item.rc_id}>
                {item.rc_name}
              </option>
            );
          }
        });
      });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorysubs`)
      .then((res) => {
        res.data.forEach((item) => {
          sub.push(
            <option key={"b" + item.rcs_id} value={item.rcs_id}>
              {item.rcs_name}
            </option>
          );
        });
      });
    let wcLists = [];
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/welfarelists/ap/${
          Helper.GetUserData().ul_id === 1 ? 0 : Helper.GetUserData().al_id
        }
        `
      )
      .then((res) => {
        console.log("here", res.data);
        wcLists = res.data;
      });
    console.log(wcLists);
    this.setState({
      wcLists: wcLists,
      revenueCategory: main,
      revenueCategorySub: sub,
      rCategory: m,
    });
  };
  componentDidMount = () => {
    this.getData();
  };
  updateField = (e) => {
    if (["n1", "n3", "n5", "n6"].includes(e.target.name)) {
      const v = Number(e.target.value) ? Number(e.target.value) : "";
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: v,
          n2: e.target.name === "n1" ? v : this.state.form.n1,
          n4:
            (e.target.name === "n1" ? v : this.state.form.n1) -
            (e.target.name === "n3" ? v : this.state.form.n3),
          n7:
            (e.target.name === "n1" ? v : this.state.form.n1) -
            (e.target.name === "n3" ? v : this.state.form.n3) -
            (e.target.name === "n6" ? v : this.state.form.n6) +
            (e.target.name === "n5" ? v : this.state.form.n5),
        },
      });
    } else if (e.target.name === "main") {
      const fRevenue = this.state.rCategory.find(
        (i) => e.target.value.toString() === i.rc_id.toString()
      );
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          i_revenue_type: fRevenue ? fRevenue.rt_id : 0,
          nName: fRevenue ? fRevenue.rc_name : "",
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/welfare-income" />;
    }
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>รายได้สวัสดิการ</div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ประเภทรายได้</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel>ประเภทรายได้หลัก</InputLabel>
                    <NativeSelect
                      native="true"
                      name="main"
                      onChange={this.updateField}
                    >
                      <option aria-label="None" value="" />
                      {this.state.revenueCategory}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลใบแจ้งหนี้</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เลขที่ใบแจ้งหนี้"
                    name="i_doc_no"
                    disabled
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันที่ออกใบแจ้งหนี้"
                    type="date"
                    name="i_doc_date"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.form.i_doc_date}
                    onChange={this.updateField}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันครบกำหนดชำระ"
                    type="date"
                    name="i_due_date"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.form.i_due_date}
                    onChange={this.updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลผู้ชำระ</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S4">
                  <Autocomplete
                    options={this.state.wcLists}
                    freeSolo
                    disableClearable
                    clearOnBlur={false}
                    getOptionLabel={(option) =>
                      option.wc_welfare_code
                        ? `${option.wc_welfare_code} (${option.wc_name})`
                        : ""
                    }
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        {...params}
                        label="หมายเลขลูกค้า"
                      />
                    )}
                    value={""}
                    onChange={(event, newValue) => {
                      let find = newValue;
                      if (typeof newValue === "string") {
                        find = this.state.wcLists.find((i) => {
                          if (i.wc_welfare_code)
                            return (
                              i.wc_welfare_code.toString().toLowerCase() ===
                              newValue.toString().toLowerCase()
                            );
                          return false;
                        });
                      }
                      if (find) {
                        this.setState({
                          inputAutoComplete: `${find.wc_welfare_code} (${find.wc_name})`,
                          form: {
                            ...this.state.form,
                            apa_name: find.wc_name,
                            apa_email: find.wc_email,
                            apa_house_no: find.wc_house_no,
                            apa_road: find.wc_road,
                            apa_building_name: find.wc_building_name,
                            apa_moo: find.wc_moo,
                            apa_tambon_code: find.wc_tambon_code,
                            apa_amphur_code: find.wc_amphur_code,
                            apa_province_code: find.wc_province_code,
                            apa_postcode: find.wc_postcode,
                            apa_country: find.wc_country ? find.wc_country : "",
                            apa_tel: find.wc_tel,
                            ref1: find.wc_welfare_code,
                            ap_inv_code: find.ap_inv_code,
                          },
                        });
                      } else {
                        this.setState({
                          inputAutoComplete: ``,
                          form: {
                            ...this.state.form,
                            apa_name: "",
                            apa_email: "",
                            apa_house_no: "",
                            apa_road: "",
                            apa_building_name: "",
                            apa_moo: "",
                            apa_tambon_code: "",
                            apa_amphur_code: "",
                            apa_province_code: "",
                            apa_postcode: "",
                            apa_country: "",
                            apa_tel: "",
                            ref1: "",
                            ap_inv_code: "",
                          },
                        });
                      }
                    }}
                    inputValue={this.state.inputAutoCompleteValue}
                    onInputChange={(event, newInputValue) => {
                      this.setState({
                        inputAutoComplete: newInputValue,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่อผู้ชำระเงิน"
                    name="apa_name"
                    value={
                      this.state.form.apa_name ? this.state.form.apa_name : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="Email"
                    name="apa_email"
                    value={
                      this.state.form.apa_email ? this.state.form.apa_email : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ที่อยู่"
                    name="apa_house_no"
                    value={
                      this.state.form.apa_house_no
                        ? this.state.form.apa_house_no
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ถนน"
                    name="apa_road"
                    value={
                      this.state.form.apa_road ? this.state.form.apa_road : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่ออาคาร"
                    name="apa_building_name"
                    value={
                      this.state.form.apa_building_name
                        ? this.state.form.apa_building_name
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="หมู่"
                    name="apa_moo"
                    value={
                      this.state.form.apa_moo ? this.state.form.apa_moo : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ตำบล/แขวง"
                    name="apa_tambon_code"
                    value={
                      this.state.form.apa_tambon_code
                        ? this.state.form.apa_tambon_code
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="อำเภอ/เขต"
                    name="apa_amphur_code"
                    value={
                      this.state.form.apa_amphur_code
                        ? this.state.form.apa_amphur_code
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="จังหวัด"
                    name="apa_province_code"
                    value={
                      this.state.form.apa_province_code
                        ? this.state.form.apa_province_code
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="รหัสไปรษณีย์"
                    name="apa_postcode"
                    value={
                      this.state.form.apa_postcode
                        ? this.state.form.apa_postcode
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ประเทศ"
                    name="apa_country"
                    value={
                      this.state.form.apa_country
                        ? this.state.form.apa_country
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    name="apa_tel"
                    value={
                      this.state.form.apa_tel ? this.state.form.apa_tel : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการชำระ</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label=""
                    value={this.state.form.nName}
                    onChange={this.updateField}
                    name="nName"
                  />
                </div>
                <div className="box-S1"></div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n1"
                    type="number"
                    value={this.state.form.n1}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  รวมเป็นเงิน
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n2"
                    disabled
                    type="number"
                    value={this.state.form.n2}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  ส่วนลด
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n3"
                    type="number"
                    value={this.state.form.n3}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  เป็นเงิน
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n4"
                    disabled
                    type="number"
                    value={this.state.form.n4}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  ค่าปรับ
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n5"
                    type="number"
                    value={this.state.form.n5}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  ปรับยอดค่าปรับ
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    type="number"
                    onChange={this.updateField}
                    name="n6"
                    value={this.state.form.n6}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  จำนวนเงินทั้งสิ้น
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    type="number"
                    onChange={this.updateField}
                    name="n7"
                    disabled
                    value={this.state.form.n7}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">หมายเหตุ</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S4">
                  <TextField
                    fullWidth
                    label="หมายเหตุ"
                    name="i_remark"
                    onChange={this.updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Bottom2Function
          back="/welfare-income"
          insertData={this.insertData.bind(this)}
        />
      </div>
    );
  }
}

export default WelfareIncomeAdd;
