/** @format */

import React from "react";
import MainMenuMobile from "./MainMenuMobile";
import { Toolbar, AppBar, IconButton } from "@material-ui/core";
import Logout from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
export default function ToolBar(props) {
  const history = useHistory();
  history.listen((location, action) => {
    props.handlerSetActiveMenu(location.pathname.substring(1).split("/")[0]);
  });
  const btnlogout = async (e) => {
    props.handlerIsLoading(true);
    await localStorage.clear();
    await props.handlerIsLoggedIn(false);
    window.location.href = "/login";
  };

  return (
    <div>
      <AppBar position="fixed" className="toolbarMain">
        <Toolbar className="justifyContent-between">
          <MainMenuMobile />
          <div
            className="boxLogoSmall pointer"
            onClick={() => {
              history.push("/");
            }}
          ></div>
          <div>
            <a
              style={{ color: "#024d85", paddingRight: "15px" }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSdukfxtJDowVfYCukNoGt4By5cL836uSxnJq9ssrUEjm1Y71Q/viewform"
              target="_blank"
              rel="noreferrer"
            >
              ข้อสอบถาม/ปัญหาการใช้งานระบบ
            </a>
            <a
              style={{ color: "#024d85" }}
              href="https://drive.google.com/drive/folders/1siBErnh2X8GwWjnkKUKPcqQOENVMzR88"
              target="_blank"
              rel="noreferrer"
            >
              คู่มือและวีดีโอการใช้งานระบบ
            </a>
            <IconButton
              aria-label="Logout"
              className="fixColorTextRed"
              onClick={btnlogout}
            >
              <Logout />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
