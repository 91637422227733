/** @format */

import React, { useState, useEffect } from "react";
import Bottom2Function from "../../global/Bottom2Function";
import Notifications from "../../global/Notifications";
import axios from "axios";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Helper from "../../global/Helper";

function UserProfile(props) {
  const history = useHistory();
  const [form, setState] = useState({
    u_fname: "",
    u_lname: "",
    u_gender: "0",
    u_birthday: "2020-01-01",
    u_email: "",
    u_tel: "",
    ul_id: "",
    u_usename: "",
    u_password: "",
    u_address: "",
    al_id: "0",
    ap_id: "0",
    u_active: "0",
    u_cgd_pass: "",
    u_cgd_user: "",
  });
  const [error, setError] = useState({
    u_fname: false,
    u_lname: false,
    u_gender: false,
    u_birthday: false,
    u_email: false,
    u_tel: false,
    ul_id: false,
    u_usename: false,
    u_password: false,
    u_address: false,
    al_id: false,
    ap_id: false,
    u_active: false,
    u_cgd_pass: false,
    u_cgd_user: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [userlevel, setUserlevel] = useState([<option key="u"></option>]);
  const [airline, setAirline] = useState([]);
  const [airport, setAirport] = useState([<option key="ap"></option>]);
  const [conpass, setConpass] = useState();
  const [conpassCGD, setConpassCGD] = useState();

  const printValues = (e) => {
    e.preventDefault();
  };

  const updateField = (e) => {
    const fieldLists = [
      "ap_id",
      "al_id",
      "u_address",
      "u_password",
      "u_usename",
      "ul_id",
      "u_tel",
      "u_email",
      "u_birthday",
      "u_gender",
      "u_lname",
      "u_fname",
      "u_active",
      "u_cgd_pass",
      "u_cgd_user",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const updateFieldcompass = (e) => {
    setConpass(e.target.value);
  };
  const updateFieldcompassCGD = (e) => {
    setConpassCGD(e.target.value);
  };

  useEffect(() => {
    const fetchMyData = async () => {
      let ultemp = "0",
        altemp = "0",
        aptemp = "0";
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/userlevels`;
      await axios.get(apiUrl).then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={item.ul_id} value={item.ul_id}>
              {item.ul_name}
            </option>
          );
          if (Helper.GetUserData().ul_id !== 3) {
            setUserlevel((userlevel) => [...userlevel, option]);
            if (item.ul_name === "ส่วนกลาง") {
              ultemp = item.ul_id;
            }
          } else {
            if (item.ul_name === "สายการบิน") {
              setUserlevel((userlevel) => [...userlevel, option]);
              ultemp = item.ul_id;
            }
          }
        });
      });

      const apiUrlAirline = `${process.env.REACT_APP_API_URL}/v1/airlines`;
      await axios.get(apiUrlAirline).then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={"al" + item.al_id} value={item.al_id}>
              {item.al_name}
            </option>
          );
          setAirline((airline) => [...airline, option]);
          if (item.al_name === "ไม่ระบุ") {
            altemp = item.al_id;
          }
        });
      });

      const apiUrlAirport = `${process.env.REACT_APP_API_URL}/v1/airports/user`;
      await axios.get(apiUrlAirport).then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={"ap" + item.ap_id} value={item.ap_id}>
              {item.ap_name}
            </option>
          );
          if (Helper.GetUserData().ul_id !== 3) {
            setAirport((airport) => [...airport, option]);
            if (item.ap_name === "ส่วนกลาง") {
              aptemp = item.ap_id;
            }
          } else {
            if (Helper.GetUserData().ap_id === item.ap_id) {
              setAirport((airport) => [...airport, option]);
              aptemp = item.ap_id;
            }
          }
        });
      });
      setState({ ...form, ul_id: ultemp, ap_id: aptemp, al_id: altemp });
    };
    fetchMyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const InsertData = (e) => {
    // console.log(form);
    if (
      form.u_fname !== "" &&
      form.u_birthday !== "" &&
      form.u_email !== "" &&
      form.u_tel !== "" &&
      // form.ul_id &&
      form.u_usename !== ""&&
      form.u_password === conpass &&
      form.u_gender !== "" 
      // &&
      // form.al_id &&
      // form.ap_id &&
      // form.ul_id !== "" &&
      // form.al_id !== "" &&
      // form.ap_id !== "" &&
      // form.u_active !== "" 
      // &&
      // form.u_cgd_pass === conpassCGD
    ) {
      props.handlerIsLoading(true);
      Notifications.NotiAsync(
        axios.post(`${process.env.REACT_APP_API_URL}/v1/users`, {
          form,
        }),
        (resp) => {
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
          history.push("/user");
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        u_fname: !form.u_fname || form.u_fname === "",
        // u_lname: !form.u_lname || form.u_lname === "",
        u_gender: !form.u_gender || form.u_gender === "",
        // u_gender: !form.u_gender || form.u_gender === "0",
        u_birthday: !form.u_birthday || form.u_birthday === "",
        u_email: !form.u_email || form.u_email === "",
        u_tel: !form.u_tel || form.u_tel === "",
        // ul_id: !form.ul_id || form.ul_id === "",
        // ap_id: !form.ap_id || form.ap_id === "0",
        ul_id: !form.ul_id || form.ul_id === "",
        ap_id: !form.ap_id || form.ap_id === "",
        u_usename: !form.u_usename || form.u_usename === "",
        u_password: !form.u_password || form.u_password === "",
        u_address: !form.u_address || form.u_address === "",
        u_cgd_pass: !form.u_cgd_pass || form.u_cgd_pass === "",
        u_cgd_user: !form.u_cgd_user || form.u_cgd_user === "",
        // al_id: !form.al_id || form.al_id === "0",
        al_id: !form.al_id || form.al_id === "",
      });
      //props.handlerIsLoading(false);
      if (form.u_password !== form.ConfirmPass) {
        Notifications.NotiWarning({ msg: "รหัสผ่านทั้งสองช่องไม่เหมือนกัน!" });
      } else {
        if (form.u_cgd_pass !== conpassCGD) {
          Notifications.NotiWarning({
            msg: "รหัสผ่านกรมบัญชีกลางทั้งสองช่องไม่เหมือนกัน!",
          });
        } else {
          Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
        }
      }
    }
  };
  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName">ข้อมูลผู้ใช้งาน</div>
      </div>
      <form onSubmit={printValues}>
        <div className="box-S4 flex-between">
          <div className="box-S2 mgBottom">
            <div className="bContentMain">
              <div className="bNameContent">ข้อมูลส่วนตัว</div>

              <div className="bContent">
                <div className="bRow">
                  <TextField
                    error={error.u_fname}
                    fullWidth
                    label="ชื่อ - นามสกุล"
                    name="u_fname"
                    onChange={updateField}
                  />
                </div>
                <div className="bRow" style={{ marginTop: "10px" }}>
                  <FormControl
                    component="fieldset"
                    className="groupRadio"
                    error={error.u_gender}
                  >
                    <FormLabel component="legend">เพศ</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="u_gender"
                      onChange={updateField}
                      value={form.u_gender}
                      row
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="ชาย"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="หญิง"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="bRow">
                  <TextField
                    error={error.u_birthday}
                    fullWidth
                    label="วันเกิด"
                    type="date"
                    name="u_birthday"
                    onChange={updateField}
                    value={form.u_birthday}
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.u_tel}
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    name="u_tel"
                    onChange={updateField}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="box-S2 mgBottom">
            <div className="bContentMain" style={{ height: "100%" }}>
              <div className="bNameContent">บัญชีผู้ใช้</div>
              <div className="bContent">
                <div className="bRow">
                  <TextField
                    error={error.u_usename}
                    fullWidth
                    label="ชื่อผู้ใช้งาน"
                    name="u_usename"
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.u_email}
                    fullWidth
                    label="อีเมล"
                    name="u_email"
                    onChange={updateField}
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.u_password}
                    fullWidth
                    label="รหัสผ่าน"
                    name="u_password"
                    onChange={updateField}
                    type="password"
                  />
                </div>
                <div className="bRow">
                  <TextField
                    error={error.ConfirmPass}
                    fullWidth
                    label="ยืนยันรหัสผ่านน"
                    name="ConfirmPass"
                    onChange={updateFieldcompass}
                    type="password"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {Helper.GetUserData().ul_id === 1 && (
          <div className="box-S4 mgBottom">
            <div className="bContentMain">
              <div className="bNameContent">กรมบัญชีกลาง</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div style={{ width: "32%" }}>
                    <TextField
                      error={error.u_cgd_user}
                      fullWidth
                      label="ชื่อผู้ใช้งาน"
                      name="u_cgd_user"
                      onChange={updateField}
                    />
                  </div>
                  <div style={{ width: "32%" }}>
                    <TextField
                      error={error.u_cgd_pass}
                      fullWidth
                      label="รหัสผ่าน"
                      name="u_cgd_pass"
                      onChange={updateField}
                      type="password"
                    />
                  </div>
                  <div style={{ width: "32%" }}>
                    <TextField
                      error={error.ConfirmPass}
                      fullWidth
                      label="ยืนยันรหัสผ่านน"
                      name="ConfirmPass"
                      onChange={updateFieldcompassCGD}
                      type="password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="box-S4 mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">สิทธิการใช้งาน</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth error={error.ul_id}>
                    <InputLabel>ระดับผู้ใช้งาน</InputLabel>
                    <NativeSelect
                      native="true"
                      onChange={updateField}
                      value={form.ul_id}
                      name="ul_id"
                    >
                      {userlevel}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <FormControl
                    component="fieldset"
                    className="groupRadio"
                    error={error.u_gender}
                  >
                    <FormLabel component="legend">การใช้งาน</FormLabel>
                    <RadioGroup
                      aria-label="u_active"
                      name="u_active"
                      onChange={updateField}
                      value={form.u_active}
                      row
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="อนุญาติ"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="ระงับ"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth error={error.ap_id}>
                    <InputLabel>สนามบิน</InputLabel>
                    <NativeSelect
                      native="true"
                      onChange={updateField}
                      value={form.ap_id}
                      name="ap_id"
                    >
                      {airport}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <FormControl fullWidth error={error.al_id}>
                    <InputLabel>สายการบิน</InputLabel>
                    <NativeSelect
                      native="true"
                      onChange={updateField}
                      value={form.al_id}
                      name="al_id"
                    >
                      {airline}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Bottom2Function back="/user" insertData={InsertData} />
    </div>
  );
}

export default UserProfile;
