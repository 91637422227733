/** @format */

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import TableAirlineRecordSpecial from "../../../datatable/airline/TableAirlineRecordSpecial";
import FillterData2 from "../../../global/FillterData2";
import { withRouter } from "react-router";
export class AirlineRecordSpecial extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.handleFiltering = this.handleFiltering.bind(this);
    this.tableData = React.createRef();
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  handleFiltering = (v) => {
    this.tableData.current.handleFiltering(v);
  };
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>บันทึกเที่ยวบินพิเศษ</div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                this.props.history.push("/airline-record-special/add");
              }}
            >
              เพิ่มเที่ยวบิน
            </Button>
          </div>
        </div>
        <FillterData2
          handleFiltering={this.handleFiltering.bind(this)}
          isDisabledField="status"
          isSpecial="1"
          fillteringPage="AirlineRecordSpecial"
        />
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการ</div>
            <div className="bContent">
              <TableAirlineRecordSpecial
                ref={this.tableData}
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AirlineRecordSpecial);
