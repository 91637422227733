/** @format */

import React, { Component } from "react";
import {
  Paper,
  TableContainer,
  TablePagination,
  Button,
  Tooltip,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MaterialTable from "material-table";
import axios from "axios";
import Helper from "../../global/Helper";
export class TableAirlineRecordSpecial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rows: 10,
      total: 0,
      flight: [],
      q: "",
    };
  }
  handleFiltering = async (v) => {
    await this.setState({ q: Helper.jsonToQueryString(v) });
    await this.setState({ page: 0 });
    this.getTransport(0, this.state.rows, Helper.jsonToQueryString(v));
  };
  handleChangePage = async (e, page) => {
    await this.setState({ page: page });
    this.getTransport(page, this.state.rows, this.state.q);
  };

  handleChangeRowPerPage = async (row) => {
    await this.setState({ rows: row });
    await this.setState({ page: 0 });
    this.getTransport(0, row, this.state.q);
  };

  getTransport = (page = 0, rows = 10, q = "") => {
    this.props.handlerIsLoading(true);
    const startRow = page * rows;
    const queryString = `limit=${rows}&offset=${startRow}&special=1${
      q ? `&${q}` : ""
    }`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/transports/pagination?${queryString}`;
    axios.get(apiUrl).then(async (res) => {
      await this.setState({ flight: res.data.data });
      await this.setState({ total: res.data.total });
      this.props.handlerIsLoading(false);
    });
  };
  componentDidMount() {
    // this.getTransport();
  }
  render() {
    return (
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title=""
            columns={[
              {
                title: "เจ้าของ",
                field: "al_owner",
                align: "center",
              },
              {
                title: "ตัวแทน",
                field: "al_agent",
                align: "center",
              },
              {
                title: "เลขที่สัญชาติและทะเบียน",
                field: "t_callsign",
                align: "center",
              },
              {
                title: "วันที่",
                field: "t_datetime",
                align: "center",
                render: (rowData) => {
                  return Helper.CastDate(new Date(rowData.t_datetime));
                },
              },
              {
                title: "เวลา",
                field: "t_datetime",
                align: "center",
                render: (rowData) => {
                  return Helper.CastTime(rowData.t_datetime);
                },
              },
              {
                title: "ต้นทาง",
                field: "t_departure_airport_code",
                align: "center",
              },
              {
                title: "ปลายทาง",
                field: "t_departure_airport_name",
                align: "center",
              },
              {
                title: "",
                field: "id",
                render: (rowData) => {
                  return (
                    <Tooltip title="ดู" arrow>
                      <Button
                        href={`/airline-record-special/detail/${rowData.t_id}`}
                        className="styleBtn blue w-auto"
                        variant="contained"
                        style={{ textTransform: "none", marginRight: "5px" }}
                        size="small"
                      >
                        <AssignmentIcon />
                      </Button>
                    </Tooltip>
                  );
                },
              },
            ]}
            data={this.state.flight}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: false,
              filtering: false,
              actionsColumnIndex: -1,
              grouping: true,
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  rowsPerPage={this.state.rows}
                  count={this.state.total ? parseInt(this.state.total) : 0}
                  page={this.state.page}
                  onChangePage={(e, page) => this.handleChangePage(e, page)}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.handleChangeRowPerPage(event.target.value);
                  }}
                />
              ),
            }}
          />
        </TableContainer>
      </Paper>
    );
  }
}

export default TableAirlineRecordSpecial;
