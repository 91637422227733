/** @format */

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router";

// Components Page
import TableMasterItem from "../../datatable/TableMasterItem";

export class MasterItem extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>รายการค่าธรรมเนียมและบริการ</div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                this.props.history.push("/master-item/add");
              }}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการ</div>
            <div className="bContent">
              <TableMasterItem
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
        {/*<BottomFunction2 />*/}
      </div>
    );
  }
}

export default withRouter(MasterItem);
