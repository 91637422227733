/** @format */

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import TableWelfareLists from "../../datatable/TableWelfareLists";
import { withRouter } from "react-router";

export class WelfareLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>รายชื่อร้านค้าสวัสดิการ</div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                this.props.history.push("/welfare-lists/add");
              }}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการ</div>
            <div className="bContent">
              <TableWelfareLists
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WelfareLists);
