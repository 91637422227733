/** @format */

import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import MaterialTable from "material-table";
import axios from "axios";
import Helper from "../global/Helper";

export default function StickyHeadTable(props) {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const query = Object.keys(props.filter)
        .map((key) => key + "=" + props.filter[key])
        .join("&");
      const result = await axios(
        `${process.env.REACT_APP_API_URL}/v1/report/logemail?${query}`
      );
      setData(result.data.data);
    };
    fetchData();
  }, [props.filter]);
  return (
    <div>
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title=""
            columns={[
              { title: "วันเวลา", field: "cd", align: "center",
                render: (rowData) => {
                  // cast to yyyy-mm-dd hh:mm:ss as 24 hour format
                  const date = new Date(rowData.cd);
                  return date.toLocaleString("th-TH", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  });
                }
             },
              { title: "ท่าอากาศยาน", field: "ap_name", align: "center" },
              { title: "email ท่าอากาศยาน", field: "el_sender", align: "center" },
              { title: "สายการบิน", field: "al_name", align: "center" },
              { title: "email สายการบิน", field: "al_receiver", align: "left" },
              { title: "สถานะ", field: "status", align: "center",
                render: (rowData) => {
                  return rowData.status === "1" ? "สำเร็จ" : "ไม่สำเร็จ";
                },
              },
              { title: "ข้อความตอบกลับจาก WorkD", field: "remark", align: "left" },
            ]}
            data={data}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: true,
              filtering: true,
              actionsColumnIndex: -1,
              grouping: true,
              exportPdf: (columns, data) => {
                const temp = data.map((i) => [
                  i.cb,
                  i.ap_name,
                  i.el_sender,
                  i.al_name,
                  i.al_receiver,
                  i.status,
                  i.remark,
                ]);
                Helper.exportPDF(columns, temp);
              },
            }}
          />
        </TableContainer>
      </Paper>
    </div>
  );
}
