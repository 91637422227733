/** @format */

import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { Button, Tooltip } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MaterialTable from "material-table";
import Helper from "../../global/Helper";

export default function StickyHeadTable(props) {
  const [valuedata, setValuedata] = useState([]);

  useEffect(() => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/revenuecategorysubs`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((repos) => {
        setValuedata(repos);
        props.handlerIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className="tabelData">
      <TableContainer>
        <MaterialTable
          title=""
          columns={[
            { title: "ประเภทรายได้ย่อย", field: "rc_name", align: "center" },
            { title: "ชื่อรายได้ย่อย", field: "rcs_name", align: "center" },
            { title: "รายละเอียด", field: "rcs_description", align: "center" },
            {
              title: "",
              field: "rcs_id",
              render: (rowData) => {
                return (
                  <Tooltip title="ดู" arrow>
                    <Button
                      href={`/revenue-category-sub/detail/${rowData.rcs_id}`}
                      className="styleBtn blue w-auto"
                      variant="contained"
                      style={{ textTransform: "none", marginRight: "5px" }}
                      size="small"
                    >
                      <AssignmentIcon />
                    </Button>
                  </Tooltip>
                );
              },
            },
          ]}
          data={valuedata}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            search: true,
            exportButton: true,
            filtering: false,
            actionsColumnIndex: -1,
            grouping: true,
            exportPdf: (columns, data) => {
              const temp = data.map((i) => [
                i.rc_name,
                i.rcs_name,
                i.rcs_description,
              ]);
              Helper.exportPDF(
                ["ประเภทรายได้ย่อย", "ชื่อรายได้ย่อย", "รายละเอียด"],
                temp
              );
            },
          }}
        />
      </TableContainer>
    </Paper>
  );
}
