/** @format */

import jsPDF from "jspdf";
import "../global/THSarabun-normal";
import "jspdf-autotable";
const Helper = {
  vInt: (v) => {
    return parseInt(v) ? parseInt(v) : 0;
  },
  vStr: (v) => {
    return v ? v : "";
  },
  GetUserData: () => {
    return JSON.parse(localStorage.getItem("DataUser"));
  },
  RanDomUnique: (length = 10) => {
    let result = [];
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    return result.join("");
  },
  GetYear: (dt = new Date()) => {
    let year = dt.getFullYear().toString();
    return year;
  },
  GetMonth: (dt = new Date()) => {
    let month = (dt.getMonth() + 1).toString();
    return month;
  },
  GetPlusDate: (dt = new Date(), days = 15) => {
    dt.setDate(dt.getDate() + days);
    return dt;
  },
  CastDate: (dt = new Date(), reverse = false) => {
    try {
      if (isNaN(dt.getTime()) || dt.getTime() === 0) return "";
      let year = dt.getFullYear().toString();
      let month = (dt.getMonth() + 1).toString();
      let day = dt.getDate().toString();
      if (month <= 9) month = "0" + month;
      if (day <= 9) day = "0" + day;
      if (year.length < 4) {
        for (let x = 0; x < 4 - year.length; x++) {
          year = "0" + year;
        }
      }
      const temp = [year, month, day];
      if (reverse) temp.reverse();
      return temp.join("-");
    } catch (error) {
      return "";
    }
  },
  CastCurrency: (number) => {
    if (Number(number)) {
      const temp = new Intl.NumberFormat("en").format(number);
      const chkDigit = temp.split(".");
      return chkDigit.length === 1
        ? `${temp}.00`
        : chkDigit[1].length === 1
        ? `${temp}0`
        : temp;
    }
    return "0.00";
  },
  CastTime: (t) => {
    try {
      const dt = new Date(t);
      if (isNaN(dt.getTime()) || dt.getTime() === 0) return "";
      const temp = dt.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      });
      return temp;
    } catch (error) {
      return "";
    }
  },
  Cast2Time: (t) => {
    return t ? `${t.slice(0, 2)}:${t.slice(-2)}` : "";
  },
  Cast2Day: (d) => {
    const day = [
      "อาทิตย์",
      "จันทร์",
      "อังคาร",
      "พุธ",
      "พฤหัสบดี",
      "ศุกร์",
      "เสาร์",
    ];
    return day[d - 1];
  },
  Cast2Month: (i) => {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return month[i - 1];
  },
  IsObjectEmpty: (obj) => {
    return Object.keys(obj).length === 0;
  },
  exportPDF: (columns, data) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    doc.setFont("THSarabun", "normal");
    doc.setFontSize(16);
    const headers = [columns];
    let content = {
      head: headers,
      body: data,
      styles: {
        font: "THSarabun",
        fontStyle: "normal",
      },
    };
    doc.autoTable(content);
    doc.save("report.pdf");
  },
  getCalculateLanding: (w) => {
    let wTemp = Math.ceil(w);
    let r = 0;
    if (wTemp > 50) {
      wTemp = wTemp - 50;
      r = r + 50 * 85;
      if (wTemp > 50) {
        wTemp = wTemp - 50;
        r = r + 50 * 95;
        if (wTemp > 0) {
          r = r + wTemp * 105;
        }
      } else {
        r = r + wTemp * 95;
      }
    } else {
      r = r + wTemp * 85;
    }
    return r ? r : 0;
  },

  getCalculateParkingOneDay: (w) => {
    let wTemp = Math.ceil(w);
    let r = 650;
    if (wTemp > 50) {
      wTemp = wTemp - 50;
      if (wTemp > 50) {
        wTemp = wTemp - 50;
        r = r + 50 * 10;
        if (wTemp > 0) {
          r = r + wTemp * 5;
        }
      } else {
        r = r + wTemp * 10;
      }
    }
    return r ? r : 0;
  },
  getCalculateParking: (dateDiff, w) => {
    let r = 0;
    let isCalculate = false;
    console.log("start calculate parking");
    console.log("time :", dateDiff, "weight :", w);
    if (
      ("hours" in dateDiff && dateDiff.hours >= 3) ||
      ("days" in dateDiff && dateDiff.days) > 0
    ) {
      isCalculate = true;
    }

    if (isCalculate) {
      let dayRemaining = 0;
      if ("days" in dateDiff) dayRemaining = dateDiff.days;
      let weight = Math.ceil(w);
      let oneDayPrice = 650;
      if (weight > 50) {
        weight = weight - 50;
        if (weight > 50) {
          weight = weight - 50;
          oneDayPrice = oneDayPrice + 50 * 10;
          if (weight > 0) {
            oneDayPrice = oneDayPrice + weight * 5;
          }
        } else {
          oneDayPrice = oneDayPrice + weight * 10;
        }
      }
      console.log("weight", weight);
      console.log("oneDayPrice", oneDayPrice);

      if (dayRemaining < 15) {
        r = r + oneDayPrice * dayRemaining;
        console.log("day < 15 :", r);
        if ("hours" in dateDiff && dateDiff.hours >= 3) {
          r = r + oneDayPrice;
          console.log("day < 15 && hour > 3 :", r);
        }
      } else {
        let i = 2;
        let tempMultiply = oneDayPrice * i <= 10000 ? oneDayPrice * i : 10000;
        dayRemaining = dayRemaining - 15;
        r = r + oneDayPrice * 15;
        console.log("1 - 15 days", r);
        while (dayRemaining > 0) {
          const currentDay = i * 15;
          if (dayRemaining >= 15) {
            r = r + 15 * tempMultiply;
            dayRemaining = dayRemaining - 15;
            console.log(
              currentDay,
              " - ",
              currentDay + 15,
              " :",
              r,
              ", remaining ",
              dayRemaining
            );
          } else {
            r = r + dayRemaining * tempMultiply;
            console.log(
              currentDay,
              " - ",
              currentDay + dayRemaining,
              " :",
              r,
              ", remaining 0"
            );
            dayRemaining = 0;
          }
          if (i < 10) i = i + 1;
          tempMultiply = oneDayPrice * i <= 10000 ? oneDayPrice * i : 10000;
        }
        if ("hours" in dateDiff && dateDiff.hours >= 3) {
          r = r + tempMultiply;
        }
      }
    }
    console.log("results :", r);
    return r;
  },
  getCalculatePSC: (dom, inter) => {
    const cDom = dom * 50;
    const cInter = inter * 400;
    return cDom + cInter;
  },
  getCalculateAPPS: (passenger) => {
    const cPassenger = passenger * 35;
    return cPassenger;
  },
  getStatusInvoice: (dt, paid, active, withBox = true) => {
    let dueDate = new Date(`${Helper.CastDate(new Date(dt))} 23:59:59`);
    const nowDate = new Date(`${Helper.CastDate()} 00:00:00`);
    let cls = "";
    let text = "-";
    if (`${paid}` === "1") {
      text = "ชำระแล้ว";
      cls = "complete";
    } else if (active === "1") {
      text = "ยกเลิก";
      cls = "cancel";
    } else if (nowDate > dueDate) {
      text = "ค้างชำระ";
      cls = "cancel";
    } else {
      text = "รอชำระ";
      cls = "pending";
    }

    if (withBox) {
      return (
        <div
          className={`TagStatus ${cls}`}
          style={{ margin: "auto", whiteSpace: "nowrap" }}
        >
          {text}
        </div>
      );
    } else {
      return text;
    }
  },

  getStatusFlightCheck: (i) => {
    let cls = "";
    let text = "-";
    switch (i) {
      case "1":
        cls = "cancel";
        text = "ไม่ตรงสถิติ";
        break;
      case "2":
        cls = "complete";
        text = "ตรงสถิติ";
        break;
      case "3":
        cls = "complete";
        text = "ยืนยันแล้ว";
        break;
      default:
        cls = "pending";
        text = "ไม่ยืนยัน";
        break;
    }
    return (
      <div
        className={`TagStatus ${cls}`}
        style={{ margin: "auto", whiteSpace: "nowrap" }}
      >
        {text}
      </div>
    );
  },

  addDays: (date, days = 1) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },

  convertToCurrency: (number) => {
    if (Number(number)) {
      return new Intl.NumberFormat("en").format(number);
    }
    return "0.00";
  },

  getCalculateDept: (type, price, percent = 5, dateStart, dateEnd) => {
    const dateDiff = Helper.getDateDiff(dateStart, dateEnd);
    const year =
      parseFloat(price) * (parseFloat(percent) / 100) * dateDiff.year;
    const month =
      parseFloat(price) * (parseFloat(percent) / 100) * (dateDiff.month / 12);
    const day =
      parseFloat(price) *
      (parseFloat(percent) / 100) *
      (dateDiff.day / dateDiff.dayOfYear);
    const result = year + month + day;
    const rounded = Math.round((result + Number.EPSILON) * 100) / 100;

    console.log("calculate dept function");
    console.log("dateStart", dateStart);
    console.log("dateEnd", dateEnd);
    console.log("dateDiff", dateDiff);
    console.log("type", type);
    console.log("price", price);
    console.log("percent", percent);
    console.log("yearPrice", year);
    console.log("monthPrice", month);
    console.log("dayPrice", day);
    console.log("result", result);
    console.log("rounded", rounded);
    console.log("######################################");
    return rounded;
  },
  getDateDiff: (startDate, endDate) => {
    const dt1 = new Date(startDate);
    const dt2 = new Date(endDate);
    const d1 = {
      year: dt1.getFullYear(),
      month: dt1.getMonth() + 1,
      day: dt1.getDate(),
    };
    const d2 = {
      year: dt2.getFullYear(),
      month: dt2.getMonth() + 1,
      day: dt2.getDate(),
    };
    const diff = {};
    diff.year = d2.year - d1.year;
    diff.month = d2.month - d1.month;

    if (dt1 === dt2) {
      diff.day = 1;
    } else {
      if (diff.year > 0 && d2.month < d1.month) {
        diff.year--;
        diff.month = 12 - d1.month + d2.month;
      }

      diff.day = d2.day - d1.day;
      if (diff.day < 0) {
        diff.month--;
        const dayOfLastMonth = new Date(d2.year, d2.month - 1, 0).getDate();
        if (d1.day > dayOfLastMonth)
          // diff.day = Math.abs(dayOfLastMonth - d1.day) + 1;
          diff.day = d2.day - 1;
        else diff.day = dayOfLastMonth + diff.day;
      } else {
        if (d1.day === d2.day) diff.day++;
      }
    }
    if (diff.day > 0 && d1.day !== d2.day) diff.day++;
    if (diff.month < 0) diff.month = 0;
    if (diff.year < 0) diff.year = 0;
    // const isLeap = new Date(d1, 1, 1).getDate() === 29;
    const isLeap = new Date(d1.year, 2, 0).getDate() === 29;
    diff.dayOfYear = isLeap ? 366 : 365;
    return diff;
  },
  dynamicSort: (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  },
  jsonToQueryString: (json) => {
    return Object.keys(json)
      .map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
      })
      .join("&");
  },
  dateRange: (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      const tempDate = currentDate;
      tempDate.setDate(tempDate.getDate() + 1);
      currentDate = tempDate;
    }
    return dateArray;
  },
  insertArray: (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ],

  isOverDue: (rowData) => {
    return (
      Helper.getStatusInvoice(
        rowData.i_due_date,
        rowData.i_paid,
        rowData.i_active,
        false
      ) === "ค้างชำระ"
    );
  },
};
export default Helper;
