/** @format */

import React, { Component } from 'react';
import {
  FormControl,
  InputLabel,
  NativeSelect,
  Button,
} from '@material-ui/core';
// Components Page
import Bottom1Function from '../global/Bottom1Function';
import Helper from '../global/Helper';
import axios from 'axios';
import Highcharts from 'highcharts/highstock';
import drilldow from 'highcharts/modules/drilldown';
import HighchartsReact from 'highcharts-react-official';
drilldow(Highcharts);
export class Dashboard extends Component {
  constructor(props) {
    super(props);
    const dt = new Date();
    let yearnow = dt.getFullYear() + 543;
    if (dt.getMonth() + 1 >= 10) yearnow = yearnow + 1;
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.state = {
      yearLists: [],
      monthLists1: [],
      monthLists2: [],
      syear: yearnow,
      smonth: '9',
      emonth: '8',
      airport_value: '0',
      tempMonths: [
        { i: 9, t: 'ตุลาคม' },
        { i: 10, t: 'พฤศจิกายน' },
        { i: 11, t: 'ธันวาคม' },
        { i: 0, t: 'มกราคม' },
        { i: 1, t: 'กุมภาพันธ์' },
        { i: 2, t: 'มีนาคม' },
        { i: 3, t: 'เมษายน' },
        { i: 4, t: 'พฤษภาคม' },
        { i: 5, t: 'มิถุนายน' },
        { i: 6, t: 'กรกฎาคม' },
        { i: 7, t: 'สิงหาคม' },
        { i: 8, t: 'กันยายน' },
      ],
      revenueOption: [],
      pieChart: null,
      lineChart: null,
      stackedColumnChart: null,
      stackedBarChart: null,
      dataFilter: {
        year: yearnow,
        smonth: 'ตุลาคม',
        emonth: 'กันยายน',
        airport: 'ทั้งหมด',
      },
      summary: {
        a: 0,
        s: 0,
        w: 0,
        o: 0,
        psc: 0,
        apps: 0,
        landing: 0,
        parking: 0,
      },
      line: [],
      pieMain: [],
      pieDrill: [],
      stColMain: [],
      stColData: [],
      stBarMain: [],
      stBarData: [],
      monthIn: "",
    };
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  createYearLists = () => {
    const years = [];
    const dt = new Date();
    let yearnow = dt.getFullYear() + 543;
    if (dt.getMonth() + 1 >= 10) yearnow = yearnow + 1;
    for (let i = yearnow; i >= 2560; i--) {
      years.push(
        <option key={`y${i}`} value={i}>
          {i}
        </option>
      );
    }
    this.setState({ yearLists: years });
  };
  createMonthLists = (cb) => {
    const monthLists1 = [];
    const monthLists2 = [];
    const fIndex = this.state.tempMonths.findIndex(item => item.i === parseInt(this.state.smonth))
    this.state.tempMonths.forEach((item, index) => {
      const year = item.i >= 9 && item.i <= 12 ? this.state.syear - 1 : this.state.syear;
      monthLists1.push(
        <option key={`m1${item.i + 1}`} value={item.i}>
          {year} - {item.t}
        </option>
      );
      const checkAvaliable = index >= fIndex ? true : false;
      if(checkAvaliable) {
        monthLists2.push(
          <option key={`m2${item.i + 1}`} value={item.i}>
            {year} - {item.t}
          </option>
        );
      }
    });

    this.setState({
      monthLists1: monthLists1,
      monthLists2: monthLists2,
      monthIn: this.getMonthIn()
    }, () => {
      if(typeof cb === 'function') cb();
    });
  };

  getMonthIn = () => {
    const monthIndex1 = this.state.tempMonths.findIndex(item => item.i === parseInt(this.state.smonth));
    const monthIndex2 = this.state.tempMonths.findIndex(item => item.i === parseInt(this.state.emonth));
    const monthIn = this.state.tempMonths.filter((item, index) => index >= monthIndex1 && index <= monthIndex2).map(item => {
      return parseInt(item.i) + 1;
    }).join(',');
    return monthIn
  }

  getAirport = () => {
    const temp = [
      <option key={`apall`} value={0}>
        ทั้งหมด
      </option>,
    ];
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      res.data.forEach((item, index) => {
        if (
          (Helper.GetUserData().ul_id === 3 &&
            Helper.GetUserData().ap_id === parseInt(item.ap_id)) ||
          Helper.GetUserData().ul_id !== 3
        ) {
          const option = (
            <option key={`ap${item.ap_id}`} value={item.ap_id}>
              {item.ap_code_name
                ? `${item.ap_code_name} (${item.ap_name})`
                : item.ap_name}
            </option>
          );
          temp.push(option);
        }
      });
      this.setState({
        airport: [...res.data],
        airportOption: temp,
        airport_value: 0,
      });
    });
  };

  createLineChart = () => {
    const option = {
      title: {
        text: '',
      },
      yAxis: {
        title: {
          text: '',
        },
      },

      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },

      tooltip: {
        headerFormat: '',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>',
      },
      xAxis: {
        categories: this.state.monthIn.split(',').map(item => {
          const month = this.state.tempMonths.find(x => x.i === parseInt(item) - 1);
          return month.t;
        })
      },
      series: this.state.line,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };

    this.setState({ lineChart: option }, () => {});
  };
  createPieChart = () => {
    // Create the chart
    const option = {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: '%',
        },
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y:.2f}',
          },
        },
      },

      tooltip: {
        headerFormat: '',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>',
      },

      series: [
        {
          name: 'Main',
          colorByPoint: true,
          data: this.state.pieMain,
        },
      ],
      drilldown: {
        series: this.state.pieDrill,
      },
    };
    this.setState({ pieChart: option }, () => {});
  };
  createStackedColumnChart = () => {
    const option = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray',
          },
        },
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
          },
        },
      },

      xAxis: {
        categories: this.state.stColMain,
      },
      series: this.state.stColData,
    };
    this.setState({ stackedColumnChart: option }, () => {});
  };
  createStackedBarChart = () => {
    const option = {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: this.state.stBarMain,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'ล้านบาท',
        },
      },

      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toFixed(2);
            },
          },
        },
      },
      series: this.state.stBarData,
    };
    this.setState({ stackedBarChart: option }, () => {});
  };

  getFillterData = () => {
    const ap = this.state.airport.find(
      (i) => i.ap_id.toString() === this.state.airport_value
    );
    const smonth = this.state.tempMonths.find(item => item.i === parseInt(this.state.smonth));
    const emonth = this.state.tempMonths.find(item => item.i === parseInt(this.state.emonth));
    this.setState(
      {
        dataFilter: {
          year: this.state.syear,
          smonth: smonth.t,
          emonth: emonth.t,
          airport:
            parseInt(this.state.airport_value) !== 0 ? ap.ap_name : 'ทั้งหมด',
        },
      },
      () => {
        this.getSummary();
        this.initLineChart();
        this.initPieChart();
        this.initStColChart();
        this.initStBarChart();
      }
    );
  };

  getSummary = () => {
    const queryString = `year=${this.state.syear.toString().slice(-2)}&months=${this.state.monthIn}&airport=${
      this.state.airport_value
    }`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/dashboard/summary?${queryString}`;
    axios.get(apiUrl).then((res) => {
      const million = 1000000.0;
      this.setState(
        {
          summary: {
            a: Helper.CastCurrency(parseFloat(res.data.total) / million),
            s: Helper.CastCurrency(parseFloat(res.data.complete) / million),
            w: Helper.CastCurrency(parseFloat(res.data.waitting) / million),
            o: Helper.CastCurrency(parseFloat(res.data.overdue) / million),
            psc: Helper.CastCurrency(parseFloat(res.data.psc) / million),
            apps: Helper.CastCurrency(parseFloat(res.data.apps) / million),
            landing: Helper.CastCurrency(
              parseFloat(res.data.landing) / million
            ),
            parking: Helper.CastCurrency(
              parseFloat(res.data.parking) / million
            ),
          },
        },
        () => {}
      );
    });
  };

  getLineChart = async (type) => {
    const queryString = `year=${this.state.syear
      .toString()
      .slice(-2)}&airport=${this.state.airport_value}&type=${type}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/dashboard/${
      ['PSC', 'APPS'].includes(type) ? 'line_pscapps' : 'line_landingparking'
    }?${queryString}`;
    await axios.get(apiUrl).then((res) => {
      const temp = {
        name: type,
        data: [],
      };
      
      this.state.monthIn.split(',').forEach(item => {
        const month = this.state.tempMonths.find(x => x.i === parseInt(item) - 1);
        if(month) {
          temp.data.push({
            name: month.t,
            y: res.data['m' + (month.i + 1)],
          });
        }
      });
      this.setState({line: [...this.state.line, temp]});
    });
  };

  getPieChart = async () => {
    const queryString = `year=${this.state.syear
      .toString()
      .slice(-2)}&airport=${this.state.airport_value}&months=${this.state.monthIn}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/dashboard/piechart?${queryString}`;
    await axios.get(apiUrl).then((res) => {
      const main = [];
      const drill = [];
      res.data.forEach((i) => {
        const m = {
          name: i.airport,
          y: i.airport_amount,
          drilldown: i.airport,
        };
        const d = {
          name: i.airport,
          id: i.airport,
          data: [
            ['APPS', i.apps],
            ['PSC', i.psc],
            ['Landing', i.landing],
            ['Parking', i.parking],
          ],
        };
        main.push(m);
        drill.push(d);
      });
      this.setState(
        {
          pieMain: main,
          pieDrill: drill,
        },
        () => {}
      );
    });
  };

  getStackedColumnChart = async () => {
    const queryString = `year=${this.state.syear
      .toString()
      .slice(-2)}&airport=${this.state.airport_value}&months=${this.state.monthIn}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/dashboard/stackedcolumn?${queryString}`;
    await axios.get(apiUrl).then((res) => {
      const airport = [];
      const valueComplete = [];
      const valueWaiting = [];
      const valueOverdue = [];
      res.data.forEach((i) => {
        airport.push(i.airport);
        valueComplete.push(parseInt(i.complete));
        valueWaiting.push(parseInt(i.waiting));
        valueOverdue.push(parseInt(i.overdue));
      });
      this.setState(
        {
          stColMain: airport,
          stColData: [
            {
              name: 'ชำระแล้ว',
              data: valueComplete,
            },
            {
              name: 'รอชำระ',
              data: valueWaiting,
            },
            {
              name: 'ค้างชำระ',
              data: valueOverdue,
            },
          ],
        },
        () => {}
      );
    });
  };

  getStackedBarChart = async () => {
    const million = 1000000.0;
    const queryString = `year=${this.state.syear
      .toString()
      .slice(-2)}&airport=${this.state.airport_value}&months=${this.state.monthIn}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/dashboard/stackedbar?${queryString}`;
    await axios.get(apiUrl).then((res) => {
      const airline = [];
      const valueParking = [];
      const valueLanding = [];
      const valueApps = [];
      const valuePsc = [];
      res.data.forEach((i) => {
        airline.push(i.airline);
        valueParking.push(parseFloat(i.parking) / million);
        valueLanding.push(parseFloat(i.landing) / million);
        valueApps.push(parseFloat(i.apps) / million);
        valuePsc.push(parseFloat(i.psc) / million);
      });
      this.setState(
        {
          stBarMain: airline,
          stBarData: [
            {
              name: 'Parking',
              data: valueParking,
            },
            {
              name: 'Landing',
              data: valueLanding,
            },
            {
              name: 'APPS',
              data: valueApps,
            },
            {
              name: 'PSC',
              data: valuePsc,
            },
          ],
        },
        () => {}
      );
    });
  };

  updateField = (e) => {
    if (e.target.name === 'smonth') {
      this.setState(
        {
          [e.target.name]: e.target.value,
          emonth: e.target.value,
        },
        () => {
          this.createMonthLists();
        }
      );
    } else if (e.target.name === 'syear') {
      this.setState({ [e.target.name]: e.target.value }, () => {
        this.createMonthLists();
      });
    } else if(e.target.name === 'emonth') {
      this.setState({ 
        [e.target.name]: e.target.value, 
      }, () => {
        this.setState({ 
          monthIn: this.getMonthIn() 
        });
      });
    }else {
      this.setState({ [e.target.name]: e.target.value }, () => {});
    }
  };

  initLineChart = async () => {
    await this.setState({ line: [] }, async () => {
      await this.getLineChart('PSC');
      await this.getLineChart('APPS');
      await this.getLineChart('Landing');
      await this.getLineChart('Parking');
      await this.createLineChart();
    });
  };
  initPieChart = async () => {
    await this.setState({ pieMain: [], pieDrill: [] }, async () => {
      await this.getPieChart();
      await this.createPieChart();
    });
  };
  initStColChart = async () => {
    await this.setState({ pieMain: [], pieDrill: [] }, async () => {
      await this.getStackedColumnChart();
      await this.createStackedColumnChart();
    });
  };
  initStBarChart = async () => {
    await this.setState({ pieMain: [], pieDrill: [] }, async () => {
      await this.getStackedBarChart();
      await this.createStackedBarChart();
    });
  };
  componentDidMount() {
    this.createYearLists();
    this.getAirport();
    this.createStackedColumnChart();

    this.createMonthLists(() => {
      this.getSummary();
      this.initLineChart();
      this.initPieChart();
      this.initStColChart();
      this.initStBarChart();
    });
  }
  render() {
    return (
      <div>
        <div className='box-S4 flex-between mgBottom'>
          <div className='box-S4 flex-between'>
            <div className='bContentMain'>
              <div className='bNameContent'>รายงาน</div>
            </div>
          </div>
        </div>
        <div className='box-S4 flex-between mgBottom'>
          <div className='box-S4 flex-between'>
            <div className='bContentMain'>
              <div className='bNameContent'>กรอง</div>
              <div className='bContent'>
                <div className='bRow flex-between'>
                  <div className='box-S1 flex-between'>
                    <FormControl fullWidth>
                      <InputLabel shrink>ปีงบประมาณ</InputLabel>
                      <NativeSelect
                        native='true'
                        name='syear'
                        onChange={this.updateField}
                      >
                        {this.state.yearLists}
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div
                    className='box-S2'
                    style={{
                      marginLeft: '10px',
                      marginRight: '10px',
                      flexDirection: 'inherit',
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel shrink>เดือน</InputLabel>
                      <NativeSelect
                        native='true'
                        name='smonth'
                        value={this.state.smonth}
                        onChange={this.updateField}
                      >
                        {this.state.monthLists1}
                      </NativeSelect>
                    </FormControl>
                    <p className='pcenter'>ถึง</p>
                    <FormControl fullWidth>
                      <InputLabel shrink>เดือน</InputLabel>
                      <NativeSelect
                        native='true'
                        name='emonth'
                        value={this.state.emonth}
                        onChange={this.updateField}
                      >
                        {this.state.monthLists2}
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className='box-S1 flex-between'>
                    <FormControl fullWidth>
                      <InputLabel shrink>ท่าอากาศยาน</InputLabel>
                      <NativeSelect
                        native='true'
                        name='airport_value'
                        onChange={this.updateField}
                      >
                        {this.state.airportOption}
                      </NativeSelect>
                    </FormControl>
                  </div>
                </div>
                <div className='bRow flex-between'>
                  <div className='box-S1 flex-between d-none'>
                    <FormControl fullWidth>
                      <InputLabel shrink>ประเภทรายได้</InputLabel>
                      <NativeSelect
                        native='true'
                        name='rc_id'
                        onChange={this.updateField}
                      >
                        {this.state.revenueOption}
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className='box-S2 flex-between'></div>
                </div>
                <div className='bRow flex-between'>
                  <div
                    className='box-S4 flex-between'
                    style={{ alignItems: 'center' }}
                  >
                    <div></div>
                    <Button
                      className='styleBtn blue'
                      onClick={() => {
                        this.getFillterData();
                      }}
                    >
                      ค้นหา
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='box-S4 flex-between mgBottom'>
          <div className='box-S4 flex-between'>
            <div className='bContentMain'>
              <div className='bNameContent'>
                สรุปรายได้ประจำปีงบประมาณ {this.state.dataFilter.year} เดือน
                {this.state.dataFilter.smonth} ถึง เดือน
                {this.state.dataFilter.emonth} ({this.state.dataFilter.airport})
              </div>
              <div className='bContent'>
                <div className='bRow flex-between'>
                  <div
                    className='box-S2 flex-between'
                    style={{ alignItems: 'center' }}
                  >
                    <div style={{ width: '100%' }}>
                      <div className='bRow flex-between'>
                        <div className='box-S4'>
                          <div className='bContentMain'>
                            <div className='bNameContent'>ทั้งหมด</div>
                            <div className='bContent txt-center'>
                              <span
                                style={{
                                  fontSize: '25px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {this.state.summary.a}
                              </span>
                              <br />
                              ล้านบาท
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='bRow flex-between'
                        style={{ paddingTop: '20px' }}
                      >
                        <div className='box-S2'>
                          <div className='bContentMain'>
                            <div className='bNameContent'>ชำระแล้ว</div>
                            <div className='bContent txt-center'>
                              <span
                                style={{
                                  fontSize: '25px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {this.state.summary.s}
                              </span>
                              <br />
                              ล้านบาท
                            </div>
                          </div>
                        </div>
                        <div className='box-S2'>
                          <div className='bContentMain'>
                            <div className='bNameContent'>รอชำระ</div>
                            <div className='bContent txt-center'>
                              <span
                                style={{
                                  fontSize: '25px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {this.state.summary.w}
                              </span>
                              <br />
                              ล้านบาท
                            </div>
                          </div>
                        </div>
                        <div className='box-S2'>
                          <div className='bContentMain'>
                            <div className='bNameContent'>ค้างชำระ</div>
                            <div className='bContent txt-center'>
                              <span
                                style={{
                                  fontSize: '25px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {this.state.summary.o}
                              </span>
                              <br />
                              ล้านบาท
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='box-S2 flex-between'>
                    <div style={{ width: '100%' }}>
                      <div className='bContent'>
                        <div className='bRow flex-between'>
                          <div className='box-S2'>
                            <div className='bContentMain'>
                              <div className='bNameContent'>PSC</div>
                              <div className='bContent txt-center'>
                                <span
                                  style={{
                                    fontSize: '25px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {this.state.summary.psc}
                                </span>
                                <br /> ล้านบาท
                              </div>
                            </div>
                          </div>
                          <div className='box-S2'>
                            <div className='bContentMain'>
                              <div className='bNameContent'>APPS</div>
                              <div className='bContent txt-center'>
                                <span
                                  style={{
                                    fontSize: '25px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {this.state.summary.apps}
                                </span>
                                <br /> ล้านบาท
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className='bRow flex-between'
                          style={{ paddingTop: '20px' }}
                        >
                          <div className='box-S2'>
                            <div className='bContentMain'>
                              <div className='bNameContent'>Landing</div>
                              <div className='bContent txt-center'>
                                <span
                                  style={{
                                    fontSize: '25px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {this.state.summary.landing}
                                </span>
                                <br />
                                ล้านบาท
                              </div>
                            </div>
                          </div>
                          <div className='box-S2'>
                            <div className='bContentMain'>
                              <div className='bNameContent'>Parking</div>
                              <div className='bContent txt-center'>
                                <span
                                  style={{
                                    fontSize: '25px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {this.state.summary.parking}
                                </span>
                                <br />
                                ล้านบาท
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='bRow flex-between'
                  style={{ paddingTop: '20px' }}
                >
                  <div className='box-S4 flex-between mgBottom'>
                    <div className='box-S2 flex-between'>
                      <div className='bContentMain'>
                        <div className='bNameContent'>Overview Revenue</div>
                        <div className='bContent'>
                          <div className='bRow flex-between'>
                            <div className='box-S4'>
                              <HighchartsReact
                                containerProps={{ style: { width: '100%' } }}
                                highcharts={Highcharts}
                                options={this.state.pieChart}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='box-S2 flex-between'>
                      <div className='bContentMain'>
                        <div className='bNameContent'>Monthly Revenue</div>
                        <div className='bContent'>
                          <div className='bRow flex-between'>
                            <div className='box-S4 flex-between justify-content-center'>
                              <HighchartsReact
                                containerProps={{ style: { width: '100%' } }}
                                highcharts={Highcharts}
                                options={this.state.lineChart}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bRow flex-between'>
                  <div className='box-S4 flex-between mgBottom'>
                    <div className='box-S2 flex-between'>
                      <div className='bContentMain'>
                        <div className='bNameContent'>Revenue Status</div>
                        <div className='bContent'>
                          <div className='bRow flex-between'>
                            <div className='box-S4 flex-between justify-content-center'>
                              <HighchartsReact
                                containerProps={{ style: { width: '100%' } }}
                                highcharts={Highcharts}
                                options={this.state.stackedColumnChart}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='box-S2 flex-between'>
                      <div className='bContentMain'>
                        <div className='bNameContent'>Revenue by Airline</div>
                        <div className='bContent'>
                          <div className='bRow flex-between'>
                            <div className='box-S4 flex-between justify-content-center'>
                              <HighchartsReact
                                containerProps={{ style: { width: '100%' } }}
                                highcharts={Highcharts}
                                options={this.state.stackedBarChart}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Bottom1Function back='/' />
      </div>
    );
  }
}

export default Dashboard;
