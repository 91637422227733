/** @format */

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import TableAirport from "../../datatable/TableAirport";
import { withRouter } from "react-router";
import Helper from "../../global/Helper";

export class Airport extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    if (Helper.GetUserData().ul_id === 3) {
      this.props.history.push(`/airport/detail/${Helper.GetUserData().ap_id}`);
    }
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>สนามบิน</div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                this.props.history.push("/airport/add");
              }}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการ</div>
            <div className="bContent">
              <TableAirport
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Airport);
