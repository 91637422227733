/** @format */

import React, { Component } from 'react';
import { withRouter } from 'react-router';

// Components Page
import FillterData5 from '../global/FillterData5';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axios from 'axios';
import Helper from '../global/Helper';

export class ReportRevenueEstimate extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.handleFiltering = this.handleFiltering.bind(this);
    this.handleAirportList = this.handleAirportList.bind(this);
    this.state = {
      airport: [],
    };
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  handleAirportList = (v) => {
    this.setState({ airport: v });
  };
  handleFiltering = (v) => {
    this.handlerIsLoading(true);
    const queryString = `filter_airport=${v.filter_airport}&filter_day_start=${v.filter_date_start}&filter_day_end=${v.filter_date_end}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/report/revenue-estimate?${queryString}`;
    axios.get(apiUrl).then((res) => {
      const psc = res.data.data.psc;
      const apps = res.data.data.apps;
      const landing = res.data.data.landing;
      const parking = res.data.data.parking;
      psc.forEach((v) => {
        v.price =
          parseInt(v.t_passenger_dom) * 50 +
          parseInt(v.t_passenger_inter) * 400;
      });
      apps.forEach((v) => {
        v.price = parseInt(v.t_passenger) * 35;
      });
      landing.forEach((v) => {
        v.price = Helper.getCalculateLanding(v.t_arrival_freight / 1000);
      });
      parking.forEach((v) => {
        v.price = Helper.getCalculateParking(
          v.date_diff,
          v.t_arrival_freight / 1000
        );
      });

      const dateRange = Helper.dateRange(
        new Date(v.filter_date_start),
        new Date(v.filter_date_end)
      );
      let data = [{}];
      data[0]['A'] = 'วันที่';
      data[0]['B'] = 'ท่าอากาศยาน';
      data[0]['C'] = 'APPS';
      data[0]['D'] = 'PSC Dom';
      data[0]['E'] = 'PSC Inter';
      data[0]['F'] = 'Landing Dom';
      data[0]['G'] = 'Landing Inter';
      data[0]['H'] = 'Parking Dom';
      data[0]['I'] = 'Parking Inter';
      dateRange.forEach((date) => {
        const currentDate = Helper.CastDate(date, true);
        if (v.filter_airport.toString() === '0') {
          this.state.airport.forEach((ap, index) => {
            const priceAPPS = apps
              .filter(
                (i) =>
                  i.t_airport_id === ap.ap_stt_code &&
                  Helper.CastDate(new Date(i.t_datetime), true) === currentDate
              )
              .reduce((a, b) => a + b.price, 0);
            const pricePSCDom = psc
              .filter(
                (i) =>
                  i.t_airport_id === ap.ap_stt_code &&
                  i.tt_dom === '0' &&
                  Helper.CastDate(new Date(i.t_datetime), true) === currentDate
              )
              .reduce((a, b) => a + b.price, 0);
            const pricePSCInter = psc
              .filter(
                (i) =>
                  i.t_airport_id === ap.ap_stt_code &&
                  i.tt_dom === '1' &&
                  Helper.CastDate(new Date(i.t_datetime), true) === currentDate
              )
              .reduce((a, b) => a + b.price, 0);
            const priceLandingDom = landing
              .filter(
                (i) =>
                  i.t_airport_id === ap.ap_stt_code &&
                  i.tt_dom === '0' &&
                  Helper.CastDate(new Date(i.t_datetime), true) === currentDate
              )
              .reduce((a, b) => a + b.price, 0);
            const priceLandingInter = landing
              .filter(
                (i) =>
                  i.t_airport_id === ap.ap_stt_code &&
                  i.tt_dom === '1' &&
                  Helper.CastDate(new Date(i.t_datetime), true) === currentDate
              )
              .reduce((a, b) => a + b.price, 0);
            const priceParkingDom = parking
              .filter(
                (i) =>
                  i.t_airport_id === ap.ap_stt_code &&
                  i.tt_dom === '0' &&
                  Helper.CastDate(new Date(i.t_datetime), true) === currentDate
              )
              .reduce((a, b) => a + b.price, 0);
            const priceParkingInter = parking
              .filter(
                (i) =>
                  i.t_airport_id === ap.ap_stt_code &&
                  i.tt_dom === '1' &&
                  Helper.CastDate(new Date(i.t_datetime), true) === currentDate
              )
              .reduce((a, b) => a + b.price, 0);
            /*
            if (
              priceAPPS > 0 ||
              pricePSCDom > 0 ||
              pricePSCInter > 0 ||
              priceLandingDom > 0 ||
              priceLandingInter > 0 ||
              priceParkingDom > 0 ||
              priceParkingInter > 0
            ) 
            */
            data.push({
              A: index === 0 ? currentDate : '',
              B: ap.ap_name,
              C: priceAPPS,
              D: pricePSCDom,
              E: pricePSCInter,
              F: priceLandingDom,
              G: priceLandingInter,
              H: priceParkingDom,
              I: priceParkingInter,
            });
          });
        } else {
          const fnd = this.state.airport.find(
            (i) => i.ap_id.toString() === v.filter_airport
          );
          const priceAPPS = apps
            .filter(
              (i) =>
                i.t_airport_id === fnd.ap_stt_code &&
                Helper.CastDate(new Date(i.t_datetime), true) === currentDate
            )
            .reduce((a, b) => a + b.price, 0);
          const pricePSCDom = psc
            .filter(
              (i) =>
                i.t_airport_id === fnd.ap_stt_code &&
                i.tt_dom === '0' &&
                Helper.CastDate(new Date(i.t_datetime), true) === currentDate
            )
            .reduce((a, b) => a + b.price, 0);
          const pricePSCInter = psc
            .filter(
              (i) =>
                i.t_airport_id === fnd.ap_stt_code &&
                i.tt_dom === '1' &&
                Helper.CastDate(new Date(i.t_datetime), true) === currentDate
            )
            .reduce((a, b) => a + b.price, 0);
          const priceLandingDom = landing
            .filter(
              (i) =>
                i.t_airport_id === fnd.ap_stt_code &&
                i.tt_dom === '0' &&
                Helper.CastDate(new Date(i.t_datetime), true) === currentDate
            )
            .reduce((a, b) => a + b.price, 0);
          const priceLandingInter = landing
            .filter(
              (i) =>
                i.t_airport_id === fnd.ap_stt_code &&
                i.tt_dom === '1' &&
                Helper.CastDate(new Date(i.t_datetime), true) === currentDate
            )
            .reduce((a, b) => a + b.price, 0);
          const priceParkingDom = parking
            .filter(
              (i) =>
                i.t_airport_id === fnd.ap_stt_code &&
                i.tt_dom === '0' &&
                Helper.CastDate(new Date(i.t_datetime), true) === currentDate
            )
            .reduce((a, b) => a + b.price, 0);
          const priceParkingInter = parking
            .filter(
              (i) =>
                i.t_airport_id === fnd.ap_stt_code &&
                i.tt_dom === '1' &&
                Helper.CastDate(new Date(i.t_datetime), true) === currentDate
            )
            .reduce((a, b) => a + b.price, 0);
          data.push({
            A: currentDate,
            B: fnd.ap_name,
            C: priceAPPS,
            D: pricePSCDom,
            E: pricePSCInter,
            F: priceLandingDom,
            G: priceLandingInter,
            H: priceParkingDom,
            I: priceParkingInter,
          });
        }
      });
      const header = {
        header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
        origin: 'A1',
        skipHeader: true,
      };
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const sheet = XLSX.utils.aoa_to_sheet([]);
      XLSX.utils.sheet_add_json(sheet, data, header);
      const wb = { Sheets: { data: sheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      });
      const excelFiles = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(excelFiles, 'รายงานประมาณการรายได้' + fileExtension);
      this.handlerIsLoading(false);
    });
  };
  render() {
    return (
      <div>
        <div className='box-S4 mgBottom'>
          <div className='bPageName flex-between'>
            <div>รายงานประมาณการรายได้</div>
          </div>
        </div>
        <FillterData5
          handleFiltering={this.handleFiltering.bind(this)}
          handleAirportList={this.handleAirportList.bind(this)}
          fillteringPage='ReportRevenueEstimate'
        />
      </div>
    );
  }
}

export default withRouter(ReportRevenueEstimate);
