/** @format */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { TextField } from "@material-ui/core";
import Bottom2Function from "../../global/Bottom2Function";
import Notifications from "../../global/Notifications";
function AirlineDetails(props) {
  const history = useHistory();
  const [form, setState] = useState({
    al_name: "",
    al_code_name: "",
    al_tax_id: "",
    al_owner: "",
    al_agent: "",
    al_house_no: "",
    al_amphur_code: "",
    al_province_code: "",
    al_postcode: "",
    al_country: "",
  });

  const [error, setError] = useState({
    al_name: false,
    al_code_name: false,
    al_tax_id: false,
    al_owner: false,
    al_agent: false,
    al_house_no: false,
    al_amphur_code: false,
    al_province_code: false,
    al_postcode: false,
    al_country: false,
  });

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
    const fieldLists = [
      "al_name",
      "al_code_name",
      "al_tax_id",
      "al_owner",
      "al_agent",
      "al_house_no",
      "al_amphur_code",
      "al_province_code",
      "al_postcode",
      "al_country",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
  };

  const insertData = () => {
    props.handlerIsLoading(true);

    if (
      form.al_name &&
      form.al_code_name &&
      form.al_tax_id &&
      form.al_owner &&
      form.al_agent &&
      form.al_house_no &&
      form.al_amphur_code &&
      form.al_province_code &&
      form.al_postcode &&
      form.al_country
    ) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/airlines`;
      Notifications.NotiAsync(
        axios.post(apiUrl, { form }),

        (resp) => {
          history.push("/airline");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        al_name: form.al_name === "",
        al_code_name: form.al_code_name === "",
        al_tax_id: form.al_tax_id === "",
        al_owner: form.al_owner === "",
        al_agent: form.al_agent === "",
        al_house_no: form.al_house_no === "",
        al_amphur_code: form.al_amphur_code === "",
        al_province_code: form.al_province_code === "",
        al_postcode: form.al_postcode === "",
        al_country: form.al_country === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>เพิ่มข้อมูลสายการบิน</div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">รายละเอียดสายการบิน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.al_name}
                  fullWidth
                  label="สายการบิน"
                  name="al_name"
                  value={form.al_name ? form.al_name : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_tax_id}
                  fullWidth
                  label="เลขประจำตัวผู้เสียภาษี"
                  name="al_tax_id"
                  value={form.al_tax_id ? form.al_tax_id : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.al_code_name}
                  fullWidth
                  label="รหัสสายการบิน"
                  name="al_code_name"
                  value={form.al_code_name ? form.al_code_name : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_owner}
                  fullWidth
                  label="เจ้าของ"
                  name="al_owner"
                  value={form.al_owner ? form.al_owner : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.al_agent}
                  fullWidth
                  label="ตัวแทน"
                  name="al_agent"
                  value={form.al_agent ? form.al_agent : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_house_no}
                  fullWidth
                  label="ที่อยู่"
                  name="al_house_no"
                  value={form.al_house_no ? form.al_house_no : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.al_building_name}
                  fullWidth
                  label="อาคาร"
                  name="al_building_name"
                  value={form.al_building_name ? form.al_building_name : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_moo}
                  fullWidth
                  label="หมู่"
                  name="al_moo"
                  value={form.al_moo ? form.al_moo : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.al_soi}
                  fullWidth
                  label="ซอย"
                  name="al_soi"
                  value={form.al_soi ? form.al_soi : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_tambon_code}
                  fullWidth
                  label="ตำบล/แขวง"
                  name="al_tambon_code"
                  value={form.al_tambon_code ? form.al_tambon_code : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.al_road}
                  fullWidth
                  label="ถนน"
                  name="al_road"
                  value={form.al_road ? form.al_road : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_amphur_code}
                  fullWidth
                  label="อำเภอ/เขต"
                  name="al_amphur_code"
                  value={form.al_amphur_code ? form.al_amphur_code : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.al_province_code}
                  fullWidth
                  label="จังหวัด"
                  name="al_province_code"
                  value={form.al_province_code ? form.al_province_code : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.al_postcode}
                  fullWidth
                  label="รหัสไปรษณีย์"
                  name="al_postcode"
                  value={form.al_postcode ? form.al_postcode : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.al_country}
                  fullWidth
                  label="ประเทศ"
                  name="al_country"
                  value={form.al_country ? form.al_country : ""}
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom2Function back="/airline" insertData={insertData} />
    </div>
  );
}

export default AirlineDetails;
