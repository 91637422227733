/** @format */

import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { Button, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MaterialTable from "material-table";
import axios from "axios";
import { useHistory } from "react-router-dom";
import SD from "../global/SwalDialog";
import Notifications from "../global/Notifications";
import Helper from "../global/Helper";

export default function StickyHeadTable(props) {
  const history = useHistory();
  const [user, setUser] = useState([]);

  const deleteData = (data) => {
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${data.u_fname} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/users/${data.u_id}`,
            {}
          ),
          (resp) => {
            getData();
            Notifications.NotiSuccess();
          },
          (err) => {
            props.handlerIsLoading(false);
            Notifications.NotiError({ msg: "ไม่สามารถลบข้อมูลได้" });
          }
        );
      },
    });
  };
  const getData = () => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/users${
      Helper.GetUserData().ul_id === 3
        ? `?ap_id=${Helper.GetUserData().ap_id}`
        : ""
    }`;
    axios.get(apiUrl).then((res) => {
      setUser(res.data);
      props.handlerIsLoading(false);
    });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className="tabelData">
      <TableContainer>
        <MaterialTable
          title=""
          columns={[
            {
              title: "ชื่อ-นามสกุล",
              field: "u_id",
              align: "center",
              render: (rowData) => {
                return `${rowData.u_fname}`;
              },
            },
            { title: "ประเภท", field: "ul_name", align: "center" },
            { title: "สนามบิน", field: "ap_name", align: "center" },
            { title: "สายการบิน", field: "al_name", align: "center" },
            { title: "อีเมล", field: "u_email", align: "center" },
            {
              title: "",
              field: "u_id",
              render: (rowData) => {
                return (
                  <div>
                    <Tooltip title="แก้ไข" arrow>
                      <Button
                        onClick={() => {
                          history.push(`/user/profile/${rowData.u_id}`);
                        }}
                        className="styleBtn blue w-auto"
                        variant="contained"
                        style={{ textTransform: "none", marginRight: "5px" }}
                        size="small"
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="ลบ" arrow>
                      <Button
                        onClick={() => {
                          deleteData(rowData);
                        }}
                        className="styleBtn red w-auto pl-5px"
                        variant="contained"
                        style={{ textTransform: "none", marginRight: "5px" }}
                        size="small"
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </Tooltip>
                  </div>
                );
              },
            },
          ]}
          data={user}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            search: true,
            exportButton: true,
            filtering: false,
            actionsColumnIndex: -1,
            grouping: true,
            exportPdf: (columns, data) => {
              const temp = data.map((i) => [
                i.u_fname,
                i.ul_name,
                i.ap_name,
                i.al_name,
                i.u_email,
              ]);
              Helper.exportPDF(
                ["ชื่อ-นามสกุล", "ประเภท", "สนามบิน", "สายการบิน", "อีเมล"],
                temp
              );
            },
          }}
        />
      </TableContainer>
    </Paper>
  );
}
