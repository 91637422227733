/** @format */

import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
export default function UserFuntion() {
  const history = useHistory();
  const Dateuser = JSON.parse(localStorage.getItem("DataUser"));
  const Dateuserlv = JSON.parse(localStorage.getItem("DataUserLevel"));
  const onClickButton = () => {
    history.push("/");
  };
  return (
    <div className="UserFunction">
      <div className="tUsername">{Dateuser ? Dateuser.u_fname : ""}</div>
      <div className="tPosition">{Dateuserlv ? Dateuserlv.ul_name : ""}</div>
      <Button fullWidth className="styleBtn blue" onClick={onClickButton}>
        ข้อมูลผู้ใช้งาน
      </Button>
    </div>
  );
}
