import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function Bottom2Function(props) {
  const history = useHistory();
  const textGreenBtn = props.textGreenBtn ? props.textGreenBtn : "บันทึก";
  const styleGreenBtn = props.styleGreenBtn ? props.styleGreenBtn : {};
  return (
    <div className="bContentMain bContent flex-between">
      <Button
        className="styleBtn"
        onClick={() => {
          if (typeof props.back === "function") {
            props.back();
          } else {
            history.push(props.back);
          }
        }}
      >
        ย้อนกลับ
      </Button>
      <Button
        style={styleGreenBtn}
        className="styleBtn space green"
        onClick={() => {
          props.insertData();
        }}
      >
        {textGreenBtn}
      </Button>
    </div>
  );
}

export default Bottom2Function;
