import AWN from "awesome-notifications";
import "../../css/toast.css";
const notifier = new AWN({
  position: "top-right",
  icons: {
    enabled: true,
  },
  durations: { warning: 2000, success: 2000, info: 2000, alert: 2000 },
});
const labelGlobal = "แจ้งเตือน";
const Notifications = {
  NotiWarning: ({ header, msg } = {}) => {
    let temp = header ? header : labelGlobal;
    msg = msg ? msg : "มีบางอย่างผิดพลาด";
    notifier.warning(msg, {
      labels: { warning: temp },
    });
  },
  NotiSuccess: ({ header, msg } = {}) => {
    let temp = header ? header : labelGlobal;
    msg = msg ? msg : "ดำเนินการเสร็จสิ้น";
    notifier.success(msg, {
      labels: { success: temp },
    });
  },
  NotiInfo: ({ header, msg } = {}) => {
    let temp = header ? header : labelGlobal;
    msg = msg ? msg : "";
    notifier.info(msg, {
      labels: { info: temp },
    });
  },
  NotiError: ({ header, msg } = {}) => {
    let temp = header ? header : labelGlobal;
    msg = msg ? msg : "";
    notifier.alert(msg, {
      labels: { alert: temp },
    });
  },
  NotiAsync: (fnReq, fnResp, fnErr, msg = "กรุณารอสักครู่") => {
    notifier.async(
      fnReq,
      (resp) => {
        fnResp(resp);
      },
      (err) => {
        fnErr(err);
      },
      msg,
      {
        labels: { async: "กำลังดำเนินการ" },
      }
    );
  },
};
export default Notifications;
