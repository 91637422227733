/** @format */

import React, { Component } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";

import { Redirect } from "react-router";
import { withRouter } from "react-router";
// Components Page
import Bottom2Function from "../../global/Bottom2Function";
import Notifications from "../../global/Notifications";
import axios from "axios";
import Helper from "../../global/Helper";

export class OtherIncomeAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      revenueCategory: [],
      revenueCategorySub: [],
      rCategory: [],
      form: {
        main: "",
        sub: "",
        i_doc_no: "",
        i_doc_date: Helper.CastDate(new Date()),
        i_due_date: Helper.CastDate(new Date()),
        apa_name: "",
        apa_email: "",
        apa_house_no: "",
        apa_road: "",
        apa_building_name: "",
        apa_moo: "",
        apa_tambon_code: "",
        apa_amphur_code: "",
        apa_province_code: "",
        apa_postcode: "",
        apa_country: "",
        apa_tel: "",
        nName: "",
        n1: 0,
        n2: 0,
        n3: 0,
        n4: 0,
        n5: 0,
        n6: 0,
        n7: 0,
        i_remark: "",
        i_persontype: "",
        apa_tax_id: "",
        i_year: new Date().getFullYear(),
        i_month: new Date().getMonth() + 1,
      },
      addr: [],
      province: [],
      amphur: [],
      tambon: [],
      personType: [],
      years: Array.from({ length: new Date().getFullYear() - 2019 }, (x, i) => (
        <option key={"y" + i} value={i + 1 + 2019}>
          {i + 1 + 2019}
        </option>
      )).reverse(),
    };

    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.insertData = this.insertData.bind(this);
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  insertData = () => {
    if (this.state.form.i_persontype === "1") {
      const nameSplit = this.state.form.apa_name.split(" ");
      if (nameSplit.length < 3) {
        Notifications.NotiError({ msg: "รูปแบบชื่อผู้ชำระเงินไม่ถูกต้อง" });
        return;
      }
    }
    if (this.state.form.apa_tax_id === "") {
      Notifications.NotiError({
        msg: "กรุณากรอก หมายเลขผู้เสียภาษี/ หมายเลขบัตรประชาชน/ เลขที่หนังสือเดินทาง",
      });
      return;
    }
    if (this.state.form.n1 === "") {
      Notifications.NotiError({ msg: "กรุณาระบุจำนวนเงิน" });
      return;
    }
    this.handlerIsLoading(true);
    let form = this.state.form;
    form.ap_id = Helper.GetUserData().ap_id;
    form.u_cgd_user = Helper.GetUserData().u_cgd_user;
    form.u_cgd_pass = Helper.GetUserData().u_cgd_pass;
    Notifications.NotiAsync(
      axios.post(`${process.env.REACT_APP_API_URL}/v1/otherincomes`, {
        form,
      }),
      (resp) => {
        this.handlerIsLoading(false);
        this.setState({ redirect: true });
        Notifications.NotiSuccess();
      },
      (err) => {
        this.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };
  getData = async () => {
    this.handlerIsLoading(true);
    let main = [];
    let sub = [];
    let m = [];
    let addr = [];
    let province = [];
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorys/type/2`)
      .then((res) => {
        m = res.data;
        res.data.forEach((item) => {
          if (item.rt_id > 1) {
            main.push(
              <option key={"a" + item.rc_id} value={item.rc_id}>
                {item.rc_name}
              </option>
            );
          }
        });
      });

    await axios.get(`${process.env.REACT_APP_API_URL}/v1/addr`).then((res) => {
      addr = res.data;
      const unique = [...new Set(res.data.map((item) => item.province))];
      province = [
        ...unique.map((item) => {
          return (
            <option key={"p" + item} value={item}>
              {item}
            </option>
          );
        }),
      ];
    });
    let personType = [];
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/invoice/persontype`)
      .then((res) => {
        personType = res.data.map((item) => {
          return (
            <option key={"persontype" + item.pt_code} value={item.pt_code}>
              {item.pt_name}
            </option>
          );
        });
      });

    let form = {};
    const id = this.props.match.params.id;
    if (id) {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/v1/otherincomes/${id}`)
        .then((res) => {
          form = res.data;
          form.nName = res.data.i_name;
          form.n1 = res.data.i_sum;
          form.n2 = res.data.i_sum;
          form.n3 = res.data.i_discount;
          form.n4 = res.data.i_sum - res.data.i_discount;
          form.n5 = res.data.i_mulct;
          form.n6 = 0;
          form.n7 = res.data.i_total;
        });
    }
    this.setState(
      {
        revenueCategory: main,
        revenueCategorySub: sub,
        rCategory: m,
        form: { ...this.state.form, ...form },
        addr: addr,
        province: province,
        personType: personType,
      },
      () => {
        this.handlerIsLoading(false);
      }
    );
  };
  componentDidMount = () => {
    this.getData();
  };
  updateField = (e) => {
    if (["n1", "n3", "n5", "n6"].includes(e.target.name)) {
      const v = Number(e.target.value) ? Number(e.target.value) : "";
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: v,
          n2: e.target.name === "n1" ? v : this.state.form.n1,
          n4:
            (e.target.name === "n1" ? v : this.state.form.n1) -
            (e.target.name === "n3" ? v : this.state.form.n3),
          n7:
            (e.target.name === "n1" ? v : this.state.form.n1) -
            (e.target.name === "n3" ? v : this.state.form.n3) -
            (e.target.name === "n6" ? v : this.state.form.n6) +
            (e.target.name === "n5" ? v : this.state.form.n5),
        },
      });
    } else if (e.target.name === "main") {
      const fRevenue = this.state.rCategory.find(
        (i) => e.target.value.toString() === i.rc_id.toString()
      );
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          i_revenue_type: fRevenue ? fRevenue.rt_id : 0,
          nName: fRevenue ? fRevenue.rc_name : "",
        },
      });
    } else if (e.target.name === "apa_province_code") {
      const unique = [
        ...new Set(
          this.state.addr
            .filter((item) => item.province === e.target.value && item.amphoe)
            .map((item) => {
              return item.amphoe;
            })
        ),
      ];
      unique.sort();
      const amph = [
        ...unique
          .filter((item) => item)
          .map((item) => {
            return (
              <option key={"amp" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          apa_amphur_code: "",
          apa_tambon_code: "",
          apa_postcode: "",
        },
        amphur: amph,
      });
    } else if (e.target.name === "apa_amphur_code") {
      const unique = [
        ...new Set(
          this.state.addr
            .filter(
              (item) =>
                item.amphoe === e.target.value &&
                item.province === this.state.form.apa_province_code &&
                item.district
            )
            .map((item) => {
              return item.district;
            })
        ),
      ];
      unique.sort();
      const district = [
        ...unique
          .filter((item) => item)
          .map((item) => {
            return (
              <option key={"tum" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          apa_tambon_code: "",
          apa_postcode: "",
        },
        tambon: district,
      });
    } else if (e.target.name === "apa_tambon_code") {
      const unique = [
        ...new Set(
          this.state.addr
            .filter(
              (item) =>
                item.district === e.target.value &&
                item.amphoe === this.state.form.apa_amphur_code &&
                item.province === this.state.form.apa_province_code &&
                item.zipcode
            )
            .map((item) => {
              return item.zipcode;
            })
        ),
      ];
      unique.sort();
      let zipFirst = "";
      const zipcode = [
        ...unique
          .filter((item) => item)
          .map((item) => {
            if (zipFirst === "") {
              zipFirst = item;
            }
            return (
              <option key={"zip" + item} value={item}>
                {item}
              </option>
            );
          }),
      ];
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
          apa_postcode: zipFirst,
        },
        zipcode: zipcode,
      });
    } else if (e.target.name === "i_remark") {
      let val = e.target.value;
      if (val.length > 50) val = val.substring(0, 50);
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: val,
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/other-income" />;
    }
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>รายได้ประเภทอื่น</div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ประเภทรายได้</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>ประเภทรายได้หลัก</InputLabel>
                    <NativeSelect
                      native="true"
                      name="main"
                      onChange={this.updateField}
                      value={this.state.form.i_revenue_id}
                    >
                      <option aria-label="None" value="" />
                      {this.state.revenueCategory}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลใบแจ้งหนี้</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เลขที่ใบแจ้งหนี้"
                    name="i_doc_no"
                    disabled
                    onChange={this.updateField}
                  />
                </div>
                <div className="box-S1">
                  <FormControl fullWidth>
                    <InputLabel shrink>รายได้ประจำปี</InputLabel>
                    <NativeSelect
                      native="true"
                      name="i_year"
                      onChange={this.updateField}
                      value={this.state.form.i_year}
                    >
                      {this.state.years}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="box-S1">
                  <FormControl fullWidth>
                    <InputLabel shrink>รายได้ประจำเดือน</InputLabel>
                    <NativeSelect
                      native="true"
                      name="i_month"
                      onChange={this.updateField}
                      value={this.state.form.i_month}
                    >
                      <option key="m1" value="1">
                        มกราคม
                      </option>
                      <option key="m2" value="2">
                        กุมภาพันธ์
                      </option>
                      <option key="m3" value="3">
                        มีนาคม
                      </option>
                      <option key="m4" value="4">
                        เมษายน
                      </option>
                      <option key="m5" value="5">
                        พฤษภาคม
                      </option>
                      <option key="m6" value="6">
                        มิถุนายน
                      </option>
                      <option key="m7" value="7">
                        กรกฎาคม
                      </option>
                      <option key="m8" value="8">
                        สิงหาคม
                      </option>
                      <option key="m9" value="9">
                        กันยายน
                      </option>
                      <option key="m10" value="10">
                        ตุลาคม
                      </option>
                      <option key="m11" value="11">
                        พฤศจิกายน
                      </option>
                      <option key="m12" value="12">
                        ธันวาคม
                      </option>
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันที่ออกใบแจ้งหนี้"
                    type="date"
                    name="i_doc_date"
                    InputLabelProps={{ shrink: true }}
                    value={Helper.CastDate(
                      new Date(this.state.form.i_doc_date)
                    )}
                    onChange={this.updateField}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันครบกำหนดชำระ"
                    type="date"
                    name="i_due_date"
                    InputLabelProps={{ shrink: true }}
                    value={Helper.CastDate(
                      new Date(this.state.form.i_due_date)
                    )}
                    onChange={this.updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลผู้ชำระ</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>ประเภทผู้ชำระเงิน</InputLabel>
                    <NativeSelect
                      native="true"
                      name="i_persontype"
                      onChange={this.updateField}
                      value={
                        this.state.form.i_persontype
                          ? this.state.form.i_persontype
                          : ""
                      }
                    >
                      <option aria-label="None" value="" />
                      {this.state.personType}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="หมายเลขผู้เสียภาษี/ หมายเลขบัตรประชาชน/ เลขที่หนังสือเดินทาง"
                    name="apa_tax_id"
                    value={
                      this.state.form.apa_tax_id
                        ? this.state.form.apa_tax_id
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่อผู้ชำระเงิน"
                    name="apa_name"
                    onChange={this.updateField}
                    value={
                      this.state.form.apa_name ? this.state.form.apa_name : ""
                    }
                  />
                  {this.state.form.i_persontype === "1" && (
                    <p
                      style={{
                        margin: 0,
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      * ตัวอย่างแบบปกติ นาย ทดสอบ ระบบดี
                      <br />
                      ** ตัวอย่างแบบมีชื่อกลาง นาย ทดสอบ กลาง ระบบดี
                    </p>
                  )}
                </div>

                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ที่อยู่"
                    name="apa_house_no"
                    value={
                      this.state.form.apa_house_no
                        ? this.state.form.apa_house_no
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>จังหวัด</InputLabel>
                    <NativeSelect
                      native="true"
                      name="apa_province_code"
                      onChange={this.updateField}
                      value={
                        this.state.form.apa_province_code
                          ? this.state.form.apa_province_code
                          : ""
                      }
                    >
                      <option aria-label="None" value="" />
                      {this.state.province}
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่ออาคาร"
                    name="apa_building_name"
                    value={
                      this.state.form.apa_building_name
                        ? this.state.form.apa_building_name
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>อำเภอ/เขต</InputLabel>
                    <NativeSelect
                      native="true"
                      name="apa_amphur_code"
                      onChange={this.updateField}
                      value={
                        this.state.form.apa_amphur_code
                          ? this.state.form.apa_amphur_code
                          : ""
                      }
                      disabled={this.state.form.apa_province_code === ""}
                    >
                      <option aria-label="None" value="" />
                      {this.state.amphur}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="หมู่"
                    name="apa_moo"
                    value={
                      this.state.form.apa_moo ? this.state.form.apa_moo : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>ตำบล/แขวง</InputLabel>
                    <NativeSelect
                      native="true"
                      name="apa_tambon_code"
                      onChange={this.updateField}
                      value={
                        this.state.form.apa_tambon_code
                          ? this.state.form.apa_tambon_code
                          : ""
                      }
                      disabled={this.state.form.apa_amphur_code === ""}
                    >
                      <option aria-label="None" value="" />
                      {this.state.tambon}
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ถนน"
                    name="apa_road"
                    value={
                      this.state.form.apa_road ? this.state.form.apa_road : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>รหัสไปรษณีย์</InputLabel>
                    <NativeSelect
                      native="true"
                      name="apa_postcode"
                      onChange={this.updateField}
                      value={
                        this.state.form.apa_postcode
                          ? this.state.form.apa_postcode
                          : ""
                      }
                      disabled={this.state.form.apa_tambon_code === ""}
                    >
                      <option aria-label="None" value="" />
                      {this.state.zipcode}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    name="apa_tel"
                    value={
                      this.state.form.apa_tel ? this.state.form.apa_tel : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ประเทศ"
                    name="apa_country"
                    value={
                      this.state.form.apa_country
                        ? this.state.form.apa_country
                        : ""
                    }
                    onChange={this.updateField}
                  />
                </div>

                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="Email"
                    name="apa_email"
                    value={
                      this.state.form.apa_email ? this.state.form.apa_email : ""
                    }
                    onChange={this.updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการชำระ</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label=""
                    value={this.state.form.nName}
                    onChange={this.updateField}
                    name="nName"
                  />
                </div>
                <div className="box-S1"></div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n1"
                    type="number"
                    value={this.state.form.n1}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  รวมเป็นเงิน
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n2"
                    disabled
                    type="number"
                    value={this.state.form.n2}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  ส่วนลด
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n3"
                    type="number"
                    value={this.state.form.n3}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  เป็นเงิน
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n4"
                    disabled
                    type="number"
                    value={this.state.form.n4}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  ค่าปรับ
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    onChange={this.updateField}
                    name="n5"
                    type="number"
                    value={this.state.form.n5}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  ปรับยอดค่าปรับ
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    type="number"
                    onChange={this.updateField}
                    name="n6"
                    value={this.state.form.n6}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2"></div>
                <div className="box-S1 d-flex-ralign-jflexend color-primary">
                  จำนวนเงินทั้งสิ้น
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label=""
                    type="number"
                    onChange={this.updateField}
                    name="n7"
                    disabled
                    value={this.state.form.n7}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">หมายเหตุ</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S4">
                  <TextField
                    fullWidth
                    label="หมายเหตุ (ไม่เกิน 50 ตัวอักษร)"
                    name="i_remark"
                    value={this.state.form.i_remark}
                    onChange={this.updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Bottom2Function
          back="/welfare-income"
          insertData={this.insertData.bind(this)}
        />
      </div>
    );
  }
}

export default withRouter(OtherIncomeAdd);
