/** @format */

import swal from "sweetalert";
const SD = {
  Input: ({ msg, value, btn, fnConfirm } = {}) => {
    let input = document.createElement("input");
    input.value = value ? value : "";
    input.type = "text";
    input.className = "swal-content__input";
    swal(msg ? msg : "", {
      content: input,
      buttons: ["ปิด", btn ? btn : ""],
    }).then((willConfirm) => {
      fnConfirm({ status: willConfirm, value: input.value });
    });
  },
  Confirm: ({ header, msg, type, fnConfirm } = {}) => {
    swal({
      title: header,
      text: msg,
      icon: type,
      dangerMode: type === "success" ? false : true,
      buttons: ["ปิด", "ยืนยัน"],
    }).then((willConfirm) => {
      if (willConfirm) {
        fnConfirm();
      }
    });
  },
  Dialog: ({ header, msg, type, fnConfirm }) => {
    swal({
      title: header,
      text: msg,
      icon: type,
      buttons: {
        confirm: { text: "ปิด", className: "styleBtn blue" },
      },
    }).then(() => {
      fnConfirm();
    });
  },
};
export default SD;
