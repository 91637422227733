/** @format */

import React, { useState } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "../../css/login.css";
import Notifications from "../global/Notifications";
import SD from "../global/SwalDialog";
function Login(props) {
  const history = useHistory();
  const [form, setState] = useState({
    u_username: "",
    u_password: "",
  });
  const printValues = (e) => {
    e.preventDefault();
  };
  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const loginSuccessed = async (res) => {
    await localStorage.setItem("DataUser", JSON.stringify(res.data[0]));
    await localStorage.setItem("status", true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/userlevels/${res.data[0].ul_id}`
      )
      .then((res) => {
        localStorage.setItem("DataUserLevel", JSON.stringify(res.data[0]));
      });
    if (localStorage.getItem("DataUser")) {
      props.handlerIsLoggedIn(true);
      history.push("/");
    }
    props.handlerIsLoading(false);
  };
  const btnlogin = async (e) => {
    props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.post(`${process.env.REACT_APP_API_URL}/v1/login`, form),
      (resp) => {
        if(resp.data.msg) {
          Notifications.NotiError({ msg: resp.data.msg });
          SD.Dialog({
            header: "ไม่สามารถเข้าสู่ระบบได้!",
            msg: resp.data.msg,
            type: "error",
            fnConfirm: () => {},
          });
          props.handlerIsLoading(false);
          return;
        }
        Notifications.NotiSuccess();
        loginSuccessed(resp);
      },
      (err) => {
        props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
        SD.Dialog({
          header: "ไม่สามารถเข้าสู่ระบบได้!",
          msg: err.response.data.msg,
          type: "error",
          fnConfirm: () => {},
        });
      }
    );
  };
  const isLogedin = () => {
    if (localStorage.getItem("DataUser")) {
      history.push("/");
    }
  };
  return (
    <div className="LoginPageMain">
      {isLogedin()}
      <div className="LoginFormMain bContentMain">
        <div className="BoxLogo">
          <div className="Logo"></div>
        </div>
        <form onSubmit={printValues}>
          <div className="LoginForm">
            <div className="bRow" style={{ marginTop: "10px" }}>
              <TextField
                className="inputUser"
                fullWidth
                label="ชื่อผู้ใช้งาน"
                value={form.u_username}
                name="u_username"
                onChange={updateField}
              />
            </div>
            <div className="bRow" style={{ marginTop: "10px" }}>
              <TextField
                fullWidth
                autoComplete="on"
                className="inputPass"
                label="รหัสผ่าน"
                value={form.u_password}
                name="u_password"
                type={showPassword ? "text" : "password"}
                onChange={updateField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="bRow" style={{ marginTop: "10px" }}>
              <Button fullWidth className="styleBtn blue" onClick={btnlogin}>
                เข้าสู่ระบบ
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className="loginFooter">
        Copyright Department of Airports, Thailand,2021. All Rights Reserved.
      </div>
    </div>
  );
}

export default Login;
