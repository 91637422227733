/** @format */

import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import Helper from "../global/Helper";

export class MainMenuList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NavItemActive: "",
      currentRoute: "",
    };
    this.user = JSON.parse(localStorage.getItem("DataUser"));
    this.menu = [
      {
        name: "ข้อมูลเที่ยวบิน",
        access: [1, 2, 3, 4],
        ul: [
          {
            item: "บันทึกเที่ยวบิน",
            tolink: "AirlineRecord",
            url: "/airline-record",
            access: [1, 2, 3],
          },
          {
            item: "บันทึกเที่ยวบินพิเศษ",
            tolink: "AirlineRecordSpacial",
            url: "/airline-record-special",
            access: [1, 2, 3],
          },
          {
            item: "ข้อมูลไม่สมบูรณ์",
            tolink: "IncompleteInformation",
            url: "/incomplete-information",
            access: [1, 2, 3],
          },
          {
            item: "นำเข้าข้อมูลเที่ยวบิน",
            tolink: "ImportTransport",
            url: "/import-transport",
            access: [1, 2, 3],
          },
          {
            item: "ยกเว้นจัดเก็บรายได้",
            tolink: "ExceptInvoice",
            url: "/except-invoice",
            access: [1, 2, 3],
          },
          {
            item: "ยืนยันข้อมูลเที่ยวบิน",
            tolink: "FlightCheck",
            url: "/flight-check",
            access: [1, 2, 3, 4],
          },
          {
            item: "ประวัติข้อมูลเที่ยวบิน",
            tolink: "FlightSchedule",
            url: "/flight-schedule",
            access: [1, 2, 3, 4],
          },
        ],
      },
      {
        name: "ข้อมูลรายรับ",
        access: [1, 2, 3],
        ul: [
          {
            item: "รายการเที่ยวบินคงค้าง",
            tolink: "TransportRemainCenter",
            url: "/transport-remain-center",
            access: [1, 2],
          },
          {
            item: "รายการเที่ยวบินคงค้าง",
            tolink: "TransportRemain",
            url: "/transport-remain",
            access: [1, 3],
          },
          {
            item: "ใบแจ้งหนี้สายการบิน",
            tolink: "Invoice",
            url: "/invoice",
            access: [1, 2, 3],
          },
          {
            item: "ใบแจ้งหนี้เที่ยวบินพิเศษ",
            tolink: "InvoiceSpecial",
            url: "/invoice-special",
            access: [1, 2, 3],
          },
          {
            item: "ใบลดหนี้",
            tolink: "ReduceDept",
            url: "/reduce-dept",
            access: [1, 2, 3],
          },
          {
            item: "รายได้ประเภทอื่น",
            tolink: "OtherIncome",
            url: "/other-income",
            access: [1, 2, 3],
          },
          {
            item: "รายได้สวัสดิการ",
            tolink: "Welfare",
            url: "/welfare-income",
            access: [1, 2, 3],
          },
          {
            item: "รายงาน",
            tolink: "Report",
            url: "/report",
            access: [1, 2],
          },
          {
            item: "แผน (ใบแจ้งหนี้)",
            tolink: "ReportTargetBudgetInvoice",
            url: "/report-target-budget-invoice",
            access: [1, 2],
          },
          {
            item: "แผน (เงินสด)",
            tolink: "ReportTargetBudgetCash",
            url: "/report-target-budget-cash",
            access: [1, 2],
          },
          {
            item: "รายงานประมาณการรายได้",
            tolink: "RevenueEstimateReport",
            url: "/report-revenue-estimate",
            access: [1, 2],
          },
          {
            item: "รายงานข้อมูลการส่งอีเมล",
            tolink: "LogEmail",
            url: "/report-log-email",
            access: [1, 2],
          },
        ],
      },
      {
        name: "ข้อมูลพื้นฐาน",
        access: [1, 2, 3],
        ul: [
          {
            item: "อากาศยาน",
            tolink: "Aircraft",
            url: "/aircraft",
            access: [1, 2, 3],
          },
          {
            item: "ท่าอากาศยาน",
            tolink: "Airport",
            url: "/airport",
            access: [1, 2, 3],
          },
          {
            item: "สายการบิน",
            tolink: "Airline",
            url: "/airline",
            access: [1, 2],
          },
          {
            item: "ที่อยู่ใบแจ้งหนี้สายการบิน",
            tolink: "AirlineAddress",
            url: "/airline-address",
            access: [1, 2, 3],
          },
          {
            item: "รายชื่อร้านค้าสวัสดิการ",
            tolink: "WelfareLists",
            url: "/welfare-lists",
            access: [1, 2, 3],
          },
          {
            item: "Dashboard",
            tolink: "Dashboard",
            url: "/dashboard",
            access: [1, 2],
          },
        ],
      },
      {
        name: "ข้อมูลตั้งค่า",
        access: [1, 2, 3],
        ul: [
          {
            item: "รายการค่าธรรมเนียมและบริการ",
            tolink: "MasterItem",
            url: "/master-item",
            access: [-1],
          },
          {
            item: "รายการประเภทรายได้หลัก",
            tolink: "RevenueCategory",
            url: "/revenue-category",
            access: [1, 2],
          },
          {
            item: "รายการประเภทรายได้ย่อย",
            tolink: "RevenueCategorySub",
            url: "/revenue-category-sub",
            access: [-1],
          },
          {
            item: "ผู้ใช้งาน",
            tolink: "User",
            url: "/user",
            access: [1, 3],
          },
        ],
      },
    ];
    this.menuRows = [];
  }
  getLiItem = (item) => {
    return (
      <li
        className={item.url.substring(1) + " nav-menu-items"}
        key={item.key + Helper.RanDomUnique()}
      >
        <Link key={item.tolink} to={item.url ? item.url : "/" + item.tolink}>
          {item.item}
        </Link>
      </li>
    );
  };
  getMenu = () => {
    this.menuRows = [];
    if (!this.user) return;
    this.menu.forEach((item) => {
      if (item.access.includes(parseInt(this.user.ul_id))) {
        if (item.hasOwnProperty("link")) {
          const liTemp = (
            <li
              className={item.link.substring(1) + " nav-menu-items"}
              key={Helper.RanDomUnique()}
            >
              <Link key={item.link} to={item.link}>
                {item.name}
              </Link>
            </li>
          );
          this.menuRows.push(liTemp);
          return;
        }
        const ul = [];
        item.ul.forEach((i) => {
          if (i.access.includes(parseInt(this.user.ul_id))) {
            ul.push(this.getLiItem(i));
          }
        });
        const temp = (
          <Accordion
            square
            key={`a${Helper.RanDomUnique()}`}
            defaultExpanded={this.state.currentRoute === item.name}
            onClick={() => {
              localStorage.setItem("MenuExpanded", item.name);
              this.setState({ currentRoute: item.name });
            }}
          >
            <AccordionSummary
              key={`b${Helper.RanDomUnique()}`}
              expandIcon={<ExpandMoreIcon />}
            >
              {item.name}
            </AccordionSummary>
            <AccordionDetails key={`c${Helper.RanDomUnique()}`}>
              <ul
                kkey={`d${Helper.RanDomUnique()}`}
                className="MenuList"
                style={{ paddingLeft: 15, marginTop: 0, marginBottom: 0 }}
              >
                {ul}
              </ul>
            </AccordionDetails>
          </Accordion>
        );
        this.menuRows.push(temp);
      }
    });
  };
  getExpanded = () => {
    const temp = window.location.pathname.substring(1).split("/");
    return temp[0];
  };
  componentWillMount = () => {
    const temp = localStorage.getItem("MenuExpanded");
    const findMenu = this.menu.find((i) => {
      const ulItem = i.ul.find((u) => u.url.includes(this.getExpanded()));
      return ulItem;
    });
    this.setState(
      {
        currentRoute: findMenu ? findMenu.name : temp,
      },
      () => {
        this.getMenu();
      }
    );
  };

  render() {
    return this.menuRows.length ? this.menuRows : [];
  }
}

export default MainMenuList;
