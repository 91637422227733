/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import fileDownload from 'js-file-download';
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import Bottom2Function from '../../global/Bottom2Function';
import Bottom1Function from '../../global/Bottom1Function';
import Notifications from '../../global/Notifications';
import Helper from '../../global/Helper';
function FlightCheckDetails(props) {

  const { id } = useParams();
  const history = useHistory();
  const DataUser = JSON.parse(localStorage.getItem('DataUser'));
  let upLoadfile = null;

  const [trafftype, setTraffType] = useState([]);
  const [porttype, setPortType] = useState([]);
  const [portType, setPort] = useState([]);
  const [transport, setTransport] = useState({});
  const [transportA, setTransportA] = useState({});
  const [transportF, setTransportF] = useState({});
  const [disabled_a, setDisableA] = useState(false);
  const [disabled_f, setDisableF] = useState(false);
  const [isDom, setIsDom] = useState(false);
  const [dateTimeA, setDateTimeA] = useState({
    date: '',
    time: '',
  });
  const [dateTimeF, setDateTimeF] = useState({
    date: '',
    time: '',
  });
  const [formFlightDetail, setState2] = useState({
    td_infants_a: 0,
    td_passenger_a: 0,
    td_passenger_dom_a: 0,
    td_passenger_inter_a: 0,
    td_passenger_transfer_a: 0,
    td_passenger_transit_a: 0,
    td_infants_f: 0,
    td_passenger_f: 0,
    td_passenger_dom_f: 0,
    td_passenger_inter_f: 0,
    td_passenger_transfer_f: 0,
    td_passenger_transit_f: 0,
    td_used: 0,
    id: id,
    td_datetime_a: '',
    td_datetime_a_date: '',
    td_datetime_a_time: '',
    td_datetime_f: '',
    td_datetime_f_date: '',
    td_datetime_f_time: '',
    td_status: 0,
    user_id: DataUser.u_id,
    td_arrival_freight_a: 0,
    td_arrival_freight_f: 0,
  });
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({
    td_datetime_a_date: false,
    td_datetime_a_time: false,
    td_datetime_f_date: false,
    td_datetime_f_time: false,
  });

  const getTrafftype = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/trafftype`)
      .then((res) => {
        setTraffType(res.data);
      });
  };
  const getPorttype = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/porttype`)
      .then((res) => {
        setPortType(res.data);
      });
  };
  const getPort = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/airports/portcode/apc_gps_code`)
      .then((res) => {
        setPort(res.data);
      });
  };
  const getTransport = () => {
    props.handlerIsLoading(true)
    axios
    .get(`${process.env.REACT_APP_API_URL}/v1/transports/ts/${id}`)
    .then(async (res) => {
      if (res.data.length > 0) {
        const resp = res.data[0];
        setTransport(resp)
        const setA = DataUser.ul_id === 4 || DataUser.ul_id === 1;
        const dataSetA = {};
        dataSetA.td_datetime_a = resp.td_datetime_a
        dataSetA.td_passenger_a = resp.td_passenger_a
        dataSetA.td_passenger_dom_a = resp.td_passenger_dom_a
        dataSetA.td_passenger_inter_a = resp.td_passenger_inter_a
        dataSetA.td_passenger_transfer_a = resp.td_passenger_transfer_a
        dataSetA.td_passenger_transit_a = resp.td_passenger_transit_a
        dataSetA.td_infants_a = resp.td_infants_a
        dataSetA.td_arrival_freight_a = resp.td_arrival_freight_a
        setDateTimeA({
          date: Helper.CastDate(new Date(resp.td_datetime_a)),
          time:  Helper.CastTime(new Date(resp.td_datetime_a)),
        })
        if(resp.td_airline_confirm === '0' && setA) {
          dataSetA.td_datetime_a = resp.t_datetime
          dataSetA.td_passenger_a = resp.t_passenger
          dataSetA.td_passenger_dom_a = resp.t_passenger_dom
          dataSetA.td_passenger_inter_a = resp.t_passenger_inter
          dataSetA.td_passenger_transfer_a = resp.t_passenger_transfer
          dataSetA.td_passenger_transit_a = resp.t_passenger_transit
          dataSetA.td_infants_a = resp.t_infants
          dataSetA.td_arrival_freight_a = resp.t_arrival_freight
          setDateTimeA({
            date: Helper.CastDate(new Date(resp.t_datetime)),
            time:  Helper.CastTime(new Date(resp.t_datetime)),
          })
        }
        setTransportA(dataSetA)
        
        const setF = DataUser.ul_id === 3 || DataUser.ul_id === 1;
        const dataSetF = {};
        dataSetF.td_datetime_f = resp.td_datetime_f
        dataSetF.td_passenger_f = resp.td_passenger_f
        dataSetF.td_passenger_dom_f = resp.td_passenger_dom_f
        dataSetF.td_passenger_inter_f = resp.td_passenger_inter_f
        dataSetF.td_passenger_transfer_f = resp.td_passenger_transfer_f
        dataSetF.td_passenger_transit_f = resp.td_passenger_transit_f
        dataSetF.td_infants_f = resp.td_infants_f
        dataSetF.td_arrival_freight_f = resp.td_arrival_freight_f
        setDateTimeF({
          date: Helper.CastDate(new Date(resp.td_datetime_f)),
          time:  Helper.CastTime(new Date(resp.td_datetime_f)),
        })
        if(resp.td_airport_confirm === '0' && setF) {
          dataSetF.td_datetime_f = resp.t_datetime
          dataSetF.td_passenger_f = resp.t_passenger
          dataSetF.td_passenger_dom_f = resp.t_passenger_dom
          dataSetF.td_passenger_inter_f = resp.t_passenger_inter
          dataSetF.td_passenger_transfer_f = resp.t_passenger_transfer
          dataSetF.td_passenger_transit_f = resp.t_passenger_transit
          dataSetF.td_infants_f = resp.t_infants
          dataSetF.td_arrival_freight_f = resp.t_arrival_freight
          setDateTimeF({
            date: Helper.CastDate(new Date(resp.t_datetime)),
            time:  Helper.CastTime(new Date(resp.t_datetime)),
          })
        }
        setTransportF(dataSetF)

        setDisableA(setF || resp.td_airline_confirm === '1' || resp.td_status === '3');
        setDisableF(setA || resp.td_airport_confirm === '1' || resp.td_status === '3');
      }
    });
  }
  const getTrafftypeName = (i) => {
    const find = trafftype.find((item) => {
      return item.tt_code === i;
    });
    return find ? `${find.tt_code} (${find.tt_description})` : '';
  };
  const getPortTypeName = (v) => {
    if (v) {
      const findItem = porttype.find((i) => i.pt_code === v);
      return findItem ? `${findItem.pt_code} (${findItem.pt_description})` : '';
    } 
    return '';
  };
  const getPortName = (i, isICAO, isCode = true) => {
    const find = portType.find((item) => {
      if (isICAO) {
        return item.apc_gps_code === i;
      } else {
        return item.apc_iata_code === i;
      }
    });
    if (isICAO) {
      if (isCode) {
        return find ? `${find.apc_gps_code}` : '';
      } else {
        return find ? `${find.apc_name}` : '';
      }
    } else {
      if (isCode) {
        return find ? `${find.apc_iata_code}` : '';
      } else {
        return find ? `${find.apc_name}` : '';
      }
    }
  };
  const getParams = (p) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    return p !== 'all' ? url.searchParams.get(p) : window.location.search;
  };
  const getFile = () => {
    props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1//download/flights/${formFlightDetail.td_upload}`,
        {
          responseType: 'blob',
        }
      ),
      (resp) => {
        fileDownload(resp.data, formFlightDetail.td_upload);
        props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };

  const updateField = (e, setType) => {
    let target = e.target.name;
    let value = e.target.value;
    if(e.target.type === 'number') value = Helper.vInt(value);
    if(setType === 'A') setTransportA({ ...transportA, [target]: value });
    if(setType === 'F') setTransportF({ ...transportF, [target]: value });
  };
  const updateFieldDateTime = (e, setType) => {
    const target = e.target.name;
    const value = e.target.value;
    if(setType === 'A') setDateTimeA({ ...dateTimeA, [target]: value });
    if(setType === 'F') setDateTimeF({ ...dateTimeF, [target]: value });
  }
  const updateData = async (t) => {
    props.handlerIsLoading(true);
    const transportData = {
      ...transportA,
      ...transportF
    }
    
    if (!disabled_a) transportData.td_datetime_a = dateTimeA.date + ' ' + dateTimeA.time;
    if (!disabled_f) transportData.td_datetime_f = dateTimeF.date + ' ' + dateTimeF.time;

    // const dtA = new Date(transportData.td_datetime_a);
    // const dtF = new Date(transportData.td_datetime_f);
    // const checkDateTimeA = !Helper.CastDate(dtA) || !Helper.CastTime(dtA);
    // const checkDateTimeF = !Helper.CastDate(dtF) || !Helper.CastTime(dtF);
    /*
    if((!disabled_a && checkDateTimeA) || (!disabled_f && checkDateTimeF)) {
      Notifications.NotiError({ msg: 'Please check date time' });
      const err = {}
      if(t === 'A') {
          err.td_datetime_a_date = !dateTimeA.date
          err.td_datetime_a_time = !dateTimeA.time
      }
      if(t === 'F') {
        err.td_datetime_f_date = !dateTimeF.date
        err.td_datetime_f_time = !dateTimeF.time
      }
      setError({
        ...error,
        ...err
      })
      props.handlerIsLoading(false);
      return;
    }
    */
    Notifications.NotiAsync(
      axios.put(`${process.env.REACT_APP_API_URL}/v1/transports/td/${id}`, {
        formFlightDetail: transportData,
        formFlight: transport,
        type: t,
      }),
      (resp) => {
        props.handlerIsLoading(false);
        history.push(
          getParams('goto') === 'createinvoice'
            ? `/invoice/create${getParams('all')}`
            : getParams('goto') && getParams('goto').includes('_')
            ? `/${getParams('goto').substring(1)}`
            : '/flight-check'
        );
        Notifications.NotiSuccess();
      },
      (err) => {
        props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };
  const sendFileUpload = (e) => {
    props.handlerIsLoading(true);
    var formData = new FormData();
    var file = document.querySelector('#filUpload');
    formData.append('file', file.files[0]);
    formData.append('id', id);
    formData.append('uid', DataUser.u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/transports/uploadfile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      ),
      (resp) => {
        document.querySelector('#filUpload').value = '';
        props.handlerIsLoading(false);
        setState2({ ...formFlightDetail, td_upload: resp.data.filename });
        Notifications.NotiSuccess();
      },
      (err) => {
        document.querySelector('#filUpload').value = '';
        props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };

  useEffect(() => {
    getPort();
    getTrafftype();
    getPorttype();
    getTransport();
  }, []);
  useEffect(() => {
    if(transport.t_traff_type) {
      const fnd = trafftype.find((item) => {
        return item.tt_code === transport.t_traff_type;
      });
      if(fnd) setIsDom(fnd.tt_dom === '0')
    }
  }, [trafftype, transport]);
  useEffect(() => {
    setTimeout(() => {
      props.handlerIsLoading(false);
    }, 1500);
  }, [disabled_a, disabled_f]);
  return (
    <div>
      <div className='box-S4 mgBottom'>
        <div className='bPageName flex-between'>
          <div>ยืนยันข้อมูลเที่ยวบิน</div>
        </div>
      </div>
      <div className='box-S4 mgBottom'>
        <div className='bContentMain'>
          <div className='bNameContent'>รายละเอียดสายการบิน</div>
          <div className='bContent'>
            <div className='bRow flex-between'>
              <div className='box-S2'>
                <TextField
                  fullWidth
                  label='ชื่อนิติบุคคล / เจ้าของเที่ยวบิน'
                  InputLabelProps={{ shrink: true }}
                  value={Helper.vStr(transport.al_name)}
                  disabled
                />
              </div>
              <div className='box-S2'>
                <div className='bRow flex-between'>
                  <div className='box-S4'>
                    <FormControl component='fieldset'>
                      <FormLabel
                        component='legend'
                        className='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-disabled Mui-disabled MuiFormLabel-filled'
                      >
                        การเดินทาง
                      </FormLabel>
                      <RadioGroup
                        disabled
                        aria-label='t_arr_dep'
                        name='t_arr_dep'
                        value={Helper.vStr(transport.t_arr_dep)}
                        onChange={updateField}
                        row
                        style={{
                          paddingBottom: '0px',
                          paddingLeft: '15px',
                        }}
                      >
                        <FormControlLabel
                          disabled
                          value='A'
                          control={<Radio />}
                          label='เข้า'
                        />
                        <FormControlLabel
                          disabled
                          value='D'
                          control={<Radio />}
                          label='ออก'
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className='bRow flex-between'>
              <div className='box-S2'>
                <TextField
                  fullWidth
                  label='ตัวแทน'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={Helper.vStr(transport.al_agent)}
                />
              </div>
              <div className='box-S2'>
                <TextField
                  fullWidth
                  label='เจ้าของ'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={Helper.vStr(transport.al_owner)}
                />
              </div>
            </div>
            <div className='bRow flex-between'>
              <div className='box-S2'>
                <TextField
                  fullWidth
                  value={Helper.vStr(transport.t_callsign)}
                  name='t_callsign'
                  label='เลขที่สัญชาติและทะเบียน'
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>
              <div className='box-S2'>
                <TextField
                  fullWidth
                  label='แบบ'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={Helper.vStr(transport.t_air_type)}
                />
              </div>
            </div>
            <div className='bRow flex-between'>
              <div className='box-S2'>
                <TextField
                  fullWidth
                  label='น้ำหนัก'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={
                    Helper.vStr(Helper.CastCurrency(transport.t_arrival_freight))
                  }
                />
              </div>
              <div className='box-S2'>
                <TextField
                  fullWidth
                  label='สนามบิน'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={Helper.vStr(transport.ap_name)}
                />
              </div>
            </div>
            <div className='bRow flex-between'>
              <div className='box-S2'>
                <TextField
                  fullWidth
                  label='รหัสเที่ยวบิน'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={Helper.vStr(transport.t_flight_no)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='box-S4 flex-between mgBottom'>
        <div className='bContentMain'>
          <div className='bNameContent'>ข้อมูลการบิน</div>
          <div className='bContent'>
            <div className='bRow flex-between'>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='ประเภทการบิน'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={getTrafftypeName(transport.t_traff_type)}
                />
              </div>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='รหัสเที่ยวบิน'
                  value={Helper.vStr(transport.t_flight_no)}
                  name='t_flight_no'
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='Port Type'
                  value={getPortTypeName(transport.t_port_type)}
                  name='t_flight_no'
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='Region'
                  value={Helper.vStr(transport.t_region_no)}
                  name='t_flight_no'
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className='bRow flex-between'>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='รหัสต้นทาง'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={ getPortName(transport.t_departure_airport_code, true, true) }
                />
              </div>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='ชื่อต้นทาง'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={getPortName(transport.t_departure_airport_code, true, false )}
                />
              </div>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='รหัสปลายทาง'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={getPortName(transport.t_arrival_airport_code, true, true)}
                />
              </div>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='ชื่อปลายทาง'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={getPortName(transport.t_arrival_airport_code, true, false)}
                />
              </div>
            </div>
            <div className='bRow flex-between'>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='Card No.'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={Helper.vStr(transport.t_card_no)}
                />
              </div>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='นักบิน'
                  value={Helper.vStr(transport.td_captain)}
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='Zone No.'
                  value={Helper.vStr(transport.t_zone_no)}
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className='box-S1'>
                <TextField
                  fullWidth
                  label='ลูกเรือ'
                  value={Helper.vStr(transport.td_crew)}
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className='bRow flex-between'>
              <div className='box-S4'>
                <TextField
                  fullWidth
                  label='หมายเหตุ'
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={Helper.vStr(transport.t_remark)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='box-S6 flex-between mgBottom'>
        <div className='box-S2'>
          {/*############################################### ข้อมูลสถิติ ###############################################*/}
          <div className='bContentMain'>
            <div className='bNameContent'>ข้อมูลสถิติ</div>
            <div
              className='bContent'
              style={{ height: `${DataUser.ul_id === 1 ? '466px' : ''}` }}
            >
              <div className='bRow flex-between'>
                <TextField
                  fullWidth
                  label='วันที่'
                  value={Helper.CastDate(new Date(transport.t_datetime))}
                  type='date'
                  name='t_datetime'
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  fullWidth
                  label='เวลา'
                  value={Helper.CastTime(transport.t_datetime)}
                  type='time'
                  name='t_datetime'
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  fullWidth
                  label='น้ำหนัก'
                  value={Helper.CastCurrency(transport.t_arrival_freight)}
                  name='t_arrival_freight'
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={Helper.vInt(transport.t_passenger_dom)}
                  name='t_passenger_dom'
                  label='คนไทย (คน)'
                  disabled
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={Helper.vInt(transport.t_passenger_inter)}
                  name='t_passenger_inter'
                  label='ต่างชาติ (คน)'
                  disabled
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={Helper.vInt(transport.t_infants)}
                  name='t_infants'
                  label='เด็กต่ำกว่า 2 ขวบ (คน)'
                  disabled
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={Helper.vInt(transport.t_passenger_transfer)}
                  name='t_passenger_transfer'
                  label='ผู้โดยสารเปลี่ยนสายการบิน (คน)'
                  disabled
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={Helper.vInt(transport.t_passenger_transit)}
                  name='t_passenger_transit'
                  label='ผู้โดยสารแวะผ่านสนามบิน (คน)'
                  disabled
                />
              </div>
              <div className='bRow flex-between'>
                {formFlightDetail.td_upload && (
                  <div className='box-S2'>
                    <Button
                      onClick={() => {
                        getFile();
                      }}
                      className='styleBtn blue'
                      style={{ width: '100%', marginTop: '10px' }}
                    >
                      ดาวน์โหลด
                    </Button>
                  </div>
                )}
                <div
                  className={formFlightDetail.td_upload ? 'box-S2' : 'box-S4'}
                >
                  <input
                    id='filUpload'
                    type='file'
                    className='hidden'
                    ref={(input) => {
                      upLoadfile = input;
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      sendFileUpload(e);
                    }}
                  />
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      upLoadfile.click();
                    }}
                    className='styleBtn blue'
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    อัพโหลด
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='box-S2'>
          {/*############################################### ข้อมูลผู้ชำระ (A)###############################################*/}
          <div className='bContentMain'>
            <div className='bNameContent'>ข้อมูลสายการบิน</div>
            <div
              className='bContent'
              style={{ height: `${DataUser.ul_id !== 1 ? '466px' : ''}` }}
            >
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={error.td_datetime_a_date}
                  fullWidth
                  value={Helper.vStr(dateTimeA.date)}
                  name='date'
                  label='วันที่'
                  disabled={disabled_a}
                  type='date'
                  onChange={e => updateFieldDateTime(e, 'A')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={error.td_datetime_a_time}
                  fullWidth
                  value={Helper.vStr(dateTimeA.time)}
                  name='time'
                  label='เวลา'
                  disabled={disabled_a}
                  type='time'
                  onChange={e => updateFieldDateTime(e, 'A')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  fullWidth
                  label='น้ำหนัก'
                  value={Helper.vInt(transportA.td_arrival_freight_a)}
                  type='Number'
                  name='td_arrival_freight_a'
                  disabled={disabled_a}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => updateField(e, 'A')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportA.td_passenger_dom_a)}
                  name='td_passenger_dom_a'
                  label='คนไทย (คน)'
                  disabled={disabled_a || !isDom}
                  onChange={e => updateField(e, 'A')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportA.td_passenger_inter_a)}
                  name='td_passenger_inter_a'
                  label='ต่างชาติ (คน)'
                  disabled={disabled_a || isDom}
                  onChange={e => updateField(e, 'A')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportA.td_infants_a)}
                  name='td_infants_a'
                  label='เด็กต่ำกว่า 2 ขวบ (คน)'
                  disabled={disabled_a}
                  onChange={e => updateField(e, 'A')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportA.td_passenger_transfer_a)}
                  name='td_passenger_transfer_a'
                  label='ผู้โดยสารเปลี่ยนสายการบิน (คน)'
                  disabled={disabled_a}
                  onChange={e => updateField(e, 'A')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportA.td_passenger_transit_a)}
                  name='td_passenger_transit_a'
                  label='ผู้โดยสารแวะผ่านสนามบิน (คน)'
                  disabled={disabled_a}
                  onChange={e => updateField(e, 'A')}
                />
              </div>
              {DataUser.ul_id === 1 && (
                <div className='bRow flex-between'>
                  <Button
                    onClick={() => {
                      updateData('a');
                    }}
                    className='styleBtn blue'
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    บันทึก
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='box-S2'>
          {/*############################################### ข้อมูลเจ้าหน้าที่ (F)###############################################*/}
          <div className='bContentMain'>
            <div className='bNameContent'>ข้อมูลเจ้าหน้าที่</div>
            <div
              className='bContent'
              style={{ height: `${DataUser.ul_id !== 1 ? '466px' : ''}` }}
            >
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={error.td_datetime_f_date}
                  fullWidth
                  value={Helper.vStr(dateTimeF.date)}
                  name='date'
                  label='วันที่'
                  disabled={disabled_f}
                  type='date'
                  onChange={e => updateFieldDateTime(e, 'F')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={error.td_datetime_f_time}
                  fullWidth
                  value={Helper.vStr(dateTimeF.time)}
                  name='time'
                  label='เวลา'
                  disabled={disabled_f}
                  type='time'
                  onChange={e => updateFieldDateTime(e, 'F')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  fullWidth
                  label='น้ำหนัก'
                  value={Helper.vInt(transportF.td_arrival_freight_f)}
                  type='Number'
                  name='td_arrival_freight_f'
                  disabled={disabled_f}
                  InputLabelProps={{ shrink: true }}
                  onChange={e => updateField(e, 'F')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportF.td_passenger_dom_f)}
                  name='td_passenger_dom_f'
                  label='คนไทย (คน)'
                  disabled={disabled_f || !isDom}
                  onChange={e => updateField(e, 'F')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportF.td_passenger_inter_f)}
                  name='td_passenger_inter_f'
                  label='ต่างชาติ (คน)'
                  disabled={disabled_f || isDom}
                  onChange={e => updateField(e, 'F')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportF.td_infants_f)}
                  name='td_infants_f'
                  label='เด็กต่ำกว่า 2 ขวบ (คน)'
                  disabled={disabled_f}
                  onChange={e => updateField(e, 'F')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportF.td_passenger_transfer_f)}
                  name='td_passenger_transfer_f'
                  label='ผู้โดยสารเปลี่ยนสายการบิน (คน)'
                  disabled={disabled_f}
                  onChange={e => updateField(e, 'F')}
                />
              </div>
              <div className='bRow flex-between'>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type='Number'
                  value={Helper.vInt(transportF.td_passenger_transit_f)}
                  name='td_passenger_transit_f'
                  label='ผู้โดยสารแวะผ่านสนามบิน (คน)'
                  disabled={disabled_f}
                  onChange={e => updateField(e, 'F')}
                />
              </div>
              {DataUser.ul_id === 1 && (
                <div className='bRow flex-between'>
                  <Button
                    onClick={() => {
                      updateData('f');
                    }}
                    className='styleBtn blue'
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    บันทึก
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {DataUser.ul_id !== 1 && (
        <Bottom2Function
          back={
            getParams('goto') === 'createinvoice'
              ? `/invoice/create${getParams('all')}`
              : getParams('goto') && getParams('goto').includes('_')
              ? `/${getParams('goto').substring(1)}`
              : '/flight-check'
          }
          insertData={() => {
            const type = DataUser.ul_id === 3 ? 'F' : 'A';
            updateData(type);
          }}
        />
      )}
      {DataUser.ul_id === 1 && (
        <Bottom1Function
          back={
            getParams('goto') === 'createinvoice'
              ? `/invoice/create${getParams('all')}`
              : getParams('goto') && getParams('goto').includes('_')
              ? `/${getParams('goto').substring(1)}`
              : '/flight-check'
          }
        />
      )}
    </div>
  );
}

export default FlightCheckDetails;
