/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import SD from "../../global/SwalDialog";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Bottom3Function from "../../global/Bottom3Function";
import Bottom1FunctionUpdateData from "../../global/Bottom1FunctionUpdateData";
import Notifications from "../../global/Notifications";
import Helper from "../../global/Helper";
function UserProfile(props) {
  const history = useHistory();
  const [form, setState] = useState({
    u_fname: "",
    u_lname: "",
    u_gender: "",
    u_birthday: "2020-01-01",
    u_email: "",
    u_tel: "",
    u_usename: "",
    u_password: "",
    u_address: "",
    conpass: "",
    ul_id: "",
    ap_id: "",
    al_id: "",
    u_active: "",
    u_cgd_pass: "",
    u_cgd_user: "",
    u_email_password: "",
  });
  const [error, setError] = useState({
    u_fname: false,
    u_lname: false,
    u_gender: false,
    u_birthday: false,
    u_email: false,
    u_tel: false,
    ul_id: false,
    u_usename: false,
    u_password: false,
    u_address: false,
    al_id: false,
    ap_id: false,
    u_active: false,
    u_cgd_pass: false,
    u_cgd_pass_con: false,
    u_cgd_user: false,
    u_email_password: false,
    u_email_con: false,
  });

  const [userlevel, setUserlevel] = useState([<option key="xu"></option>]);
  const [airline, setAirline] = useState([]);
  const [airport, setAirport] = useState([<option key="xap"></option>]);
  const [conpassCGD, setConpassCGD] = useState();

  const printValues = (e) => {
    e.preventDefault();
  };

  const updateField = (e) => {
    const fieldLists = [
      "ap_id",
      "al_id",
      "u_address",
      "u_password",
      "u_usename",
      "ul_id",
      "u_tel",
      "u_email",
      "u_birthday",
      "u_gender",
      "u_lname",
      "u_fname",
      "u_active",
      "u_cgd_pass",
      "u_cgd_user",
      "u_email_password",
      "u_email_password_con",
      "u_cgd_pass_con",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const updateFieldcompassCGD = (e) => {
    setConpassCGD(e.target.value);
    if (e.target.value !== form.u_cgd_pass) {
      setError({
        ...error,
        u_cgd_pass: true,
        u_cgd_pass_con: true,
      });
    } else {
      setError({
        ...error,
        u_cgd_pass: false,
        u_cgd_pass_con: false,
      });
    }
  };

  const updateFieldcompassEmail = (e) => {
    if (e.target.value !== form.u_email_password) {
      setError({
        ...error,
        u_email_password: true,
        u_email_password_con: true,
      });
    } else {
      setError({
        ...error,
        u_email_password: false,
        u_email_password_con: false,
      });
    }
  };

  const isReadonly = props.isReadonly;
  let id = useParams();
  if (!isReadonly) {
    id = id.id;
  } else {
    if (localStorage.getItem("DataUser")) {
      const temp = JSON.parse(localStorage.getItem("DataUser"));
      id = temp.u_id;
    } else {
      history.push("/login");
    }
  }

  const UpdateData = (e) => {
    props.handlerIsLoading(true);
    if (
      form.u_fname !== "" &&
      form.u_birthday !== "" &&
      form.u_email !== "" &&
      form.u_tel !== "" &&
      form.u_usename !== "" &&
      form.u_password === form.conpass &&
      form.u_gender !== "" &&
      form.ul_id !== "" &&
      form.al_id !== "" &&
      form.ap_id !== "" &&
      form.u_active !== ""
      // form.u_cgd_pass === conpassCGD &&
      // form.u_email_password === conpassEmail
    ) {
      Notifications.NotiAsync(
        axios.put(`${process.env.REACT_APP_API_URL}/v1/users/${id}`, { form }),
        (resp) => {
          props.handlerIsLoading(false);
          if (!isReadonly) {
            history.push("/user");
          } else {
            history.push("/");
          }
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        u_fname: !form.u_fname || form.u_fname === "",
        // u_lname: !form.u_lname || form.u_lname === "",
        u_gender: !form.u_gender || form.u_gender === "",
        u_birthday: !form.u_birthday || form.u_birthday === "",
        u_email: !form.u_email || form.u_email === "",
        u_tel: !form.u_tel || form.u_tel === "",
        ul_id: !form.ul_id || form.ul_id === "",
        ap_id: !form.ap_id || form.ap_id === "",
        u_usename: !form.u_usename || form.u_usename === "",
        u_password: !form.u_password || form.u_password === "",
        u_address: !form.u_address || form.u_address === "",
        al_id: !form.al_id || form.al_id === "",
        u_cgd_pass: !form.u_cgd_pass || form.u_cgd_pass === "",
        u_cgd_user: !form.u_cgd_user || form.u_cgd_user === "",
        u_email_password:
          !form.u_email_password || form.u_email_password === "",
      });
      props.handlerIsLoading(false);
      if (form.u_password !== form.conpass) {
        Notifications.NotiWarning({ msg: "รหัสผ่านทั้งสองช่องไม่เหมือนกัน!" });
      } else if (form.u_cgd_pass !== conpassCGD) {
        Notifications.NotiWarning({
          msg: "รหัสผ่านกรมบัญชีกลางทั้งสองช่องไม่เหมือนกัน!",
        });
      } else {
        Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
      }
    }
  };

  const DeleteData = (e) => {
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${form.u_fname} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.delete(`${process.env.REACT_APP_API_URL}/v1/users/${id}`, {}),
          (resp) => {
            Notifications.NotiSuccess();
            props.handlerIsLoading(false);
            history.push("/user");
          },
          (err) => {
            props.handlerIsLoading(false);
            Notifications.NotiError({ msg: "ไม่สามารถลบข้อมูลได้" });
          }
        );
      },
    });
  };
  useEffect(() => {
    props.handlerIsLoading(true);
    const fetchMyData = async () => {
      const apiUrlUserLevel = `${process.env.REACT_APP_API_URL}/v1/userlevels`;
      await axios.get(apiUrlUserLevel).then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={item.ul_id} value={item.ul_id}>
              {item.ul_name}
            </option>
          );
          setUserlevel((userlevel) => [...userlevel, option]);
        });
      });

      const apiUrlAirline = `${process.env.REACT_APP_API_URL}/v1/airlines`;
      await axios.get(apiUrlAirline).then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={item.al_id} value={item.al_id}>
              {item.al_name}
            </option>
          );
          setAirline((airline) => [...airline, option]);
        });
      });

      const apiUrlAirport = `${process.env.REACT_APP_API_URL}/v1/airports/user`;
      await axios.get(apiUrlAirport).then((res) => {
        res.data.forEach((item, index) => {
          const option = (
            <option key={item.ap_id} value={item.ap_id}>
              {item.ap_name}
            </option>
          );
          setAirport((airport) => [...airport, option]);
        });
      });

      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/users/${id}`;
      await axios.get(apiUrl).then((res) => {
        if (res.data.length > 0) {
          res.data[0].conpass = res.data[0].u_password;
          setState(res.data[0]);
        } else {
          localStorage.clear();
          history.push("/login");
          props.handlerIsLoggedIn(false);
        }
      });
      props.handlerIsLoading(false);
    };
    fetchMyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showBottomFunction = () => {
    if (!isReadonly) {
      return (
        <Bottom3Function
          back="/user"
          updateData={UpdateData}
          deleteData={DeleteData}
        />
      );
    } else {
      return <Bottom1FunctionUpdateData updateData={UpdateData} />;
    }
  };
  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName">ข้อมูลผู้ใช้งาน</div>
      </div>
      <form onSubmit={printValues}>
        <div className="box-S4 mgBottom">
          <div className="bContentMain" style={{ height: "100%" }}>
            <div className="bNameContent">บัญชีผู้ใช้</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div style={{ width: "32%" }}>
                  <TextField
                    error={error.u_usename}
                    fullWidth
                    label="ชื่อผู้ใช้งาน"
                    value={form.u_usename ? form.u_usename : ""}
                    name="u_usename"
                    onChange={updateField}
                    disabled={isReadonly}
                    type="text"
                  />
                </div>
                <div style={{ width: "32%" }}>
                  <TextField
                    error={error.u_password}
                    fullWidth
                    label="รหัสผ่าน"
                    name="u_password"
                    onChange={updateField}
                    type="password"
                  />
                </div>
                <div style={{ width: "32%" }}>
                  <TextField
                    error={error.conpass}
                    fullWidth
                    label="ยืนยันรหัสผ่าน"
                    name="conpass"
                    onChange={updateField}
                    type="password"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลส่วนตัว</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่อ - นามสกุล"
                    value={form.u_fname ? form.u_fname : ""}
                    name="u_fname"
                    onChange={updateField}
                    type="text"
                    error={error.u_fname}
                  />
                </div>
                <div className="box-S2">
                  <FormControl
                    component="fieldset"
                    className="groupRadio"
                    error={error.u_gender}
                  >
                    <FormLabel component="legend">เพศ</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      value={form.u_gender ? form.u_gender : "0"}
                      name="u_gender"
                      onChange={updateField}
                      row
                      style={{ marginTop: "0px" }}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="ชาย"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="หญิง"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    error={error.u_birthday}
                    fullWidth
                    label="วันเกิด"
                    type="date"
                    value={form.u_birthday.substring(0, 10)}
                    name="u_birthday"
                    onChange={updateField}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    error={error.u_tel}
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    value={form.u_tel ? form.u_tel : ""}
                    name="u_tel"
                    onChange={updateField}
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between">
          <div className="box-S4 mgBottom">
            <div className="bContentMain" style={{ height: "100%" }}>
              <div className="bNameContent">อีเมล</div>
              <div className="bContent">
                <div className="bRow flex-between">
                  <div style={{ width: "32%" }}>
                    <TextField
                      error={error.u_email}
                      fullWidth
                      label="อีเมล"
                      value={form.u_email ? form.u_email : ""}
                      name="u_email"
                      onChange={updateField}
                      type="text"
                    />
                  </div>
                  <div style={{ width: "32%", display: "none" }}>
                    <TextField
                      error={error.u_email_password}
                      fullWidth
                      label="รหัสผ่าน"
                      name="u_email_password"
                      onChange={updateField}
                      type="password"
                    />
                  </div>
                  <div style={{ width: "32%", display: "none" }}>
                    <TextField
                      error={error.u_email_password_con}
                      fullWidth
                      label="ยืนยันรหัสผ่าน"
                      name="conpassEmail"
                      onChange={updateFieldcompassEmail}
                      type="password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Helper.GetUserData().ul_id === 4 ||
          (isReadonly && (
            <div className="box-S4 mgBottom">
              <div className="bContentMain">
                <div className="bNameContent">กรมบัญชีกลาง</div>
                <div className="bContent">
                  <div className="bRow flex-between">
                    <div style={{ width: "32%" }}>
                      <TextField
                        error={error.u_cgd_user}
                        fullWidth
                        label="ชื่อผู้ใช้งาน"
                        name="u_cgd_user"
                        value={form.u_cgd_user ? form.u_cgd_user : ""}
                        onChange={updateField}
                      />
                    </div>
                    <div style={{ width: "32%" }}>
                      <TextField
                        error={error.u_cgd_pass}
                        fullWidth
                        label="รหัสผ่าน"
                        name="u_cgd_pass"
                        onChange={updateField}
                        type="password"
                      />
                    </div>
                    <div style={{ width: "32%" }}>
                      <TextField
                        error={error.u_cgd_pass_con}
                        fullWidth
                        label="ยืนยันรหัสผ่านน"
                        name="ConfirmPass"
                        onChange={updateFieldcompassCGD}
                        type="password"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className="box-S4 mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">สิทธิการใช้งาน</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl
                    fullWidth
                    disabled={isReadonly}
                    error={error.ul_id}
                  >
                    <InputLabel>ระดับผู้ใช้งาน</InputLabel>
                    <NativeSelect
                      native="true"
                      onChange={updateField}
                      value={form.ul_id}
                      name="ul_id"
                    >
                      {userlevel}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <FormControl
                    component="fieldset"
                    className="groupRadio"
                    error={error.u_active}
                  >
                    <FormLabel component="legend">การใช้งาน</FormLabel>
                    <RadioGroup
                      aria-label="u_active"
                      name="u_active"
                      onChange={updateField}
                      value={form.u_active}
                      disabled={isReadonly}
                      row
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio disabled={isReadonly} />}
                        label="อนุญาติ"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio disabled={isReadonly} />}
                        label="ระงับ"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl
                    fullWidth
                    disabled={isReadonly}
                    error={error.ap_id}
                  >
                    <InputLabel>สนามบิน</InputLabel>
                    <NativeSelect
                      native="true"
                      onChange={updateField}
                      value={form.ap_id}
                      name="ap_id"
                    >
                      {airport}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <FormControl
                    fullWidth
                    disabled={isReadonly}
                    error={error.al_id}
                  >
                    <InputLabel>สายการบิน</InputLabel>
                    <NativeSelect
                      native="true"
                      onChange={updateField}
                      value={form.al_id}
                      name="al_id"
                    >
                      {airline}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="box-S4 mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">บัญชีผู้ใช้</div>
            <div className="bContent">
              <div className="bRow">
                <TextField
                  fullWidth
                  
                  label="ที่อยู่"
                  value={form.u_address}
                  name="u_address"
                  onChange={updateField}
                />
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel>จังหวัด</InputLabel>
                    <NativeSelect native>
                      <option aria-label="None" />
                      <option>List 1</option>
                      <option>List 1</option>
                      <option>List 1</option>
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel>อำเภอ/เขต</InputLabel>
                    <NativeSelect native>
                      <option aria-label="None" value="" />
                      <option>List 1</option>
                      <option>List 1</option>
                      <option>List 1</option>
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel>ตำบล/แขวง</InputLabel>
                    <NativeSelect native>
                      <option aria-label="None" value="" />
                      <option>List 1</option>
                      <option>List 1</option>
                      <option>List 1</option>
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    
                    label="รหัสไปรษณีย์"
                    value={form.u_zipcode}
                    name="u_zipcode"
                    onChange={updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </form>
      {showBottomFunction()}
    </div>
  );
}

export default UserProfile;
