/** @format */

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import Bottom3Function from "../../global/Bottom3Function";
import Notifications from "../../global/Notifications";
import SD from "../../global/SwalDialog";
//import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";

function AirportDetails(props) {
  const history = useHistory();
  const { id } = useParams();
  /*const MapWithAMarker = withGoogleMap((props) => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
      <Marker position={{ lat: -34.397, lng: 150.644 }} />
    </GoogleMap>
  ));*/
  const user = JSON.parse(localStorage.getItem("DataUser"));
  const [error, setError] = useState({
    ap_name: false,
    ap_code_name: false,
    ap_type: false,
    ap_tel: false,
    ap_email: false,
    ap_address: false,
    ap_city: false,
    ap_state: false,
    ap_district: false,
    ap_zipcode: false,
    ap_lat: false,
    ap_lng: false,
    ap_department_name: false,
    ap_department_director_name: false,
    ap_department_tel: false,
  });
  const [form, setState] = useState({
    ap_name: "",
    ap_code_name: "",
    ap_type: "",
    ap_tel: "",
    ap_email: "",
    ap_address: "",
    ap_city: "",
    ap_state: "",
    ap_district: "",
    ap_zipcode: "",
    ap_lat: "",
    ap_lng: "",
    ap_department_name: "",
    ap_department_director_name: "",
    ap_department_tel: "",
    ap_department_fax: "",
    uid: user.u_id,
  });
  const [city, setAddrCity] = useState([<option value="" key={"c"}></option>]);
  const [state, setAddrState] = useState([
    <option value="" key={"s"}></option>,
  ]);
  const [district, setAddrDistrict] = useState([
    <option value="" key={"d"}></option>,
  ]);
  const updateField = (e) => {
    const fieldLists = [
      "ap_name",
      "ap_code_name",
      "ap_type",
      "ap_tel",
      "ap_email",
      "ap_address",
      "ap_city",
      "ap_state",
      "ap_district",
      "ap_zipcode",
      "ap_lat",
      "ap_lng",
      "ap_department_name",
      "ap_department_director_name",
      "ap_department_tel",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    switch (e.target.name) {
      case "ap_city":
        setAddrStateOption(e.target.value);
        break;
      case "ap_state":
        setAddrDistrictOption(e.target.value);
        break;
      case "ap_district":
        setZipcode(e.target.value, e.target.value);
        break;
      default:
        break;
    }
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const setAddrStateOption = (v) => {
    props.handlerIsLoading(true);
    setState({ ...form, ap_zipcode: "" });
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/amphoes/p/${v}`;
    axios.get(apiUrl).then((res) => {
      setAddrState([<option value="" key={"s"}></option>]);
      res.data.forEach((item, i) => {
        setAddrState((state) => [
          ...state,
          <option key={"a" + i} value={item.amphoe}>
            {item.amphoe}
          </option>,
        ]);
      });
      props.handlerIsLoading(false);
    });
  };

  const setAddrDistrictOption = (v) => {
    props.handlerIsLoading(true);
    setState({ ...form, ap_zipcode: "" });
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/districts/a/${v}`;
    axios.get(apiUrl).then((res) => {
      setAddrDistrict([<option value="" key={"d"}></option>]);
      res.data.forEach((item, i) => {
        setAddrDistrict((district) => [
          ...district,
          <option key={"d" + i} value={item.district}>
            {item.district}
          </option>,
        ]);
      });
      props.handlerIsLoading(false);
    });
  };

  const setZipcode = (v, district) => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/districts/d/${v}`;
    axios.get(apiUrl).then((res) => {
      setState({
        ...form,
        ap_district: district,
        ap_zipcode: res.data[0].zipcode,
      });
      setError({
        ap_zipcode: false,
      });
      props.handlerIsLoading(false);
    });
  };

  const UpdateData = (e) => {
    props.handlerIsLoading(true);
    if (
      form.ap_name &&
      form.ap_code_name &&
      //form.ap_type &&
      form.ap_tel &&
      //form.al_id !== "0"
      form.ap_email &&
      form.ap_address &&
      form.ap_city &&
      form.ap_state &&
      form.ap_district &&
      form.ap_zipcode &&
      // form.ap_lat &&
      // form.ap_lng &&
      form.ap_department_name &&
      form.ap_department_director_name &&
      form.ap_department_tel
    ) {
      Notifications.NotiAsync(
        axios.put(`${process.env.REACT_APP_API_URL}/v1/airports/${id}`, {
          form,
        }),
        (resp) => {
          history.push("/airport");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        ap_name: form.ap_name === "",
        ap_code_name: form.ap_code_name === "",
        ap_type: form.ap_type === "",
        ap_tel: form.ap_tel === "",
        ap_email: form.ap_email === "",
        ap_address: form.ap_address === "",
        ap_city: form.ap_city === "",
        ap_state: form.ap_state === "",
        ap_district: form.ap_district === "",
        ap_zipcode: form.ap_zipcode === "",
        // ap_lat: form.ap_lat === "",
        // ap_lng: form.ap_lng === "",
        ap_department_name: form.ap_department_name === "",
        ap_department_director_name: form.ap_department_director_name === "",
        ap_department_tel: form.ap_department_tel === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };

  const DeleteData = (e) => {
    SD.Confirm({
      header: "ยืนยันการลบข้อมูล",
      msg: `ต้องการลบ ${form.ap_name} ใช่หรือไม่?`,
      type: "warning",
      fnConfirm: () => {
        props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/airports/${id}/${user.u_id}`,
            {}
          ),
          (resp) => {
            history.push("/airport");
            props.handlerIsLoading(false);
            Notifications.NotiSuccess();
          },
          (err) => {
            props.handlerIsLoading(false);
            Notifications.NotiError({ msg: err.response.data.msg });
          }
        );
      },
    });
  };

  useEffect(() => {
    props.handlerIsLoading(true);
    const fetchMyData = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/provinces`;
      await axios.get(apiUrl).then((res) => {
        res.data.forEach((item, i) => {
          setAddrCity((city) => [
            ...city,
            <option key={"p" + i} value={item.province}>
              {item.province}
            </option>,
          ]);
        });
      });
      const apiUrlData = `${process.env.REACT_APP_API_URL}/v1/airports/${id}`;
      await axios.get(apiUrlData).then(async (res) => {
        await setAddrStateOption(res.data[0].ap_city);
        props.handlerIsLoading(true);
        await setAddrDistrictOption(res.data[0].ap_state);
        props.handlerIsLoading(true);
        await setState(res.data[0]);
      });
      props.handlerIsLoading(false);
    };
    fetchMyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>ข้อมูลสนามบิน</div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="box-S2">
          <div className="bContentMain" style={{ height: "100%" }}>
            <div className="bNameContent">รายละเอียดสนามบิน</div>
            <div className="bContent">
              <div className="bRow">
                <div className="box-S4">
                  <TextField
                    error={error.ap_name}
                    fullWidth
                    label="ชื่อสนามบิน"
                    name="ap_name"
                    value={form.ap_name ? form.ap_name : ""}
                    onChange={updateField}
                  />
                </div>
              </div>
              <div className="bRow">
                <div className="box-S4">
                  <TextField
                    error={error.ap_code_name}
                    fullWidth
                    label="รหัสย่อสนามบิน"
                    name="ap_code_name"
                    value={form.ap_code_name ? form.ap_code_name : ""}
                    onChange={updateField}
                  />
                </div>
              </div>
              <div className="bRow">
                <div className="box-S4">
                  <TextField
                    error={error.ap_tel}
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    name="ap_tel"
                    value={form.ap_tel ? form.ap_tel : ""}
                    onChange={updateField}
                  />
                </div>
              </div>
              <div className="bRow">
                <div className="box-S4">
                  <TextField
                    error={error.ap_email}
                    fullWidth
                    label="อีเมล"
                    name="ap_email"
                    value={form.ap_email ? form.ap_email : ""}
                    onChange={updateField}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label="Invoice Code"
                    name="ap_inv_code"
                    value={form.ap_inv_code ? form.ap_inv_code : ""}
                    onChange={updateField}
                  />
                </div>
                <div className="box-S1">
                  <TextField
                    fullWidth
                    label="Doc Code"
                    name="ap_doc_code"
                    value={form.ap_doc_code ? form.ap_doc_code : ""}
                    onChange={updateField}
                  />
                </div>
                <div className="box-S1">
                  <TextField
                    error={error.ap_email}
                    fullWidth
                    label="Short Name"
                    name="ap_name_short"
                    value={form.ap_name_short ? form.ap_name_short : ""}
                    onChange={updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S2">
          <div className="bContentMain">
            <div className="bNameContent">สถานที่ตั้ง</div>
            <div className="bContent">
              <div className="bRow">
                <div className="box-S4">
                  <TextField
                    error={error.ap_address}
                    fullWidth
                    label="ที่อยู่"
                    name="ap_address"
                    value={form.ap_address ? form.ap_address : ""}
                    onChange={updateField}
                  />
                </div>
              </div>
              <div className="bRow">
                <div className="box-S4">
                  <FormControl fullWidth error={error.ap_city}>
                    <InputLabel>จังหวัด</InputLabel>
                    <NativeSelect
                      name="ap_city"
                      value={form.ap_city ? form.ap_city : ""}
                      onChange={updateField}
                      native="true"
                    >
                      {city}
                    </NativeSelect>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="bRow">
                <div className="box-S4">
                  <FormControl fullWidth error={error.ap_state}>
                    <InputLabel>อำเภอ/เขต</InputLabel>
                    <NativeSelect
                      name="ap_state"
                      value={form.ap_state ? form.ap_state : ""}
                      onChange={updateField}
                      native="true"
                    >
                      {state}
                    </NativeSelect>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="bRow">
                <div className="box-S4">
                  <FormControl fullWidth error={error.ap_district}>
                    <InputLabel>ตำบล/แขวง</InputLabel>
                    <NativeSelect
                      name="ap_district"
                      value={form.ap_district ? form.ap_district : ""}
                      onChange={updateField}
                      native="true"
                    >
                      {district}
                    </NativeSelect>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="bRow">
                <div className="box-S4">
                  <TextField
                    error={error.ap_zipcode}
                    fullWidth
                    label="รหัสไปรษณีย์"
                    name="ap_zipcode"
                    value={form.ap_zipcode ? form.ap_zipcode : ""}
                    onChange={updateField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-S4 mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">หน่วยงาน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.ap_department_name}
                  fullWidth
                  label="ชื่อหน่วยงาน"
                  name="ap_department_name"
                  value={form.ap_department_name ? form.ap_department_name : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.ap_department_fax}
                  fullWidth
                  label="โทรสาร"
                  name="ap_department_fax"
                  value={form.ap_department_fax ? form.ap_department_fax : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.ap_department_director_name}
                  fullWidth
                  label="ผู้มีอำนาจ"
                  name="ap_department_director_name"
                  value={
                    form.ap_department_director_name
                      ? form.ap_department_director_name
                      : ""
                  }
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.ap_department_tel}
                  fullWidth
                  label="เบอร์โทรศัพท์"
                  name="ap_department_tel"
                  value={form.ap_department_tel ? form.ap_department_tel : ""}
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-S4 mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">สถานที่ตั้ง</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.ap_lat}
                  fullWidth
                  label="ละติจูด"
                  name="ap_lat"
                  value={form.ap_lat ? form.ap_lat : ""}
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.ap_lng}
                  fullWidth
                  label="ลองติจูด"
                  name="ap_lng"
                  value={form.ap_lng ? form.ap_lng : ""}
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow">
              {/*<div className="box-S4">
                <MapWithAMarker
                  containerElement={
                    <div
                      style={{
                        height: `400px`,
                        width: `100%`,
                        marginTop: "10px",
                      }}
                    />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
                </div>*/}
            </div>
          </div>
        </div>
      </div>
      <Bottom3Function
        back="/airport"
        updateData={UpdateData}
        deleteData={DeleteData}
      />
    </div>
  );
}

export default AirportDetails;
