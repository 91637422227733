import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import * as XLSX from "xlsx-js-style";

export class TableTargetBudgetCash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear() + 543,
      month: [
        "Oct",
        "Nov",
        "Dec",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
      data: [],
      content: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.props.handlerIsLoading(true);
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/report/target-cash?year=${this.state.year}`
    );
    let data = [];
    if (Array.isArray(result.data.data)) {
      data = result.data.data;
    }
    this.setState({ data: data }, () => {
      this.props.handlerIsLoading(false);
    });
    this.props.handleSetOptions(this.state.year, data);
  };

  handleFiltering = (v) => {
    this.setState({ year: v }, () => {
      this.fetchData();
    });
  };

  handleExport = () => {
    const { data, month, year } = this.state;
    const wb = XLSX.utils.book_new();
    const title = `แผน-ผลรายได้ปีงบประมาณ พ.ศ. ${year} (เงินสดที่จัดเก็บได้จริง)`;
    const border = {
      top: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } },
    };
    let row = [
      [
        {
          v: title,
          t: "s",
          s: {
            font: { name: "TH SarabunPSK", sz: 20, bold: true },
            alignment: { horizontal: "center" },
            border: border,
          },
        },
      ],
    ];

    row.push([
      { v: "", t: "s", s: { border: border } },
      { v: "", t: "s", s: { border: border } },
      { v: "", t: "s", s: { border: border } },
    ]);
    for (let i = 0; i < month.length; i++) {
      const y = i < 3 ? year - 1 : year;
      row[1].push({
        v: month[i] + "-" + y.toString().substr(2, 2),
        t: "s",
        s: {
          font: { name: "TH SarabunPSK", sz: 16, bold: true },
          alignment: { horizontal: "center" },
          border: border,
        },
      });
    }

    const merges = [];
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      let temp = [];
      const bold = Number.isInteger(d.order);
      let fill = "FFFFFF";
      if (bold) fill = "D9E1F2";
      if (d.order === 0) fill = "E2EFDA";
      const bg = { fgColor: { rgb: fill } };
      temp.push({
        v: d.order !== 0 ? d.order : "",
        t: "s",
        s: {
          fill: bg,
          font: { name: "TH SarabunPSK", sz: 16, bold: bold },
          alignment: { horizontal: "center" },
          border: border,
        },
      });
      temp.push({
        v: d.title,
        t: "s",
        s: {
          fill: bg,
          font: { name: "TH SarabunPSK", sz: 16, bold: bold },
          alignment: { horizontal: "left", wrapText: true },
          border: border,
        },
      });
      temp.push({
        v: "แผน",
        t: "s",
        s: {
          fill: bg,
          font: { name: "TH SarabunPSK", sz: 16, bold: true },
          alignment: { horizontal: "left" },
          border: border,
        },
      });
      for (let j = 0; j < d.values.plan.length; j++) {
        temp.push({
          v: d.values.plan[j],
          t: "s",
          s: {
            fill: bg,
            font: { name: "TH SarabunPSK", sz: 16, bold: true },
            alignment: { horizontal: "right" },
            border: border,
          },
        });
      }
      row.push(temp);
      temp = [];

      if (d.values.hasOwnProperty("result")) {
        temp.push(
          { v: "", t: "s", s: { fill: bg, border: border } },
          { v: "", t: "s", s: { fill: bg, border: border } }
        );
        temp.push({
          v: "ผล",
          t: "s",
          s: {
            fill: bg,
            font: { name: "TH SarabunPSK", sz: 16, bold: true },
            alignment: { horizontal: "left" },
            border: border,
          },
        });
        for (let j = 0; j < d.values.result.length; j++) {
          temp.push({
            v: d.values.result[j],
            t: "s",
            s: {
              fill: bg,
              font: { name: "TH SarabunPSK", sz: 16, bold: bold },
              alignment: { horizontal: "right" },
              border: border,
            },
          });
        }
        row.push(temp);
        temp = [];
      }

      if (d.values.hasOwnProperty("plan_collect") && d.order === 0) {
        temp.push(
          { v: "", t: "s", s: { fill: bg, border: border } },
          { v: "", t: "s", s: { fill: bg, border: border } }
        );
        temp.push({
          v: "แผนสะสม",
          t: "s",
          s: {
            fill: bg,
            font: { name: "TH SarabunPSK", sz: 16, bold: true },
            alignment: { horizontal: "left" },
            border: border,
          },
        });
        for (let j = 0; j < d.values.plan_collect.length; j++) {
          temp.push({
            v: d.values.plan_collect[j],
            t: "s",
            s: {
              fill: bg,
              font: { name: "TH SarabunPSK", sz: 16, bold: bold },
              alignment: { horizontal: "right" },
              border: border,
            },
          });
        }
        row.push(temp);
        temp = [];
      }

      if (d.values.hasOwnProperty("result_collect")) {
        temp.push(
          { v: "", t: "s", s: { fill: bg, border: border } },
          { v: "", t: "s", s: { fill: bg, border: border } }
        );
        temp.push({
          v: "ผลสะสม",
          t: "s",
          s: {
            fill: bg,
            font: { name: "TH SarabunPSK", sz: 16, bold: true },
            alignment: { horizontal: "left" },
            border: border,
          },
        });
        for (let j = 0; j < d.values.result_collect.length; j++) {
          temp.push({
            v: d.values.result_collect[j],
            t: "s",
            s: {
              fill: bg,
              font: { name: "TH SarabunPSK", sz: 16, bold: bold },
              alignment: { horizontal: "right" },
              border: border,
            },
          });
        }
        row.push(temp);
        temp = [];
      }

      if (d.values.hasOwnProperty("sum")) {
        temp.push(
          { v: "", t: "s", s: { fill: bg, border: border } },
          { v: "", t: "s", s: { fill: bg, border: border } }
        );
        temp.push({
          v: "ผล",
          t: "s",
          s: {
            fill: bg,
            font: { name: "TH SarabunPSK", sz: 16, bold: true },
            alignment: { horizontal: "left" },
            border: border,
          },
        });
        for (let j = 0; j < d.values.sum.length; j++) {
          temp.push({
            v: d.values.sum[j],
            t: "s",
            s: {
              fill: bg,
              font: { name: "TH SarabunPSK", sz: 16, bold: bold },
              alignment: { horizontal: "right" },
              border: border,
            },
          });
        }
        row.push(temp);
        temp = [];
      }
    }

    const ws = XLSX.utils.aoa_to_sheet(row);
    ws["!merges"] = [
      {
        s: { r: 0, c: 0 },
        e: { r: 0, c: 14 },
      },
      {
        s: { r: 1, c: 0 },
        e: { r: 1, c: 2 },
      },
      ...merges,
    ];
    ws["!cols"] = [
      { wch: 5 },
      { wch: 35 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, title + ".xlsx");
  };

  initCell = (alpb) => {
    const cells = [];
    for (let i = 0; i < 13; i++) {
      cells.push(<TableCell key={alpb + i}></TableCell>);
    }
    return cells;
  };

  render() {
    const { data, month, year } = this.state;
    const content = [];
    console.log("data", data);
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const values = data[i].values;
        const rows = [];
        let mergeRow = 0;
        const planCell = this.initCell("a");
        if (values.plan) {
          planCell[0] = (
            <TableCell align="right" key={"fix9"}>
              แผน
            </TableCell>
          );
          for (let j = 0; j < values.plan.length; j++) {
            planCell[j + 1] = (
              <TableCell align="right" key={i + "a" + j}>
                {values.plan[j]}
              </TableCell>
            );
          }
          mergeRow++;
        }

        const resultCell = this.initCell("b");
        if (values.result) {
          resultCell[2] = (
            <TableCell align="right" key={"fix8"}>
              ผล
            </TableCell>
          );
          for (let j = 0; j < values.result.length; j++) {
            resultCell[j + 3] = (
              <TableCell align="right" key={i + "b" + j}>
                {values.result[j]}
              </TableCell>
            );
          }
          rows.push(resultCell);
          mergeRow++;
        }

        const planCollectCell = this.initCell("c");
        if (values.plan_collect) {
          planCollectCell[2] = (
            <TableCell align="right" key={"fix7"}>
              แผนสะสม
            </TableCell>
          );
          for (let j = 0; j < values.plan_collect.length; j++) {
            planCollectCell[j + 3] = (
              <TableCell align="right" key={i + "c" + j}>
                {values.plan_collect[j]}
              </TableCell>
            );
          }
          rows.push(planCollectCell);
          mergeRow++;
        }

        const resultCollectCell = this.initCell("d");
        if (values.result_collect) {
          resultCollectCell[2] = (
            <TableCell align="right" key={"fix6"}>
              ผลสะสม
            </TableCell>
          );
          for (let j = 0; j < values.result_collect.length; j++) {
            resultCollectCell[j + 3] = (
              <TableCell align="right" key={i + "d" + j}>
                {values.result_collect[j]}
              </TableCell>
            );
          }
          rows.push(resultCollectCell);
          mergeRow++;
        }

        const sumCell = this.initCell("e");
        if (values.sum) {
          sumCell[2] = (
            <TableCell align="right" key={"fix5"}>
              ผล
            </TableCell>
          );
          for (let j = 0; j < values.sum.length; j++) {
            sumCell[j + 3] = (
              <TableCell align="right" key={i + "e" + j}>
                {values.sum[j]}
              </TableCell>
            );
          }
          rows.push(sumCell);
          mergeRow++;
        }

        const row = (
          <TableRow key={i + "i"} rowSpan={mergeRow}>
            <TableCell key={"fix1"}>
              {data[i].order !== 0 ? data[i].order : ""}
            </TableCell>
            <TableCell key={"fix2"}>{data[i].title}</TableCell>
            {planCell}
          </TableRow>
        );
        content.push(row);
        for (let j = 0; j < rows.length; j++) {
          content.push(<TableRow key={i + "j" + j}>{rows[j]}</TableRow>);
        }
        mergeRow = 0;
      }
    }
    return (
      <Paper className="tabelData">
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={15} align="center" key={"title1"}>
                  แผน-ผลรายได้ปีงบประมาณ พ.ศ. {year} (เงินสดที่จัดเก็บได้จริง)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} key={"empty1"}></TableCell>
                {month.map((item, index) => (
                  <TableCell align="center" key={index + "k"}>
                    {item}-{index <= 2 ? year - 1 : year}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{content}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

export default TableTargetBudgetCash;
