/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import Helper from "./Helper";
import axios from "axios";

function FillterData4(props) {
  const [form, setState] = useState({
    filter_date_schedule: Helper.CastDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    filter_flight_no: "",
    filter_icao: "",
  });
  const [airport, setAirport] = useState(
    [1, 2].includes(Helper.GetUserData().ul_id)
      ? [<option key="ap" value=""></option>]
      : []
  );
  const getAirport = () => {
    const pagename = "fillter_data_4_" + props.fillteringPage;
    let sesstion = sessionStorage.getItem(pagename);
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      res.data.forEach((i) => {
        if (
          (Helper.GetUserData().ap_id &&
            Helper.GetUserData().ap_id.toString()) === i.ap_id.toString() ||
          [1, 2].includes(Helper.GetUserData().ul_id)
        ) {
          setAirport((item) => [
            ...item,
            <option key={i.ap_id} value={i.ap_code_name}>
              {i.ap_code_name} ({i.ap_name})
            </option>,
          ]);
          if (sesstion) {
            sesstion = JSON.parse(sesstion);
            setState({ ...form, ...sesstion, filter_icao: i.ap_code_name });
            submitFilltering(sesstion);
          } else {
            setState({ ...form, filter_icao: i.ap_code_name });
          }
        }
      });
    });
  };
  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getAirport();
  }, []);

  const submitFilltering = (data) => {
    sessionStorage.setItem(
      "fillter_data_4_" + props.fillteringPage,
      JSON.stringify(data)
    );
    props.handleFiltering(data);
    console.log("submitFiltering");
  };
  return (
    <div className="box-S4 flex-between mgBottom">
      <div className="bContentMain">
        <div className="bNameContent">กรอง</div>
        <div className="bContent">
          <div className="bRow flex-between">
            <div className="box-S1">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="วันที่ทำการบิน"
                type="date"
                name="filter_date_schedule"
                onChange={updateField}
                value={form.filter_date_schedule}
              />
            </div>
            <div className="box-S3">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="Flight No."
                    type="text"
                    name="filter_flight_no"
                    onChange={updateField}
                    value={form.filter_flight_no}
                  />
                </div>
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>รหัสท่าอากาศยาน</InputLabel>
                    <NativeSelect
                      native="true"
                      name="filter_icao"
                      onChange={updateField}
                      value={form.filter_icao}
                    >
                      <option aria-label="None" value="" />
                      {airport}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className="bRow flex-between" style={{ marginTop: "10px" }}>
            <div></div>
            <Button
              className="styleBtn blue"
              onClick={() => {
                submitFilltering(form);
              }}
            >
              ค้นหา
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillterData4;
