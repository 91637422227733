/** @format */

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router";

// Components Page
import TableRevenueCategory from "../../../datatable/revenue/TableRevenueCategory";

export class RevenueCategory extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  redirectHandler = () => {
    this.props.history.push("/revenue-category/add");
  };
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>รายการประเภทรายได้หลัก</div>
            <Button
              className="styleBtn blue"
              onClick={(e) => this.redirectHandler()}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการ</div>
            <div className="bContent">
              <TableRevenueCategory
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
        {/*<BottomFunction2 />*/}
      </div>
    );
  }
}

export default withRouter(RevenueCategory);
