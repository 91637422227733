/** @format */

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router";

// Components Page
import TableInvoiceSpecial from "../datatable/TableInvoiceSpecial";
import FillterData3 from "../global/FillterData3";

export class InvoiceSpecial extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.handleFiltering = this.handleFiltering.bind(this);
    this.tableRef = React.createRef();
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  handleFiltering = (v) => {
    this.tableRef.current.handleFiltering(v);
  };
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>ใบแจ้งหนี้เที่ยวบินพิเศษ</div>
            <div>
              <Button className="styleBtn green ml-10px w-auto">
                ตรวจสอบสถานะ
              </Button>
              <Button
                className="styleBtn create ml-10px"
                onClick={() => {
                  this.props.history.push("/invoice-special/create");
                }}
              >
                ออกใบแจ้งหนี้
              </Button>
            </div>
          </div>
        </div>
        <FillterData3
          handleFiltering={this.handleFiltering.bind(this)}
          fillteringPage="InvoiceSpecial"
        />
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการใบแจ้งหนี้</div>
            <div className="bContent">
              <TableInvoiceSpecial
                ref={this.tableRef}
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InvoiceSpecial);
