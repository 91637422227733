/** @format */

import React, { Component } from "react";
import TableFlightCheckInvoiceWithQueryData from "../datatable/flight/TableFlightCheckInvoiceWithQueryData";
import Bottom1Function from "../global/Bottom1Function";
import { withRouter } from "react-router";
import { TextField, Button } from "@material-ui/core";
import axios from "axios";
import "../../css/invoice.css";
import Notifications from "../global/Notifications";
import Helper from "../global/Helper";
import SD from "../global/SwalDialog";

export class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 3,
      isDisable: this.props.match.params.type !== "create",
      modalIsOpennDiscount: false,
      modalIsOpennDiscountBaht: false,
      airline: [<option key="a" value=""></option>],
      income_type: [],
      year: [],
      month: [],
      form: {
        select_airline_id: "",
        select_airline: "",
        select_incometype: "",
        select_year: "",
        select_month: "",
        val_taxno: "",
        val_invoicedate: "",
        val_payablename: "",
        val_invoiceexpire: "",
        datail: [],
        revenue_filter: [],
        i_discount_dom: 0,
        i_discount_inter: 0,
      },
      revenue: [],
      addr: {},
      paymentAddr: [],
      paymentOption: [],
      summary: {
        getTotalPrice: 0,
        getDiscount: 0,
        getTotalPriceChange: 0,
        getTotal: 0,
        data: [],
      },
      upload_name: "",
    };
    this.upLoadfile = null;
  }
  getAirline = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airlines`).then((res) => {
      res.data.forEach((i) => {
        this.setState({
          airline: [
            ...this.state.airline,
            <option key={i.al_id} value={i.al_code_name}>
              {i.al_name}
            </option>,
          ],
        });
      });
    });
  };
  getIncomeType = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/masteritems/rc/7`)
      .then((res) => {
        let temp = [<option key="i" value=""></option>];
        res.data.forEach((i) => {
          const option = (
            <option key={`i${i.mi_id}`} value={i.mi_name}>
              {i.mi_description}
            </option>
          );
          temp.push(option);
        });
        this.setState({ income_type: temp });
      });
  };
  getPaymentName = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/airlinespayment`)
      .then((res) => {
        let temp = [];
        res.data.forEach((i) => {
          const option = (
            <option key={`p${i.apa_id}`} value={i.apa_id}>
              {i.apa_name}
            </option>
          );
          temp.push(option);
        });
        this.setState({ paymentOption: temp, paymentAddr: res.data });
      });
  };
  getInvoice = () => {
    this.props.handlerIsLoading(true);
    const id = this.props.match.params.id;
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/invoice/detail/${id}`)
      .then(async (res) => {
        if (res.data) {
          const data = res.data.data;
          const detail = res.data.detail;
          let tempDiscount = 0;
          let tempChange = 0;
          let tempSumDom = 0;
          let tempSumInt = 0;
          let i_discount_dom = 0;
          let i_discount_inter = 0;
          const fRevenue = this.state.revenue.find(
            (i) => `${i.rc_id}` === `${data.i_revenue_id}`
          );
          const filterRevenue = this.state.revenue.filter(
            (i) => i.rc_code === fRevenue.rc_code
          );
          await detail.forEach((i) => {
            tempDiscount = tempDiscount + i.id_dis_bath;
            tempChange = tempChange + i.id_adjust;
            if (i.tt_dom === "0") {
              tempSumDom = tempSumDom + i.id_price;
              i_discount_dom = i_discount_dom + i.id_dis_bath + i.id_adjust;
            }
            if (i.tt_dom === "1") {
              tempSumInt = tempSumInt + i.id_price;
              i_discount_inter = i_discount_inter + i.id_dis_bath + i.id_adjust;
            }
          });

          this.setState(
            {
              form: {
                ...data,
                i_total: Helper.CastCurrency(parseFloat(data.i_total)),
                i_doc_date: Helper.CastDate(new Date(data.i_doc_date)),
                i_due_date: Helper.CastDate(new Date(data.i_due_date)),
                i_discount: Helper.CastCurrency(tempDiscount + tempChange),
                i_all: Helper.CastCurrency(data.i_total - tempDiscount),
                i_discount_dom: i_discount_dom,
                i_discount_inter: i_discount_inter,
                data: detail,
                revenue_filter: filterRevenue.map((i) => {
                  if (i.rc_source_type === "D") {
                    i.sum = tempSumDom;
                  }
                  if (i.rc_source_type === "I") {
                    i.sum = tempSumInt;
                  }
                  return i;
                }),
              },
            },
            () => {
              this.props.handlerIsLoading(false);
            }
          );
        }
      });
  };

  sendFileUpload = (e) => {
    const id = this.props.match.params.id;
    const user = JSON.parse(localStorage.getItem("DataUser"));
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    var file = document.querySelector("#filUpload");
    formData.append("file", file.files[0]);
    formData.append("id", id);
    formData.append("name", this.state.upload_name);
    formData.append("uid", user.u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/invoice/uploadfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        document.querySelector("#filUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        document.querySelector("#filUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };
  getIncomeType = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/revenuecategorys/type/1`)
      .then((res) => {
        this.setState({ revenue: res.data }, () => {
          const id = this.props.match.params.id;
          if (id) this.getInvoice();
        });
      });
  };
  componentDidMount() {
    this.props.handlerIsLoading(true);
    this.getAirline();
    this.getIncomeType();
    this.getPaymentName();
    this.getIncomeType();
  }
  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>สร้างใบแจ้งหนี้</div>
            <div>
              <input
                id="filUpload"
                type="file"
                className="hidden"
                accept="application/pdf"
                ref={(input) => {
                  this.upLoadfile = input;
                }}
                onChange={(e) => {
                  e.preventDefault();
                  this.sendFileUpload(e);
                }}
              />
              <Button
                className={`styleBtn ${
                  this.state.form.i_active === "1" ? "" : "upload"
                }`}
                disabled={this.state.form.i_active === "1"}
                onClick={(e) => {
                  e.preventDefault();
                  SD.Input({
                    msg: "เลขที่เอกสาร",
                    value: this.state.upload_name,
                    btn: "Upload",
                    fnConfirm: (value) => {
                      if (value.status) {
                        if (value.value) {
                          this.upLoadfile.click();
                        } else {
                          Notifications.NotiWarning({
                            msg: "กรุณากรอกเลขที่เอกสาร",
                          });
                        }
                      }
                      this.setState({
                        upload_name: value.value,
                      });
                    },
                  });
                }}
              >
                อัพโหลด
              </Button>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลใบแจ้งหนี้</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เลขใบแจ้งหนี้"
                    disabled={this.state.step !== 1}
                    name="i_doc_no"
                    value={
                      this.state.form.i_doc_no ? this.state.form.i_doc_no : ""
                    }
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันออกใบแจ้งหนี้"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.step !== 1}
                    name="i_doc_date"
                    value={
                      this.state.form.i_doc_date
                        ? this.state.form.i_doc_date
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="สำนักงานสายการบิน"
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.step !== 1}
                    name="al_name"
                    value={
                      this.state.form.al_name ? this.state.form.al_name : ""
                    }
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="วันครบกำหนดชำระ"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    disabled={this.state.step !== 1}
                    name="i_due_date"
                    value={
                      this.state.form.i_due_date
                        ? this.state.form.i_due_date
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลที่อยู่</div>
            <div className="bContent">
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่อผู้ชำระเงิน"
                    name="apa_name"
                    disabled={true}
                    value={
                      this.state.form.apa_name ? this.state.form.apa_name : ""
                    }
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="Email"
                    name="apa_email"
                    value={
                      this.state.form.apa_email ? this.state.form.apa_email : ""
                    }
                    disabled={true}
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ที่อยู่"
                    name="apa_house_no"
                    value={
                      this.state.form.apa_house_no
                        ? this.state.form.apa_house_no
                        : ""
                    }
                    disabled={true}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ถนน"
                    name="apa_road"
                    value={
                      this.state.form.apa_road ? this.state.form.apa_road : ""
                    }
                    disabled={true}
                  />
                </div>
              </div>

              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ชื่ออาคาร"
                    name="apa_building_name"
                    value={
                      this.state.form.apa_building_name
                        ? this.state.form.apa_building_name
                        : ""
                    }
                    disabled={true}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="หมู่"
                    name="apa_moo"
                    value={
                      this.state.form.apa_moo ? this.state.form.apa_moo : ""
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ตำบล/แขวง"
                    name="apa_tambon_code"
                    value={
                      this.state.form.apa_tambon_code
                        ? this.state.form.apa_tambon_code
                        : ""
                    }
                    disabled={true}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="อำเภอ/เขต"
                    name="apa_amphur_code"
                    value={
                      this.state.form.apa_amphur_code
                        ? this.state.form.apa_amphur_code
                        : ""
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="จังหวัด"
                    name="apa_province_code"
                    value={
                      this.state.form.apa_province_code
                        ? this.state.form.apa_province_code
                        : ""
                    }
                    disabled={true}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="รหัสไปรษณีย์"
                    name="apa_postcode"
                    value={
                      this.state.form.apa_postcode
                        ? this.state.form.apa_postcode
                        : ""
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="bRow flex-between">
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="ประเทศ"
                    name="apa_country"
                    value={
                      this.state.form.apa_country
                        ? this.state.form.apa_country
                        : ""
                    }
                    disabled={true}
                  />
                </div>
                <div className="box-S2">
                  <TextField
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    name="apa_tel"
                    value={
                      this.state.form.apa_tel ? this.state.form.apa_tel : ""
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.step === 3 && (
          <div className="box-S4 flex-between mgBottom">
            <div className="bContentMain">
              <div className="bNameContent flex-between">
                <div>รายการชำระ</div>
              </div>
              <div className="bContent">
                <div className="boxListInvoice">
                  <div className="bRow flex-between">
                    <div className="textlist">
                      {this.state.form.revenue_filter.map((i) => {
                        return (
                          <div className="bRow flex-between">
                            <div className="textlist">{i.rc_description}</div>
                            <div className="textlist-num">
                              {Helper.CastCurrency(i.sum)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="boxTotalInvoice">
                  <div className="bRow flex-between">
                    <div className="textlist">รวมเป็นเงิน</div>
                    <div className="textlist-num">
                      {Helper.CastCurrency(this.state.form.i_sum)}
                    </div>
                  </div>
                  {this.state.form.revenue_filter.length === 1 && (
                    <div className="bRow flex-between">
                      <div className="textlist">ส่วนลด + ปรับยอด</div>
                      <div className="textlist-num">
                        {Helper.CastCurrency(this.state.form.i_discount)}
                      </div>
                    </div>
                  )}
                  {this.state.form.revenue_filter.length === 2 && (
                    <div className="bRow flex-between">
                      <div className="textlist">
                        ส่วนลด + ปรับยอด (ในประเทศ)
                      </div>
                      <div className="textlist-num">
                        {Helper.CastCurrency(this.state.form.i_discount_dom)}
                      </div>
                    </div>
                  )}
                  {this.state.form.revenue_filter.length === 2 && (
                    <div className="bRow flex-between">
                      <div className="textlist">
                        ส่วนลด + ปรับยอด (ต่างประเทศ)
                      </div>
                      <div className="textlist-num">
                        {Helper.CastCurrency(this.state.form.i_discount_inter)}
                      </div>
                    </div>
                  )}
                  <div className="bRow flex-between">
                    <div className="textlist">รวมเป็นเงิน</div>
                    <div className="textlist-num">
                      {Helper.CastCurrency(
                        parseFloat(this.state.form.i_sum) -
                          parseFloat(this.state.form.i_discount)
                      )}
                    </div>
                  </div>
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 1 && (
                      <div className="bRow flex-between">
                        <div className="textlist">ค่าปรับ</div>
                        <div className="textlist-num">
                          {Helper.CastCurrency(this.state.form.i_mulct)}
                        </div>
                      </div>
                    )}
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 1 && (
                      <div className="bRow flex-between">
                        <div className="textlist">ปรับยอดค่าปรับ</div>
                        <div className="textlist-num">
                          {Helper.CastCurrency(this.state.form.i_adjust)}
                        </div>
                      </div>
                    )}
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 2 && (
                      <div className="bRow flex-between">
                        <div className="textlist">ค่าปรับ (ในประเทศ)</div>
                        <div className="textlist-num">
                          {Helper.CastCurrency(this.state.form.i_mulct_dom)}
                        </div>
                      </div>
                    )}
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 2 && (
                      <div className="bRow flex-between">
                        <div className="textlist">
                          ปรับยอดค่าปรับ (ในประเทศ)
                        </div>
                        <div className="textlist-num">
                          {Helper.CastCurrency(this.state.form.i_adjust_dom)}
                        </div>
                      </div>
                    )}
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 2 && (
                      <div className="bRow flex-between">
                        <div className="textlist">ค่าปรับ (ต่างประเทศ)</div>
                        <div className="textlist-num">
                          {Helper.CastCurrency(this.state.form.i_mulct_inter)}
                        </div>
                      </div>
                    )}
                  {this.state.form.i_inv_type === "1" &&
                    this.state.form.revenue_filter.length === 2 && (
                      <div className="bRow flex-between">
                        <div className="textlist">
                          ปรับยอดค่าปรับ (ต่างประเทศ)
                        </div>
                        <div className="textlist-num">
                          {Helper.CastCurrency(this.state.form.i_adjust_inter)}
                        </div>
                      </div>
                    )}
                </div>
                <div className="boxGrandTotalInvoice">
                  <div className="bRow flex-between">
                    <div className="textlist">จำนวนเงินทั้งสิ้น</div>
                    <div className="textlist-num">
                      {this.state.form.i_total}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">รายการเที่ยวการบิน</div>
            <div className="bContent">
              {this.state.step !== 1 && (
                <TableFlightCheckInvoiceWithQueryData
                  isReadonly={this.state.step === 3}
                  data={this.state.form.data}
                />
              )}
            </div>
          </div>
        </div>
        <Bottom1Function
          back={this.props.isSpecial ? "/invoice-special" : "/invoice"}
        />
      </div>
    );
  }
}

export default withRouter(InvoiceDetails);
