/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Bottom1Function from "../global/Bottom1Function";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
} from "@material-ui/core";
import Notifications from "../global/Notifications";
import fileDownload from "js-file-download";
import Helper from "../global/Helper";

function FlightScheduleDetails(props) {
  const { id } = useParams();
  const DataUser = JSON.parse(localStorage.getItem("DataUser"));
  const [formFlight, setState] = useState({
    //  Section 1
    t_airline_id: "",
    t_airline: "",
    al_owner: "",
    al_agent: "",
    t_arr_dep: "A",
    t_air_type_id: "",
    t_air_type: "",
    t_callsign: "",
    t_callsign_id: "",
    ac_weight: "",
    t_flight_no: "",
    t_airport_id: "0",
    t_airport: "",
    td_used: "0",
    //  Section 2
    t_year: "",
    t_month: "",
    t_day: "",
    t_trn_date: "01/01/2020",
    t_trn_yy: "",
    t_trn_mm: "",
    t_trn_dd: "",
    t_trn_time: "",
    t_trn_day: "",
    t_departure_airport_code: "",
    t_departure_airport_name: "",
    t_traff_type: "",
    t_traff_type_id: "",
    t_port_type: "",
    t_arrival_airport_code: "",
    t_arrival_airport_name: "",
    t_card_no: "",
    t_zone_no: "",
    t_region_no: "",
    t_captian: "",
    t_crew: "",
    t_remark: "",
    // Section 3
    t_passenger_dom: "",
    t_passenger_inter: "",
    t_passenger_infrant: "",
    t_passenger_transfer: "",
    t_passenger_transit: "",
  });
  const [formFlightDetail, setState2] = useState({
    td_arrival_freight_a: 0,
    td_infants_a: 0,
    td_passenger_a: 0,
    td_passenger_dom_a: 0,
    td_passenger_inter_a: 0,
    td_passenger_transfer_a: 0,
    td_passenger_transit_a: 0,
    td_arrival_freight_f: 0,
    td_infants_f: 0,
    td_passenger_f: 0,
    td_passenger_dom_f: 0,
    td_passenger_inter_f: 0,
    td_passenger_transfer_f: 0,
    td_passenger_transit_f: 0,
    td_used: 0,
    id: id,
    td_datetime_a: "",
    td_datetime_a_date: "",
    td_datetime_a_time: "",
    td_datetime_f: "",
    td_datetime_f_date: "",
    td_datetime_f_time: "",
    td_air_type_a: "",
    td_air_type_f: "",
    td_status: 0,
    user_id: DataUser.u_id,
  });
  const [airline, setAirline] = useState([]);
  const [trafftype, setTraff] = useState([]);
  const [porttype, setPortType] = useState([]);
  const [callsignLists, setCallSign] = useState([]);
  const [checkSetDetail, setCheckDetail] = useState(false);
  const getTraff = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/trafftype`)
      .then((res) => {
        setTraff(res.data);
      });
  };
  const getAircraft = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/aircrafts/al/${id}`)
      .then((res) => {
        setCallSign(res.data);
      });
  };
  const getData = async () => {
    props.handlerIsLoading(true);
    await getTraff();
    await getPorttype();
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/airlines`)
      .then((res) => {
        setAirline(res.data);
      });
  };
  const getData2 = async () => {
    props.handlerIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/ts/${id}`)
      .then((res) => {
        const setA = false;
        const setF = false;
        setState({
          ...formFlight,
          ...res.data[0],
          t_passenger: res.data[0].t_passenger ? res.data[0].t_passenger : 0,
          t_passenger_dom: res.data[0].t_passenger_dom
            ? res.data[0].t_passenger_dom
            : 0,
          t_passenger_inter: res.data[0].t_passenger_inter
            ? res.data[0].t_passenger_inter
            : 0,
          t_passenger_transit: res.data[0].t_passenger_transit
            ? res.data[0].t_passenger_transit
            : 0,
          t_passenger_transfer: res.data[0].t_passenger_transfer
            ? res.data[0].t_passenger_transfer
            : 0,
          t_infants: res.data[0].t_infants ? res.data[0].t_infants : 0,
        });
        setState2({
          ...formFlightDetail,
          td_datetime_a: setA ? res.data[0].t_datetime : "",
          td_passenger_a: setA ? parseInt(res.data[0].t_passenger) : 0,
          td_passenger_dom_a: setA ? parseInt(res.data[0].t_passenger_dom) : 0,
          td_passenger_inter_a: setA
            ? parseInt(res.data[0].t_passenger_inter)
            : 0,
          td_passenger_transfer_a: setA
            ? parseInt(res.data[0].t_passenger_transfer)
            : 0,
          td_passenger_transit_a: setA
            ? parseInt(res.data[0].t_passenger_transit)
            : 0,
          td_infants_a: setA ? parseInt(res.data[0].t_infants) : 0,
          td_datetime_f: setF ? res.data[0].t_datetime : "",
          td_passenger_f: setF ? parseInt(res.data[0].t_passenger) : 0,
          td_passenger_dom_f: setF ? parseInt(res.data[0].t_passenger_dom) : 0,
          td_passenger_inter_f: setF
            ? parseInt(res.data[0].t_passenger_inter)
            : 0,
          td_passenger_transfer_f: setF
            ? parseInt(res.data[0].t_passenger_transfer)
            : 0,
          td_passenger_transit_f: setF
            ? parseInt(res.data[0].t_passenger_transit)
            : 0,
          td_infants_f: setF ? parseInt(res.data[0].t_infants) : 0,
          td_air_type_a: setA ? res.data[0].t_air_type : "",
          td_air_type_f: setF ? res.data[0].t_air_type : "",
        });
        setCheckDetail(true);
      });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/${id}`)
      .then(async (res) => {
        if (res.data.length > 0) {
          if (res.data[0].td_status && res.data[0].td_status !== "") {
            const temp = {};
            for (const [key, value] of Object.entries(res.data[0])) {
              temp[key] = value;
            }
            setState2({
              ...formFlightDetail,
              ...temp,
            });
          }
        }
      });
    props.handlerIsLoading(false);
  }, [checkSetDetail]);
  const getPorttype = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/transports/porttype`)
      .then((res) => {
        setPortType(res.data);
      });
  };
  useEffect(() => {
    if (airline.length > 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/transports/ts/${id}`)
        .then((res) => {
          const find = airline.find((i) => {
            return i.al_id === res.data[0].t_airline_id;
          });
          if (find) {
            setState({
              ...formFlight,
              ...res.data[0],
              al_owner: find.al_owner,
              al_agent: find.al_agent,
            });
            getAircraft(find.al_id);
          } else {
            setState({
              ...formFlight,
              ...res.data[0],
            });
          }
          props.handlerIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airline]);

  useEffect(() => {
    if (callsignLists.length > 0) {
      const callsign = callsignLists.find(
        (i) =>
          i.ac_callsign.toString().toLowerCase() ===
          formFlight.t_callsign.toString().toLowerCase()
      );
      setState({
        ...formFlight,
        t_callsign_id: callsign.ac_id,
        t_callsign: callsign.ac_callsign,
        ac_weight: callsign.ac_weight,
        t_air_type_id: callsign.at_id,
        t_air_type: callsign.at_name,
      });
      let c1 = "",
        c2 = "";
      if (formFlightDetail.td_airtype_id_a) {
        const f = callsignLists.find(
          (i) =>
            formFlightDetail.td_airtype_id_a.toString() === i.at_id.toString()
        );
        if (f) {
          c1 = `${f.at_name}`;
        }
      }
      if (formFlightDetail.td_airtype_id_f) {
        const f2 = callsignLists.find(
          (i) =>
            formFlightDetail.td_airtype_id_f.toString() === i.at_id.toString()
        );
        if (f2) {
          c2 = `${f2.at_name}`;
        }
      }
      setState2({
        ...formFlightDetail,
        td_air_type_a_show: c1,
        td_air_type_f_show: c2,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callsignLists]);

  useEffect(() => {
    getData();
    getData2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTrafftypeName = (i) => {
    const find = trafftype.find((item) => {
      return item.tt_code === i;
    });
    return find ? `${find.tt_code} (${find.tt_description})` : "";
  };

  const getPortTypeName = (v) => {
    if (v) {
      const findItem = porttype.find((i) => i.pt_code === v);
      return findItem ? `${findItem.pt_code} (${findItem.pt_description})` : "";
    }
    return "";
  };

  const getFile = () => {
    props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1//download/flights/${formFlightDetail.td_upload}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, formFlightDetail.td_upload);
        props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };
  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>ประวัติข้อมูลเที่ยวบิน</div>
        </div>
      </div>
      <div className="box-S4 mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">รายละเอียดสายการบิน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="ชื่อนิติบุคคล / เจ้าของเที่ยวบิน"
                  value={formFlight.al_name ? formFlight.al_name : ""}
                  disabled
                />
              </div>
              <div className="box-S2">
                <div className="bRow flex-between">
                  <div className="box-S4">
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-disabled Mui-disabled MuiFormLabel-filled"
                      >
                        การเดินทาง
                      </FormLabel>
                      <RadioGroup
                        disabled
                        aria-label="t_arr_dep"
                        name="t_arr_dep"
                        value={formFlight.t_arr_dep}
                        row
                        style={{
                          paddingBottom: "0px",
                          paddingLeft: "15px",
                        }}
                      >
                        <FormControlLabel
                          disabled
                          value="A"
                          control={<Radio />}
                          label="เข้า"
                        />
                        <FormControlLabel
                          disabled
                          value="D"
                          control={<Radio />}
                          label="ออก"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="ตัวแทน"
                  disabled
                  value={formFlight.al_agent ? formFlight.al_agent : ""}
                />
              </div>
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="เจ้าของ"
                  disabled
                  value={formFlight.al_owner ? formFlight.al_owner : ""}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  fullWidth
                  value={formFlight.t_callsign ? formFlight.t_callsign : ""}
                  name="t_callsign"
                  label="เลขที่สัญชาติและทะเบียน"
                  disabled
                />
              </div>
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="แบบ"
                  disabled
                  value={formFlight.t_air_type ? formFlight.t_air_type : ""}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="น้ำหนัด"
                  disabled
                  value={
                    formFlight.t_arrival_freight
                      ? formFlight.t_arrival_freight
                      : ""
                  }
                />
              </div>
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="สนามบิน"
                  disabled
                  value={formFlight.ap_name ? formFlight.ap_name : ""}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  fullWidth
                  label="รหัสเที่ยวบิน"
                  disabled
                  value={formFlight.t_flight_no ? formFlight.t_flight_no : ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">ข้อมูลการบิน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="ประเภทการบิน"
                  disabled
                  value={
                    formFlight.t_traff_type
                      ? getTrafftypeName(formFlight.t_traff_type)
                      : ""
                  }
                />
              </div>
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="รหัสเที่ยวบิน"
                  value={formFlight.t_flight_no ? formFlight.t_flight_no : ""}
                  name="t_flight_no"
                  disabled
                />
              </div>
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="Port Type"
                  value={getPortTypeName(formFlight.t_port_type)}
                  name="t_flight_no"
                  disabled
                />
              </div>
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="Region"
                  value={formFlight.t_region_no ? formFlight.t_region_no : ""}
                  name="t_flight_no"
                  disabled
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="รหัสต้นทาง"
                  disabled
                  value={formFlight.t_departure_airport_code}
                />
              </div>
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="รหัสปลายทาง"
                  disabled
                  value={formFlight.t_departure_airport_name}
                />
              </div>
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="รหัสปลายทาง"
                  disabled
                  value={formFlight.t_arrival_airport_code}
                />
              </div>
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="ชื่อปลายทาง"
                  disabled
                  value={formFlight.t_arrival_airport_name}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="Card No."
                  disabled
                  value={formFlight.t_card_no ? formFlight.t_card_no : ""}
                />
              </div>
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="นักบิน"
                  value={formFlight.td_captain ? formFlight.td_captain : ""}
                  name="t_flight_no"
                  disabled
                />
              </div>
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="Zone No."
                  value={formFlight.t_zone_no ? formFlight.t_zone_no : ""}
                  name="t_flight_no"
                  disabled
                />
              </div>
              <div className="box-S1">
                <TextField
                  fullWidth
                  label="ลูกเรือ"
                  value={formFlight.td_crew ? formFlight.td_crew : ""}
                  name="t_flight_no"
                  disabled
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S4">
                <TextField
                  fullWidth
                  label="หมายเหตุ"
                  disabled
                  value={formFlight.t_remark ? formFlight.t_remark : ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-S6 flex-between mgBottom">
        <div className="box-S2">
          {/*############################################### ข้อมูลสถิติ ###############################################*/}
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลสถิติ</div>
            <div className="bContent" style={{ height: "466px" }}>
              <div className="bRow flex-between">
                <TextField
                  fullWidth
                  label="วันที่"
                  value={
                    formFlight.t_datetime
                      ? Helper.CastDate(new Date(formFlight.t_datetime))
                      : ""
                  }
                  type="date"
                  name="t_datetime"
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  fullWidth
                  label="เวลา"
                  value={
                    formFlight.t_datetime
                      ? Helper.CastTime(formFlight.t_datetime)
                      : ""
                  }
                  name="t_datetime"
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  fullWidth
                  label="น้ำหนัก"
                  value={
                    formFlight.t_arrival_freight
                      ? Helper.CastCurrency(formFlight.t_arrival_freight)
                      : ""
                  }
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={formFlight.t_passenger_dom}
                  name="t_passenger_dom"
                  label="คนไทย (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={formFlight.t_passenger_inter}
                  name="t_passenger_inter"
                  label="ต่างชาติ (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={formFlight.t_infants}
                  name="t_infants"
                  label="เด็กต่ำกว่า 2 ขวบ (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={formFlight.t_passenger_transfer}
                  name="t_passenger_transfer"
                  label="ผู้โดยสารเปลี่ยนสายการบิน (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={formFlight.t_passenger_transit}
                  name="t_passenger_transit"
                  label="ผู้โดยสารแวะผ่านสนามบิน (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                {formFlightDetail.td_upload && (
                  <div className="box-S4">
                    <Button
                      onClick={() => {
                        getFile();
                      }}
                      className="styleBtn blue"
                      style={{ width: "100%", marginTop: "10px" }}
                    >
                      ดาวน์โหลด
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="box-S2">
          {/*############################################### ข้อมูลผู้ชำระ ###############################################*/}
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลสายการบิน</div>
            <div className="bContent" style={{ height: `466px` }}>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={
                    formFlightDetail.td_datetime_a
                      ? Helper.CastDate(
                          new Date(formFlightDetail.td_datetime_a)
                        )
                      : ""
                  }
                  name="td_datetime_a_date"
                  label="วันที่"
                  disabled
                  type="date"
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={
                    formFlightDetail.td_datetime_a
                      ? Helper.CastTime(formFlightDetail.td_datetime_a)
                      : ""
                  }
                  name="td_datetime_a_time"
                  label="เวลา"
                  type="time"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={
                    formFlightDetail.td_arrival_freight_a
                      ? Helper.CastCurrency(
                          formFlightDetail.td_arrival_freight_a
                        )
                      : "0.00"
                  }
                  label="น้ำหนัก"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={formFlightDetail.td_passenger_dom_a}
                  name="td_passenger_dom_a"
                  label="คนไทย (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={formFlightDetail.td_passenger_inter_a}
                  name="td_passenger_inter_a"
                  label="ต่างชาติ (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={
                    formFlightDetail.td_infants_a
                      ? formFlightDetail.td_infants_a
                      : 0
                  }
                  name="td_infants_a"
                  label="เด็กต่ำกว่า 2 ขวบ (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={formFlightDetail.td_passenger_transfer_a}
                  name="td_passenger_transfer_a"
                  label="ผู้โดยสารเปลี่ยนสายการบิน (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={formFlightDetail.td_passenger_transit_a}
                  name="td_passenger_transit_a"
                  label="ผู้โดยสารแวะผ่านสนามบิน (คน)"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="box-S2">
          {/*############################################### ข้อมูลเจ้าหน้าที่ ###############################################*/}
          <div className="bContentMain">
            <div className="bNameContent">ข้อมูลเจ้าหน้าที่</div>
            <div className="bContent" style={{ height: "466px" }}>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={
                    formFlightDetail.td_datetime_f
                      ? Helper.CastDate(
                          new Date(formFlightDetail.td_datetime_f)
                        )
                      : ""
                  }
                  name="td_datetime_a_date"
                  label="วันที่"
                  disabled
                  type="date"
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={
                    formFlightDetail.td_datetime_f
                      ? Helper.CastTime(formFlightDetail.td_datetime_f)
                      : ""
                  }
                  name="td_datetime_f_time"
                  label="เวลา"
                  disabled
                  type="time"
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={
                    formFlightDetail.td_arrival_freight_f
                      ? Helper.CastCurrency(
                          formFlightDetail.td_arrival_freight_f
                        )
                      : "0.00"
                  }
                  label="น้ำหนัก"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={formFlightDetail.td_passenger_dom_f}
                  name="td_passenger_dom_f"
                  label="คนไทย (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={formFlightDetail.td_passenger_inter_f}
                  name="td_passenger_inter_f"
                  label="ต่างชาติ (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={formFlightDetail.td_infants_f}
                  name="td_infants_f"
                  label="เด็กต่ำกว่า 2 ขวบ (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={formFlightDetail.td_passenger_transfer_f}
                  name="td_passenger_transfer_f"
                  label="ผู้โดยสารเปลี่ยนสายการบิน (คน)"
                  disabled
                />
              </div>
              <div className="bRow flex-between">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="Number"
                  value={formFlightDetail.td_passenger_transit_f}
                  name="td_passenger_transit_f"
                  label="ผู้โดยสารแวะผ่านสนามบิน (คน)"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bottom1Function back="/flight-schedule" />
    </div>
  );
}

export default FlightScheduleDetails;
