/** @format */

import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

// Components Page
import Login from "./components/page/Login";
import ToolBar from "./components/menu/ToolBar";
import User from "./components/page/user/User";
import UserProfile from "./components/page/user/UserProfile";
import UserProfileAdd from "./components/page/user/UserProfileAdd";
import Aircraft from "./components/page/aircraft/Aircraft";
import AircraftDetails from "./components/page/aircraft/AircraftDetails";
import AircraftDetailsAdd from "./components/page/aircraft/AircraftDetailsAdd";
import Airport from "./components/page/airport/Airport";
import AirportDetailsAdd from "./components/page/airport/AirportDetailsAdd";
import AirportDetails from "./components/page/airport/AirportDetails";
import Airline from "./components/page/airline/Airline";
import AirlineAddress from "./components/page/airline/AirlineAddress";
import AirlineAddressDetailsAdd from "./components/page/airline/AirlineAddressDetailsAdd";
import AirlineAddressDetails from "./components/page/airline/AirlineAddressDetails";
import AirlineDetailsAdd from "./components/page/airline/AirlineDetailsAdd";
import AirlineDetails from "./components/page/airline/AirlineDetails";
import AirlineRecord from "./components/page/airline/record/AirlineRecord";
import AirlineRecordDetails from "./components/page/airline/record/AirlineRecordDetails";
import AirlineRecordDetailsAdd from "./components/page/airline/record/AirlineRecordDetailsAdd";
import AirlineRecordSpecial from "./components/page/airline/record/AirlineRecordSpecial";
import AirlineRecordSpecialDetails from "./components/page/airline/record/AirlineRecordSpecialDetails";
import AirlineRecordSpecialDetailsAdd from "./components/page/airline/record/AirlineRecordSpecialDetailsAdd";
import OtherIncome from "./components/page/other_income/OtherIncome";
import OtherIncomeDetails from "./components/page/other_income/OtherIncomeDetails";
import OtherIncomeAdd from "./components/page/other_income/OtherIncomeAdd";
import FlightSchedule from "./components/page/FlightSchedule";
import FlightScheduleDetails from "./components/page/FlightScheduleDetails";
import IncomeDetails from "./components/page/IncomeDetails";
import FlightCheck from "./components/page/flight/FlightCheck";
import FlightCheckDetails from "./components/page/flight/FlightCheckDetails";
import Invoice from "./components/page/Invoice";
import InvoiceSpecial from "./components/page/InvoiceSpecial";
import InvoiceCreate from "./components/page/InvoiceCreate";
import InvoiceDetails from "./components/page/InvoiceDetails";
import RevenueCategory from "./components/page/revenue/main/RevenueCategory";
import RevenueCategoryDetail from "./components/page/revenue/main/RevenueCategoryDetail";
import RevenueCategoryAdd from "./components/page/revenue/main/RevenueCategoryAdd";
import RevenueCategorySub from "./components/page/revenue/sub/RevenueCategorySub";
import RevenueCategorySubDetail from "./components/page/revenue/sub/RevenueCategorySubDetail";
import RevenueCategorySubAdd from "./components/page/revenue/sub/RevenueCategorySubAdd";
import MasterItem from "./components/page/master_item/MasterItem";
import MasterItemAdd from "./components/page/master_item/MasterItemAdd";
import MasterItemDetail from "./components/page/master_item/MasterItemDetail";

import ReduceDept from "./components/page/reduce_dept/ReduceDept";
import ReduceDeptAdd from "./components/page/reduce_dept/ReduceDeptAdd";
import ReduceDeptDetails from "./components/page/reduce_dept/ReduceDeptDetails";

import UserFuntion from "./components/menu/UserFuntion";
import MainMenuList from "./components/menu/MainMenuList";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import TransportRemain from "./components/page/TransportRemain";
import TransportRemainCenter from "./components/page/TransportRemainCenter";

import Report from "./components/page/Report";
import Dashboard from "./components/page/Dashboard";

import WelfareIncome from "./components/page/welfare_income/WelfareIncome";
import WelfareIncomeDetails from "./components/page/welfare_income/WelfareIncomeDetails";
import WelfareIncomeAdd from "./components/page/welfare_income/WelfareIncomeAdd";
import WelfareLists from "./components/page/welfare-lists/WelfareLists";
import WelfareListsDetailsAdd from "./components/page/welfare-lists/WelfareListsDetailsAdd";
import WelfareListsDetails from "./components/page/welfare-lists/WelfareListsDetails";

import IncompleteInformation from "./components/page/IncompleteInformation";
import ExceptInvoice from "./components/page/ExceptInvoice";

import ImportTransport from "./components/page/ImportTransport";
import ReportRevenueEstimate from "./components/page/ReportRevenueEstimate";

import ReportTargetBudgetInvoice from "./components/page/ReportTargetBudgetInvoice";
import ReportTargetBudgetCash from "./components/page/ReportTargetBudgetCash";

import LogEmail from "./components/page/LogEmail";
export default class App extends Component {
  constructor(props) {
    super(props);
    var status = localStorage.getItem("status");
    this.state = {
      isLoggedIn: status,
      isLoading: false,
    };
    this.handlerIsLoggedIn = this.handlerIsLoggedIn.bind(this);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.handlerSetActiveMenu = this.handlerSetActiveMenu.bind(this);
  }
  componentDidMount() {
    const temp = window.location.pathname.substring(1).split("/");
    this.handlerSetActiveMenu(temp[0]);
    if (!this.state.isLoggedIn && !["login", "embeded"].includes(temp[0])) {
      window.location.href = "/login";
    }
  }
  handlerIsLoggedIn = (v) => {
    this.setState({
      isLoggedIn: v,
    });
  };
  handlerIsLoading = (v) => {
    this.setState({
      isLoading: v,
    });
  };
  handlerSetActiveMenu = async (path) => {
    try {
      path = path ? path : "unset";
      var elems = await document.querySelectorAll(".nav-menu-items.active");
      const temp = `.${path}.nav-menu-items`;
      var elems2 = await document.querySelectorAll(temp);
      [].forEach.call(elems, function (el) {
        el.classList.remove("active");
      });
      [].forEach.call(elems2, function (el) {
        el.classList.add("active");
      });
    } catch (e) {}
  };
  render() {
    return (
      <Router>
        <Loader
          visible={this.state.isLoading}
          type="RevolvingDot"
          color="#024D85"
          height={100}
          width={100}
          className="bg-loading"
        />
        <Route exact path="/embeded/report-target-budget-invoice">
          <style> {`body {padding-top:0 !important}`}</style>
          <div className="wrapMainLayout">
            <div className="LayoutContent">
              <ReportTargetBudgetInvoice
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </Route>
        <Route exact path="/embeded/report-target-budget-cash">
          <style> {`body {padding-top:0 !important}`}</style>
          <div className="wrapMainLayout">
            <div className="LayoutContent">
              <ReportTargetBudgetCash
                handlerIsLoading={this.handlerIsLoading.bind(this)}
              />
            </div>
          </div>
        </Route>
        {!this.state.isLoggedIn && (
          <Route exact path="/login">
            <Login
              handlerIsLoading={this.handlerIsLoading.bind(this)}
              handlerIsLoggedIn={this.handlerIsLoggedIn.bind(this)}
            />
          </Route>
        )}
        {this.state.isLoggedIn && (
          <div>
            <ToolBar
              handlerIsLoading={this.handlerIsLoading.bind(this)}
              handlerIsLoggedIn={this.handlerIsLoggedIn.bind(this)}
              handlerSetActiveMenu={this.handlerSetActiveMenu.bind(this)}
            />
            <div className="wrapMainLayout">
              <div className="Lpc-tablet LayoutMenu">
                <UserFuntion />
                <div className="MainMenuList">
                  <MainMenuList />
                </div>
              </div>
              <div className="LayoutContent">
                <Route exact path="/">
                  <UserProfile
                    handlerIsLoggedIn={this.handlerIsLoggedIn.bind(this)}
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                    isReadonly={true}
                  />
                </Route>
                <Route exact path="/user">
                  <User handlerIsLoading={this.handlerIsLoading.bind(this)} />
                </Route>
                <Route exact path="/user/profile/:id">
                  <UserProfile
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/user/add">
                  <UserProfileAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/aircraft">
                  <Aircraft
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/aircraft/add">
                  <AircraftDetailsAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/aircraft/detail/:id">
                  <AircraftDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airport">
                  <Airport
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airport/add">
                  <AirportDetailsAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airport/detail/:id">
                  <AirportDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline">
                  <Airline
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline-address">
                  <AirlineAddress
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline-address/add">
                  <AirlineAddressDetailsAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>

                <Route exact path="/airline-address/detail/:id">
                  <AirlineAddressDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline/add">
                  <AirlineDetailsAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline/detail/:id">
                  <AirlineDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline-record">
                  <AirlineRecord
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline-record/add">
                  <AirlineRecordDetailsAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline-record/detail/:id">
                  <AirlineRecordDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline-record-special">
                  <AirlineRecordSpecial
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/airline-record-special/add">
                  <AirlineRecordSpecialDetailsAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                  {/*<AirlineRecordSpecialDetailsAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />*/}
                </Route>
                <Route exact path="/airline-record-special/detail/:id">
                  <AirlineRecordSpecialDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/other-income">
                  <OtherIncome
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/other-income/detail/:id">
                  <OtherIncomeDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/other-income/add/:id">
                  <OtherIncomeAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/other-income/add">
                  <OtherIncomeAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/flight-schedule">
                  <FlightSchedule
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/flight-schedule/detail/:id">
                  <FlightScheduleDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/IncomeDetails">
                  <IncomeDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/flight-check">
                  <FlightCheck
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/flight-check/details/:id">
                  <FlightCheckDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/invoice-special">
                  <InvoiceSpecial
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/invoice-special/detail/:id">
                  <InvoiceDetails
                    isSpecial={true}
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/invoice-special/create">
                  <InvoiceCreate
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                    isSpecial={true}
                  />
                </Route>
                <Route exact path="/invoice">
                  <Invoice
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/invoice/create/:id">
                  <InvoiceCreate
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                    isSpecial={false}
                  />
                </Route>
                <Route exact path="/invoice/create">
                  <InvoiceCreate
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/invoice/detail/:id">
                  <InvoiceDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/revenue-category">
                  <RevenueCategory
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/revenue-category/add">
                  <RevenueCategoryAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/revenue-category/detail/:id">
                  <RevenueCategoryDetail
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/revenue-category-sub">
                  <RevenueCategorySub
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/revenue-category-sub/add">
                  <RevenueCategorySubAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/revenue-category-sub/detail/:id">
                  <RevenueCategorySubDetail
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>

                <Route exact path="/master-item">
                  <MasterItem
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/master-item/add">
                  <MasterItemAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/master-item/detail/:id">
                  <MasterItemDetail
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>

                <Route exact path="/reduce-dept">
                  <ReduceDept
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/reduce-dept/detail/:id">
                  <ReduceDeptDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/reduce-dept/add">
                  <ReduceDeptAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>

                <Route exact path="/transport-remain">
                  <TransportRemain
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/transport-remain-center">
                  <TransportRemainCenter
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/report">
                  <Report handlerIsLoading={this.handlerIsLoading.bind(this)} />
                </Route>
                <Route exact path="/report-target-budget-invoice">
                  <ReportTargetBudgetInvoice
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/report-target-budget-cash">
                  <ReportTargetBudgetCash
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/dashboard">
                  <Dashboard
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/welfare-income">
                  <WelfareIncome
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/welfare-income/detail/:id">
                  <WelfareIncomeDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/welfare-income/add">
                  <WelfareIncomeAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/welfare-lists">
                  <WelfareLists
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/welfare-lists/add">
                  <WelfareListsDetailsAdd
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>

                <Route exact path="/welfare-lists/detail/:id">
                  <WelfareListsDetails
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>

                <Route exact path="/incomplete-information">
                  <IncompleteInformation
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/except-invoice">
                  <ExceptInvoice
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/import-transport">
                  <ImportTransport
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/report-revenue-estimate">
                  <ReportRevenueEstimate
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
                <Route exact path="/report-log-email">
                  <LogEmail
                    handlerIsLoading={this.handlerIsLoading.bind(this)}
                  />
                </Route>
              </div>
            </div>
          </div>
        )}
      </Router>
    );
  }
}
