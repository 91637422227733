/** @format */

import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import {
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Description from "@material-ui/icons/Description";
import MaterialTable from "material-table";
import axios from "axios";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import fileDownload from "js-file-download";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import Notifications from "../global/Notifications";
import SD from "../global/SwalDialog";

import Helper from "../global/Helper";
export class TableWelfareIncome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upload_id: "",
      upload_name: "",
      otherIncome: [],
      filter_airport: Helper.GetUserData().ap_id,
      airportOption: [
        <option key="ap" value="-1">
          ทั้งหมด
        </option>,
      ],
    };
    this.upLoadfile = null;
  }
  getData = async (isSetState = true) => {
    this.props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/otherincomes?rt_id=3&filter_airport=${this.state.filter_airport}`;
    let result = [];
    await axios.get(apiUrl).then((res) => {
      result = res.data;
      if (isSetState) {
        this.setState({ otherIncome: res.data });
      }
      this.props.handlerIsLoading(false);
    });
    return result;
  };
  async componentDidMount() {
    const data = await this.getData(false);
    const ap = await this.getAirport();

    this.setState({
      airportOption: [...this.state.airportOption, ...ap],
      filter_airport: Helper.GetUserData().ap_id,
      otherIncome: data,
    });
  }

  getAirport = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/airports?withCenter=1`;
    const temp = [];
    await axios.get(apiUrl).then((res) => {
      res.data.forEach((i, index) => {
        temp.push(
          <option key={index} value={i.ap_id}>
            {i.ap_name}
          </option>
        );
      });
    });
    return temp;
  };

  getFile = (mode, name) => {
    this.props.handlerIsLoading(true);
    const tempName = name.split("-");
    const temp = tempName.length > 1 ? name.split("-")[1] : name;
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/welfareincomes/${mode}/${temp}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name + ".pdf");
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  getFilePDF = (name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        // `/download/otherincome/invoice`,
        `${process.env.REACT_APP_API_URL}/v1/download/otherincomes/pdf/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name + ".pdf");
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  getStatus = (dt, i, withBox = true, active = 0) => {
    const d = new Date(dt);
    const n = new Date();
    let cls = "";
    let text = "-";
    if (active.toString() === "1") {
      text = "ยกเลิก";
      cls = "cancel";
    } else {
      if (n > d && i !== "3") {
        text = "ค้างชำระ";
        cls = "cancel";
      } else {
        if (i === "3") {
          text = "ชำระแล้ว";
          cls = "complete";
        } else {
          text = "รอชำระ";
          cls = "pending";
        }
      }
    }
    if (withBox) {
      return (
        <div className={`TagStatus ${cls}`} style={{ margin: "auto" }}>
          {text}
        </div>
      );
    } else {
      return text;
    }
  };

  cancelInvoice = (data) => {
    SD.Confirm({
      header: `ยืนยันการยกเลิกใบแจ้งหนี้ "${data.i_doc_no}"`,
      msg: `การยกเลิกจะเป็นการคืนสถานะเที่ยวบินทั้งหมดไปสู่สถานะยืนยันข้อมูล`,
      type: "warning",
      fnConfirm: () => {
        Notifications.NotiAsync(
          axios.delete(
            `${process.env.REACT_APP_API_URL}/v1/invoice/delete-otherincome`,
            {
              data: {
                ...data,
              },
            }
          ),
          (resp) => {
            this.getData();
            Notifications.NotiSuccess();
          },
          (err) => {
            Notifications.NotiError({ msg: "ไม่สามารถลบข้อมูลได้" });
          }
        );
      },
    });
  };

  sendFileUpload = (e) => {
    const user = JSON.parse(localStorage.getItem("DataUser"));
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    var file = document.querySelector("#filUpload");
    formData.append("file", file.files[0]);
    formData.append("id", this.state.upload_id);
    formData.append("name", this.state.upload_name);
    formData.append("uid", user.u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/otherincomes/uploadfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        document.querySelector("#filUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
        this.getData();
      },
      (err) => {
        document.querySelector("#filUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };
  updateAirport = (e) => {
    this.setState({ filter_airport: e.target.value });
  };
  render() {
    return (
      <div>
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">กรอง</div>
            <div className="bContent">
              <div className="bRow" style={{ display: "flex" }}>
                <div className="box-S2">
                  <FormControl fullWidth>
                    <InputLabel shrink>สนามบิน</InputLabel>
                    <NativeSelect
                      native="true"
                      name="filter_airport"
                      value={this.state.filter_airport}
                      onChange={this.updateAirport}
                    >
                      {this.state.airportOption}
                    </NativeSelect>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </div>
                <div className="box-S1">
                  <Button
                    style={{ marginTop: "14px", marginLeft: "14px" }}
                    className="styleBtn blue"
                    onClick={() => {
                      this.getData();
                    }}
                  >
                    ค้นหา
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input
          id="filUpload"
          type="file"
          className="hidden"
          accept="application/pdf"
          ref={(input) => {
            this.upLoadfile = input;
          }}
          onChange={(e) => {
            e.preventDefault();
            this.sendFileUpload(e);
          }}
        />
        <Paper className="tabelData">
          <TableContainer>
            <MaterialTable
              title=""
              columns={[
                {
                  title: "วันที่ออก",
                  field: "i_due_date",
                  align: "center",
                  render: (rowData) => {
                    return Helper.CastDate(new Date(rowData.i_doc_date));
                  },
                },
                {
                  title: "เลขที่ใบแจ้งหนี้",
                  field: "i_doc_no",
                  align: "center",
                },
                { title: "Ref 1", field: "i_ref1", align: "center" },
                { title: "Ref 2", field: "i_ref2", align: "center" },
                {
                  title: "กำหนดชำระ",
                  field: "i_due_date",
                  align: "center",
                  render: (rowData) => {
                    return Helper.CastDate(new Date(rowData.i_due_date));
                  },
                },
                {
                  title: "ประเภทรายการ",
                  field: "rc_name",
                  align: "center",
                },
                {
                  title: "ชื่อผู้ชำระเงิน",
                  field: "apa_name",
                  align: "center",
                },
                {
                  title: "สถานะ",
                  field: "total",
                  align: "center",
                  render: (rowData) => {
                    return this.getStatus(
                      rowData.i_due_date,
                      rowData.i_upload || rowData.i_paid ? "3" : "0",
                      true,
                      rowData.i_active
                    );
                  },
                },
                {
                  title: "",
                  field: "i_id",
                  align: "center",
                  render: (rowData) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <Tooltip title="ดู" arrow>
                          <Button
                            className="w-auto styleBtn blue"
                            href={`/welfare-income/detail/${encodeURI(
                              rowData.i_id
                            )}`}
                            variant="contained"
                            style={{
                              textTransform: "none",
                              marginRight: "5px",
                            }}
                            size="small"
                          >
                            <AssignmentIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="ใบแจ้งหนี้" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_active === "1" ||
                                rowData.i_revenue_type !== "3"
                                  ? ""
                                  : "green"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                rowData.i_active === "1" ||
                                rowData.i_revenue_type !== "3"
                              }
                              onClick={() => {
                                this.getFile(
                                  "invoice",
                                  "ใบแจ้งหนี้สวัสดิการ-" + rowData.i_id
                                );
                              }}
                              size="small"
                            >
                              <ReceiptIcon />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="ยกเลิก" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_active === "1" ||
                                rowData.i_upload ||
                                rowData.i_paid ||
                                this.getStatus(
                                  rowData.i_due_date,
                                  rowData.i_upload || rowData.i_paid
                                    ? "3"
                                    : "0",
                                  false
                                ) === "ค้างชำระ"
                                  ? ""
                                  : "red"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                rowData.i_active === "1" ||
                                rowData.i_upload ||
                                rowData.i_paid ||
                                this.getStatus(
                                  rowData.i_due_date,
                                  rowData.i_upload || rowData.i_paid
                                    ? "3"
                                    : "0",
                                  false
                                ) === "ค้างชำระ"
                              }
                              size="small"
                              onClick={() => {
                                this.cancelInvoice(rowData);
                              }}
                            >
                              <CancelPresentationIcon />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="ใบเสร็จ" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_active === "1" ||
                                rowData.i_revenue_type !== "3" ||
                                this.getStatus(
                                  rowData.i_due_date,
                                  rowData.i_upload || rowData.i_paid
                                    ? "3"
                                    : "0",
                                  false
                                ) === "ค้างชำระ"
                                  ? ""
                                  : "pink"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                rowData.i_active === "1" ||
                                rowData.i_revenue_type !== "3" ||
                                this.getStatus(
                                  rowData.i_due_date,
                                  rowData.i_upload || rowData.i_paid
                                    ? "3"
                                    : "0",
                                  false
                                ) === "ค้างชำระ"
                              }
                              onClick={() => {
                                this.getFile(
                                  "receipt",
                                  "ใบเสร็จรับเงินรายได้สวัสดิการ-" +
                                    rowData.i_id
                                );
                              }}
                              size="small"
                            >
                              <MenuBookIcon />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="Upload" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_active === "1" ||
                                rowData.i_revenue_type !== "3" ||
                                this.getStatus(
                                  rowData.i_due_date,
                                  rowData.i_upload || rowData.i_paid
                                    ? "3"
                                    : "0",
                                  false
                                ) === "ค้างชำระ"
                                  ? ""
                                  : "purple"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                rowData.i_active === "1" ||
                                rowData.i_revenue_type !== "3" ||
                                this.getStatus(
                                  rowData.i_due_date,
                                  rowData.i_upload || rowData.i_paid
                                    ? "3"
                                    : "0",
                                  false
                                ) === "ค้างชำระ"
                              }
                              size="small"
                              onClick={() => {
                                SD.Input({
                                  msg: "เลขที่เอกสาร",
                                  value: rowData.upload_name,
                                  btn: "Upload",
                                  fnConfirm: (value) => {
                                    if (value.status) {
                                      if (value.value) {
                                        this.upLoadfile.click();
                                      } else {
                                        Notifications.NotiWarning({
                                          msg: "กรุณากรอกเลขที่เอกสาร",
                                        });
                                      }
                                    }
                                    rowData.upload_name = value.value;
                                    this.setState({
                                      upload_id: rowData.i_id,
                                      upload_name: value.value,
                                    });
                                  },
                                });
                              }}
                            >
                              <Description />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="Download" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                !rowData.i_upload ||
                                rowData.i_revenue_type !== "3" ||
                                this.getStatus(
                                  rowData.i_due_date,
                                  rowData.i_upload || rowData.i_paid
                                    ? "3"
                                    : "0",
                                  false
                                ) === "ค้างชำระ"
                                  ? ""
                                  : "lightblue"
                              }`}
                              variant="contained"
                              disabled={
                                !rowData.i_upload ||
                                rowData.i_revenue_type !== "3" ||
                                this.getStatus(
                                  rowData.i_due_date,
                                  rowData.i_upload || rowData.i_paid
                                    ? "3"
                                    : "0",
                                  false
                                ) === "ค้างชำระ"
                              }
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              size="small"
                              onClick={() => {
                                this.getFilePDF(rowData.i_upload);
                              }}
                            >
                              <SystemUpdateAltIcon />
                            </Button>
                          </span>
                        </Tooltip>
                      </div>
                    );
                  },
                },
              ]}
              data={this.state.otherIncome}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50, 100],
                search: true,
                exportButton: true,
                filtering: false,
                actionsColumnIndex: -1,
                grouping: true,
                exportPdf: (columns, data) => {
                  const temp = data.map((i) => [
                    i.i_doc_date,
                    i.i_doc_no,
                    i.icr_refno1,
                    i.icr_refno2,
                    i.i_due_date,
                    i.rc_name,
                    i.apa_name,
                    this.getStatus(i.i_due_date, i.i_paid, false),
                  ]);

                  Helper.exportPDF(
                    [
                      "วันที่ออก",
                      "เลขที่ใบแจ้งหนี้",
                      "Ref 1",
                      "Ref 2",
                      "กำหนดชำระ",
                      "ประเภทรายการ",
                      "ชื่อผู้ชำระเงิน",
                      "สถานะ",
                    ],
                    temp
                  );
                },
              }}
            />
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default TableWelfareIncome;
