import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import Bottom2Function from "../../global/Bottom2Function";
import Notifications from "../../global/Notifications";

function AircraftDetailsAdd(props) {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("DataUser"));
  const [error, setError] = useState({
    al_id: false,
    ac_name: false,
    ac_callsign: false,
    at_id: false,
    ac_weight: false,
    ac_remark: false,
  });
  const [form, setState] = useState({
    al_id: "0",
    ac_name: "",
    ac_callsign: "",
    at_id: "",
    ac_weight: "",
    ac_remark: "",
    uid: user.u_id,
  });
  const [airline, setAirlineOption] = useState([
    <option key="a" value="0"></option>,
  ]);
  const [actype, setAircraftTypeOption] = useState([
    <option key="at" value="0"></option>,
  ]);
  const updateField = (e) => {
    const fieldLists = [
      "al_id",
      "ac_name",
      "ac_callsign",
      "ac_weight",
      "at_id",
      "ac_remark",
    ];
    if (fieldLists.includes(e.target.name)) {
      setError({ ...error, [e.target.name]: false });
    }
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const insertData = () => {
    props.handlerIsLoading(true);
    if (
      // form.ac_name &&
      form.ac_callsign &&
      form.ac_weight &&
      form.al_id !== "0" &&
      form.at_id !== "0"
      // form.ac_remark
    ) {
      Notifications.NotiAsync(
        axios.post(`${process.env.REACT_APP_API_URL}/v1/aircrafts`, {
          form,
        }),
        (resp) => {
          history.push("/aircraft");
          props.handlerIsLoading(false);
          Notifications.NotiSuccess();
        },
        (err) => {
          props.handlerIsLoading(false);
          Notifications.NotiError({ msg: err.response.data.msg });
        }
      );
    } else {
      setError({
        // ac_remark: form.ac_remark === "",
        ac_name: form.ac_name === "",
        ac_callsign: form.ac_callsign === "",
        ac_weight: form.ac_weight === "",
        al_id: form.al_id === "0",
        at_id: form.at_id === "0" || form.at_id === "",
      });
      props.handlerIsLoading(false);
      Notifications.NotiWarning({ msg: "กรุณากรอกข้อมูลให้ครบถ้วน!" });
    }
  };
  const getData = async () => {
    props.handlerIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/airlines`;
    await axios.get(apiUrl).then((res) => {
      res.data.forEach((item, i) => {
        setAirlineOption((airline) => [
          ...airline,
          <option key={"a" + i} value={item.al_id}>
            {item.al_name}
          </option>,
        ]);
      });
    });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/aircrafts/type`)
      .then((res) => {
        res.data.forEach((item, i) => {
          setAircraftTypeOption((actype) => [
            ...actype,
            <option key={"at" + i} value={item.at_id}>
              {item.at_name}
            </option>,
          ]);
        });
      });
    props.handlerIsLoading(false);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="box-S4 mgBottom">
        <div className="bPageName flex-between">
          <div>เพิ่มอากาศยาน</div>
        </div>
      </div>
      <div className="box-S4 flex-between mgBottom">
        <div className="bContentMain">
          <div className="bNameContent">รายละเอียดอากาศยาน</div>
          <div className="bContent">
            <div className="bRow flex-between">
              <div className="box-S2">
                <FormControl fullWidth error={error.al_id}>
                  <InputLabel>สายการบิน</InputLabel>
                  <NativeSelect
                    name="al_id"
                    value={form.al_id ? form.al_id : ""}
                    onChange={updateField}
                    native="true"
                  >
                    {airline}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
              <div className="box-S2">
                {/*<TextField
                  error={error.ac_name}
                  fullWidth
                  label="ชื่ออากาศยาน"
                  value={form.ac_name}
                  name="ac_name"
                  onChange={updateField}
                />*/}
                <FormControl fullWidth error={error.at_id}>
                  <InputLabel>แบบ</InputLabel>
                  <NativeSelect
                    name="at_id"
                    value={form.at_id ? form.at_id : ""}
                    onChange={updateField}
                    native="true"
                  >
                    {actype}
                  </NativeSelect>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S2">
                <TextField
                  error={error.ac_callsign}
                  fullWidth
                  label="เลขสัญชาติและทะเบียน"
                  value={form.ac_callsign}
                  name="ac_callsign"
                  onChange={updateField}
                />
              </div>
              <div className="box-S2">
                <TextField
                  error={error.ac_weight}
                  fullWidth
                  label="Gross Weight(Kg.)"
                  value={form.ac_weight}
                  type="number"
                  name="ac_weight"
                  onChange={updateField}
                />
              </div>
            </div>
            <div className="bRow flex-between">
              <div className="box-S4">
                <TextField
                  error={error.ac_remark}
                  fullWidth
                  label="หมายเหตุ"
                  value={form.ac_remark}
                  name="ac_remark"
                  onChange={updateField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom2Function back="/aircraft" insertData={insertData} />
    </div>
  );
}

export default AircraftDetailsAdd;
